import { React, useCallback, useEffect, useRef, useState } from "react";
import MainHeader from "../../common/Header";
import MainFooter from "../../common/Footer";
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from "react-helmet";
import MCRedirectModal from "components/common/Modals/MCRedirectModal";
import { ReactComponent as ArrowDownIcon } from "images/icon-arrow-down.svg";
import { styled } from "@mui/material";
import TutorialPopup from "./TutorialPopup";

const tutorials = [
  {
    icon: "/images/tutorial/icons/rocket.png",
    title: "Getting Started",
    content: "An introduction to Ad Designer and its features",
    id: "",
  },
  {
    icon: "/images/tutorial/icons/color-picker.png",
    title: "Creating Designs",
    content: "How to create various designs using Ad Designer’s templates",
    id: "",
  },

  {
    icon: "/images/tutorial/icons/text.png",
    title: "Working with Text",
    content: "How to add text over designs and change its position",
    id: "",
  },
  {
    icon: "/images/tutorial/icons/shape.png",
    title: "Working with Shapes",
    content: "How to insert shapes and modify them",
    id: "",
  },
  {
    icon: "/images/tutorial/icons/image.png",
    title: "Creating Images",
    content: "How to add and modify images in your designs",
    id: "",
  },
  {
    icon: "/images/tutorial/icons/export.png",
    title: "Exporting and Duplicating Designs",
    content:
      "How to save and export designs, return to unfinished designs and repurpose existing designs for a new project",
    id: "",
  },
];

const Tutorial = () => {
  const [isOpen, setisOpen] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(0);
  useEffect(() => {
    document.body.classList.add("faq-page");
    return () => {
      document.body.classList.remove("faq-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Ad Designer - Tutorial</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen max-h-[100vh]">
          <MainHeader />

          <div className="flex flex-col grow">
            <div className="masthead mb-[30px]">
              <img
                src={"/images/tutorial/masthead_desktop.svg"}
                alt="masthead"
                className="object-cover w-[100%] hidden sm:block"
              ></img>
              <img
                src={"/images/tutorial/masthead_mobile.svg"}
                alt="masthead"
                className="object-cover w-[100%] sm:hidden"
              ></img>
            </div>

            <div className="relative mb-[50px] px-5">
              <div className="container mx-auto md:max-w-[1140px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-9 lg:gap-x-10 lg:gap-y-[100px] mb-[50px]">
                  {tutorials.map((tutorial, i) => (
                    <div className="flex gap-7" key={i}>
                      <div className="grow-0 shrink-0 w-[80px] md:w-[120px]">
                        <img src={tutorial.icon} alt="" />
                      </div>
                      <div>
                        <h4 className="text-[20px] md:text-[30px] font-normal text-[#111] mb-4">
                          {tutorial.title}
                        </h4>
                        <p className="text-[#555] mb-2 text-[18px] md:text-2xl font-light font-['Sofia_Pro_Light']">
                          {tutorial.content}
                        </p>
                        <div
                          className="text-[#7B22EC] underline underline-offset-4 flex items-center gap-2 text-[18px] md:text-2xl cursor-pointer"
                          onClick={() => {
                            setSelectedTutorial(i);
                            setisOpen(true);
                          }}
                        >
                          View tutorial
                          <svg
                            style={{
                              marginTop: "4px",
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.47998 11.98H19.47"
                              stroke="#7B22EC"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>

                            <path
                              d="M13.4829 5.98798L19.5199 12L13.4829 18.012"
                              stroke="#7B22EC"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <MainFooter />
        </div>
        <TutorialPopup
          onClose={() => setisOpen(false)}
          open={isOpen}
          selectedTutorial={selectedTutorial}
        />
        <MCRedirectModal />
      </PageAnimation>
    </>
  );
};

export default Tutorial;
