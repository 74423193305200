import { useState } from "react";

const usePrompt = () => {
  const [param, setParam] = useState({
    modalStatus: false,
    loading: false,
    value: "",
    onSubmit: null,
  });

  const triggerPrompt = (param) => {
    setParam({
      ...param,
      ...param,
      modalStatus: true,
    });
  };

  const closeModal = () => setParam({ ...param, modalStatus: false });

  return {
    closeModal,
    triggerPrompt,
    ...param,
  };
};

export default usePrompt;
