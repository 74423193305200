import {
  StyledAssetGalleryOuter,
  StyledTab,
  StyledTabs,
} from "./AssetGallery.styled";
import ImageTabContent from "./ImageTabContent";
import UserTabContent from "./UserTabContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: "14px 18px" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

export default function AssetGallery({
  onThumbClick,
  uploaded,
  activeTab,
  setActiveTab,
  userNewUploadImages,
  skeletonAmount,
  ...props
}) {
  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <StyledAssetGalleryOuter>
      <StyledTabs value={activeTab} onChange={handleTabChange}>
        <StyledTab label="Library" />
        <StyledTab label="My Gallery" />
      </StyledTabs>
      <TabPanel value={activeTab} index={0}>
        <ImageTabContent onThumbClick={onThumbClick} />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <UserTabContent onThumbClick={onThumbClick} userNewUploadImages={userNewUploadImages} skeletonAmount={skeletonAmount} />
      </TabPanel>
    </StyledAssetGalleryOuter>
  );
}
