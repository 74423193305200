/** External Dependencies */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { RegularPolygon } from 'react-konva';

/** Internal Dependencies */
import nodesCommonPropTypes from '../nodesCommonPropTypes';
import Konva from "konva";

const PolygonNode = ({
  id,
  name,
  fill,
  x,
  y,
  radius,
  scaleX,
  scaleY,
  offsetX,
  offsetY,
  rotation,
  sides,
  annotationEvents,
  stroke,
  strokeWidth,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor,
  shadowOpacity,
  opacity,
  dash,
  dashEnabled=false,
  blurRadius,
  ...otherProps
}) => {

  const polygonRef = React.useRef();
  useEffect(() => {
    // polygonRef?.current?.cache({ offset: 100 });
  })

  return (
    <RegularPolygon
      id={id}
      name={name}
      rotation={rotation}
      scaleX={scaleX}
      scaleY={scaleY}
      stroke={stroke}
      strokeWidth={strokeWidth}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      shadowBlur={shadowBlur}
      shadowColor={shadowColor}
      shadowOpacity={shadowOpacity}
      fill={fill}
      x={x}
      y={y}
      radius={radius}
      offsetX={-radius}
      offsetY={-radius}
      sides={sides}
      opacity={opacity}
      dash={dash}
      dashEnabled= {dashEnabled}
      filters= {[ Konva.Filters.Blur]}
      blurRadius={blurRadius}
      ref={polygonRef}
      {...annotationEvents}
      {...otherProps}
    />
  )
};

PolygonNode.defaultProps = {
  ...nodesCommonPropTypes.defaults,
  fill: '#000',
  sides: 3,
};

PolygonNode.propTypes = {
  ...nodesCommonPropTypes.definitions,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  annotationEvents: PropTypes.instanceOf(Object).isRequired,
  radius: PropTypes.number.isRequired,
  fill: PropTypes.string,
  sides: PropTypes.number,
};

export default PolygonNode;
