/** External Dependencies */
import styled from 'styled-components';
import Label from '@scaleflex/ui/core/label';

const StyledOptions = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const StyledOptionPopupContent = styled.div`
  background: ${({ theme }) => theme.palette['bg-secondary']};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.palette['light-shadow']};
  border-radius: 2px;
  overflow: visible;

  * {
    font-family: 'Roboto', sans-serif;
  }
`;

const StyledSpacedOptionFields = styled.div`
  /* padding: 8px 12px; */
  //margin-top: 1.2rem;
  margin-top: 0;
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledSpacedOptionFieldsEffect = styled.div`
  /* padding: 8px 12px; */
  //margin-top: 1.2rem;
  margin-top: 0;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeadline = styled(Label)`
  font-weight: 500;
  margin-bottom: 12px;
`;

const StyledTwoColumnsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTwoColumnsDirectionContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledColumn = styled.div`
  //&:not(:first-child) {
  //  margin-left: 12px;
  //}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const StyledIconWrapper = styled.div(
  ({ theme, addThinBorder, noMargin, secondaryIconColor }) => `
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
    display: inline-block;

    svg {
      vertical-align: middle;
      margin: 0 auto;
    }

    ${
      addThinBorder
        ? `border: 0.5px solid ${theme.palette['borders-secondary']}`
        : ''
    };
    color: ${secondaryIconColor ? '#959DA8' : ''};

    &[aria-selected='true'] {
      background: #EAEAEA;

      * {
        color: ${theme.palette['accent-primary-active']};
      }
    }

    :hover {
      background: ${theme.palette['bg-primary-active']};
    }
  `,
);

const StyledLabel = styled.div`
  color: #777;
  font-size: 12px;
  font-family: 'MarkForMCNrw';
  /* padding-left: 5px; */
  margin-bottom: 3px;
`;

const StyledSubWrapper = styled.div`
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  &:last-child {
    border-bottom-width: 0;
  }
  &:first-child {
    padding-top: 0;
  }
`;

const StyledSubWrapperInline = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 0;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  .input-color-bg{
    position: absolute;
    right: 16px;
  }
  /* div:last-child{
    position: absolute;
  } */
`;

export {
  StyledHeadline,
  StyledTwoColumnsContainer,
  StyledTwoColumnsDirectionContainer,
  StyledColumn,
  StyledIconWrapper,
  StyledSpacedOptionFields,
  StyledSpacedOptionFieldsEffect,
  StyledOptions,
  StyledOptionPopupContent,
  StyledSubWrapper,
  StyledSubWrapperInline,
  StyledLabel,
  FlexRow,
};
