/** External Dependencies */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CheckedIcon } from "images/icon-checkbox-check.svg";
import { ReactComponent as UncheckedIcon } from "images/icon-checkbox-uncheck.svg";

/** Internal Dependencies */
import restrictNumber from "../../../utils/restrictNumber";
import {
  StyledLabel,
  StyledSpacedOptionFields,
} from "./AnnotationOptions.styled";
import { ReactComponent as StrokeIcon } from "../../../../../../../../assets/svg/stroke.svg";
import StrokeFill from "./StrokeFill";
import { Checkbox, FormControlLabel } from "@mui/material";
import BorderTypeDropdown from "./BorderTypeDropdown";
import InputStandard from "../InputStandard";
import RectCornerField from "../../tools/Rect/RectCornerField";
import { useStore } from "../../../hooks";
import PolygonSidesField from "./PolygonSides";

const MIN_PERCENTANGE = 0;
const MAX_PERCENTANGE = 100;

const BorderFields = ({
  annotation,
  updateAnnotation,
  disabledRadiusCorner,
}) => {
  const { toolId } = useStore();
  const [fields, setFields] = useState({
    width: 0,
  });

  const [borderEnabled, setBorderEnabled] = useState(
    Boolean(annotation.strokeEnabled) ||
      toolId === "Line" ||
      toolId === "Arrow" ||
      Boolean(annotation.strokeWidth)
  );
  // const [dashArr, setDashArr] = useState([])
  const { strokeWidth } = annotation;
  // console.log(annotation);

  const changeStrokeWidth = (e) => {
    setFields({ ...fields, width: parseFloat(e.target.value) });
    updateAnnotation({
      strokeWidth: restrictNumber(
        e.target.value,
        MIN_PERCENTANGE,
        MAX_PERCENTANGE
      ),
    });
  };

  useEffect(() => {
    if (borderEnabled) {
      if (fields.width) {
        updateAnnotation({
          strokeWidth: fields.width,
          strokeEnabled: true
        });
      } else {
        if (!annotation.strokeWidth) {
          setFields({ ...fields, width: 1 });
          updateAnnotation({
            strokeWidth: annotation.strokeWidth || 1,
            stroke: annotation.stroke || "#000",
            // dashEnabled: annotation.dashEnabled || true,
            // dash: [20],
            strokeEnabled: true,
          });
        }
      }
    } else {
      updateAnnotation({
        strokeWidth: 0,
        // dashEnabled: annotation.dashEnabled || true,
        // dash: [20],
        strokeEnabled: false,
      });
    }
  }, [borderEnabled]);

  const [dash, gap] = annotation.dash || [];
  const handleChange = (e) => {
    const { name, value } = e.target;

    let keyName = name;
    let dashArr = annotation.dash || [];
    if (name === "dash" || name === "gap") {
      keyName = "dash";
      if (name === "dash") {
        dashArr = [restrictNumber(value, MIN_PERCENTANGE), gap];
      } else {
        dashArr = [dash, restrictNumber(value, MIN_PERCENTANGE)];
      }
    }

    updateAnnotation({
      [keyName]: dashArr.filter((item) => item),
    });
  };

  // console.log(annotation);

  return (
    <div>
      <FormControlLabel
        sx={{
          margin: 0,
          gap: "8px",

          "&.Mui-disabled": {
            cursor: "not-allowed",

            "& .MuiCheckbox-root": {
              opacity: 0.3,
            },
          },
          "& .MuiFormControlLabel-label": {
            fontSize: 12,
            color: "#555",
            fontFamily: "Sofia Pro",
          },
          "& .MuiCheckbox-root": {
            padding: 0,
          },
        }}
        disabled={toolId === "Line" || toolId === "Arrow"}
        control={
          <Checkbox
            onChange={() => setBorderEnabled(!borderEnabled)}
            value={borderEnabled}
            checkedIcon={
              <CheckedIcon
                style={{ width: "14px", height: "14px", borderRadius: "2px" }}
              />
            }
            icon={
              <UncheckedIcon
                style={{ width: "14px", height: "14px", borderRadius: "2px" }}
              />
            }
            checked={borderEnabled}
          />
        }
        label={"Border"}
      />

      {borderEnabled ? (
        <>
          <div className="flex justify-between gap-12">
            <StrokeFill
              updateAnnotation={updateAnnotation}
              annotation={annotation}
            />

            <div className="flex gap-2 flex-1">
              {toolId === "Polygon" ? (
                <PolygonSidesField
                  annotation={annotation}
                  updateAnnotation={updateAnnotation}
                  disabledRadiusCorner={disabledRadiusCorner}
                />
              ) : (
                <RectCornerField
                  annotation={annotation}
                  updateAnnotation={updateAnnotation}
                  disabledRadiusCorner={disabledRadiusCorner}
                />
              )}
              <StyledSpacedOptionFields className="flex-1">
                <StrokeIcon className="basis-4 shrink-0 grow" />
                <InputStandard
                  // sx={{ width: "70px"}}
                  type="number"
                  onChange={changeStrokeWidth}
                  value={strokeWidth}
                />
              </StyledSpacedOptionFields>
            </div>
          </div>
          <div className="flex justify-between gap-2">
            <div>
              <StyledLabel>Type</StyledLabel>
              <BorderTypeDropdown
                updateAnnotation={updateAnnotation}
                annotation={annotation}
              />
            </div>

            <div>
              <StyledLabel>Dash</StyledLabel>
              <InputStandard
                disabled={!annotation.dashEnabled}
                value={dash || 0}
                type="number"
                name="dash"
                min={0}
                onChange={handleChange}
              />
            </div>
            <div>
              <StyledLabel>Gap</StyledLabel>
              <InputStandard
                disabled={!annotation.dashEnabled}
                value={gap || 0}
                type="number"
                name="gap"
                min={0}
                onChange={handleChange}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

BorderFields.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
};

export default BorderFields;
