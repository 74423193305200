import { useEffect, useRef, useState } from 'react';
import Accordion from './react-filerobot-image-editor/src/components/common/Accordion';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMainContext from '../../../context/Main';
import useAuth from '../../../utils/Auth';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlankCanvas from '../../../assets/images/blank-canvas.png';
import { useStore } from './react-filerobot-image-editor/src/hooks';
import LeavePageConfirmation from 'components/common/Modals/LeavePageConfirmation';
import {TrackGoogleAnalyticsEvent} from "../../../utils/GA4";
import { StyledSpinner } from './react-filerobot-image-editor/src/components/common/Spinner/Spinner.styled';

const TemplateThumbnailList = () => {
    // const random = Math.floor(Date.now() / 1000)
    const source = useRef(null)
    const [page, setPage] = useState(0)
    const [loadMore, setLoadMore] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [showLeaveModal, setShowLeaveModal] = useState({
        status: false,
        reason: null
    })
    const [pendingImported, setPendingImported] = useState(null)
    const [templates, setTemplates] = useState([])
    const { haveNotSavedChanges } = useStore()
    const {
        project,
        openTemplate,
        setProject,
        reloadProjectIndex,
    } = useMainContext()
    const {user} = useAuth()

    const fetchData = async (pageNo) => {
        try {
            const response = await axios.get(`/template/?page=${ pageNo }&sortBy=-1&records=10&preset=${ project.presetDimension_id._id }`);
            if (response?.data?.data && response?.data?.success) {
                setTemplates(currentTemplates => {
                    return [...currentTemplates, ...response.data.data?.docs.map(template => {
                        return {
                            id: template._id,
                            title: template.name,
                            thumbnail: template.thumbnail_url,
                            partner: template?.partner,
                            templateName: template.template_group_id.name,
                            group: template.presetDimension_id.descriptionKey,
                            widthHeight: `${template.presetDimension_id.width}x${template.presetDimension_id.height}`
                        }
                    })]
                })
                const {hasNextPage, page, totalPages, totalDocs, limit} = response.data.data;
                setLoadMore(hasNextPage)
                setTotalRecords(page >= totalPages ? totalDocs : limit * page)
            } else {
                // console.error(response);
                setLoadMore(false)
                setTotalRecords(0)
            }
        } catch (error) {
            // console.error(error);
            setLoadMore(false)
            setTotalRecords(0)
        }
    }

    useEffect(() => {
        setPage(1)
        setLoadMore(false)
        setTotalRecords(0)
        setTemplates([])
        fetchData(1)
    }, [project?.presetDimension_id?._id])

    useEffect(() => {
        if (page > 1) {
            fetchData(page)
        }
    }, [page])

    // const confirmImportTemplate = (item) => {
    //     if (haveNotSavedChanges) {
    //         setPendingImported(item)
    //         return setShowLeaveModal(true)
    //     }

    //     importTemplate(item)
    // }

    const confirmLeave = ({ reason, pendingFn, immediateFn }) => {
        if (haveNotSavedChanges) {
            if (pendingFn) {
                pendingFn()
            }
            return setShowLeaveModal({ status: true, reason})
        }

        if (immediateFn) {
            immediateFn()
        }
    }

    const importTemplate = async (item, e) => {
        try {
            setTemplates(templates.map(project => {
                if (project?.id === item?.id) {
                    return {...project, ...{loading: true}}
                }
                return {...project, ...{loading: null}}
            }));
            const defaultTemplateName = user?.role === 'User' && project?.name ? project.name : null;
            await openTemplate(item?.id, defaultTemplateName, {signal: source.current.signal});
            setTemplates(templates.map(project => {
                return {...project, ...{loading: null}}
            }));
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        source.current = new AbortController();
        return () => {
            if (source.current) {
                source.current.abort()
            }
        }
    }, []);

    const newProject = async () => {
        const {name, presetDimension_id} = JSON.parse(localStorage.getItem('project'));
        localStorage.setItem('project', JSON.stringify({name, presetDimension_id}));
        setProject({name, presetDimension_id});
        reloadProjectIndex();
        // window.location.reload();
    }

    return (
        <div>
            <Accordion title='Step 2: Pick a template'>
                <div className="tour__templates">
                    <InfiniteScroll
                        dataLength={totalRecords}
                        next={() => setPage(page + 1)}
                        hasMore={loadMore}
                        loader={<h4 className="mt-[5px] text-center">Loading...</h4>}
                        height={512}
                        endMessage={totalRecords <= 0 ? <p>No Template Available.</p> : null}
                        scrollableTarget="LeftPanelScrollableDiv"
                    >
                        <div className='flex justify-start items-start flex-wrap gap-[4px]'>
                            <div className="relative" key={`template-list-blank-canvas`}>
                                <div
                                    className={`w-[124px] h-[124px] overflow-hidden rounded-[10px] bg-no-repeat bg-top bg-cover cursor-pointer hover:border-[#7B22EC] border-[#e0e0e0] border border-solid box-border`}
                                    style={{ backgroundImage: `url(${BlankCanvas})` }}
                                    onClick={() => {
                                        TrackGoogleAnalyticsEvent('Inside Editor', 'Use Template', 'Blank Template')
                                        confirmLeave({
                                            reason: 'new-template',
                                            immediateFn: newProject
                                        })
                                    }}>
                                </div>
                            </div>
                            {templates && templates.map(template =>
                                {
                                    return <div className="relative" key={`template-list-${template.id}`}>
                                        <div
                                            className={`relative w-[124px] h-[124px] overflow-hidden rounded-[10px] bg-no-repeat bg-top bg-cover cursor-pointer hover:border-[#7B22EC] border-[#e0e0e0] border border-solid box-border`} style={{ backgroundImage: `url(${template.thumbnail})` }}
                                            onClick={() => {
                                                TrackGoogleAnalyticsEvent('Inside Editor', 'Use Template', `${template.templateName} : ${template.group} : ${template.widthHeight} `);
                                                confirmLeave({
                                                    reason: 'switch-template',
                                                    immediateFn: () => importTemplate(template),
                                                    pendingFn: () => setPendingImported(template),
                                                });
                                            } }
                                        >
                                            {template?.partner ? <div className='absolute bottom-0 w-full text-center'><div className=' inline-flex px-4 h-[18px] text-center text-white bg-[#7B22EC] rounded-t-xl items-center justify-center text-[9px] font-["Sofia_Pro"]'>Inspired by {template?.partner}</div></div> : ''}
                                        </div>
                                        {template?.loading && (
                                            <div className="absolute inset-0 w-full h-full rounded-[10px] backdrop-blur-[2px]">
                                                <div className="flex items-center justify-center h-full">
                                                    <StyledSpinner size="24px" />
                                                </div>
                                            </div>
                                        )}
                                    </div>;
                                }
                            )}
                        </div>
                    </InfiniteScroll>
                </div>
            </Accordion>
            <LeavePageConfirmation
                showModal={showLeaveModal.status}
                closeModal={() => setShowLeaveModal({...showLeaveModal, status: false})}
                onConfirm={() => {
                    if (showLeaveModal.reason === 'switch-template') {
                        importTemplate(pendingImported)
                    } else {
                        newProject()
                    }
                    setShowLeaveModal({...showLeaveModal, status: false})
                }}
            />
        </div>
    )
}

export default TemplateThumbnailList
