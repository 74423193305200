/** External Dependencies */
import React from "react";
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const AMSelect = styled((props) => (
  <Select
    {...props}
  />
))( () => ({
  color: '#555',
  width: '119px',
  borderRadius: '4px',
  background: 'rgba(244, 244, 244, 0.6)',
  border: '1px solid #ddd',
  fontSize: '12px',
  position: 'relative',
  '&::before': {
    display: 'none',
  },
}));

const  StrokeItem = styled.div`
  font-size: 12px;
  font-family: 'MarkForMCNrw';
`;

export {
  AMSelect,
  StrokeItem
};