import { useEffect, useState } from 'react';
// import { Avatar } from "@nextui-org/react";
import useAuth from '../../../utils/Auth';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import Button from '../../common/Button';
import PasswordInput from '../../common/PasswordInput';
import Input from '../../common/Input';
import { ReactComponent as KeyIcon } from '../../../assets/svg/icon-key.svg';
import { ReactComponent as UserIcon } from '../../../assets/svg/icon-user.svg';
import ImageOne from '../../../assets/images/profile/profile-img-1.png';
import ImageTwo from '../../../assets/images/profile/profile-img-2.png';
import ImageThree from '../../../assets/images/profile/profile-img-3.png';
import ImageFour from '../../../assets/images/profile/profile-img-4.png';
import ImageFive from '../../../assets/images/profile/profile-img-5.png';
import ImageSix from '../../../assets/images/profile/profile-img-6.png';
import ImageSeven from '../../../assets/images/profile/profile-img-7.png';
import ImageEight from '../../../assets/images/profile/profile-img-8.png';
import ImageNine from '../../../assets/images/profile/profile-img-9.png';
import ImageTen from '../../../assets/images/profile/profile-img-10.png';
import ImageEleven from '../../../assets/images/profile/profile-img-11.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useMainContext from '../../../context/Main';
import { toast } from 'react-toastify';
import PageAnimation from 'components/common/PageAnimation';
import { Helmet } from 'react-helmet';

const Profile = () => {
  const navigate = useNavigate();
  const { user, updateUser, logout } = useAuth();
  const [showHide, setShowHide] = useState(false);
  const { loader, setLoader } = useMainContext();

  useEffect(() => {
    setLoader(false)
  }, [])

  const handleLogout = () => {
    setLoader(false);
    logout();
    navigate('/login', { replace: true });
  }

  const profile = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.first_name ?? '',
      lastName: user?.last_name ?? '',
      email: user?.email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(16, 'First name should not exceed 16 characters').matches(/^[aA-zZ]+$/, 'Invalid first name'),
      lastName: Yup.string().max(16, 'Last name should not exceed 16 characters').matches(/^[aA-zZ]+$/, 'Invalid last name'),
      // email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      let { firstName, lastName } = values;
      try {
        const formData = new FormData();
        formData.append("fname", firstName);
        formData.append("lname", lastName);
        // formData.append("email", email);
        const response = await axios.post(`user/profile`, formData, {
          headers: {
            Accept: "application/json",
          },
        });
        if (response?.data?.success && response?.data?.data) {
          setLoader(false);
          const loggedInUser = localStorage.getItem('user');
          if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            if (foundUser) {
              localStorage.setItem('user', JSON.stringify({ ...foundUser, ...response.data.data }));
              updateUser(response.data.data);
            }
          } else {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            updateUser(response.data.data);
          }
          toast.success('Profile Updated.');
        } else {
          setLoader(false);
          toast.error(JSON.stringify(response));
        }
      } catch (error) {
        setLoader(false);
        toast.error(JSON.stringify(error));
      }
    }
  })

  const password = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().min(8, 'Password should be 8-16 characters').max(16, 'Password should be 8-16 characters').required('Current Password is required'),
      newPassword: Yup.string().required('New Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
      confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      let { currentPassword, newPassword, confirmPassword } = values;
      try {
        const formData = new FormData();
        formData.append("oldPassword", currentPassword);
        formData.append("newPassword", newPassword);
        formData.append("confirmNewPassword", confirmPassword);
        const response = await axios.post(`user/reset-password`, formData, {
          headers: {
            Accept: "application/json",
          },
        });
        if (response?.data?.success) {
          alert('Password changed successfully. Please login with the new password.');
          handleLogout();
        } else {
          setLoader(false);
          console.error(response);
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Ad Designer - Profile</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen max-h-[100vh]">
          <MainHeader />
          <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/profile/profile-bg.png")] grow flex flex-col justify-center relative'>
            <img src={ImageOne} alt="" className="absolute hidden md:block w-[65px] md:top-[9%]"></img>
            <img src={ImageTwo} alt="" className="absolute hidden md:block w-[15px] top-[10%] left-[8%]"></img>
            <img src={ImageThree} alt="" className="absolute hidden md:block w-[90px] top-[38%] left-[4%]"></img>
            <img src={ImageFour} alt="" className="absolute hidden md:block w-[15px] bottom-[35%] left-[4%]"></img>
            <img src={ImageFive} alt="" className="absolute hidden md:block w-[80px] bottom-[14%] left-[2%]"></img>
            <img src={ImageSix} alt="" className="absolute w-[18px] md:bottom-[15%] md:left-[9%] bottom-[2%] left-[22%]"></img>
            <img src={ImageSeven} alt="" className="absolute hidden md:block w-[88px] top-[19%] right-[3%]"></img>
            <img src={ImageEight} alt="" className="absolute hidden md:block w-[75px] top-[51%] right-[6%]"></img>
            <img src={ImageNine} alt="" className="absolute hidden md:block w-[75px] bottom-[18%] right-0"></img>
            <img src={ImageTen} alt="" className="absolute md:hidden block w-[100px] bottom-0 right-[8%]"></img>
            <img src={ImageEleven} alt="" className="absolute md:hidden block w-[22px] top-[16%] right-0"></img>
            <div className="container mx-auto md:w-9/12 w-10/12 relative">
              <div className="grid grid-cols-12 border border-[#555555] rounded-[20px] my-[60px] overflow-hidden">
                <div className="col-span-12 md:col-span-3 md:border-r border-[#555555] bg-[#E7E8FE66] md:rounded-tl-[20px] md:rounded-bl-[20px] md:rounded-tr-none rounded-tl-[20px] rounded-tr-[20px]">
                  <div className="border-b border-[#555555] md:border-none flex md:block">
                    <p className="text-sm font-['Sofia_Pro'] text-[#555555] tracking-[.12em] md:pt-[50px] md:pl-[40px] md:pb-[25px] hidden md:block">ACCOUNT</p>
                    <div className="w-[50%] md:w-[auto]">
                      <div className={`cursor-pointer h-[52px] profile-tab flex items-center md:pl-[40px] ${showHide ? '' : 'active'}`} onClick={() => setShowHide(false)}>
                        <div className="flex mx-auto md:mx-0">
                          <UserIcon className="w-[20px] h-[20px]" />
                          <p className="text-[#111111] font-['Sofia_Pro'] text-base ml-2.5">Profile</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[50%] md:w-[auto]">
                      <div className={`cursor-pointer h-[52px] profile-tab flex items-center md:pl-[40px] ${showHide ? 'active' : ''}`} onClick={() => setShowHide(true)}>
                        <div className="flex mx-auto md:mx-0">
                          <KeyIcon className="w-[20px] h-[20px]" />
                          <p className="text-[#111111] font-['Sofia_Pro'] text-base ml-2.5">Password</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-9 bg-[#FFFFFFB2] md:rounded-tr-[20px] md:rounded-br-[20px] rounded-bl-[20px] rounded-br-[20px] md:min-h-[800px] min-h-[535px]" >
                  <div className={`md:pt-[50px] pt-[28px] relative h-full ${showHide ? 'hidden' : 'block'}`}>
                    <form onSubmit={profile.handleSubmit}>
                      <div className="md:px-[80px] px-[28px]">
                        <p className="text-[26px] text-[#111111] font-['Sofia_Pro'] md:mb-4 mb-1">Profile</p>
                        <hr className="border-[##DBDBDB]"></hr>
                        {/* <div className="my-[25px]">
                          <Avatar
                            bordered
                            color="secondary"
                            size="xl"
                            src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                          />
                        </div> */}
                        <div className="grid grid-cols-12 mt-[25px]">
                          <div className="col-span-12 md:col-span-6 md:mr-[10px]">
                            <Input type="text" id="firstName" name="firstName" label="First Name" placeholder="" value={profile.values.firstName} onChange={profile.handleChange} onBlur={profile.handleBlur} {...(profile.touched.firstName && profile.errors.firstName && { error: true, errorMsg: profile.errors.firstName })}> </Input>
                          </div>
                          <div className="col-span-12 md:col-span-6 md:ml-[10px]">
                            <Input type="text" id="lastName" name="lastName" label="Last Name" placeholder="" value={profile.values.lastName} onChange={profile.handleChange} onBlur={profile.handleBlur} {...(profile.touched.lastName && profile.errors.lastName && { error: true, errorMsg: profile.errors.lastName })}> </Input>
                          </div>
                        </div>
                        <div className="col-span-12">
                          {/* <Input type="text" id="email" name="email" label="Email" placeholder="" value={profile.values.email} onChange={profile.handleChange} onBlur={profile.handleBlur} {...(profile.touched.email && profile.errors.email && { error: true, errorMsg: profile.errors.email })}> </Input> */}
                          <div className="mb-[20px]">
                            <label className="font-['Sofia_Pro'] block mb-2 text-sm text-[#555555] mb-[8px]">Email</label>
                            <input readOnly placeholder={profile.values.email} className={`font-['Sofia_Pro'] text-sm text-[#333333] rounded-lg focus:ring-blue-500 border block w-full p-2.5 bg-[#eeeeee]`} />
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 w-full">
                        <hr className="border-t border-[#555555]" />
                        <div className="flex md:justify-end justify-center md:mt-[18px] md:mr-[30px] md:mb-[23px] text-center mt-[10px] mb-[18px]">
                          <Button color="white" text="Update Profile" className="w-[165px] whitespace-nowrap" type="submit" disabled={loader}></Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={`md:pt-[50px] pt-[28px] relative h-full ${showHide ? 'block' : 'hidden'}`}>
                    <form onSubmit={password.handleSubmit}>
                      <div className="md:px-[80px] px-[28px]">
                        <p className="text-[26px] text-[#111111] font-['Sofia_Pro'] md:mb-4 mb-1">Password</p>
                        <hr className="border-[##DBDBDB] mb-[25px]"></hr>
                        <div className="grid grid-cols-12">
                          <div className="col-span-12">
                            <PasswordInput id="currentPassword" name="currentPassword" label="Current Password" value={password.values.currentPassword} onChange={password.handleChange} onBlur={password.handleBlur} {...(password.touched.currentPassword && password.errors.currentPassword && { error: true, errorMsg: password.errors.currentPassword })}> </PasswordInput>
                          </div>
                          <div className="col-span-12">
                            <PasswordInput id="newPassword" name="newPassword" label="New Password" value={password.values.newPassword} onChange={password.handleChange} onBlur={password.handleBlur} {...(password.touched.newPassword && password.errors.newPassword && { error: true, errorMsg: password.errors.newPassword })}> </PasswordInput>
                          </div>
                          <div className="col-span-12">
                            <PasswordInput id="confirmPassword" name="confirmPassword" label="Confirm Password" value={password.values.confirmPassword} onChange={password.handleChange} onBlur={password.handleBlur} {...(password.touched.confirmPassword && password.errors.confirmPassword && { error: true, errorMsg: password.errors.confirmPassword })}> </PasswordInput>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 w-full">
                        <hr className="border-t border-[#555555]" />
                        <div className="flex md:justify-end justify-center md:mt-[18px] md:mr-[30px] md:mb-[23px] text-center mt-[10px] mb-[18px]">
                          <Button color="white" text="Change Password" className="w-[165px] whitespace-nowrap" type="submit" disabled={loader}></Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MainFooter />
        </div>
      </PageAnimation>
    </>
  )
}

export default Profile
