import React, {useEffect, useState} from 'react'
import { Modal, Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MCRedirectModal = (props) => {
    
    const [ShowStatus, setShowStatus] = useState(false)

    const getRedirected = () => {
        if (document.referrer !== 'https://www.mastercard.us/' && process.env.REACT_APP_REDIRECT_CHECK !== 'false') {
            setShowStatus(true)
            setTimeout(function() {
                window.location.replace(process.env.REACT_APP_MC_REDIRECT_URL)
            },3000)
        } else {
            localStorage.setItem('MC_REDIRECT', true);
        }
    }
    
    useEffect(() => {
        const MCREDIRECT = localStorage.getItem("MC_REDIRECT");
        if (!MCREDIRECT) {
            getRedirected()
        }
    }, [])

    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } }
            }}
            open={ShowStatus}
            onClose={() => props.closeModal()}
        >
            <Box style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "none",
                outline: "none",
                boxShadow: 24,
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.8)",
                overflow: "hidden",
            }}>
                <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center px-[30px] font-['Sofia_Pro'] text-[15px] h-[40px]">Redirecting...</div>
                <div className='container p-[30px] bg-[#F9F9F9]'>
                    <div className="text-center">
                        <FontAwesomeIcon className="text-[50px] mb-[30px] text-center" icon="fa-solid fa-spinner" spinPulse />
                        <p className="font-['Sofia_Pro'] text-[14px] leading-[18x] text-[#555555]">You are being redirected. Please access Ad Designer via the Marketing Hub in Mastercard Digital Doors.</p>
                    </div>
                   
                </div>
                {/* <div className='px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center'>
                    <button className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center" onClick={(e) => props.closeModal(e)}>Cancel</button>
                    <button className="font-['Sofia_Pro'] text-[14px] text-white bg-[#DF1E1E] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center disabled:bg-[#BBBBBB] disabled:opacity-75" onClick={() => {props.onConfirm(); props.closeModal()}}>Proceed</button>
                </div> */}
            </Box>
        </Modal>
    )
}

export default MCRedirectModal