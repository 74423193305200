import { Box, Modal } from '@mui/material'

const ImportProjectModal = ({showModal, closeModal}) => {
    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } } 
            }}
            open={showModal}
            onClose={closeModal}
        >
            <Box
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 720,
                    bgcolor: "background.paper",
                    border: "none",
                    outline: "none",
                    boxShadow: 24,
                    borderRadius: "20px",
                    background: "rgba(255, 255, 255, 0.8)",
                    overflow: "hidden",
                }}
            >
                <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center font-['Sofia_Pro'] text-[15px] h-[40px]">Import Project</div>
                <div className='p-[30px] bg-[#F9F9F9]'>
                    <div className="px-[28px] py-[13px] bg-[#FFFFFF99] rounded-[12px]">
                        <table className="table-auto w-full">
                            <thead className="font-['Sofia_Pro'] text-[10px] text-[#000000] tracking-[.16em] uppercase whitespace-nowrap">
                                <tr>
                                    <th>PROJECT NAME</th>
                                    <th>TYPE</th>
                                    <th>DIMENSION</th>
                                    <th>DATE ADDED</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="font-['Sofia_Pro'] text-[13px] text-[#555555]">
                                <tr>
                                    <td className='flex justify-start items-center gap-[8px]'>
                                        <img src="https://via.placeholder.com/28" alt="" className='rounded-[4px]' />
                                        <span className='line-clamp-1'>The Sliding Mr. Bones (Next Stop, Pottersville)</span>
                                    </td>
                                    <td>Malcolm Lockyer</td>
                                    <td>1961</td>
                                    <td>78 Sep 2022</td>
                                    <td>
                                        <input type="radio" name="select" id="1" className='mx-[20px] hidden' />
                                        <label htmlFor="1">test</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='flex justify-start items-center gap-[8px]'>
                                        <img src="https://via.placeholder.com/28" alt="" className='rounded-[4px]' />
                                        <span className='line-clamp-1'>Witchy Woman</span>
                                    </td>
                                    <td>The Eagles</td>
                                    <td>1972</td>
                                    <td>1 Sep 2022</td>
                                    <td>
                                        <input type="radio" name="select" id="2" className='mx-[20px] hidden' />
                                        <label htmlFor="2">test</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='flex justify-start items-center gap-[8px]'>
                                        <img src="https://via.placeholder.com/28" alt="" className='rounded-[4px]' />
                                        <span className='line-clamp-1'>Shining Star</span>
                                    </td>
                                    <td>Earth, Wind, and Fire</td>
                                    <td>1975</td>
                                    <td>1 Sep 2022</td>
                                    <td>
                                        <input type="radio" name="select" id="3" className='mx-[20px] hidden' />
                                        <label htmlFor="3">test</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center'>
                    <button className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center" onClick={closeModal}>Cancel</button>
                    <button type="submit" className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center">Import</button>
                </div>
            </Box>
        </Modal>
    )
}

export default ImportProjectModal