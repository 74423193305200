import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useStore } from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import ShadowFields from "../../../common/AnnotationOptions/ShadowFields";
import { ReactComponent as CheckedIcon } from "images/icon-checkbox-check.svg";
import { ReactComponent as UncheckedIcon } from "images/icon-checkbox-uncheck.svg";
import BlurField from "../../../common/AnnotationOptions/BlurField";
import { TRANSFORMERS_LAYER_ID } from "../../../../utils/constants";

export default function Effect({ updateAnnotation, annotation, t }) {
  const { toolId, selectionsIds = [], designLayer } = useStore();
  const canvasStage = designLayer.getStage();
  const node = canvasStage.findOne(`#${selectionsIds}`);

  const [effects, setEffects] = useState([
    {
      label: "Drop Shadow",
      id: "dropShadow",
      status: false,
    },
    {
      label: "Blur",
      id: "blur",
      status: false,
    },
  ]);

  const handleChange = (e) => {
    setEffects(
      effects.map((effect) => {
        if (effect.id === e.target.name) {
          effect.status = e.target.checked;
        }
        if (e.target.name === "blur" && !e.target.checked) {
          updateAnnotation({
            blurRadius: 0,
          });
        }
        if (e.target.name === "dropShadow" && !e.target.checked) {
          updateAnnotation({
            shadowBlur: 0,
            shadowOffsetY: 0,
            shadowOffsetX: 0,
          });
        }
        return effect;
      })
    );
  };

  const checkShadowStatus =
    node?.attrs?.shadowBlur > 0 ||
    node?.attrs?.shadowOffsetX > 0 ||
    node?.attrs?.shadowOffsetY > 0
      ? true
      : false;
  const checkBlurStatus = node?.attrs?.blurRadius > 0 ? true : false;
  useEffect(() => {
    const newEffects = effects.map((effect) => {
      if (effect.id === "dropShadow") {
        return { ...effect, status: checkShadowStatus };
      } else if (effect.id === "blur") {
        return { ...effect, status: checkBlurStatus };
      } else {
        return effect;
      }
    });
    setEffects(newEffects);
  }, [selectionsIds]);

  return (
    <div>
      <div>
        {effects.map((effect, index) => (
          <div key={effect.id}>
            {index === 0 || toolId === "Image" ? (
              <FormControlLabel
                sx={{
                  margin: 0,
                  marginBottom: ".3rem",
                  gap: "8px",

                  "&.Mui-disabled": {
                    cursor: "not-allowed",

                    "& .MuiCheckbox-root": {
                      opacity: 0.3,
                    },
                  },
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                    color: "#555",
                    fontFamily: "Sofia Pro",
                  },
                  "& .MuiCheckbox-root": {
                    padding: 0,
                  },
                }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name={effect.id}
                    checkedIcon={
                      <CheckedIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "2px",
                        }}
                      />
                    }
                    icon={
                      <UncheckedIcon
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "2px",
                        }}
                      />
                    }
                    checked={effect.status}
                  />
                }
                label={effect.label}
              />
            ) : null}
            {effect.status && effect.id === "dropShadow" ? (
              <ShadowFields
                updateAnnotation={updateAnnotation}
                annotation={annotation}
                t={t}
              />
            ) : null}
            {effect.status && effect.id === "blur" ? (
              <BlurField
                updateAnnotation={updateAnnotation}
                annotation={annotation}
                t={t}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
