import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CheckedIcon } from "../../../images/icon-checkbox-check.svg";
import { ReactComponent as UncheckedIcon } from "../../../images/icon-checkbox-uncheck.svg";
import {
  BgCheckBoxItem,
  BgCheckBox,
  BgCustomCheckBox,
  BgCustomCheckBoxLabel,
} from "./Index.styled";
import axios from "axios";
import useMainContext from "../../../context/Main";
import Accordion from "../../Page/Editor/react-filerobot-image-editor/src/components/common/Accordion";
import { toast } from "react-toastify";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

function BgDimension(props) {
  const source = useRef(null);
  const [select, setSelect] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  const { project, setProject, openTemplate } = useMainContext();

  const fetchData = async () => {
    try {
      if (
        project?.presetDimension_id?.category &&
        project?.presetDimension_id?.group
      ) {
        const response = await axios.get(
          `app/crop-presets/${project?.presetDimension_id?.category}/${project?.presetDimension_id?.group}`
        );
        if (response?.data?.data && response?.data?.success) {
          const presetResponse = response.data.data.map((preset) => {
            if (
              preset?.category === "Custom" &&
              preset?.group === "Custom" &&
              project?.state?.editedImageObject?.width &&
              project?.state?.editedImageObject?.height
            ) {
              return {
                ...preset,
                ...{
                  width: project.state.editedImageObject.width,
                  height: project.state.editedImageObject.height,
                },
              };
            } else {
              return preset;
            }
          });
          setDimensions(presetResponse);
          let defaultValueSet = false;
          for (let dmsn of presetResponse) {
            if (dmsn?._id === project?.presetDimension_id?._id) {
              setSelect(project.presetDimension_id._id);
              props.onCheck(dmsn);
              defaultValueSet = true;
            }
          }
          if (defaultValueSet === false && select === null) {
            setSelect(presetResponse[0]._id);
            props.onCheck(presetResponse[0]);
            presetSelect(true, presetResponse[0]);
          }
        } else {
          console.error(response);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [project]);

  // useEffect(() => {
  //   if (dimensions) {
  //     // setSelect(null);
  //     for (let dmsn of dimensions) {
  //       if (dmsn?._id === project?.asset?.presetDimension_id) {
  //         setSelect(project?.asset?.presetDimension_id);
  //         props.onCheck(dmsn);
  //       }
  //     }
  //     if (select === null) {
  //       setSelect(dimensions[0]?._id);
  //       props.onCheck(dimensions[0]);
  //     }
  //   }
  // }, [project])

  useEffect(() => {
    source.current = new AbortController();
    return () => {
      if (source.current) {
        source.current.abort();
      }
    };
  }, []);

  async function presetSelect(checked, object) {
    if (checked) {
      if (project?.template_group_id?.id && object?._id) {
        const response = await axios.get(
          `/template/same/${project?.template_group_id?.id}/${object?._id}`
        );
        if (response?.data?.data && response?.data?.success) {
          await openTemplate(
            response.data.data?._id,
            project?.name ? project.name : null,
            { signal: source.current.signal }
          );
          return;
        } else {
          toast.error(
            "Unable to load Template for selected dimension. Please try again later."
          );
        }
      }
      // setSelect(object._id);
      let currentProject = { presetDimension_id: { _id: object._id } };
      if (project?.presetDimension_id) {
        currentProject = {
          ...project,
          ...{
            presetDimension_id: { ...project?.presetDimension_id, ...object },
          },
        };
      }
      localStorage.setItem("project", JSON.stringify(currentProject));
      setProject(currentProject);
      // props.onCheck(object);
    }
    // else {
    //   setSelect(null);
    //   if (project?.asset) {
    //     setProject({asset: {...project.asset, ...{presetDimension_id: object._id}}})
    //   } else {
    //     setProject({asset: {presetDimension_id: null}})
    //   }
    //   props.onCheck({
    //     ratioTitleKey: 'original',
    //     ratio: 'original',
    //   })
    // }
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(85, 85, 85, 1)",
      maxWidth: 220,
      fontSize: "14px",
      border: "1px solid #dadde9",
      padding: "12px",
      fontFamily: "Sofia Pro",
    },
  }));

  return (
    <Accordion title="Step 1: Select your dimensions">
      <div className="max-h-[265px] overflow-y-auto tour__dimension">
        {dimensions &&
          dimensions.map((dimension, i) => {
            return (
              <HtmlTooltip
                key={i}
                placement="right"
                title={
                  dimension.group !== "Custom" && (
                    <div className="">
                      <p>{dimension?.hoverText}</p>
                    </div>
                  )
                }
              >
                <BgCheckBoxItem key={`dimension-${dimension._id}`}>
                  <BgCheckBox
                    name="dimension"
                    value={dimension._id}
                    id={`dimension-${dimension._id}`}
                    checked={select === dimension._id}
                    onChange={(e) => presetSelect(e.target.checked, dimension)}
                  />
                  <BgCustomCheckBox htmlFor={`dimension-${dimension._id}`}>
                    <CheckedIcon
                      className="check"
                      id={`dimension-${dimension._id}`}
                    />
                    <UncheckedIcon className="uncheck" />
                    <BgCustomCheckBoxLabel>
                      <span>{dimension.descriptionKey}</span>
                      <span>
                        {dimension.width} x {dimension.height}
                      </span>
                    </BgCustomCheckBoxLabel>
                  </BgCustomCheckBox>
                </BgCheckBoxItem>
              </HtmlTooltip>
            );
          })}
      </div>
    </Accordion>
  );
}

export default BgDimension;
