import axios from "axios";

export default function SendVerificationBtn(props) {
  if (!props?.error?.actionBtn) return;
  return (
    <button
      type="submit"
      className="font-['Sofia_Pro'] text-[14px] border border-solid border-[#7B22EC] text-[#7B22EC] bg-transparent py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center"
      onClick={() => {
        props.closeModal();

        if (props?.error?.data?.email) {
          axios
            .post("/user/resend", {
              email: props?.error?.data?.email,
            })
            .then((res) => {
              if (res.data.success) {
                window.location.href =
                  "/verify-email?email=" + props?.error?.data?.email;
                props.onClick();
              }
            });
        }
      }}
    >
      Resend verification email
    </button>
  );
}
