/** External Dependencies */
import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";

/** Internal Dependencies */
import MainCanvas from "../MainCanvas";
import { ROOT_CONTAINER_CLASS_NAME } from "../../utils/constants";
import Topbar from "../Topbar";
// import ToolsBar from '../ToolsBar';
import {
  HIDE_LOADER,
  SET_CANVAS_SIZE,
  SET_FEEDBACK,
  SET_ORIGINAL_IMAGE,
  SHOW_LOADER,
  UPDATE_STATE,
} from "../../actions";
import loadImage from "../../utils/loadImage";
import {
  usePhoneScreen,
  useResizeObserver,
  useStore,
  useTransformedImgData,
} from "../../hooks";
import Spinner from "../common/Spinner";
// import { getBackendTranslations } from '../../utils/translator';
// import cloudimageQueryToDesignState from '../../utils/cloudimageQueryToDesignState';
import finetunesStrsToClasses from "../../utils/finetunesStrsToClasses";
import filterStrToClass from "../../utils/filterStrToClass";
import isSameImage from "../../utils/isSameImage";
import { StyledAppWrapper } from "./App.styled";
import {
  LeftPanelHeader,
  LeftPanelContent,
  BackButton,
  EditorContent,
  CenterPanelContent,
  RightPanelContent,
} from "../../../../../../../Layout.styled";
import { ReactComponent as EditIcon } from "../../../../../../../images/icon-edit.svg";
import { ReactComponent as LeftArrowIcon } from "../icons/chevron-left.svg";
// import AMTabs from '../common/AMTabs';
import ToolProperties from "../ToolProperties";
// import { SELECT_TOOL, SET_CROP, SET_RESIZE, ZOOM_CANVAS } from '../../actions';
// import { DEFAULT_ZOOM_FACTOR } from '../../utils/constants';
// import getZoomFitFactor from '../../utils/getZoomFitFactor';
// import toPrecisedFloat from '../../utils/toPrecisedFloat';
import WebFont from "webfontloader";
import {
  formatFonts,
  googleFonts,
} from "components/Page/Editor/react-filerobot-image-editor/src/utils/googleFont";
import googleFontsJson from "../../../../../../../assets/fonts/google-fonts.json";
import BgDimension from "../../../../../../common/BgDimension";
import useMainContext from "../../../../../../../context/Main";
import { useNavigate } from "react-router-dom";
import ProjectNameInputModal from "../../../../../../common/Modals/ProjectNameInputModal";
import TemplateThumbnailList from "../../../../TemplateThumbnailList";
import { SET_ARTBOARD } from "../../actions/setArtboard";

const App = () => {
  const navigate = useNavigate();
  const { project } = useMainContext();
  const [openProjectNameDialog, setOpenProjectNameDialog] = useState(false);
  const { annotations } = useStore();

  const {
    config,
    isLoadingGlobally,
    haveNotSavedChanges,
    dispatch,
    originalImage,
    // shownImageDimensions,
    t,
    feedback = {},
  } = useStore();
  const {
    loadableDesignState,
    // useCloudimage,
    // cloudimage,
    source,
    avoidChangesNotSavedAlertOnLeave,
    // useBackendTranslations,
    // translations,
    // language,
    defaultSavedImageName,
    observePluginContainerSize,
    getCurrentImgDataFnRef,
    updateStateFnRef,
  } = config;

  const [observeResize, unobserveElement] = useResizeObserver();
  const [rootSize, setRootSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [reSizeDimension, setReSizeDimension] = useState({
    width: null,
    height: null,
  });
  const isPhoneScreen = usePhoneScreen();
  const pluginRootRef = useRef(null);
  const isFirstRender = useRef(true);
  const cloudimageQueryLoaded = useRef(false);
  const imageBeingLoadedSrc = useRef(null);
  // Hacky solution, For being used in beforeunload event
  // as it won't be possible to have the latest value of the state variable in js event handler.
  const haveNotSavedChangesRef = useRef(haveNotSavedChanges);
  const transformImgFn = useTransformedImgData();

  const setNewOriginalImage = useCallback((newOriginalImage) => {
    dispatch({
      type: SET_ORIGINAL_IMAGE,
      payload: {
        originalImage: newOriginalImage,
      },
    });
  }, []);

  const setError = useCallback((newError) => {
    dispatch({
      type: SET_FEEDBACK,
      payload: {
        feedback: {
          message: newError.message || newError,
          duration: 0,
        },
      },
    });
  }, []);

  // We are promisifying the image loading for mixing it with other promises
  const loadAndSetOriginalImage = (imgToLoad) => {
    dispatch({
      type: SET_ARTBOARD,
      payload: {
        width: reSizeDimension.width,
        height: reSizeDimension.height,
      },
    });

    return new Promise((resolve) => {
      const imgSrc = imgToLoad?.src || imgToLoad;
      if (
        imageBeingLoadedSrc.current === imgSrc ||
        (!imgSrc && originalImage) ||
        isSameImage(imgSrc, originalImage)
      ) {
        if (!imageBeingLoadedSrc.current) {
          resolve();
        }
        return;
      }

      const triggerResolve = () => {
        imageBeingLoadedSrc.current = null;
        resolve();
      };

      imageBeingLoadedSrc.current = imgSrc;

      if (typeof imgToLoad === "string") {
        loadImage(imgToLoad, defaultSavedImageName)
          .then(setNewOriginalImage)
          .catch(setError)
          .finally(triggerResolve);
      } else if (imgToLoad instanceof HTMLImageElement) {
        setNewOriginalImage(imgToLoad);
        triggerResolve();
      } else {
        setError(t("invalidImageError"));
        triggerResolve();
      }
    });
  };

  const formattedFontList = useMemo(() => {
    return formatFonts(
      googleFontsJson.items.filter((font) => googleFonts.includes(font.family))
    );
  }, []);

  const inCanvasFonts = useMemo(() => {
    return Object.keys(annotations)
      .filter((key) => annotations[key].name === "Text")
      .map((key) => {
        const variants =
          annotations[key]?.variants
            ?.map((v) => (v === "regular" ? "400" : v))
            .filter((v) => /^\d+$/.test(v))
            .join(",") ?? [];
        return `${annotations[key].fontFamily}:${variants}`;
      });
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: inCanvasFonts.length ? inCanvasFonts : formattedFontList,
      },
    });
  }, []);

  const promptDialogIfHasChangeNotSaved = (e) => {
    if (haveNotSavedChangesRef.current) {
      // setShowLeaveModal(true)
      e.preventDefault();
      e.returnValue = "";
    }
  };

  // loadingPromisesFn is a function for enabling the ability to show loader first then trigger requests not vice versa.
  const handleLoading = (loadingPromisesFn = () => []) => {
    dispatch({ type: SHOW_LOADER });

    return Promise.all(loadingPromisesFn()).finally(() => {
      dispatch({ type: HIDE_LOADER });
    });
  };

  const updateDesignStateWithLoadableOne = () => {
    if (loadableDesignState && Object.keys(loadableDesignState).length > 0) {
      dispatch({
        type: UPDATE_STATE,
        payload: {
          ...loadableDesignState,
          finetunes: finetunesStrsToClasses(loadableDesignState?.finetunes),
          filter: filterStrToClass(loadableDesignState?.filter),
        },
      });
    }
  };

  useEffect(() => {
    if (
      !isFirstRender.current &&
      source &&
      !isSameImage(source, originalImage)
    ) {
      cloudimageQueryLoaded.current = false;
      handleLoading(() => [loadAndSetOriginalImage(source)]);
    }
  }, [source]);

  useEffect(() => {
    if (!isFirstRender.current) {
      const newImgSrc = loadableDesignState?.imgSrc;
      if (newImgSrc && !isSameImage(newImgSrc, originalImage)) {
        handleLoading(() => [
          loadAndSetOriginalImage(newImgSrc).then(
            updateDesignStateWithLoadableOne
          ),
        ]);
      } else {
        updateDesignStateWithLoadableOne();
      }
    }
  }, [loadableDesignState]);

  useEffect(() => {
    let isUnmounted = false;
    if (observePluginContainerSize && pluginRootRef.current) {
      observeResize(pluginRootRef.current.parentNode, ({ width, height }) =>
        setRootSize({ width, height })
      );
    } else if (rootSize.width && rootSize.height && !isUnmounted) {
      setRootSize({ width: undefined, height: undefined });
    }

    return () => {
      if (observePluginContainerSize && pluginRootRef.current) {
        unobserveElement(pluginRootRef.current);
      }

      isUnmounted = true;
    };
  }, [observePluginContainerSize]);

  useEffect(() => {
    const initialRequestsPromisesFn = () => [
      loadAndSetOriginalImage(loadableDesignState?.imgSrc || source),
      // ...(useBackendTranslations
      //   ? [getBackendTranslations(language, translations)]
      //   : []),
    ];

    handleLoading(initialRequestsPromisesFn);
    isFirstRender.current = false;

    if (window && !avoidChangesNotSavedAlertOnLeave) {
      window.addEventListener("beforeunload", promptDialogIfHasChangeNotSaved);
    }

    return () => {
      if (window && !avoidChangesNotSavedAlertOnLeave) {
        window.removeEventListener(
          "beforeunload",
          promptDialogIfHasChangeNotSaved
        );
      }
    };
  }, []);

  useEffect(() => {
    if (updateStateFnRef && typeof updateStateFnRef === "object") {
      updateStateFnRef.current = (newStatePartObjOrFn) => {
        dispatch({
          type: UPDATE_STATE,
          payload: newStatePartObjOrFn,
        });
      };
    }
  }, [updateStateFnRef, dispatch]);

  useEffect(() => {
    if (getCurrentImgDataFnRef && typeof getCurrentImgDataFnRef === "object") {
      getCurrentImgDataFnRef.current = transformImgFn;
    }
  }, [transformImgFn]);

  useEffect(() => {
    haveNotSavedChangesRef.current = haveNotSavedChanges;
  }, [haveNotSavedChanges]);

  useEffect(() => {
    // alert('change dimension')
    if (reSizeDimension?.width && reSizeDimension?.height) {
      loadAndSetOriginalImage(
        `https://dummyimage.com/${reSizeDimension.width}x${reSizeDimension.height}/ffffff/ffffff.png`
      );
    }
  }, [reSizeDimension]);

  const resizeBgImage = (object) => {
    if (object?.width && object?.height) {
      setReSizeDimension({
        ...reSizeDimension,
        ...{ width: object.width, height: object.height },
      });
    }
  };

  return (
    <>
      <div className="main-items">
        <LeftPanelHeader>
          <BackButton onClick={() => navigate(-2)}>
            <LeftArrowIcon />
            Back
          </BackButton>

          <div></div>
          <button
            className="min-h-[30px] border-0 bg-transparent flex justify-center items-center flex-row flex-nowrap gap-[4px] ml-[10px]"
            onClick={() => setOpenProjectNameDialog(true)}
          >
            <EditIcon className="shrink-0" />
            {project?.name && (
              <span className="font-['Sofia_Pro'] text-[14px] leading-[14px] text-[#555555] max-w-[175px] line-clamp-1">
                {project.name}
              </span>
            )}
          </button>
        </LeftPanelHeader>
        <LeftPanelContent id="LeftPanelScrollableDiv">
          <BgDimension onCheck={resizeBgImage} />
          <TemplateThumbnailList />
        </LeftPanelContent>
      </div>

      <StyledAppWrapper
        className={ROOT_CONTAINER_CLASS_NAME + " main-items"}
        data-phone={isPhoneScreen}
        ref={pluginRootRef}
        $size={rootSize}
      >
        {isLoadingGlobally && <Spinner label={t("loading")} />}
        <Topbar />
        {originalImage && feedback.duration !== 0 && (
          <EditorContent>
            <CenterPanelContent>
              <MainCanvas />
            </CenterPanelContent>
            <RightPanelContent>
              <ToolProperties />
            </RightPanelContent>
          </EditorContent>
        )}
      </StyledAppWrapper>
      <ProjectNameInputModal
        showModal={openProjectNameDialog}
        closeModal={() => setOpenProjectNameDialog(false)}
      />
    </>
  );
};

export default memo(App);
