import parseFontStyle from "./textUtils";

let textarea;
let textNode;
let transformer;
let editFinishCallback;
let disableTextEditCallback;
let updateText2;
let id;

function handleOutsideClick(e) {
  // const textValue = textarea.value;
  // eslint-disable-next-line no-use-before-define
  updateText2(latest => ({
    id: latest.id,
    opacity: 1,
    visible: true,
  }));

  if (textarea) {
    deactivateTextChange();
  }
  // editFinishCallback(textValue);
}

const deactivateTextChange = () => {
  if (textarea) {
    textarea.remove(textarea);
  }
  if (window) {
    window.removeEventListener("click", handleOutsideClick);
  }
  if (textNode) {
    textNode.show();
  }
  if (transformer) {
    transformer.show();
    transformer.forceUpdate();
  }
  if (typeof disableTextEditCallback === "function") {
    disableTextEditCallback();
  }
  textNode = null;
  textarea = null;
  transformer = null;
};

const activateTextChange = (
  textNodeId,
  canvasStage,
  currentTransformer,
  finishingCallback,
  dismissingTextEditing,
  saveText,
  updateText
) => {
  editFinishCallback = finishingCallback;
  disableTextEditCallback = dismissingTextEditing;
  transformer = currentTransformer;
  updateText2 = saveText;
  id = textNodeId;
  textNode = canvasStage.findOne(`#${textNodeId}`);
  // hide text node and transformer:
  textNode.hide();
  // transformer.hide();

  // at first lets find position of text node relative to the stage:
  const textPosition = textNode.absolutePosition();

  // create textarea and style it
  textarea = document.createElement("textarea");
  canvasStage.container().parentNode.appendChild(textarea);

  // apply many styles to match text on canvas as close as possible
  // remember that text rendering on canvas and on the textarea can be different
  // and sometimes it is hard to make it 100% the same. But we will try...
  textarea.value = textNode.text();
  textarea.style.position = "absolute";
  textarea.style.top = `${textPosition.y}px`;
  textarea.style.left = `${textPosition.x}px`;
  textarea.style.width = `${
    textNode.width() * textNode.getAbsoluteScale().x
  }px`;
  textarea.style.maxWidth = `${
    textNode.width() * textNode.getAbsoluteScale().x
  }px`;
  textarea.style.fontSize = `${
    textNode.fontSize() * textNode.getAbsoluteScale().x
  }px`;
  textarea.style.padding = "0px";
  textarea.style.top = `${
    textPosition.y - 2 * textNode.getAbsoluteScale().y
  }px`;

  textarea.style.overflow = "hidden";
  textarea.style.background = "transparent";
  textarea.style.resize = "none";
  textarea.style.outline = "none";
  textarea.style.boxShadow = "none";
  textarea.style.border = "none";
  textarea.style.lineHeight = textNode.lineHeight();
  textarea.style.fontFamily = textNode.fontFamily();
  textarea.style.transformOrigin = "left top";
  textarea.style.textAlign = textNode.align();
  textarea.style.fontWeight = parseFontStyle(textNode.fontStyle()).fontWeight;
  textarea.style.fontStyle = parseFontStyle(textNode.fontStyle()).fontItalic;
  textarea.style.letterSpacing = textNode.letterSpacing() * textNode.getAbsoluteScale().y  + "px";
  textarea.style.color = textNode.fill();
  textarea.style.opacity = "1";
  const rotation = textNode.rotation();
  let transform = "";
  if (rotation) {
    transform += `rotateZ(${rotation}deg)`;
  }

  let firefoxMovePx = 0;
  // also we need to slightly move textarea on firefox
  // because it jumps a bit
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  if (isFirefox) {
    firefoxMovePx += 2 + Math.round(textNode.fontSize() / 20);
  }
  transform += `translateY(-${firefoxMovePx}px)`;

  textarea.style.transform = transform;

  // reset height
  textarea.style.height = "auto";
  // // after browsers resized it we can set actual value
  textarea.style.height = `${textarea?.scrollHeight}px`;
  // textarea.style.background = "blue";
  // saveText((latestText) => ({
  //   id: latestText.id,
  //   height: textarea.scrollHeight / textNode.getAbsoluteScale().y,
  // }));
  textarea.focus();

  function setTextareaWidth(newTextWidth) {
    let newWidth = newTextWidth;
    if (!newWidth) {
      // set width for placeholder
      newWidth = textNode.placeholder.length * textNode.fontSize();
    }
    // some extra fixes on different browsers
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari || isFirefox) {
      newWidth = Math.ceil(newWidth);
    }

    const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    if (isEdge) {
      newWidth += 1;
    }
    textarea.style.width = `${newWidth}px`;
  }

  textarea.addEventListener("keyup", (event) => {
    const textContent = textarea.value;
    editFinishCallback(textContent);
  });

  textarea.addEventListener("keydown", (event) => {
    const textContent = textarea.value;
    editFinishCallback(textContent);
    if (event.key === "Escape") {
      saveText((latestText) => ({
        id: latestText.id,
        visible: true,
        opacity: 1,
      }));
      deactivateTextChange();
    }
  });

  textarea.addEventListener("input", (event) => {
    textarea.style.display = "block";
    textarea.style.height = "auto";
    textarea.style.height = `${textarea?.scrollHeight}px`;
    const scale = textNode.getAbsoluteScale().x;
    setTextareaWidth(textNode.width() * scale);
    saveText((latestText) => ({
      id: latestText.id,
      height: textarea?.scrollHeight / textNode.getAbsoluteScale().y,
      visible: false,
    }));
  });

  // // Select the node that will be observed for mutations
  // const targetNode = document.getElementsByTagName('textarea')

  // // Options for the observer (which mutations to observe)
  // const config = { attributes: true, childList: true, subtree: true };

  // // Callback function to execute when mutations are observed
  // const callback = (mutationList, observer) => {
  //   for (const mutation of mutationList) {
  //     console.log('any changes')
  //     if (mutation.type === "childList") {
  //       console.log("A child node has been added or removed.");
  //     } else if (mutation.type === "attributes") {
  //       console.log(`The ${mutation.attributeName} attribute was modified.`);
  //     }
  //   }
  // };

  // // Create an observer instance linked to the callback function
  // const observer = new MutationObserver(callback);

  // // Start observing the target node for configured mutations
  // observer.observe(targetNode, config);

  // // Later, you can stop observing
  // observer.disconnect();

  if (window) {
    setTimeout(() => {
      // window.addEventListener("click", handleOutsideClick)
      textarea.addEventListener("blur", handleOutsideClick);
    }, 30);

    window.addEventListener("wheel", (e) => {
      deactivateTextChange();
    });
  }
};

export { activateTextChange, deactivateTextChange };
