import { useNavigate } from "react-router-dom";

export default function RedirectToLoginBtn() {
  // const navigate = useNavigate();
  const handleClick = () => {
    // navigate("/login");
    window.location.href = "/login"
  };

  return (
    <button
      className="font-['Sofia_Pro'] text-[14px] border border-solid border-[#7B22EC] text-[#7B22EC] bg-transparent py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center"
      onClick={handleClick}
    >
      Login
    </button>
  );
}
