import { ReactComponent as TrashIcon } from "./images/trash.svg";
import { ReactComponent as CloneIcon } from "./images/clone.svg";

import { ReactComponent as TextIcon } from "./images/text.svg";
import { ReactComponent as ImageIcon } from "./images/image.svg";
import { ReactComponent as ShapeIcon } from "./images/shape.svg";
import { TOOLS_IDS } from "../../../utils/constants";
import { Draggable } from "react-beautiful-dnd";
import LayerItem from "./LayerItem";

const layerIcons = {
  [TOOLS_IDS.TEXT]: TextIcon,
  [TOOLS_IDS.IMAGE]: ImageIcon,
  [TOOLS_IDS.LINE]: ShapeIcon,
  [TOOLS_IDS.RECT]: ShapeIcon,
  [TOOLS_IDS.ELLIPSE]: ShapeIcon,
  [TOOLS_IDS.POLYGON]: ShapeIcon,
  [TOOLS_IDS.ARROW]: ShapeIcon,
};

const LayerList = ({ annotations }) => {
  return (
    <>
      {annotations.map((layer, index) => {
        const Icon = layerIcons[layer.name];
        return (
          <Draggable draggableId={layer.id} index={index} key={layer.id}>
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <LayerItem
                    layer={layer}
                    Icon={Icon}
                    isDragging={snapshot.isDragging}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
};

export default LayerList
