import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useState } from "react";

import { useAnnotation } from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import useImportImages from "components/Page/Editor/react-filerobot-image-editor/src/hooks/useImportImage";
import { TOOLS_IDS } from "components/Page/Editor/react-filerobot-image-editor/src/utils/constants";
import { ReactComponent as MagicIcon } from "../../icons/magic-wand.svg";
import { ReactComponent as InfoIcon } from "../../../../../../../../assets/svg/info.svg";
import useAuth from "utils/Auth";
import { IconButton } from "../NodeControls.styled";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F7F7F7",
    color: "#888888",
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #DDDDDD",
  },
}));

export default function RemoveBackground() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [image] = useAnnotation(
    {
      name: TOOLS_IDS.IMAGE,
      opacity: 1,
    },
    false
  );
  const { constructImage } = useImportImages({ replace: true });

  const handlleRemoveBG = async (e) => {
    setLoading(true);

    try {
      let blob = await fetch(
        typeof image.image === "string" ? image.image : image.image.src
      ).then((r) => {
        return r.blob();
      });
      // console.log(blob)

      let data = new FormData();
      data.append("account", user?._id);
      data.append("image", blob, `${image.id}.jpg`);

      const p1 = new Promise(async (resolve, reject) => {
        const response = await axios({
          method: "post",
          url: "/image/remove-background",
          responseType: "blob",
          data,
        });
        if (response.data ){
          resolve(response.data);
        }
      });

      p1.then(
        (imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob);
          setLoading(false);
          constructImage(imageUrl, () => {
            URL.revokeObjectURL(imageUrl);
          });
        }
      );

    } catch (error) {
      setLoading(false);
    }
  };

  const isBlobUrl = React.useMemo(() => {
    return image?.image?.src?.startsWith('blob')
  }, [image?.image?.src])

  return (
    <IconButton
      onClick={handlleRemoveBG}
      disabled={loading || isBlobUrl}
      pathStyle={{ fill: "#777" }}
      sx={{
        opacity: isBlobUrl ? .3 : 1,
        pointerEvents: isBlobUrl ? "none" : "auto"
      }}
    >
      {loading ? (
        <>
          <CircularProgress style={{ width: "14px", height: "14px" }} />
          <div>Processing...</div>
        </>
      ) : (
        <>
          <HtmlTooltip
            disableInteractive
            title={
              <div className="flex items-start">
                <InfoIcon style={{ margin: "0px 4px 0 0", width: "22px" }} />
                {
                  "Use images with cleaner backgrounds for optimal masking results."
                }
              </div>
            }
            enterDelay={100}
            leaveDelay={300}
          >
            <div className="flex">
              <MagicIcon style={{ width: "16px" }} className="mr-2" />
              Remove Background
            </div>
          </HtmlTooltip>
        </>
      )}
    </IconButton>
  );
}
