import React from "react";
import { Modal, Box } from "@mui/material";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/icon-error.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

const GenericProjectNameModal = (props) => {
  const validationRules = {
    filename: Yup.string()
      .matches(/^[\w\-\s]+$/, "Invalid file name")
      .required("File name is required"),
  };

  const formResetToDefault = () => {
    formik.setFieldValue("filename", props.initialValue ?? "");
  };

  const formik = useFormik({
    initialValues: {
      filename: props.initialValue,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: () => {
      props.onSubmit(formik.values.filename.trim());
    },
  });

  return (
    <Modal
      componentsProps={{
        backdrop: { sx: { backdropFilter: "blur(10px)" } },
      }}
      open={props?.modalStatus}
      onClose={() => props.closeModal()}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "none",
          outline: "none",
          boxShadow: 24,
          borderRadius: "20px",
          background: "rgba(255, 255, 255, 0.8)",
          overflow: "hidden",
        }}
      >
        <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center font-['Sofia_Pro'] text-[15px] h-[40px]">
          {props.modalTitle ?? "Save as"}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-[30px] bg-[#F9F9F9]">
            <div>
              <label
                htmlFor="filename"
                className="text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]"
              >
                Project Name
              </label>
              <input
                type="text"
                id="filename"
                name="filename"
                placeholder="Project Name"
                defaultValue={formik.values.filename || props.initialValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`w-full border border-solid ${
                  formik.touched.filename && formik.errors.filename
                    ? "border-[#DF1E1E]"
                    : "border-[#E5E5E5]"
                } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
              />
              {formik.touched.filename && formik.errors.filename && (
                <label className="py-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.filename}
                </label>
              )}
            </div>
          </div>
          <div className="px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center">
            <button
              type="button"
              className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center"
              onClick={() => {
                formResetToDefault();
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center"
            >
              {props.confirmButtonText ?? "Save as"}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default GenericProjectNameModal;
