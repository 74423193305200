import { useEffect, useState } from "react";
import MainContext from "./MainContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const MainProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loader, setLoader] = useState(false);
    const [projectReloadIndex, setProjectReloadIndex] = useState(0);
    const [projectLoading, setProjectLoading] = useState(null);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [strokeStatus, setStrokeStatus] = useState('solidStroke');
    const [errorModalController, setErrorModalController] = useState({});
    const [openHeadlineGeneratorModal, setOpenHeadlineGeneratorModal] =
      useState(false);
    const [anchorEl, setAnchorEl] = useState();

    const fetchProject = async (id) => {
        try {
            const loggedInUser = localStorage.getItem('user');
            if (loggedInUser) {
                const foundUser = JSON.parse(loggedInUser);
                if (foundUser?.token) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${ foundUser.token }`;
                }
                if (foundUser?.role === 'Designer') {
                    await fetchTemplate(id)
                } else {
                    const response = await axios.get(`/project/${ id }`);
                    if (response?.data?.success && response?.data?.data) {
                        // setTimeout(() => {
                            setProject(response.data.data);
                        // },500)

                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const fetchTemplate = async (id) => {
        try {
            const loggedInUser = localStorage.getItem('user');
            if (loggedInUser) {
                const foundUser = JSON.parse(loggedInUser);
                if (foundUser?.token) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${ foundUser.token }`;
                }
            }
            const response = await axios.get(`/template/${ id }`);
            if (response?.data?.success && response?.data?.data) {
                // setTimeout(() => {
                    setProject(response.data.data);
                // },500)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (location.pathname === '/editor') {
            const foundProject = localStorage.getItem('project');
            if (foundProject) {
                reloadProjectIndex();
                const currentProject = JSON.parse(foundProject);
                setProject(currentProject);
                if (currentProject?._id) {
                    reloadProjectIndex();
                    fetchProject(currentProject._id);
                } else if (currentProject?.t_id) {
                    reloadProjectIndex();
                    fetchTemplate(currentProject.t_id);
                }
            }
        }
    }, []);

    const reloadProjectIndex = () => {
        let reloadIndex = projectReloadIndex + 1;
        setProjectReloadIndex(reloadIndex);
    }

    const openProject = async (id, params = {}) => {
        const response = await axios.get(`/project/${ id }`, params);
        if (response?.data?.success && response?.data?.data) {
            reloadProjectIndex();
            const currentProject = response.data.data;
            localStorage.setItem('project', JSON.stringify({...currentProject, ...{state: {}}}));
            setProject(currentProject);
            navigate('/editor');
        } else {
            console.error('when opening project.')
        }
    }

    const openTemplate = async (id, defaultName = null, params = {}) => {
        setTemplateLoading(true)
        const response = await axios.get(`/template/${ id }`, params);
        if (response?.data?.success && response?.data?.data) {
            reloadProjectIndex();
            const currentProject = defaultName ?
                {
                    ...response.data.data,
                    ...{ name: defaultName }
                } :
                response.data.data;

            // Patch for visibility issue
            const patchedCurrentProject = JSON.parse(JSON.stringify(currentProject))
            Object.values(patchedCurrentProject.state.designState.annotations).forEach(object => object.visible = true);

            localStorage.setItem('project', JSON.stringify({...patchedCurrentProject, ...{state: {}}}));
            setTemplateLoading(false)
            setProject(patchedCurrentProject);
            navigate('/editor');
        } else {
            setTemplateLoading(false)
            console.error('when opening template.')
        }
    }

    return (
        <MainContext.Provider value={{
            loader,
            setLoader,
            project,
            setProject,
            projectReloadIndex,
            reloadProjectIndex,
            strokeStatus,
            setStrokeStatus,
            errorModalController,
            setErrorModalController,
            projectLoading,
            setProjectLoading,
            templateLoading,
            setTemplateLoading,
            openProject,
            openTemplate,
            openHeadlineGeneratorModal,
            setOpenHeadlineGeneratorModal,
            anchorEl,
            setAnchorEl,
        }}>
            {children}
        </MainContext.Provider>
    )
}

export default MainProvider
