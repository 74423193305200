import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../utils/Auth';
import { Modal, Box } from '@mui/material';
import {ReactComponent as IconClose} from '../../../assets/svg/icon-close.svg';
import IconArrowRight from '../../../assets/images/icon-arrow-right.png';
import ImageNotFound from '../../../assets/images/image-not-found.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import fileDownload from 'js-file-download';
import {toast} from "react-toastify";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import useMainContext from "../../../context/Main";

const TemplateTypeDimensionModal = (props) => {
    const random = Math.floor(Date.now() / 1000)
    const navigate = useNavigate();
    const {user} = useAuth()
    const [data, setData] = useState(null)
    const [group, setGroup] = useState(null)
    const [dimension, setDimension] = useState(null)
    const [openDeleteConformationModal, setOpenDeleteConformationModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const {loader, setLoader, templateLoading} = useMainContext();
    const sortOrderArray = ['Facebook', 'Instagram', 'X', 'LinkedIn', 'Banner'];

    const getData = async () => {
        if (props?.id) {
            const response = await axios.get(`/template/groups/${ props.id }`)
            if (response?.data?.success && response?.data?.data) {
                if (!response.data.data.thumbnail_url) {
                    response.data.data.thumbnail_url = ImageNotFound;
                }

                const sortedGroup = response.data.data.groups.sort((a, b) => {
                    return sortOrderArray.indexOf(a) - sortOrderArray.indexOf(b);
                  });

                response.data.data.groups = sortedGroup
                setData(response.data.data)
            }
        }
    }

    useEffect(() => {
        getData()
    }, [props])

    async function downloadTemplate(template) {
        if (template?._id && template?.thumbnail_url) {
            try {
                const response = await axios.get(`/template/download/${ template?._id }`, {
                    responseType: 'blob',
                });
                if (response?.data) {
                    fileDownload(response.data, template?.thumbnail_url.substring(template?.thumbnail_url.lastIndexOf('/')+1));
                } else {
                    console.error(response);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            toast.error('Unable to Download. No template linked to group.');
        }
    }

    async function deleteTemplate() {

        try {
            if (user) {
                if (selectedItem?._id) {
                    setLoader(true);
                    const response = await axios.delete(`/template/${ selectedItem?._id }`);
                    if (response?.data?.success && response?.data?.data) {
                        setSelectedItem({})
                        await getData();
                    }
                } else {
                    toast.error('Unable to Delete. No template linked to group.');
                }
                setLoader(false);
            } else {
                setLoader(false);
                navigate('/login', {replace: true});
            }
        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    }

    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } }
            }}
            open={props?.showModal}
            onClose={() => {setDimension(null); setGroup(null); setData(null); props.closeModal(); }}
        >
            <Box style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "75vw",
                bgcolor: "background.paper",
                border: "none",
                outline: "none",
                boxShadow: 24,
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.8)",
                overflow: "hidden",
            }}>
                <div className="bg-[#DBDBDB] h-[40px] grid grid-cols-3 gap-4">
                    <div className="w-full"></div>
                    <div className="w-full flex justify-center items-center font-['Sofia_Pro'] text-[15px] text-[#111111]">Select Creative Type and Dimensions</div>
                    <div className="flex items-center justify-end w-full">
                        <button className='px-3' onClick={() => {props.closeModal()}}><IconClose /></button>
                    </div>
                </div>
                <div className='px-[40px] pt-[40px] pb-[48px] bg-[#FFFFFF] flex flex-row flex-nowrap gap-[20px]'>
                    <div className='shrink-0 min-w-[300px]'>
                        <div className="flex overflow-hidden max-w-[280px] h-[150px] mb-4">
                            {dimension?.thumbnail_url || data?.thumbnail_url ? (
                                <img className='rounded-[10px] object-contain m-auto h-full b-4 transition duration-200 ease-in group-hover:scale-[120%]' src={`${dimension?.thumbnail_url ?? data?.thumbnail_url}?date=${random}`} alt="Template Preview" />
                            ) : <div className='bg-slate-200 w-[150px] h-[150px] rounded-[10px] object-contain m-auto h-full b-4 transition duration-200 ease-in group-hover:scale-[120%]' />}
                        </div>
                        <label className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#555555]">Selected Design:</label>
                        <p className="font-['Sofia_Pro'] text-[24px] leading-[28px] text-[#111111]">{data?.name}</p>
                    </div>
                    <div className='h-[220px] w-[1px] border-l border-[#DBDBDB]'></div>
                    <div className='flex flex-col justify-between grow'>
                        <div className="flex flex-row flex-nowrap justify-start items-start gap-[20px]">
                            <div className='shrink-0'>
                                {data?.groups.map((category, index) =>
                                    <button key={`category-${index.toString()}`} className={`font-['Sofia_Pro'] text-[24px] leading-[28px] text-[#111111] w-[237px] h-[44px] hover:bg-[#EEEEEE] ${group === category ? 'bg-[#EEEEEE]' : ''} p-[8px] flex flex-row justify-between items-center`} onClick={() => {setGroup(category); setDimension(null)}}>
                                        <span>{category}</span>
                                        <img className='h-[12px]' src={IconArrowRight} alt="Arrow right" />
                                    </button>
                                )}
                            </div>
                            <div className='grow'>
                                {data?.templates.map((template, index) =>
                                    template.presetDimension_id.group === group ? <div key={`dimension-${index.toString()}`} className={`flex items-center justify-between font-['Sofia_Pro'] text-[14px] leading-[16px] text-[#111111] text-left w-full h-[44px] hover:bg-[#EEEEEE] more-action-parent ${template?._id === dimension?._id ? 'bg-[#EEEEEE]' : ''} p-[8px]`} onClick={() => setDimension(template)}>
                                        <div>{template.presetDimension_id.descriptionKey} - {template.presetDimension_id.width} x {template.presetDimension_id.height}px</div>
                                        {
                                            user?.role === 'Designer' && (
                                                <div className="more-action-child">
                                                    <FontAwesomeIcon icon="fa-solid fa-trash" className="hover:text-[#777777] mr-3" onClick={(e) => {e.stopPropagation(); setSelectedItem(template); setOpenDeleteConformationModal(true);}} />
                                                    <FontAwesomeIcon icon="fa-solid fa-download" className="hover:text-[#777777]" onClick={(e) => {e.stopPropagation(); downloadTemplate(template);}} />
                                                </div>
                                            )
                                        }
                                    </div> : ''
                                )}
                            </div>
                        </div>
                        <div className={`${!group || !dimension ? 'hidden' : ''} w-full flex justify-end mt-4`}>
                            <button className={`btn-3d-2 font-['Sofia_Pro'] flex items-center justify-center text-[#111111] border border-[#111111] bg-[#FFFFFF] hover:bg-[#000000] hover:text-white font-medium rounded-lg text-sm px-5 py-[14px] text-center opacity-100`} onClick={() => props.confirmModal(dimension)}>{templateLoading ? "Loading..." : "Confirm"}</button>
                        </div>
                    </div>
                </div>

                <DeleteConfirmationModal showModal={openDeleteConformationModal} closeModal={() => {setOpenDeleteConformationModal(false); setSelectedItem({})}} item={selectedItem} conform={() => deleteTemplate(selectedItem)} />
            </Box>
        </Modal>
    )
}

export default TemplateTypeDimensionModal