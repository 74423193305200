import axios from "axios";

const IfLoggedIn = ({ children }) => {
  const loggedInUser = localStorage.getItem('user');
  if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    if (foundUser?.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${ foundUser.token }`;
    }
  }
  return children
}

export default IfLoggedIn;