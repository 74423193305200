import React from "react";
import MainHeader from "../../common/Header";
import MainFooter from "../../common/Footer";
import { Link } from "react-router-dom";
import PageAnimation from "../../common/PageAnimation";
import { Helmet } from "react-helmet";
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Ad Designer - Terms of Use</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen">
          <MainHeader />

          <div className="max-w-[800px] mx-auto my-10 min-h-[80vh] px-6 page-content">
            <h1 className="font-['Sofia_Pro'] text-[24px] leading-[28px] text-[#111111] mb-[20px]">
              Website Terms of Use
            </h1>

            <div className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#555555] mb-[20px] page-content">
              <ol start="1" type="1">
                <li>
                  In order to give online access to details about Ad Designer, the
                  Platform, the goods, the services and opportunities we offer,
                  Ad Designer runs a website (&ldquo;Site&rdquo;) that contains a
                  link to these Terms of Use. Our Customer Terms of Use can be
                  assessed at https://theaddesigner.com/terms which governs use of
                  Ad Designer
                  <strong>.</strong>
                </li>
              </ol>
              <ol start="2" type="1">
                <li>
                  You accept these Terms of Use by using the Site and gaining
                  access to it.
                </li>
              </ol>
              <ol start="3" type="1">
                <li>
                  These Terms of Use are subject to change at any time without
                  previous notification from Ad Designer<strong>.&nbsp;</strong>You
                  agree to obey and be bound by these Terms of Use as modified
                  if you continue to use the Site after any such changes.
                </li>
              </ol>
              <ol start="4" type="1">
                <li>
                  The Terms of Use are as follows:
                  <ol start="1" type="a">
                    <li>
                      <strong>Permitted Use of the Site</strong>
                      <ol start="1" type="i">
                        <li>
                          You may only use the Site, data, words, images,
                          sounds, or other creations that you come across on the
                          Site (individually or collectively, the "Content") for
                          business and individual purposes or to learn more
                          about Ad Designer&rsquo;s goods and services, and only in
                          accordance with these Terms of Use.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      <strong>Prohibited Use of The Site</strong>
                      <ol start="1" type="i">
                        <li>
                          You agree that you will not use this Website for any
                          unlawful activity or use it in any way that would
                          violate these Terms of Use. Also, you shall not
                          restrict or inhibit any other user from accessing any
                          service provided by this Website, nor shall you
                          upload, email, transmit, distribute, or otherwise
                          publish any of the following on this Website.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          By accessing the Site, you agree that you will not:
                          <ol start="1" type="1">
                            <li>
                              Use the Site in violation of these Terms of Use.
                            </li>
                          </ol>
                          <ol start="2" type="1">
                            <li>
                              Use the Site in violation of the terms of
                              Ad Designer&rsquo;s Acceptable Use Policy at&nbsp;
                              <strong>(</strong>
                              <Link to="/privacy">
                                https://theaddesigner.com/privacy
                              </Link>
                              <strong>)</strong>
                            </li>
                          </ol>
                          <ol start="3" type="1">
                            <li>
                              Copy, modify, create a derivative work from,
                              reverse engineer or reverse assemble the Site, or
                              otherwise attempt to discover any source code, or
                              allow any third party to do so;
                            </li>
                          </ol>
                          <ol start="4" type="1">
                            <li>
                              Sell, sublicense, distribute, commercially
                              exploit, grant a security interest in, or
                              otherwise transfer any right in, the Content or
                              Service, or make it in any way accessible to a
                              third party;
                            </li>
                          </ol>
                          <ol start="5" type="1">
                            <li>
                              Use the website or platform in any way that
                              interferes with another party's use and enjoyment
                              of the website or platform, or damages, disables,
                              overburdens, or degrades it;
                            </li>
                          </ol>
                          <ol start="6" type="1">
                            <li>
                              Attempt to gain unauthorized access to the Site;
                            </li>
                          </ol>
                          <ol start="7" type="1">
                            <li>
                              Access the website by a method other than the
                              interface made available by Aiken Digital LLC for
                              that purpose;
                            </li>
                          </ol>
                          <ol start="8" type="1">
                            <li>
                              Use the Site for any illegal or prohibited
                              activity as defined by this Agreement;
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          This system is monitored to ensure proper operation,
                          to verify the functioning of applicable security
                          features, and for comparable purposes. Anyone using
                          this system expressly consents to such monitoring.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          <strong>
                            Unauthorized attempts to modify any information
                            stored on this system, to defeat or circumvent
                            security features, or to utilize this system for
                            other than its intended purposes are prohibited and
                            may result in criminal prosecution.
                          </strong>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      <strong>Copyrights and Trademarks</strong>
                      <ol start="1" type="i">
                        <li>
                          The Content is part of the Site, which is built using
                          exclusive Aiken Digital LLC&rsquo;s technology.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          The applicable intellectual property and other laws,
                          such as trademark and copyright laws, protect the
                          Site.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          The Site belongs to and is the property of Aiken
                          Digital LLC or its licensors, including all of the
                          intellectual property rights in the Site.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          All copyrights in the Content are owned by and
                          reserved to Aiken Digital LLC.
                        </li>
                      </ol>
                      <ol start="5" type="i">
                        <li>
                          Except where expressly stated on the Site, the Content
                          may not be copied, reproduced, modified, published,
                          uploaded, posted, transmitted, performed, or
                          distributed in any way, and you agree not to alter the
                          Content or the Site, in whole or in part, by any
                          means, or to rent, lease, loan, sell, distribute,
                          transmit, broadcast, or create derivative works based
                          on the Content or the Site.
                        </li>
                      </ol>
                      <ol start="6" type="i">
                        <li>
                          Aiken Digital LLC owns the trademarks and other marks
                          it uses from time to time, including any associated
                          logos.
                        </li>
                      </ol>
                      <ol start="7" type="i">
                        <li>
                          The Ad Designer site's appearance, organisation, colour
                          scheme, and design are all protected. The
                          aforementioned are not given any rights or licences to
                          the customer.
                        </li>
                      </ol>
                      <ol start="8" type="i">
                        <li>
                          Any recommendations or other feedback you offer to
                          Aiken Digital LLC may be used and incorporated into
                          the Site or the Platform Service without restriction
                          or compensation.
                        </li>
                      </ol>
                      <ol start="9" type="i">
                        <li>
                          Pursuant to Title 17, United States Code, Section
                          512(c)(2), notifications of claimed copyright
                          infringement on the Site or the Service should be sent
                          to&nbsp;<strong>(info@theaddesigner.com).</strong>&nbsp;See
                          the Claims of Copyright Infringement instructions
                          below.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      <strong>
                        Information and Materials You Post or Provide
                      </strong>
                      <ol start="1" type="i">
                        <li>
                          You warrant that you own all rights, title, and
                          interest in and to the materials (collectively,
                          "Materials") that you upload, input, or otherwise
                          provide to Aiken Digital LLC through the Site,
                          including, but not limited to, all consents,
                          authorizations, releases, clearances, and licences
                          from third parties (including, but not limited to,
                          releases relating to rights of privacy or publicity),
                          and that posting such Materials does not violate or
                          otherwise amount to a criminal offence.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          You further warrant that you will not provide
                          fictitious, false, or inaccurate information about
                          yourself, that all information contained in uploaded
                          information or materials is true and your own work or
                          work you have permission to submit, and that the
                          uploaded materials don't contain any material that is
                          harassing, libellous, false, defamatory, offensive,
                          obscene, or pornographic or other material that would
                          violate any law. You acknowledge that you will not
                          intentionally and knowingly supply false information.
                          You guarantee and indicate that the materials and data
                          you offer do not go against these Terms of Use.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          In general, information presented on this web site,
                          unless otherwise indicated, is considered in the
                          public domain. It may be distributed or copied as
                          permitted by law. However, the State does make use of
                          copyrighted data (e.g., photographs or other
                          materials) which may require additional permissions
                          prior to your use. The State shall have the unlimited
                          right to use for any purpose, free of any charge, all
                          information submitted via this site, except those
                          submissions made under separate legal contract
                          provided that the customer gives permission to
                          disclose the information.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="5" type="a">
                    <li>
                      <strong>Links to Third-Party Web Sites</strong>
                      <ol start="1" type="i">
                        <li>
                          Links on the Site leading to information or web pages
                          operated by third parties may be provided to you. You
                          will leave the Site if you click on these links. Such
                          connections do not represent or suggest that Aiken
                          Digital LLC supports, sponsors, or advises the third
                          party, the third-party website, or the content there.
                          Any such websites' accessibility is not guaranteed by
                          Aiken Digital LLC. Any such websites or the content on
                          them are not the responsibility or liability of Aiken
                          Digital LLC. You will leave the Site and be subject to
                          the terms of use and privacy policy that are
                          applicable to those websites if you utilise the links
                          to the websites of Aiken Digital LLC&rsquo;s
                          affiliates or service providers.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          These links are provided as an information service
                          only. It is the responsibility of the user to evaluate
                          the content and usefulness of information obtained
                          from other websites. Linking to another website takes
                          you away from Ad Designer&rsquo;s Website and subjects you
                          to the privacy policy of the new website.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="6" type="a">
                    <li>
                      <strong>Downloading Files</strong>
                      <ol start="1" type="i">
                        <li>
                          Downloading files from this Site is free of charge and
                          the user assumes all risks of any damages that may
                          occur, including but not limited to loss of data,
                          damages to hardware, or loss of business profits.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          These files have not been tested for functionality or
                          contamination. Many have been sent to us by visitors
                          like yourself. Thus, we suggest that you do a virus
                          scan using an up-to-date version of an anti-virus
                          program (Such as Norton or McAfee).
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>Please use at your own risk.</li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="7" type="a">
                    <li>
                      <strong>Disclaimers, Limitations of Liability</strong>
                      <ol start="1" type="i">
                        <li>
                          Disclaimer
                          <ol start="1" type="1">
                            <li>
                              Construction of the disclaimers contained herein
                              and the resolution of disputes, thereof, are
                              governed by the laws of the State of California.
                              Any use of this Website shall comply with all
                              State of California laws and regulations, as well
                              as with all federal laws and regulations.
                            </li>
                          </ol>
                          <ol start="2" type="1">
                            <li>
                              (ii) The suitability, reliability, availability,
                              or accuracy of the information contained within or
                              in relation to the Site / Platform for any of the
                              defined purposes included in this agreement are
                              not guaranteed by Aiken Digital LLC.
                            </li>
                          </ol>
                          <ol start="3" type="1">
                            <li>
                              (iii) To the maximum extent permitted by law, all
                              information, images, products, services, and
                              software are provided to customers &ldquo;as
                              is&rdquo; without warranty or condition of any
                              kind. This includes and is not limited to all
                              implied warranties in the use of the Site /
                              Platform and its content, the fitness for a
                              particular purpose, title, and non-infringement.
                            </li>
                          </ol>
                          <ol start="4" type="1">
                            <li>
                              (iv) Neither the Website nor its contributors
                              shall be held liable for any improper or incorrect
                              use of the information described and/or contained
                              herein and assumes no responsibility for anyone's
                              use of the information.
                            </li>
                          </ol>
                          <ol start="5" type="1">
                            <li>
                              (v) Aiken Digital LLC hereby disclaims all
                              warranties and conditions of any kind or form with
                              regard to the Site / Platform and content. No
                              statement or information obtained from Ad Designer in
                              any way or manner whatsoever shall give rise to
                              any warranty that is not expressly and thereby
                              stated in this agreement.
                            </li>
                          </ol>
                          <ol start="6" type="1">
                            <li>
                              (vi) To the fullest extent permitted by applicable
                              law, Aiken Digital LLC will not be responsible for
                              any direct, indirect, punitive, incidental,
                              special, consequential, exempt, or other types of
                              damages, including without limitation damages for
                              loss of use, data, revenue, or profits, arising
                              from or related to the use or performance of the
                              website or platform.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Disclaimer of Duty to Continue Providing Data
                          <ol start="1" type="1">
                            <li>
                              Aiken Digital LLC may add, change, improve, or
                              update the information on this Website without
                              notice and also reserves its exclusive right in
                              its sole discretion to alter, limit, or
                              discontinue any part of this Website. The use of
                              this Website is at the user's sole risk.
                            </li>
                          </ol>
                          <ol start="2" type="1">
                            <li>
                              Aiken Digital LLC does not warrant that access to
                              this Website will be uninterrupted or that any
                              material posted on the Website is free from
                              errors, inaccuracies, or omissions. Neither Aiken
                              Digital LLC or this Website, nor any entity
                              jointly created by them, each of their affiliates,
                              nor any of their respective employees, agency,
                              third party content providers or licenser, warrant
                              that the service will be uninterrupted or
                              error-free.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          No Guarantee of Confidentiality or Reliability
                          <ol start="1" type="1">
                            <li>
                              The reliability or confidentiality of any messages
                              sent through this website is not guaranteed by
                              Aiken Digital LLC. You should not send Aiken
                              Digital LLC any private or sensitive information
                              over the Internet since someone else might
                              intercept it. Furthermore, information sent to
                              Ad Designer electronically through this website may
                              not be received at all, may not be received, or
                              reviewed promptly, or may be received by an
                              incorrect party.
                            </li>
                          </ol>
                          <ol start="2" type="1">
                            <li>
                              Aiken Digital LLC makes every effort to minimize
                              disruption caused by technical errors. However, we
                              cannot guarantee that our service will not be
                              interrupted or otherwise affected by such
                              problems. Our goal is to keep this information
                              timely and accurate.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="8" type="a">
                    <li>
                      <strong>Indemnification</strong>
                      <ol start="1" type="i">
                        <li>
                          You understand and agree that you are personally
                          responsible for your behaviour on the Site / Platform.
                          You agree to indemnify, defend and hold harmless Aiken
                          Digital LLC&rsquo;s affiliated companies, joint
                          venturers, business partners, licensors, employees,
                          agents, and any third-party information providers from
                          and against all claims, losses, expenses, damages and
                          costs (including, but not limited to, direct,
                          incidental, consequential, exemplary and indirect
                          damages), and reasonable solicitor's fees, resulting
                          from or arising out of your use, misuse, or inability
                          to use the Site or the Content, or any violation by
                          you of these Terms of Use.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="9" type="a">
                    <li>
                      <strong>Privacy</strong>
                      <ol start="1" type="i">
                        <li>
                          Your use of the Site is subject to Ad Designer&rsquo;s
                          Privacy Policy, available at&nbsp;
                          <Link to="/privacy">
                            https://theaddesigner.com/privacy
                          </Link>
                          <strong>.</strong>
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          In the State of California, laws exist to ensure that
                          the government is open, and that the public has a
                          right to access records and information created or
                          maintained by State government. At the same time,
                          certain records and information are exempt from
                          disclosure. These exemptions serve various needs,
                          including maintaining the privacy of individuals. Both
                          State and federal laws provide exemptions.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          All information collected at this site becomes a
                          public record that may be subject to inspection and
                          copying by the public unless an exemption applies.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          In the event of a conflict between these Terms of Use
                          and the Public Records Act, the Information Practices
                          Act, or other law governing the disclosure of public
                          records, the Public Records Act, the Information
                          Practices Act, or other applicable law will control.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="10" type="a">
                    <li>
                      <strong>General Provisions</strong>
                      <ol start="1" type="i">
                        <li>
                          Entire Agreement/No Waiver
                          <ol start="1" type="1">
                            <li>
                              The parties' agreement on this page's subject
                              matter is complete with these Terms of Use.
                            </li>
                          </ol>
                          <ol start="2" type="1">
                            <li>
                              No breach or default under these terms may be
                              waived by Aiken Digital LLC in conjunction with
                              any other breach or default, whether prior or
                              subsequent.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Enforcement.
                          <ol start="1" type="1">
                            <li>
                              The invalidity or unenforceability of any portion
                              of these Terms of Use shall not affect the
                              validity or enforceability of any other part of
                              these Terms of Use, which shall otherwise continue
                              in full force and effect. The laws of the United
                              States and each of its individual States shall
                              apply to and be interpreted in accordance with any
                              and all disputes relating to these Terms of Use,
                              Ad Designer&rsquo;s Privacy Policy, your use of the
                              Site, any other Ad Designer web site / Platform, or
                              the Content, without giving effect to any
                              principles of conflicts of laws.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          You agree to the sole and exclusive jurisdiction and
                          venue of the federal or state courts in the applicable
                          US State in the event of any dispute of any kind
                          arising from or relating to these Terms of Use,
                          Ad Designer&rsquo;s Privacy Policy, your use of the Site /
                          Platform or the Content.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <MainFooter />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  );
};

export default Terms;
