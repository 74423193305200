/** External Dependencies */
import React from "react";
import styled from 'styled-components';
import { Input } from '@mui/material';

const InputLabel = styled.span`
  min-width: 10px;
  color: #777;
  font-size: 12px;
`;

const AMInput = styled((props) => (
  <Input
    {...props}
  />
))( () => ({
  color: '#555 !important',
  width: '70px',
  borderRadius: '4px',
  background: 'rgba(244, 244, 244, 0.6)',
  border: '1px solid #ddd',
  fontSize: '12px !important',
  position: 'relative',
  padding: '0 6px',
  fontFamily: 'MarkForMCNrw',
  height: '30px',

  '.MuiInputBase-input': {
    padding: 0
  },
  '&::before': {
    display: 'none',
  },
  '&::after': {
    border: 'none !important',
  }
}));

const InputUnit = styled.span`
  margin-right: 5px;
  color: #aaa;
  font-size: 12px;
`;

const AngleCorner = styled.span`
  width: 8px;
  height: 8px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  display: block;
  margin-bottom: 6px
`;

const FlipButton = styled.span`
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  background: ${({ active}) => active ? '#EAEAEA' : 'transparent'}
`

export {
  InputLabel,
  AMInput,
  InputUnit,
  AngleCorner,
  FlipButton
};