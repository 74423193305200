/** External Dependencies */
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
`;

const StyledSpinnerWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11111;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  user-select: none;

  label {
    color: #ffffff;
  }
`;

const StyledSpinner = styled.div`
  width: ${(props) => props.size || "48px"};
  height: ${(props) => props.size || "48px"};
  /* border: 5px solid #7B22EC; */
  border: ${({ size = 48 }) => (parseInt(size) / 9.6 < 2 ? 2 : parseInt(size) / 9.6)}px
    solid ${(p) => p.color || "#fff"};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { StyledSpinnerWrapper, StyledSpinner };
