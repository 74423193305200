/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

/** Internal Dependencies */
import restrictNumber from "../../../utils/restrictNumber";
import ColorInput from "../ColorInput";
import {
  StyledTwoColumnsDirectionContainer,
  StyledColumn,
  StyledSpacedOptionFieldsEffect,
  FlexRow,
} from "./AnnotationOptions.styled";
import Slider from "../Slider";
import InputAdornment from "@mui/material/InputAdornment";
import { AMInput, InputLabel, InputUnit } from "../AMTransform/index.styled";
import { useStore } from "../../../hooks";
import InputStandard from "../InputStandard";

const ShadowFields = ({ annotation, updateAnnotation, t }) => {
  const {
    shadowOffsetX,
    shadowOffsetY,
    shadowBlur,
    shadowColor,
    shadowOpacity,
  } = annotation;

  const { dispatch, selectionsIds = [] } = useStore();

  const changeSliderValue = (property, newValue, min = 0, max = 100) => {
    // dispatch({
    //   type: 'SET_FILTER',
    //   payload: {
    //     selected: selectionsIds[0],
    //     filter: {
    //       name: [property],
    //       value: restrictNumber(newValue, min, max),
    //     }
    //   }
    // })
    updateAnnotation({
      [property]: restrictNumber(newValue, min, max),
    });
  };

  const changeInputValue = (property, e) => {
    const v = e.target.value;
    // console.log(typeof v)
    if (property === "shadowOpacity") {
      updateAnnotation({
        [property]: typeof v === "string" ? annotation[property] : v / 100,
      });
    } else {
      updateAnnotation({
        [property]: v,
      });
    }
  };

  const changeShadowColor = (newShadowColor) => {
    updateAnnotation({ shadowColor: newShadowColor });
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(221, 221, 221, 1)",
        borderRadius: "5px",
        padding: "8px 12px",
      }}
    >
      <StyledSpacedOptionFieldsEffect>
        <StyledTwoColumnsDirectionContainer>
          <StyledColumn>
            <InputLabel>X</InputLabel>
            <InputStandard
              type="number"
              className="m-2"
              value={shadowOffsetX}
              sx={{ width: "70px" }}
              onChange={(val) =>
                changeInputValue("shadowOffsetX", val, -100, 100)
              }
            />
          </StyledColumn>
          <StyledColumn>
            <InputLabel>Y</InputLabel>

            <InputStandard
              type="number"
              className="m-2"
              sx={{ width: "70px" }}
              onChange={(val) =>
                changeInputValue("shadowOffsetY", val, -100, 100)
              }
              value={shadowOffsetY}
            />
          </StyledColumn>
        </StyledTwoColumnsDirectionContainer>
        <StyledTwoColumnsDirectionContainer>
          <StyledColumn>
            <InputLabel>Blur</InputLabel>

            <InputStandard
              type="number"
              className="m-2"
              sx={{ width: "70px" }}
              onChange={(val) => changeInputValue("shadowBlur", val)}
              value={shadowBlur}
            />
          </StyledColumn>

          {/* dummy, will be removed in the future */}
          <StyledColumn>
            <AMInput
              sx={{
                opacity: 0,
              }}
              annotation="px"
              type="number"
              className="m-2"
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <InputUnit>px</InputUnit>
                </InputAdornment>
              }
            />
          </StyledColumn>
        </StyledTwoColumnsDirectionContainer>
      </StyledSpacedOptionFieldsEffect>
      <StyledSpacedOptionFieldsEffect>
        <FlexRow className="mt-2">
          <ColorInput color={shadowColor} onChange={changeShadowColor} />
          <Slider
            annotation="%"
            className="pr-3 pl-3"
            onChange={(val) =>
              changeSliderValue("shadowOpacity", val / 100, 0, 1)
            }
            value={Math.round(shadowOpacity * 100)}
          />

          <InputStandard
            suffix={"%"}
            type="text"
            className="m-2"
            sx={{ width: "50px" }}
            min={0}
            max={1}
            onChange={(val) => changeInputValue("shadowOpacity", val)}
            value={Math.round(shadowOpacity * 100)}
          />
        </FlexRow>
      </StyledSpacedOptionFieldsEffect>
    </Box>
  );
};

ShadowFields.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ShadowFields;
