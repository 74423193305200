/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";
import AmSlider from "@mui/material/Slider";
import styled from "styled-components";

/** Internal Dependencies */

const RangeSlider = styled(AmSlider)(({ theme }) => ({
  width: "100%",
  // minWidth: '153px',
  color: "#777777 !important",
  marginRight: "20px",
  padding: "6px 0px !important",
  "& .MuiSlider-thumb": {
    backgroundColor: "#fff",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    width: "10px",
    height: "10px",
    "& :hover": {
      boxShadow: "none",
    },
    "&:focus, &:hover, &.Mui-active": {
      borderRadius: "50%",
      width: "10px",
      height: "10px",
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.02)",
    },
  },
  "& .MuiSlider-rail, & .MuiSlider-track ": {
    backgroundColor: "#777777",
    height: "3px",
  },
  "& .MuiSlider-root": {
    backgroundColor: "none",
  },
}));

const Title = styled.div`
  font-family: "Mark Offc Pro";
  font-size: 12px;
`;

const Slider = ({ onChange, title, ...props }) => {
  return (
    <div style={{ minWidth: "140px", padding: "0", margin: "3px 0 0" }}>
      <Title>{title}</Title>
      <RangeSlider
        annotation=""
        onChange={(_e, val) => (onChange ? onChange(val) : undefined)}
        {...props}
      />
    </div>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Slider;
