/** External Dependencies */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';

/** Internal Dependencies */
import nodesCommonPropTypes from '../nodesCommonPropTypes';
import Konva from "konva";

const RectNode = ({
  id,
  name,
  fill,
  x,
  y,
  width,
  height,
  scaleX,
  scaleY,
  rotation,
  annotationEvents,
  stroke,
  strokeWidth,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor,
  shadowOpacity,
  opacity,
  cornerRadius,
  dash,
  dashEnabled=false,
  shadowEnabled = true,
  blurRadius,
  ...otherProps
}) => {

  const rectRef = React.useRef();
  useEffect(() => {
    // rectRef?.current?.cache({ offset: 100 });
  })

  return(
    <Rect
      id={id}
      name={name}
      rotation={rotation}
      scaleX={scaleX}
      scaleY={scaleY}
      stroke={stroke}
      strokeWidth={strokeWidth}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      shadowBlur={shadowBlur}
      shadowColor={shadowColor}
      shadowOpacity={shadowOpacity}
      fill={fill}
      x={x}
      y={y}
      width={width}
      height={height}
      opacity={opacity}
      cornerRadius={cornerRadius}
      dash={dash}
      dashEnabled={dashEnabled}
      shadowEnabled={shadowEnabled}
      filters={[Konva.Filters.Blur]}
      blurRadius={blurRadius}
      ref={rectRef}
      {...annotationEvents}
      {...otherProps}
    />
  )
};

RectNode.defaultProps = {
  ...nodesCommonPropTypes.defaults,
  fill: '#000',
  cornerRadius: 0,
  width: 0,
  height: 0,
  shadowEnabled: true,
};

RectNode.propTypes = {
  ...nodesCommonPropTypes.definitions,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  annotationEvents: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  cornerRadius: PropTypes.number,
  shadowEnabled: PropTypes.bool,
};

export default RectNode;
