import React, { useEffect, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "./react-filerobot-image-editor/src";
import { MainLayout } from "../../../Layout.styled";
import axios from "axios";
import MainHeader from "../../common/Header";
import useAuth from "../../../utils/Auth";
import useMainContext from "../../../context/Main";
import { Modal, Box } from "@mui/material";
import GetCreative from "../../common/GetCreative/GetCreative";
import ProjectNameInputModal from "../../common/Modals/ProjectNameInputModal";
import ProjectSavingModal from "../../common/Modals/ProjectSavingModal";
import { ReactComponent as IconClose } from "../../../assets/svg/icon-close.svg";
import ImageOne from "../../../assets/images/profile/profile-img-1.png";
import ImageTwo from "../../../assets/images/profile/profile-img-2.png";
import ImageThree from "../../../assets/images/profile/profile-img-3.png";
import ImageFour from "../../../assets/images/profile/profile-img-4.png";
import ImageFive from "../../../assets/images/profile/profile-img-5.png";
import ImageSix from "../../../assets/images/profile/profile-img-6.png";
import ImageSeven from "../../../assets/images/profile/profile-img-7.png";
import ImageEight from "../../../assets/images/profile/profile-img-8.png";
import ImageNine from "../../../assets/images/profile/profile-img-9.png";
import ImageTen from "../../../assets/images/profile/profile-img-10.png";
import ImageEleven from "../../../assets/images/profile/profile-img-11.png";
import { ReactComponent as IconErrorTriangle } from "../../../assets/svg/icon-error-triangle.svg";
import { useTour } from "@reactour/tour";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import isMobileOrTablet from "../../../utils/isMobileOrTablet";

const Editor = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { project, setProject, projectLoading, setProjectLoading } =
    useMainContext();
  const [projectAPI, setProjectAPI] = useState("/project");
  const [openDimensionDialog, setOpenDimensionDialog] = useState(false);
  const [openProjectNameDialog, setOpenProjectNameDialog] = useState(false);
  const { setIsOpen, setCurrentStep } = useTour();
  const [projectChecking, setProjectChecking] = useState(true);
  // const [windowSize, setWindowSize] = useState(getWindowSize());

  // useEffect(() => {
  //     function handleWindowResize() {
  //         setWindowSize(getWindowSize());
  //     }
  //     window.addEventListener('resize', handleWindowResize);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowResize);
  //     };
  // }, []);

  // function getWindowSize() {
  //     const {innerWidth, innerHeight} = window;
  //     return {innerWidth, innerHeight};
  // }

  // Tour Guide Trigger
  useEffect(() => {
    const localShowTour = JSON.parse(localStorage.getItem("showTour"));

    if (
      !projectChecking &&
      !openProjectNameDialog &&
      (!localShowTour || !localShowTour[user._id])
    ) {
      setTimeout(() => {
        setCurrentStep(0);
        setIsOpen(true);
      }, 500);
    }
  }, [projectChecking, openProjectNameDialog]);

  useEffect(() => {
    if (user?.role === "Designer") {
      setProjectAPI("/template");
    } else {
      setProjectAPI("/project");
    }
  }, [user]);

  useEffect(() => {
    // if (windowSize.innerWidth >= 1024 && windowSize.innerHeight >= 678) {
    if (!isMobileOrTablet()) {
      if (
        project?.presetDimension_id?.category &&
        project?.presetDimension_id?.group
      ) {
        setOpenDimensionDialog(false);
        if (project?.name) {
          setOpenProjectNameDialog(false);
        } else {
          setOpenProjectNameDialog(true);
        }
        setProjectChecking(false);
      } else {
        setOpenDimensionDialog(true);
      }
    }
  });
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const JsonToBlob = (object) =>
    new Blob([JSON.stringify(object)], { type: "application/json" });
  const chunkUpload = async (
    blob,
    fileInfo = {},
    chunkSize = 1024 * 1024 * 3
  ) => {
    const totalChunks =
      parseFloat(blob.size) > parseFloat(chunkSize)
        ? Math.ceil(blob.size / chunkSize)
        : 1;
    let beginChunk = 0;
    let endChunk = chunkSize;

    try {
      let chunks = [];
      const filename = project.name;
      for (let counter = 0; counter < totalChunks; counter++) {
        const chunk = blob.slice(beginChunk, endChunk);
        const formData = new FormData();
        formData.append("chunk_order", counter);
        formData.append("filename", filename);
        formData.append("chunk", chunk, filename);
        chunks.push(
          axios.post(`${projectAPI}/upload-chunk`, formData, {
            headers: {
              Accept: "application/json",
            },
          })
        );
        beginChunk = endChunk;
        endChunk = endChunk + chunkSize;
      }
      await Promise.all(chunks);
      // const allChunk = await Promise.all(chunks);
      // console.log(allChunk);
      // allChunk.map(response => path = response?.data?.data?.path || null);
      // if (!path) {
      //   throw new Error('Invalid chunk path.');
      // }
      const formData = new FormData();
      formData.append("chunk_count", totalChunks);
      if (project?.groupname) {
        // onCreate
        formData.append("groupname", project.groupname);
      } else if (project?.template_group_id?.name) {
        // onEdit
        formData.append("groupname", project.template_group_id.name);
      }
      formData.append("filename", filename);
      formData.append("partner", project.partner);
      formData.append(
        "thumbnail",
        dataURLtoFile(
          fileInfo.imageBase64,
          `${filename}.${fileInfo.extension}`
        ),
        `${filename}.${fileInfo.extension}`
      );
      formData.append("thumbnail_ext", fileInfo.extension);
      formData.append("thumbnail_mimeType", fileInfo.mimeType);
      if (project?.description) {
        formData.append("description", project.description);
      }
      if (project?._id) {
        formData.append("project_id", project._id);
      }
      if (project?.presetDimension_id?._id) {
        formData.append("presetDimension_id", project.presetDimension_id._id);
      }
      const response = await axios.post(`${projectAPI}/save`, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      setProjectLoading(null);
      if (response?.data?.data && response?.data?.success) {
        toast.success("Asset saved successfully.");
        let currentProject = { ...project, ...response.data.data };
        localStorage.setItem(
          "project",
          JSON.stringify({ ...currentProject, ...{ state: {} } })
        );
        setProject(currentProject);
      } else {
        toast.error("Asset not saved. Something went wrong");
        console.error(response);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
      setProjectLoading(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ad Designer - Creative Designer</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <MainHeader />
        <MainLayout>
          <FilerobotImageEditor
            loadableDesignState={project?.state?.designState ?? null}
            source={
              project?.state?.designState?.imgSrc ??
              process.env.PUBLIC_URL + "/images/blank.png"
            }
            onSave={async (editedImageObject, designState) => {
              if (user) {
                await chunkUpload(
                  JsonToBlob({ editedImageObject, designState }),
                  editedImageObject
                );
              }
            }}
            tabsIds={[TABS.FILTERS, TABS.ANNOTATE, TABS.WATERMARK]}
            defaultTabId={TABS.ANNOTATE}
            defaultToolId={TOOLS.ARTBOARD}
          />
          {/* {!(windowSize.innerWidth >= 1024 && windowSize.innerHeight >= 678) && ( */}
          {isMobileOrTablet() && (
            <div className='bg-cover bg-bottom bg-[url("../../assets/images/profile/profile-bg-mobile.png")] sm:bg-center sm:bg-[url("../../assets/images/profile/profile-bg.png")] bg-white grow flex flex-col justify-center absolute w-full h-[calc(100%-55px)]'>
              <img
                src={ImageOne}
                alt=""
                className="absolute hidden md:block w-[65px] md:top-[9%]"
              ></img>
              <img
                src={ImageTwo}
                alt=""
                className="absolute hidden md:block w-[15px] top-[10%] left-[8%]"
              ></img>
              <img
                src={ImageThree}
                alt=""
                className="absolute hidden md:block w-[90px] top-[38%] left-[4%]"
              ></img>
              <img
                src={ImageFour}
                alt=""
                className="absolute hidden md:block w-[15px] bottom-[35%] left-[4%]"
              ></img>
              <img
                src={ImageFive}
                alt=""
                className="absolute hidden md:block w-[80px] bottom-[14%] left-[2%]"
              ></img>
              <img
                src={ImageSix}
                alt=""
                className="absolute w-[18px] md:bottom-[15%] md:left-[9%] bottom-[2%] left-[22%]"
              ></img>
              <img
                src={ImageSeven}
                alt=""
                className="absolute hidden md:block w-[88px] top-[19%] right-[3%]"
              ></img>
              <img
                src={ImageEight}
                alt=""
                className="absolute hidden md:block w-[75px] top-[51%] right-[6%]"
              ></img>
              <img
                src={ImageNine}
                alt=""
                className="absolute hidden md:block w-[75px] bottom-[18%] right-0"
              ></img>
              <img
                src={ImageTen}
                alt=""
                className="absolute md:hidden block w-[100px] bottom-0 right-[8%]"
              ></img>
              {/* <img src={ImageEleven} alt="" className="absolute md:hidden block w-[22px] top-[16%] right-0"></img> */}
              <div className="absolute top-[50%] left-[50%] w-[350px] h-[290px] rounded-[24px] border border-[#555555] bg-[#FFFFFFB2] translate-y-[-50%] translate-x-[-50%] flex justify-center items-center flex-col px-[28px] py-[55px]">
                <IconErrorTriangle />
                <p className="font-['Sofia_Pro'] text-[36px] text-[#111111] text-center mt-[13px] mb-[16px]">
                  Use Ad Designer on desktop
                </p>
                <p className="font-['Sofia_Pro'] text-[18px] leading-[25px] text-[#555555] text-center">
                  At this time, Ad Designer is not available on mobile devices. Use
                  it on desktop and learn more about its functionality{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/features")}
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          )}
        </MainLayout>
        <Modal
          componentsProps={{
            backdrop: { sx: { backdropFilter: "blur(10px)" } },
          }}
          open={openDimensionDialog}
          onClose={() => setOpenDimensionDialog(false)}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75vw",
              bgcolor: "background.paper",
              border: "none",
              outline: "none",
              boxShadow: 24,
              borderRadius: "20px",
              background: "rgba(255, 255, 255, 0.8)",
              overflow: "hidden",
            }}
          >
            <div className="bg-[#DBDBDB] h-[40px] grid grid-cols-3 gap-4">
              <div className="w-full"></div>
              <div className="w-full flex justify-center items-center font-['Sofia_Pro'] text-[15px] text-[#111111]">
                Choose one
              </div>
              <div className="flex items-center justify-end w-full">
                <button
                  className="px-3"
                  onClick={() => {
                    setOpenDimensionDialog(false);
                    navigate(-1);
                  }}
                >
                  <IconClose />
                </button>
              </div>
            </div>
            <div className="px-[40px] pt-[40px] pb-[48px] bg-[#FFFFFF]">
              <GetCreative />
            </div>
          </Box>
        </Modal>
        <ProjectNameInputModal
          showModal={openProjectNameDialog}
          closeModal={() => setOpenProjectNameDialog(false)}
        />
        {projectLoading && (
          <ProjectSavingModal
            modalLabel={projectLoading}
            showModal={projectLoading}
          />
        )}
      </motion.div>
    </>
  );
};

export default Editor;
