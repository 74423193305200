import React from "react";
import { Modal, Box } from "@mui/material";
import PropTypes from "prop-types";

const ConfirmModal = (props) => {
  return (
    <Modal
      componentsProps={{
        backdrop: { sx: { backdropFilter: "blur(10px)" } },
      }}
      open={props?.modalStatus}
      onClose={() => props.closeModal()}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "none",
          outline: "none",
          boxShadow: 24,
          borderRadius: "20px",
          background: "rgba(255, 255, 255, 0.8)",
          overflow: "hidden",
        }}
      >
        <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center font-['Sofia_Pro'] text-[15px] h-[40px]">
          {props.modalTitle ?? "Save as"}
        </div>

        <div className="container p-[30px] bg-[#F9F9F9]">
          <p className="font-['Sofia_Pro'] text-[14px] leading-[18x] text-[#555555]">
            {props.modalContent ?? "Changes you made may not be saved."}
          </p>
        </div>
        <div
          className="px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center"
          style={{
            justifyContent: props.hideDismissButton
              ? "flex-end"
              : "space-between",
          }}
        >
          {!props.hideDismissButton ? (
            <button
              className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center"
              onClick={(e) => props.closeModal(e)}
            >
              {props.dismissText ?? "Cancel"}
            </button>
          ) : null}
          <button
            className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center disabled:bg-[#BBBBBB] disabled:opacity-75"
            onClick={() => {
              props.onConfirm();
              props.closeModal();
            }}
          >
            {props.confirmText ?? "Proceed"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;

ConfirmModal.propTypes = {
  modalStatus: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  hideDismissButton: PropTypes.bool,
  dismissText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  closeModal: PropTypes.func,
};
