const { styled } = require("@mui/material");

export const StyledLayerItem = styled("div")`
  user-select: none;
  font-size: 12px;
  border-bottom-width: 1px;
  border-top-width: ${({ isDragging }) => (isDragging ? "1px" : 0)};
  /* padding: 8px; */
  height: 38px;
  color: #555555;
  cursor: default !important;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ active }) => (active ? "#F1F2F3" : "white")};
`;

export const Scroller = styled('div')({
  // maxHeight: '50vh',
  overflowY: "auto"
})