/** External Depepdencneis */
import React, { useCallback, useEffect, useMemo } from "react";

/** Internal Depepdencneis */
import { SELECT_TOOL } from "../../actions";
import { TOOLS_ITEMS, GROUP_TOOLS, GROUP_IDS } from "../tools/tools.constants";
import { useStore } from "../../hooks";
import {
  StyledToolbarGroup,
  StyledToolbarGroupItems,
  StyledToolsBar,
  StyledToolsBarItems,
} from "./ToolsBar.styled";

const ToolsBar = () => {
  const {
    t,
    dispatch,
    toolId,
    config: { defaultToolId },
  } = useStore();
  const currentToolId = toolId || defaultToolId;

  const selectTool = useCallback((newToolId) => {
    dispatch({
      type: SELECT_TOOL,
      payload: {
        toolId: newToolId,
      },
    });
  }, []);

  const items = useMemo(
    () =>
      Object.keys(GROUP_TOOLS).map((id) => {
        if (id === GROUP_IDS.NONE) {
          return GROUP_TOOLS[GROUP_IDS.NONE].map((id) => {
            const { Item } = TOOLS_ITEMS[id];
            return (
              Item && (
                <Item
                  key={id}
                  selectTool={selectTool}
                  t={t}
                  isSelected={currentToolId === id}
                />
              )
            );
          });
        } else {
          const { Icon, items } = GROUP_TOOLS[id];

          return (
            <StyledToolbarGroup key={"group-" + id} aria-selected={GROUP_TOOLS[GROUP_IDS.SHAPES].items.includes(currentToolId)}>
              <span className="tour__shapes">
                <Icon />
              </span>
              <StyledToolbarGroupItems>
                {items &&
                  items.map((id) => {
                    const { Item } = TOOLS_ITEMS[id];
                    return (
                      Item && (
                        <Item
                          key={id}
                          selectTool={selectTool}
                          t={t}
                          isSelected={currentToolId === id}
                        />
                      )
                    );
                  })}
              </StyledToolbarGroupItems>
            </StyledToolbarGroup>
          );
        }
      }),
    [currentToolId]
  );

  useEffect(() => {
    if (!toolId && currentToolId) {
      dispatch({
        type: SELECT_TOOL,
        payload: { toolId: currentToolId },
      });
    }
  }, []);

  return (
    <>
      <StyledToolsBar>
        {items && <StyledToolsBarItems>{items}</StyledToolsBarItems>}
      </StyledToolsBar>
    </>
  );
};

export default ToolsBar;
