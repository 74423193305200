/** External Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TextIcon } from '../../icons/text.svg'

/** Internal Dependencies */
import ToolsBarItemButton from '../../ToolsBar/ToolsBarItemButton';
import { TOOLS_IDS } from '../../../utils/constants';

const TextButton = ({ selectTool, isSelected, t }) => (
  <ToolsBarItemButton
    className="FIE_text-tool-button tour__text"
    id={TOOLS_IDS.TEXT}
    // label={t('textTool')}
    Icon={TextIcon}
    onClick={selectTool}
    isSelected={isSelected}
  />
);

TextButton.defaultProps = {
  isSelected: false,
};

TextButton.propTypes = {
  selectTool: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default TextButton;
