import ReactDOM from 'react-dom/client';
import './assets/scss/app.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </Router>
);
