import { LoadingButton } from "@mui/lab";

const { styled, Button, Typography } = require("@mui/material");

export const StyledDialogWrapper = styled("header")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  borderRadius: "20px",
  background: "rgba(255, 255, 255, 0.8)",
  overflow: "hidden",
  paddingTop: 0,
  paddingBottom: "48px",
});

export const StyledDialogHeader = styled(Typography)({
  color: "white",
  fontSize: "15px",
  background: "rgba(68, 68, 68, 0.72)",
  display: "flex",
  alignItems: "center",
  paddingLeft: "28px",
  height: "40px",
  fontSize: "15px",
});

export const StyledUploadTitle = styled(Typography)({
  textTransform: "uppercase",
  color: "#888888",
  fontSize: "10px",
  letterSpacing: ".1em",
  marginBottom: "5px",
});

export const StyledDropzoneWrapper = styled("div")({
  background: "rgba(255, 255, 255, 0.72)",
  borderRadius: "8px",
  marginBottom: "20px",
  padding: "10px",
});

export const StyledThumbsContainer = styled("aside")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
});

export const StyledCancelBtn = styled(Button)({
  color: "#888",
  fontSize: "13px",
  textTransform: "none",
});

export const StyledMainBtn = styled(LoadingButton)({
  background: "#7B22EC",
  fontSize: "13px",
  color: "#fff",
  textTransform: "none",
  display: 'flex',
  gap: '10px',

  '&.Mui-disabled': {
    color: '#fff',
    opacity: .6
  },

  '.MuiLoadingButton-loadingIndicator': {
    position: 'relative'
  },
  "&:hover": {
    background: "#7B22EC",
    opacity: "0.7"
  },
});

export const StyledDialogFooter = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 24px",
  height: "48px",
  fontSize: "13px",
  background: "none",
});


