import {React, useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import useAuth from '../../../utils/Auth';
import axios from 'axios';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import Button from '../../common/Button';
import PasswordInput from '../../common/PasswordInput';
import Input from '../../common/Input';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/icon-error.svg';
import { useFormik } from "formik";
import * as Yup from "yup";
import useMainContext from '../../../context/Main';
// import PrivacyModal from '../../common/Modals/PrivacyModal';
// import TermsModal from '../../common/Modals/TermsModal';
import PageAnimation from 'components/common/PageAnimation';
import { Helmet } from 'react-helmet';
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Register = () => {
  // const auth = useAuth();
  // const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const {loader, setLoader} = useMainContext();
  // const [openPrivacyModal, setOpenPrivacyModal] = useState(false)
  // const [openTermsModal, setOpenTermsModal] = useState(false)

  const formik = useFormik({
    initialValues:{
      email: '',
      password: '',
      passwordConfirmation: '',
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
      passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirmation Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
      termsAndConditions: Yup.boolean().oneOf([true],'You need to accept our terms of use'),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      let {email, password} = values;
      try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        const response = await axios.post(`/user`, formData, {
            headers: {
                Accept: "application/json",
            },
        });
        if (response?.data?.success) {
            setLoader(false);
            navigate('/verify-email');
        } else {
            setLoader(false);
            console.error(response);
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  })

  useEffect(() => {
    document.body.classList.add("register-page");
    return () => {
      document.body.classList.remove("register-page");
    };
  }, []);

  return (
    <>
      <Helmet>
          <title>Ad Designer - Register</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                  <form onSubmit={ formik.handleSubmit }>
                      <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Sign Up</h5>
                      <Input type="text" id="email" name="email" label="Email" placeholder="name@domain.com" value={ formik.values.email } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.email && formik.errors.email && { error: true, errorMsg: formik.errors.email })}> </Input>
                      <PasswordInput id="password" name="password" label="Password" value={ formik.values.password } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.password && formik.errors.password && { error: true, errorMsg: formik.errors.password })}> </PasswordInput>
                      <PasswordInput id="passwordConfirmation" name="passwordConfirmation" label="Re-type Password " value={ formik.values.passwordConfirmation } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && { error: true, errorMsg: formik.errors.passwordConfirmation })}> </PasswordInput>
                      <div className="flex items-start">
                          <div className="flex items-start">
                              <div className="flex items-center h-5">
                                  <input id="termsAndConditions" type="checkbox" onChange={ formik.handleChange } onBlur={ formik.handleBlur } className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800" />
                              </div>
                              <label htmlFor="termsAndConditions" className="ml-2 text-sm font-['Sofia_Pro'] font-normal text-[#555555]">By selecting this checkbox, you agree to our <Link to="/terms" className="font-['Sofia_Pro'] text-[#000000] underline hover:no-underline">Terms of Use</Link> and have read our <Link type='button'className="font-['Sofia_Pro'] text-[#000000] underline hover:no-underline" to="/privacy">Privacy Notice</Link>.</label>
                          </div>
                      </div>
                      { formik.touched.termsAndConditions && formik.errors.termsAndConditions && (
                        <label className="py-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                          <ErrorIcon className="mr-[7px] shrink-0"/>
                          { formik.errors.termsAndConditions }
                        </label>
                      )}
                      <Button type="submit" color="blue" text="Sign up" className="mt-[44px]" disabled={loader}></Button>
                      <div className="text-center my-[40px]">
                          <p><span className="text-[#999999] text-md font-['Sofia_Pro']">Already have an account?</span>  <Link to="/login" className="font-['Sofia_Pro'] text-[#555555] hover:underline">Log in</Link></p>
                      </div>
                  </form>
              </div>
            </div>
            <MainFooter />
            {/* <PrivacyModal showModal={openPrivacyModal} closeModal={() => setOpenPrivacyModal(false)} />
            <TermsModal showModal={openTermsModal} closeModal={() => setOpenTermsModal(false)} /> */}
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  )
}

export default Register
