/** External Dependencies */
import styled from 'styled-components';

const StyledAppWrapper = styled.div.attrs(({ $size = {} }) => ({
  style: {
    width: $size.width ?? '100%',
    height: $size.height ?? '100%',
  }
}))`
  
`;

const StyledMainContent = styled.div`
 
`;

const StyledCanvasAndTools = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledPhoneToolsAndTabs = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export {
  StyledAppWrapper,
  StyledMainContent,
  StyledCanvasAndTools,
  StyledPhoneToolsAndTabs,
};
