export const SET_FILTER = 'SET_FILTER';

const setFilter = (state, payload) => {
  const { annotations } = state;
  const selected = payload.selected

  const newAnnotations = {
    ...annotations,
    [selected]: {
      ...annotations[selected],
      [payload.filter.name]: payload.filter.value
    }
  }

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    // isDesignState: payload.isDesignState || true,
    annotations: newAnnotations,
  };
};

export default setFilter;
