import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Sofia Pro',
    fontSize: 12
  },
  palette: {
    type: 'light',
    primary: {
      main: '#7B22EC',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#F1F2F3',
    },
    text: {
      primary: '#555555',
      secondary: '#000',
      disabled: '#AAAAAA',
      hint: '#111',
    },
    divider: '#E0E0E0',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
  },
  components: {
    'MuiInputBase': {
      styleOverrides: {
        root: {
          color: '#555',
          border: '1px solid #ddd',
          borderRadius: '4px',
          background: 'rgba(244, 244, 244, 0.6)',
          fontSize: '12px',
          position: 'relative',
          padding: '0 6px',
          height: '30px',

          '&:hover, &:focus, &:active': {
            background: '#fff'
          },

          '.MuiInputBase-input': {
            padding: 0
          },
          '&::before': {
            display: 'none',
          },
          '&::after': {
            border: 'none !important',
          }
        },
      },
    },
    'MuiInputAdornment': {
      styleOverrides: {
        root: {
          '*': {
            color: '#AAAAAA !important',
            cursor: 'pointer',
            fontSize: '12px !important',
            userSelect: 'none'
          }
        }
      }
    }
  },
});

export default theme;