import {StyledNotFoundContainer} from "./AssetGallery.styled";
import {ReactComponent as EmptyIcon} from "../../../icons/empty-state.svg";


export default function NotFound({
     text = "Custom not found text",
     visibility = false,
 }) {
    return (
        <>
            {visibility && (
                <StyledNotFoundContainer>
                    {text}
                    <EmptyIcon style={{width: "2em", height: "2em"}}/>
                </StyledNotFoundContainer>
            )}
        </>
    );
};