/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import restrictNumber from "../../../utils/restrictNumber";
import {
  StyledSpacedOptionFields,
  StyledLabel,
} from "./AnnotationOptions.styled";
import Slider from "../Slider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { AMInput, InputUnit } from "../AMTransform/index.styled";
import { TOOLS_IDS } from "../../../utils/constants";
import { useStore } from "../../../hooks";
import InputStandard from "../InputStandard";

const MIN_PERCENTANGE = -100;
const MAX_PERCENTANGE = 100;

const Input = styled(MuiInput)`
  width: 48px;
  padding: 5px;
  color: #555;
  &::before {
    display: none;
  }
  &::after {
    content: "%";
    color: #aaa;
    transform: scaleX(1);
    right: 0;
    position: relative;
    border-width: 0;
    padding-bottom: 0px;
  }
`;

const ContrastField = ({ annotation, updateAnnotation, t, title }) => {
  const { contrast } = annotation;
  const { dispatch, selectionsIds = [] } = useStore();

  const changeContrast = (newContrast) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        selected: selectionsIds[0],
        filter: {
          name: "contrast",
          value: newContrast,
        },
      },
    });
  };

  const changeContrastInput = (newContrast) => {
    if (!isNaN(newContrast.target.value) || newContrast.target.value == "-") {
      updateAnnotation({
        contrast: newContrast.target.value,
      });
    } else {
      return false;
    }
  };

  return (
    <StyledSpacedOptionFields>
      <Grid container sx={{ marginTop: "-6px", flexWrap: "nowrap" }}>
        <Grid item className="w-full" sx={{ marginRight: "18px" }}>
          <StyledLabel
            sx={{
              color: "#777",
              fontSize: "12px",
              fontFamily: "MarkForMCNrw",
              paddingLeft: "5px",
              marginBottom: "3px",
            }}
          >
            {title}
          </StyledLabel>
          <Slider
            annotation="%"
            onChange={changeContrast}
            min={-100}
            max={100}
            step={1}
            value={contrast}
          />
        </Grid>
        <Grid item className="flex items-end">
          <InputStandard
            sx={{ width: "70px" }}
            onChange={changeContrastInput}
            value={contrast}
            suffix={"%"}
          />
        </Grid>
      </Grid>
    </StyledSpacedOptionFields>
  );
};

ContrastField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ContrastField;
