/** External Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import AlignCenter from '@scaleflex/icons/align-center';
import AlignLeft from '@scaleflex/icons/align-left';

/** Internal Dependencies */
import {
  StyledSpacedOptionFields,
  StyledIconWrapper,
} from '../../../common/AnnotationOptions/AnnotationOptions.styled';

import { ReactComponent as AlignLeftIcon } from '../../../icons/align-left.svg'
import { ReactComponent as AlignCenterIcon } from '../../../icons/align-center.svg'
import { ReactComponent as AlignRightIcon } from '../../../icons/align-right.svg'
import { ReactComponent as AlignJustifyIcon } from '../../../icons/align-justify.svg'

const TextAlignmentFields = ({
  annotation: text,
  updateAnnotation: updateText,
}) => {
  const { align } = text;

  const changeHorizontalAlignment = (newHorizonalAlignment) => {
    updateText({ align: newHorizonalAlignment });
  };

  return (
    <StyledSpacedOptionFields>
      <StyledIconWrapper
        onClick={() => changeHorizontalAlignment('left')}
        aria-selected={align === 'left'}
      >
        <AlignLeftIcon />
      </StyledIconWrapper>
      <StyledIconWrapper
        onClick={() => changeHorizontalAlignment('center')}
        aria-selected={align === 'center'}
      >
        <AlignCenterIcon />
      </StyledIconWrapper>
      <StyledIconWrapper
        onClick={() => changeHorizontalAlignment('right')}
        aria-selected={align === 'right'}
      >
        <AlignRightIcon />
      </StyledIconWrapper>
      <StyledIconWrapper
        onClick={() => changeHorizontalAlignment('justify')}
        aria-selected={align === 'justify'}
      >
        <AlignJustifyIcon />
      </StyledIconWrapper>
    </StyledSpacedOptionFields>
  );
};

TextAlignmentFields.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
};

export default TextAlignmentFields;
