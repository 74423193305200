import getSizeAfterRotation from "../utils/getSizeAfterRotation";

export const SET_POSITION = "SET_POSITION";

const setPosition = (state, payload) => {
  const { annotations, selectionsIds, artboard } = state;
  const { position = "top" } = payload;

  let updatedAnnotations = {};
  if (selectionsIds.length > 1) {
    let topMost = null;
    let leftMost = null;
    let rightMost = null;

    // Find the top-most, left-most or right-most annotation based on the `position` parameter
    selectionsIds.forEach((id) => {
      const annotation = annotations[id];
      if (
        position === "top" &&
        (topMost === null || annotation.y < annotations[topMost].y)
      ) {
        topMost = annotation.id;
      } else if (
        position === "left" &&
        (leftMost === null || annotation.x < annotations[leftMost].x)
      ) {
        leftMost = annotation.id;
      } else if (
        position === "right" &&
        (rightMost === null ||
          annotation.x + annotation.width >
            annotations[rightMost].x + annotations[rightMost].width)
      ) {
        rightMost = annotation.id;
      }
    });

    // Calculate the new positions for all selected annotations
    const newY = topMost ? annotations[topMost].y : null;
    const newX =
      position === "right"
        ? annotations[rightMost].x
        : leftMost
        ? annotations[leftMost].x
        : null;
    selectionsIds.forEach((id) => {
      const annotation = annotations[id];
      updatedAnnotations[id] = {
        ...annotation,
        x: newX !== null ? newX : annotation.x,
        y: newY !== null ? newY : annotation.y,
      };
    });
  } else if (selectionsIds.length === 1) {
    const annotation = { ...annotations[selectionsIds[0]] };

    const {
      width,
      height,
      scaleX = 1,
      scaleY = 1,
      rotation = 0,
      radius,
      radiusX,
      radiusY,
    } = annotation;

    const annotationWidth = width || (radius || radiusX) * 2;
    const annotationHeight = height || (radius || radiusY) * 2;
    const scaledRotatedAnnotationSize = getSizeAfterRotation(
      annotationWidth * scaleX,
      annotationHeight * scaleY,
      rotation
    );
    switch (position) {
      case "top":
        annotation.y = scaledRotatedAnnotationSize.offsetTop;
        break;
      case "right":
        annotation.x =
          artboard?.width -
          scaledRotatedAnnotationSize?.width +
          scaledRotatedAnnotationSize.offsetLeft;
        break;
      case "left":
        annotation.x = scaledRotatedAnnotationSize.offsetLeft;
        break;
      case "bottom":
        annotation.y =
          artboard?.height -
          scaledRotatedAnnotationSize?.height +
          scaledRotatedAnnotationSize.offsetTop;
        break;
      case "horizontal-center":
        annotation.y =
          artboard?.height / 2 -
          (scaledRotatedAnnotationSize?.height / 2 -
            scaledRotatedAnnotationSize.offsetTop);
        break;
      case "vertical-center":
        annotation.x =
          artboard?.width / 2 -
          (scaledRotatedAnnotationSize?.width / 2 -
            scaledRotatedAnnotationSize.offsetLeft);
        break;
      default:
        break;
    }

    updatedAnnotations = { [annotation.id]: annotation };
  }

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    isDesignState: payload.isDesignState || true,
    annotations: { ...annotations, ...updatedAnnotations },
  };
};

export default setPosition;
