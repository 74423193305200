import React from "react";
import {
  SEND_BACKWARD,
  SEND_FORWARD,
} from "components/Page/Editor/react-filerobot-image-editor/src/actions";
import { useStore } from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import { ReactComponent as BackwardIcon } from "../../icons/bring-backward.svg";
import { ReactComponent as ForwardIcon } from "../../icons/bring-forward.svg";
import { StyledButton } from "./Order.styled";

export default React.memo(function Order() {
  const { dispatch, selectionsIds = [] } = useStore();

  const handleForward = () => {
    dispatch({
      type: SEND_FORWARD,
      payload: {
        annotationsIds: selectionsIds,
      },
    });
  };

  const handleSendBackward = () => {
    dispatch({
      type: SEND_BACKWARD,
      payload: {
        annotationsIds: selectionsIds,
      },
    });
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <StyledButton variant="outlined" onClick={handleForward}>
        <ForwardIcon /> Bring Forward
      </StyledButton>
      <StyledButton variant="outlined" onClick={handleSendBackward}>
        <BackwardIcon /> Bring Backward
      </StyledButton>
    </div>
  );
});
