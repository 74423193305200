import {StyledGalleryImageItem} from "./AssetGallery.styled";
import InfiniteScroll from "react-infinite-scroll-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Masonry, Skeleton} from "@mui/lab";
import {useState, useEffect} from "react";
import axios from "axios";
import NotFound from "./NotFound";
import { StyledSpinner } from "@nextui-org/react";

const PER_PAGE = 15;

export default function UserTabContent({onThumbClick, userNewUploadImages, skeletonAmount}) {
    const [images, setImages] = useState([]);
    const [imageIds, setImageIds] = useState([])
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [loadMore, setLoadMore] = useState(true);
    const [selected, setSelected] = useState(null);
    const [dataLength, setDataLength] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [actualPage, setActualPage] = useState(null);
    const [deletedFiles, setDeletedFiles] = useState(0);
    const [scrollCount, setScrollCount] = useState(0);
    const [skeletons, setSkeletons] = useState([]);

    const isSelected = (image) => {
        if (selected?.image_url) {
            return selected?.image_url === image?.image_url
        } else if (selected?.id) {
            return selected?.id === image?.id
        } else {
            return false
        }
    }

    const fetchImages = () => {
        if(loading) return;
        setLoading(true)
        axios("/image/my-library", {
            params: {
                page: page,
                records: PER_PAGE,
            },
        })
            .then((res) => {
                if (res?.data?.images && res?.data?.success) {
                    const newImages = [];
                    res?.data?.images?.docs.forEach(img => {
                        if (!imageIds.includes(img?._id)) {
                            newImages.push({
                                ...img,
                            })
                        }
                    })

                    const data = [...images, ...newImages]

                    setImages(data);
                    setImageIds((prevState) => {
                        return [
                            ...prevState,
                            ...newImages.map(img => img?._id)
                        ]
                    });
                    setDataLength(data.length)
                    const {hasNextPage, page, totalPages, totalDocs, limit} = res.data.images;
                    setTotalImages(totalDocs)
                    setLoadMore(hasNextPage)
                    setDataLength(page >= totalPages ? totalDocs : limit * page)
                } else {
                    console.error(res);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onDelete = (id) => {
        const getDeleteImageIndex = (image => {
            return image._id === id;
        })
        const targetIndex = images.findIndex(getDeleteImageIndex);
        if (targetIndex < 0) {
            console.error('Invalid Image Id')
            return;
        }

        images.splice(targetIndex, 1)
        // imageIds.splice(targetIndex, 1)

        // const newDataLength = images.length - 1;
        const newTotalImages = totalImages - 1 >= 0 ? totalImages - 1 : 0
        setTotalImages(newTotalImages)
        setDeletedFiles(deletedFiles + 1)

        axios.delete(`/image/my-library/${id}`).then(() => fetchImages());
    }

    const nextPage = () => {
        if (actualPage === page) {
            fetchImages()
        }

        setPage(actualPage || page + 1)
        setActualPage(null)
    }

    useEffect(() => {
        setScrollCount(dataLength + deletedFiles)
    }, [dataLength, deletedFiles, images]);

    useEffect(() => {
        fetchImages();
    }, [page]);

    useEffect(() => {
        if (userNewUploadImages.length <= 0) return
        const images = userNewUploadImages.filter(element => {
            return element !== undefined;
        });
        setImages(current => {
            return [
                ...images,
                ...current,
            ]
        });
        setImageIds((prevState) => {
            return [
                ...prevState,
                ...images.map(img => img?._id)
            ]
        });

        document.getElementById('UserImageLibraryScrollable').scrollTop = 0;

        const newDataLength = dataLength + images.length;
        const newTotalImages = totalImages + images.length
        setDataLength(newDataLength)
        setTotalImages(newTotalImages)

        if (newDataLength < newTotalImages) {
            const newActualPage = newDataLength / PER_PAGE;
            setActualPage(
                newActualPage !== page ? newActualPage >= page ? Math.floor(newActualPage) + 1 : Math.floor(newActualPage) : null
            )
        }
    }, [userNewUploadImages])

    useEffect(() => {
        const arr = []
        for (let i = 0; i < skeletonAmount; i++) {
            arr.push((
                <div key={`SKT_${Date.now() * Math.random()}`} style={{ height: '100px' }}>
                    <Skeleton
                        sx={{ transform: "unset", height: "100%" }}
                    />
                </div>
            ))
        }

        setSkeletons(arr)
    }, [skeletonAmount])

    return (
        <div id="UserImageLibraryScrollable" className="h-[246px] rounded-[5px] overflow-y-auto">
            <InfiniteScroll
                dataLength={scrollCount} // only can increase, decrease will break infinite scroll
                next={nextPage}
                hasMore={loadMore}
                loader={<div className="w-full flex justify-center h-[24px]"><StyledSpinner size="20px" color="#333" /></div>}
                endMessage={<NotFound text="Upload your images" visibility={dataLength <= 0} />}
                scrollableTarget="UserImageLibraryScrollable"
                scrollThreshold="100px"
                className="!overflow-hidden"
            >
                {images && images.length > 0 && (
                    <Masonry columns="3" spacing="2" sx={{ width: "unset" }}>

                        {skeletons}
                        {images.map((image) => {
                            return (
                                <StyledGalleryImageItem
                                    selected={isSelected(image)}
                                    onClick={() => {
                                        setSelected(image);
                                        onThumbClick(image);
                                    }}
                                    key={image?.id || image?.image_url}
                                    className='user-upload-image'
                                >
                                    <img src={image?.image_url_thumbnail} className="animate-in fade-in zoom-in" alt="" />
                                    <div className="delete-icon p-1 absolute top-2 right-1" onClick={(e) => {
                                        e.stopPropagation()
                                        onDelete(image?._id || image?.id)
                                    }}>
                                        <FontAwesomeIcon className="w-[18px] h-[18px]" icon="fa-solid fa-trash-can" />
                                    </div>
                                </StyledGalleryImageItem>
                            );
                        })}
                    </Masonry>
                )}
            </InfiniteScroll>
        </div>
    );
}
