import { useCallback } from "react";
import hasNumber from "components/Page/Editor/react-filerobot-image-editor/src/utils/hasNumber";
import { useAnnotation } from "../../hooks";
import { TOOLS_IDS } from "../../utils/constants";
import restrictNumber from "../../utils/restrictNumber";
import { ReactComponent as BoldIcon } from "../icons/bold.svg";
import { ReactComponent as ItalicIcon } from "../icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "../icons/underline.svg";
import { IconButton } from "./NodeControls.styled";

export default function FontStylePopup() {
  const [text, saveText] = useAnnotation({ name: TOOLS_IDS.TEXT });

  const changeFontStyle = useCallback(
    (newStyle) => {
      let fontStyle = text.fontStyle?.replace("normal", "").split(" ") || [];

      if (Object.keys(fontStyle).length > 0 && fontStyle.includes(newStyle)) {
        fontStyle = fontStyle.filter((style) => style !== newStyle);
      } else {
        fontStyle.push(newStyle);
      }

      changeTextProps({
        target: {
          name: "fontStyle",
          value: fontStyle.join(" ").trim() || "400",
        },
      });
    },
    [text]
  );
  const toggleBold = useCallback(() => {
    let fontStyle = text.fontStyle?.replace("normal", "").split(" ") || [];

    if (hasNumber(text.fontStyle)) {
      fontStyle = fontStyle.map(item => {
        if (hasNumber(item)) {
          if (parseInt(item, 10) > 400) {
            return '400'
          } else {
            return '800'
          }
        } else {
          return item
        }
      })
    } else {
      fontStyle.push('800')
    }

    changeTextProps({
      target: {
        name: "fontStyle",
        value: fontStyle.join(" ").trim() || "400",
      },
    });
  }, [text])

  const toggleTextDecoration = useCallback(() => {
    changeTextProps({
      target: {
        name: "textDecoration",
        value: text.textDecoration === "underline" ? "none" : "underline",
      },
    });
  }, [text]);

  const changeTextProps = useCallback(
    (e) => {
      const { name, value, type } = e.target;
      saveText((latestText) => ({
        id: latestText.id,
        [name]: type === "number" ? restrictNumber(value, 1, 500) : value,
      }));
    },
    [saveText]
  );

  const checkBold = () => {
    if (hasNumber(text.fontStyle)) {
      const num = text.fontStyle.split(' ').find(item => {
        if (hasNumber(item)) {
          // console.log({item})
          return item
        } else {
          return false
        }
      })

      // console.log({num})

      return num > 400
    }
  }

  // console.log(typeof text.fontStyle, text.fontStyle)

  return (
    <>
      <IconButton
        active={checkBold()}
        onClick={toggleBold}
      >
        <BoldIcon />
      </IconButton>

      <IconButton
        active={text?.fontStyle?.includes("italic")}
        onClick={() => changeFontStyle("italic")}
      >
        <ItalicIcon />
      </IconButton>

      <IconButton
        onClick={toggleTextDecoration}
        active={text?.textDecoration === "underline"}
      >
        <UnderlineIcon />
      </IconButton>
    </>
  );
}
