import useMainContext from "../../../context/Main";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import useAuth from "../../../utils/Auth";
import {TrackGoogleAnalyticsEvent} from '../../../utils/GA4';

const GetCreative = () => {
  const { user } = useAuth();
  const { setProject, reloadProjectIndex } = useMainContext();
  const navigate = useNavigate();

  const resizeFunction = () => {
    if (document.getElementById("btnWithIcon") !== null) {
      const resizedWidth = document
        .getElementById("btnWithIcon")
        .getBoundingClientRect().width;
      if (document.getElementById("button-menu-container")) {
        const elem = document
          .getElementById("button-menu-container")
          .getElementsByClassName("MuiPopover-paper")[0];
        elem.style.width = resizedWidth + "px";
      }
    }
  };

  const resizeMenu = () => {
    window.setTimeout(function () {
      resizeFunction();
    }, 100);
  };

  window.addEventListener("resize", function () {
    resizeFunction();
  });

  const openEditor = (config) => {
    const name = config.presetDimension_id.group;
    TrackGoogleAnalyticsEvent('Get Creative', 'Platform', name)

    if (user) {
      setProject(config);
      reloadProjectIndex();
      navigate("/editor");
    } else {
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 gap-[60px]">
      <div className="flex flex-col">
        <img
          src={"/images/homepage/box1.png"}
          alt="masthead"
          className="object-cover w-[100%] mb-4"
        ></img>
        <h4 className="text-[24px] font-['Sofia_Pro'] text-[#111111] mb-[8px]">
          Social Ads
        </h4>
        <p className="flex-1 font-['Sofia_Pro'] text-sm text-[#555555]">
          Create ads for your Social Media pages.
        </p>
        <div className="relative inline-block text-left" id="btnWithIcon">
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <div {...bindTrigger(popupState)} onClick={resizeMenu}>
                  <button
                    {...bindTrigger(popupState)}
                    className="font-['Sofia_Pro'] btn-3d-2 w-full mt-[40px] text-[#111111] border border-[#111111] bg-[#FFFFFF] hover:bg-[#000000] hover:text-white font-medium rounded-lg text-sm h-[50px] text-center group min-w-[181px]"
                  >
                    <span className="top-[14px] relative">
                      Create Social Ads
                    </span>{" "}
                    <span className="float-right border-l border-[#000] px-[20px] py-[14px] group-hover:border-[#FFF]">
                      <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                    </span>
                  </button>
                </div>
                <Menu
                  {...bindMenu(popupState)}
                  className="w-full"
                  id="button-menu-container"
                >
                  <MenuItem
                    onClick={() =>
                      openEditor({
                        presetDimension_id: {
                          category: "Social",
                          group: "Facebook",
                        },
                      })
                    }
                  >
                    Facebook
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      openEditor({
                        presetDimension_id: {
                          category: "Social",
                          group: "Instagram",
                        },
                      })
                    }
                  >
                    Instagram
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      openEditor({
                        presetDimension_id: {
                          category: "Social",
                          group: "X",
                        },
                      })
                    }
                  >
                    X
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      openEditor({
                        presetDimension_id: {
                          category: "Social",
                          group: "LinkedIn",
                        },
                      })
                    }
                  >
                    LinkedIn
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </div>
      </div>
      <div className="flex flex-col">
        <img
          src={"/images/homepage/box2.png"}
          alt="masthead"
          className="object-cover w-[100%] mb-4"
        ></img>
        <h4 className="text-[24px] font-['Sofia_Pro'] text-[#111111] mb-[8px]">
          Banners
        </h4>
        <p className="flex-1 font-['Sofia_Pro'] text-sm text-[#555555]">
          Banners for Desktop and Mobile audiences.
        </p>
        <Button
          color="black"
          text="Create Banners"
          className="mt-[40px]"
          onClick={() =>
            openEditor({
              presetDimension_id: { category: "Banner", group: "Banner" },
            })
          }
        ></Button>
      </div>
      {/* <div className="flex flex-col">
                <img src={'/images/homepage/box3.png'} alt="masthead" className="object-cover w-[100%] mb-4"></img>
                <h4 className="text-[24px] font-['Sofia_Pro'] text-[#111111] mb-[8px]">EDMs</h4>
                <p className="flex-1 font-['Sofia_Pro'] text-sm text-[#555555]">Mobile-optimised EDMs to better engage your audience.</p>
                <button className="btn-3d-2 w-full mt-[40px] text-[#111111] border border-[#111111] bg-[#FFFFFF] hover:bg-[#000000] hover:text-white font-medium rounded-lg text-sm px-5 py-[14px] text-center">Create EDMs</button>
            </div> */}
      <div className="flex flex-col">
        <img
          src={"/images/homepage/box3.png"}
          alt="masthead"
          className="object-cover w-[100%] mb-4"
        ></img>
        <h4 className="text-[24px] font-['Sofia_Pro'] text-[#111111] mb-[8px]">
          Custom
        </h4>
        <p className="flex-1 font-['Sofia_Pro'] text-sm text-[#555555]">
          Define your creative size and layout to suit your marketing needs.
        </p>
        <Button
          color="black"
          text="Create Custom"
          className="mt-[40px]"
          onClick={() =>
            openEditor({
              presetDimension_id: {
                _id: "635f2d633202872dbd29437a",
                category: "Custom",
                group: "Custom",
              },
            })
          }
        ></Button>
      </div>
    </div>
  );
};

export default GetCreative;
