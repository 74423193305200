import { React, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import GetCreative from '../../common/GetCreative/GetCreative';
import axios from 'axios';
import ThumbnailCardSkeleton from '../../common/Thumbnail/ThumbnailCardSkeleton';
import ThumbnailCard from '../../common/Thumbnail/ThumbnailCard';
import ThumbnailCardTopImage from '../../common/Thumbnail/ThumbnailCardTopImage';
import useMainContext from '../../../context/Main';
import useAuth from '../../../utils/Auth';
import DeleteConfirmationModal from '../../common/Modals/DeleteConfirmationModal';
import fileDownload from 'js-file-download';
import ProjectPreviewModal from '../../common/Modals/ProjectPreviewModal';
import PageAnimation from "../../common/PageAnimation";
import TemplateTypeDimensionModal from '../../common/Modals/TemplateTypeDimensionModal';
import { toast } from 'react-toastify';
import isMobileOrTablet from "../../../utils/isMobileOrTablet";
import {TrackGoogleAnalyticsEvent} from "../../../utils/GA4";
import usePrompt from '../Editor/react-filerobot-image-editor/src/hooks/usePrompt';
import GenericProjectNameModal from 'components/common/Modals/GenericProjectNameModal';
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Home = () => {
  const source = useRef(null)
  const navigate = useNavigate()
  const {user} = useAuth()
  const [recentProject, setRecentProject] = useState([])
  const [popularTemplate, setPopularTemplate] = useState([])
  const [recentTemplate,
    setRecentTemplate] = useState([])
  const [partnerTemplate, setPartnerTemplate] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [openDeleteConformationModal, setOpenDeleteConformationModal] = useState(false)
  const [openProjectPreviewModal, setOpenProjectPreviewModal] = useState(false)
  const {openProject, openTemplate} = useMainContext()
  const [loader, setLoader] = useState(false);
  const [openTemplateTypeDimensionModal, setOpenTemplateTypeDimensionModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const { triggerPrompt, value, ...prompt } = usePrompt();
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedProject, setSelectedProject] = useState('')

  const moreButton = [
    {
      id : 1,
      name: 'Preview',
    },
    {
      id : 2,
      name: 'Edit'
    },
    {
      id : 3,
      name: 'Duplicate'
    },
    {
      id : 4,
      name: 'Download'
    },
    {
      id : 5,
      name: 'Delete',
      textColor: '!text-[#DF1E1E]'
    },
  ]
  const moreTemplateButton = [
    {
      id : 1,
      name: 'Preview',
    },
    {
      id : 2,
      name: 'Use Template'
    },
  ]

  const [isMobile, setIsMobile] = useState(isMobileOrTablet());

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(isMobileOrTablet())
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const mapProject = (source) => {
    return {
        id: source?._id,
        image: source?.thumbnail_url,
        thumbnail: source?.thumbnail_preview_url,
        type: source?.presetDimension_id?.group,
        size: `${ source?.presetDimension_id?.width }x${ source?.presetDimension_id?.height }`,
        link: '/editor',
        title: source?.name,
        created_at: source?.createdAt,
        insituBG: source?.presetDimension_id?.insituBG,
        position: source?.presetDimension_id?.position,
        group: source?.presetDimension_id?.group,
    }
  }
  const fetchData = async () => {
    try {
        setLoader(true);
        const response = await axios.get(`/home`);
        setLoader(false);
        if (response?.data?.data && response?.data?.success) {
            if (response.data.data?.recentProject) {
              setRecentProject(
                response.data.data.recentProject.map(mapProject)
              )
            }
            if (response.data.data?.recentTemplate) {
              setRecentTemplate(
                response.data.data.recentTemplate.map(item => {
                  return {
                      id: item?._id,
                      image: item?.preview_template_id?.thumbnail_url,
                      thumbnail: item?.preview_template_id?.thumbnail_preview_url,
                      // type: item?.preview_template_id?.presetDimension_id?.group,
                      // size: `${ item?.preview_template_id?.presetDimension_id?.width }x${ item?.preview_template_id?.presetDimension_id?.height }`,
                      link: '/editor',
                      title: item?.name,
                      detail: item?.preview_template_id?.description || <>&nbsp;</>,
                      preview_template_id: item?.preview_template_id?._id,
                      insituBG: item?.preview_template_id?.presetDimension_id?.insituBG,
                      position: item?.preview_template_id?.presetDimension_id?.position,
                      group: item?.preview_template_id?.presetDimension_id?.group,
                  }
                })
              )
            }
            if (response.data.data?.partnerTemplate) {
              setPartnerTemplate(
                response.data.data.partnerTemplate.map(item => {
                  // console.log(item)
                  return {
                      id: item?._id,
                      image: item?.preview_template_id?.thumbnail_url,
                      thumbnail: item?.preview_template_id?.thumbnail_preview_url,
                      // type: item?.preview_template_id?.presetDimension_id?.group,
                      // size: `${ item?.preview_template_id?.presetDimension_id?.width }x${ item?.preview_template_id?.presetDimension_id?.height }`,
                      partner: item?.partner,
                      link: '/editor',
                      title: item?.name,
                      detail: item?.preview_template_id?.description || <>&nbsp;</>,
                      preview_template_id: item?.preview_template_id?._id,
                      insituBG: item?.preview_template_id?.presetDimension_id?.insituBG,
                      position: item?.preview_template_id?.presetDimension_id?.position,
                      group: item?.preview_template_id?.presetDimension_id?.group,
                  }
                })
              )
            }

            if (response.data.data?.popularTemplate) {
              setPopularTemplate(
                response.data.data.popularTemplate.map(item => {
                  return {
                      id: item?._id,
                      image: item?.preview_template_id?.thumbnail_url,
                      thumbnail: item?.preview_template_id?.thumbnail_preview_url,
                      // type: item?.preview_template_id?.presetDimension_id?.group,
                      // size: `${ item?.preview_template_id?.presetDimension_id?.width }x${ item?.preview_template_id?.presetDimension_id?.height }`,
                      partner: item?.preview_template_id?.partner,
                      link: '/editor',
                      title: item?.name,
                      detail: item?.preview_template_id?.description || <>&nbsp;</>,
                      preview_template_id: item?.preview_template_id?._id,
                      insituBG: item?.preview_template_id?.presetDimension_id?.insituBG,
                      position: item?.preview_template_id?.presetDimension_id?.position,
                      group: item?.preview_template_id?.presetDimension_id?.group,
                  }
                })
              )
            }
        } else {
            console.error(response);
        }
    } catch (error) {
        console.error(error);
    }
  }

  useEffect(() => {
    source.current = new AbortController();
    fetchData();
    document.body.classList.add("home-page");
    return () => {
      if (source.current) {
        source.current.abort()
      }
      document.body.classList.remove("home-page");
    };
  }, []);

  const viewProject = async (item) => {
    try {
      if (user) {
            setRecentProject(recentProject.map(project => {
                if (project?.id === item?.id) {
                    return {...project, ...{loading: true}}
                } else {
                    return {...project, ...{loading: null}}
                }
            }));
            await openProject(item?.id, {signal: source.current.signal});
        } else {
            navigate('/login', {replace: true});
        }
    } catch (error) {
        console.error(error);
    }
  }

  const deleteProject = async (item) => {
    try {
      if (user) {
            const response = await axios.delete(`/project/${ item?.id }`);
            if (response?.data?.success && response?.data?.data) {
                setRecentProject([])
                setPopularTemplate([])
                setRecentTemplate([])
                setSelectedItem({})
                setOpenDeleteConformationModal(false)
                setOpenProjectPreviewModal(false)
                fetchData()
            }
        } else {
            navigate('/login', {replace: true});
        }
    } catch (error) {
        console.error(error);
    }
  }

  const triggerSaveAsHandler = (project) => {
    triggerPrompt({
      onSubmit: async (newProjectName) => {
        prompt.closeModal();

        if (project?.id) {
          const formData = new FormData();
          formData.append("project_id", project.id);
          formData.append("filename", newProjectName); // saveas filename
          const response = await axios.post(`project/clone`, formData, {
            headers: {
              Accept: "application/json",
            },
          });
          if (
            response?.data?.success &&
            response?.data?.data &&
            response.data?.message
          ) {
            setRecentProject([...[response.data.data].map(mapProject), ...recentProject ])
            toast.success(response.data.message);
          } else {
            toast.error(JSON.stringify(response));
          }
        }
      },
    });
  };

  const moreAction = async (action, item) => {
    const {name} = action;
    if (name === 'Preview') {
      setOpenProjectPreviewModal(true)
    } else if (name === 'Edit') {
        await viewProject(item);
    } else if (name === "Duplicate") {
      triggerSaveAsHandler(item)
    } else if (name === 'Download') {
        if (item?.id && item?.image) {
          try {
              const response = await axios.get(`/project/download/${ item?.id }`, {
                  responseType: 'blob',
              });
              if (response?.data) {
                  fileDownload(response.data, item?.image.substring(item?.image.lastIndexOf('/')+1));
              } else {
                  console.error(response);
              }
          } catch (error) {
              console.error(error);
          }
        }
    } else if (name === 'Duplicate') {
    } else if (name === 'Delete') {
        setOpenDeleteConformationModal(true);
    }
  }

  const openTemplateFile = async (template) => {
    TrackGoogleAnalyticsEvent(selectedLocation, 'Use Template', `${selectedProject} : ${template?.presetDimension_id?.descriptionKey} : ${template?.presetDimension_id?.width}x${template?.presetDimension_id?.height}`)
    const res = await openTemplate(template?.id, null, {signal: source.current.signal});
  }

  const viewPopularTemplate = async (item) => {
    if (isMobile) return;

    try {
      if (user) {
          // setPopularTemplate(popularTemplate.map(project => {
          //     if (project?.id === item?.id) {
          //         return {...project, ...{loading: true}}
          //     } else {
          //         return {...project, ...{loading: null}}
          //     }
          // }));
          // await openTemplateFile(item);
          if (item?.id) {
              setSelectedLocation('Popular Templates')
              setSelectedProject(item.title)
              setSelectedId(item.id)
              setOpenTemplateTypeDimensionModal(true);
          } else {
              toast.error('Something went wrong');
          }
      } else {
          navigate('/login', {replace: true});
      }
    } catch (error) {
        console.error(error);
    }
  }

  const morePopularTemplateAction = async (action, item) => {
    const {name} = action;
    if (name === 'Preview') {
      TrackGoogleAnalyticsEvent('Popular Templates', 'Preview Template', item.title)
      setOpenProjectPreviewModal(true)
    } else if (name === 'Use Template') {
      await viewPopularTemplate(item);
    }
  }

  const viewPartnerTemplate = async (item) => {
    if (isMobile) return;

    try {
      if (user) {
            // setRecentTemplate(recentTemplate.map(project => {
            //     if (project?.id === item?.id) {
            //         return {...project, ...{loading: true}}
            //     } else {
            //         return {...project, ...{loading: null}}
            //     }
            // }));
            // await openTemplateFile(item);
            if (item?.id) {
                setSelectedLocation('Partner Templates')
                setSelectedProject(item.title)
                setSelectedId(item.id)
                setOpenTemplateTypeDimensionModal(true);
            } else {
                toast.error('Something went wrong');
            }
      } else {
          navigate('/login', {replace: true});
      }
    } catch (error) {
        console.error(error);
    }
  }

  const viewRecentTemplate = async (item) => {
    if (isMobile) return;

    try {
      if (user) {
            // setRecentTemplate(recentTemplate.map(project => {
            //     if (project?.id === item?.id) {
            //         return {...project, ...{loading: true}}
            //     } else {
            //         return {...project, ...{loading: null}}
            //     }
            // }));
            // await openTemplateFile(item);
            if (item?.id) {
                setSelectedLocation('Recent Templates')
                setSelectedProject(item.title)
                setSelectedId(item.id)
                setOpenTemplateTypeDimensionModal(true);
            } else {
                toast.error('Something went wrong');
            }
      } else {
          navigate('/login', {replace: true});
      }
    } catch (error) {
        console.error(error);
    }
  }

  const moreRecentTemplateAction = async (action, item) => {
    const {name} = action;
    if (name === 'Preview') {
      TrackGoogleAnalyticsEvent('Recent Templates', 'Preview Template', item.title)
      setOpenProjectPreviewModal(true)
    } else if (name === 'Use Template') {
      await viewRecentTemplate(item);
    }
  }

  const morePartnerTemplateAction = async (action, item) => {
    const {name} = action;
    if (name === 'Preview') {
      TrackGoogleAnalyticsEvent('Partner Templates', 'Preview Template', item.title)
      setOpenProjectPreviewModal(true)
    } else if (name === 'Use Template') {
      await viewPartnerTemplate(item);
    }
  }

  return (
    <PageAnimation>
      <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
          <MainHeader />
          <div className='flex flex-col grow'>
            <div className="masthead mb-[30px]">
              <img src={'/images/homepage/masthead_desktop.png'} alt="masthead" className="object-cover w-[100%] hidden sm:block"></img>
              <img src={'/images/homepage/masthead_mobile.png'} alt="masthead" className="object-cover w-[100%] sm:hidden"></img>
            </div>
            <div className="relative mb-[50px] px-5 md:px-0 hidden sm:block">
              <hr className="absolute top-[50%] w-full z-[-1] border-[#555555] hidden md:block" />
              <div className="container mx-auto max-w-7xl">
                <fieldset className="border border-[#555555] rounded-[24px] px-[30px] md:px-[60px] py-[48px] bg-white md:mx-10">
                  <legend align="center" className="legend text-center text-[12px] font-[700] font-['Sofia_Pro'] uppercase tracking-[0.2em] text-[#111111] relative"> &nbsp;  Get Creative  &nbsp; </legend>
                  <GetCreative />
                </fieldset>
              </div>
            </div>

            <div className="sm:hidden relative mb-[50px] md:px-0" data-aos="fade-up-right">
              <hr className="absolute top-[50%] w-full z-[-1] border-[#555555]" />
              <div className="container mx-auto max-w-7xl">
                <div className="w-[90%] mx-auto border border-[#555555] rounded-[24px] px-[20px] py-[28px] bg-white">
                  <img src={'/images/homepage/use_on_desktop.png'} alt="masthead" className="m-auto w-[75%]"></img>
                  <p className="text-[20px] font-['Sofia_Pro'] text-[#555555] mt-[20px]">At this time, Ad Designer is not available on mobile devices. Use it on desktop and learn more about its functionality <a style={{textDecoration: 'underline'}} onClick={() => navigate('/features')}>here</a>.</p>
                </div>
              </div>
            </div>

            <div className="sm:hidden relative mb-[50px] md:px-0" data-aos="fade-up-left">
              <div className="container w-[90%] mx-auto">
                <div className="flex flex-col col-span-12  md:col-span-6 rounded-[20px] p-[40px] bg-[#EEF3F9]">
                  <h3 className="font-['Sofia_Pro'] text-[30px] text-[#111111] group-hover:text-[#7B22EC] leading-[34px] mb-[16px]">Discover what Ad Designer can do for you</h3>
                  <p className="flex-1 mb-[16px] font-['Sofia_Pro'] text-[#555555]" >
                    No matter your role or level of design skills, we are here to make your worklife easier.
                    <span className="mt-[15px] flex items-center">
                        <Link className="h-full inline-block font-['Sofia_Pro'] text-[#7B22EC]" to='/features'>See Features</Link>
                        <span className="inline-block pl-1 cursor-pointer"><img src="/images/features/arrow.png" alt="arrow" onClick={() => navigate('/features')} /></span>
                    </span>
                  </p>
                  <img src='/images/homepage/feature_image.png' alt="feature page" className="m-auto" />
                </div>
              </div>
            </div>

            {user?.role === 'User' && recentProject && recentProject.length > 0 && (
              <div className="w-full bg-[#F0F4F9] mb-[50px]">
                <div className="container mx-auto max-w-6xl py-[50px]">
                    <div className={`flex justify-between mb-[20px] mx-[20px] md:mx-[40px]`}>
                      <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Recent Projects</h4>
                      <Link to={'/my-projects'} className="font-['Sofia_Pro'] text-[11px] text-[#7B22EC] hover:underline flex items-center tracking-[.12em]">SEE ALL</Link>
                    </div>
                    <div className="mx-[20px] md:mx-[40px]">
                      <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                        {recentProject.slice(0,4).map(item =>
                        <div key={item.id}>
                          <ThumbnailCardTopImage item={item} clicked={(e) => viewProject(item)} loading={item?.loading} more={moreButton} moreClicked={(e) => {setSelectedItem(item); moreAction(e, item)}} />
                        </div>
                        )}
                        <DeleteConfirmationModal showModal={openDeleteConformationModal} closeModal={() => {setOpenDeleteConformationModal(false); setSelectedItem({})}} item={selectedItem} conform={() => deleteProject(selectedItem)} />
                      </div>
                  </div>
                </div>
              </div>
            )}
            {user?.role === 'User' && loader && !(recentProject && recentProject.length > 0) && (
              <div className="w-full bg-[#F0F4F9] mb-[50px]">
                <div className="container mx-auto max-w-6xl py-[50px]">
                    <div className={`flex justify-start mb-[20px] mx-[20px] md:mx-[40px]`}>
                      <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Recent Projects</h4>
                    </div>
                    <div className="mx-[20px] md:mx-[40px]">
                      <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                        {[...Array(4)].map((x, i) =>
                            <ThumbnailCardSkeleton key={`recent-project-${ i }`} />
                        )}
                      </div>
                  </div>
                </div>
              </div>
            )}
            {partnerTemplate && partnerTemplate.length > 0 && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-between mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Partner Templates</h4>
                  <Link to={'/creative-templates/recent'} className="font-['Sofia_Pro'] text-[11px] text-[#7B22EC] hover:underline flex items-center tracking-[.12em]">SEE ALL</Link>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {partnerTemplate.map(item =>
                        <div key={item.id}>
                            <ThumbnailCard item={item} clicked={(e) => viewRecentTemplate(item)} loading={item?.loading} more={isMobile ? null : moreTemplateButton} moreClicked={(e) => {setSelectedItem(item); morePartnerTemplateAction(e, item)}} />
                        </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {loader && !(partnerTemplate && partnerTemplate.length > 0) && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-start mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Partner Templates</h4>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {[...Array(4)].map((x, i) =>
                        <ThumbnailCardSkeleton key={`partner-template-${ i }`} />
                    )}
                  </div>
                </div>
              </div>
            )}
            {recentTemplate && recentTemplate.length > 0 && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-between mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Recent Templates</h4>
                  <Link to={'/creative-templates/recent'} className="font-['Sofia_Pro'] text-[11px] text-[#7B22EC] hover:underline flex items-center tracking-[.12em]">SEE ALL</Link>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {recentTemplate.map(item =>
                        <div key={item.id}>
                            <ThumbnailCard item={item} clicked={(e) => viewRecentTemplate(item)} loading={item?.loading} more={isMobile ? null : moreTemplateButton} moreClicked={(e) => {setSelectedItem(item); moreRecentTemplateAction(e, item)}} />
                        </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {loader && !(recentTemplate && recentTemplate.length > 0) && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-start mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Recent Templates</h4>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {[...Array(4)].map((x, i) =>
                        <ThumbnailCardSkeleton key={`recent-template-${ i }`} />
                    )}
                  </div>
                </div>
              </div>
            )}
            {popularTemplate && popularTemplate.length > 0 && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-between mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Popular Templates</h4>
                  <Link to={'/creative-templates/popular'} className="font-['Sofia_Pro'] text-[11px] text-[#7B22EC] hover:underline flex items-center tracking-[.12em]">SEE ALL</Link>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {popularTemplate.filter(item => !item.partner).map(item =>
                        <div key={item.id}>
                            <ThumbnailCard item={item} clicked={(e) => viewPopularTemplate(item)} loading={item?.loading} more={isMobile ? null : moreTemplateButton} moreClicked={(e) => {setSelectedItem(item); morePopularTemplateAction(e, item)}} />
                        </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {loader && !(popularTemplate && popularTemplate.length > 0) && (
              <div className="container mx-auto max-w-6xl mb-[50px]">
                <div className={`flex justify-start mb-[20px] mx-[20px] md:mx-[40px]`}>
                  <h4 className="text-[#111111] text-[26px] font-['Sofia_Pro'] weight-700">Popular Templates</h4>
                </div>
                <div className="mx-[20px] md:mx-[40px]">
                  <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-[20px]`}>
                    {[...Array(4)].map((x, i) =>
                        <ThumbnailCardSkeleton key={`popular-template-${ i }`} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <MainFooter />
          <ProjectPreviewModal showModal={openProjectPreviewModal} closeModal={() => {setOpenProjectPreviewModal(false); setSelectedItem({})}} item={selectedItem} />
          <TemplateTypeDimensionModal showModal={openTemplateTypeDimensionModal} closeModal={() => {setOpenTemplateTypeDimensionModal(false)}} confirmModal={(event) => openTemplateFile(event)} id={selectedId} />
          {selectedItem?.id && (
            <GenericProjectNameModal
              {...prompt}
              initialValue={selectedItem?.title ?? ""}
              confirmButtonText="Duplicate"
              modalTitle="Duplicate"
            />
           )}
      </div>
      <MCRedirectModal />
    </PageAnimation>
  )
}

export default Home
