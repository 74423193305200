/** External Dependencies */
import styled from 'styled-components';

const BgCheckBoxItem = styled.div`
  padding: 6px 0 6px 0;
  border-bottom: 1px solid #E0E0E0;
  input[type=checkbox]:checked + label {
    .check {
      display: block;
    }
    .uncheck {
      display: none;
    }
  }
  &:last-child {
    border: none;
  }
`;

const BgCheckBox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 1px;
  float: left;
`;

const BgCustomCheckBox = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  .check {
    display: none;
    width: 12px;
    border-radius: 2px;
  }
  .uncheck {
    display: block;
    width: 12px;
    border-radius: 2px;
  }
`;

const BgCustomCheckBoxLabel = styled.div`
  padding: 0 0 0 8px;
  flex: 1 0;
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'MarkForMCNrw';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  span:last-child {
    white-space: nowrap;
  }
`;

export {
  BgCheckBoxItem,
  BgCheckBox,
  BgCustomCheckBox,
  BgCustomCheckBoxLabel,
};
