import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import useMainContext from "../../../context/Main";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/icon-error.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../../utils/Auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Hint } from "react-autocomplete-hint";

const ProjectNameInputModal = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { project, setProject } = useMainContext();
  const [hintData, setHintData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectWidth, setProjectWidth] = useState("");
  const [projectHeight, setProjectHeight] = useState("");
  const [partner, setPartner] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [validationRules, setValidationRules] = useState({
    filename: Yup.string()
      .matches(/^[\w\-\s]+$/, "Invalid file name")
      .required("File name is required"),
  });

  const getData = async () => {
    const response = await axios.get(`/template/group-name`);
    if (response?.data?.success && response?.data?.data) {
      setHintData(response.data.data);
    }
  };

  useEffect(() => {
    if (user?.role && user?.role !== "User") {
      getData();
      setValidationRules({
        ...validationRules,
        ...{
          groupname: Yup.string().required("Group Name is required"),
          description: Yup.string().required("Description is required"),
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      project?.presetDimension_id?.category === "Custom" &&
      project?.presetDimension_id?.group === "Custom"
    ) {
      setValidationRules({
        ...validationRules,
        ...{
          width: Yup.number().required("Width is required"),
          height: Yup.number().required("Height is required"),
        },
      });
    }
    formResetToDefault();
  }, [project]);

  const formResetToDefault = () => {
    setGroupName(project?.template_group_id?.name ?? "");
    formik.setFieldValue("groupname", project?.template_group_id?.name ?? "");
    setProjectName(project?.name ?? "");
    formik.setFieldValue("filename", project?.name ?? "");
    setPartner(project?.partner ?? "");
    formik.setFieldValue("partner", project?.partner ?? "");
    setProjectWidth(project?.state?.editedImageObject?.width ?? "");
    formik.setFieldValue(
      "width",
      project?.state?.editedImageObject?.width ?? ""
    );
    setProjectHeight(project?.state?.editedImageObject?.height ?? "");
    formik.setFieldValue(
      "height",
      project?.state?.editedImageObject?.height ?? ""
    );
    setProjectDescription(project?.description ?? "");
    formik.setFieldValue("description", project?.description ?? "");
  };

  const formik = useFormik({
    initialValues: {
      groupname: groupName,
      filename: projectName,
      partner: '',
      width: projectWidth,
      height: projectHeight,
      description: projectDescription,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: (values) => {
      let { groupname, filename, width, height, description, partner } = values;
      let currentProject = {
        ...project,
        ...{ groupname, name: filename.trim(), description, partner },
      };
      if (
        project?.presetDimension_id?.category === "Custom" &&
        project?.presetDimension_id?.group === "Custom"
      ) {
        if (currentProject?.state) {
          currentProject.state = {
            ...currentProject.state,
            ...{ editedImageObject: { width: +width, height: +height } },
          };
        } else {
          currentProject = {
            ...currentProject,
            ...{
              state: { editedImageObject: { width: +width, height: +height } },
            },
          };
        }
        localStorage.setItem("project", JSON.stringify(currentProject));
      } else {
        localStorage.setItem(
          "project",
          JSON.stringify({ ...currentProject, ...{ state: {} } })
        );
      }
      setProject(currentProject);
      props.closeModal();
    },
  });

  return (
    <Modal
      componentsProps={{
        backdrop: { sx: { backdropFilter: "blur(10px)" } },
      }}
      open={props?.showModal}
      onClose={() => props.closeModal()}
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "none",
          outline: "none",
          boxShadow: 24,
          borderRadius: "20px",
          background: "rgba(255, 255, 255, 0.8)",
          overflow: "hidden",
        }}
      >
        <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center font-['Sofia_Pro'] text-[15px] h-[40px]">
          {project?._id ? "Edit Project" : "New Project"}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-[30px] bg-[#F9F9F9]">
            {user?.role && user?.role === "Designer" && (
              <div className="pb-[20px]">
                <label
                  htmlFor="groupname"
                  className="text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]"
                >
                  Group Name
                </label>
                <Hint
                  options={hintData}
                  allowTabFill
                  onFill={(value) => {
                  }}
                >
                  <input
                    type="text"
                    id="groupname"
                    name="groupname"
                    placeholder="Group Name"
                    value={formik.values.groupname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full border border-solid ${
                      formik.touched.groupname && formik.errors.groupname
                        ? "border-[#DF1E1E]"
                        : "border-[#E5E5E5]"
                    } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
                  />
                </Hint>
                {formik.touched.groupname && formik.errors.groupname && (
                  <label className="py-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                    <ErrorIcon className="mr-[7px] shrink-0" />
                    {formik.errors.groupname}
                  </label>
                )}
              </div>
            )}
            <div>
              <label
                htmlFor="filename"
                className="text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]"
              >
                Project Name
              </label>
              <input
                type="text"
                id="filename"
                name="filename"
                placeholder="Project Name"
                value={formik.values.filename}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`w-full border border-solid ${
                  formik.touched.filename && formik.errors.filename
                    ? "border-[#DF1E1E]"
                    : "border-[#E5E5E5]"
                } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
              />
              {formik.touched.filename && formik.errors.filename && (
                <label className="py-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.filename}
                </label>
              )}
            </div>

            <div className={`mt-5 ${user?.role === "Designer" ? "" : " hidden "}`}>
              <label
                htmlFor="partner"
                className={`text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]`}
              >
                Partner
              </label>
              <input
                type="text"
                id="partner"
                name="partner"
                placeholder="Partner"
                value={formik.values.partner}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`w-full border border-solid ${
                  formik.touched.partner && formik.errors.partner
                    ? "border-[#DF1E1E]"
                    : "border-[#E5E5E5]"
                } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
              />
              {formik.touched.partner && formik.errors.partner && (
                <label className="py-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.partner}
                </label>
              )}
            </div>
            <div
              className={`pt-[20px] ${
                project?.presetDimension_id?.category === "Custom" &&
                project?.presetDimension_id?.group === "Custom"
                  ? ""
                  : "hidden"
              }`}
            >
              <label
                htmlFor="width"
                className="text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]"
              >
                Dimension
              </label>
              <div className="flex justify-between items-center flex-row flex-nowrap gap-[20px]">
                <div className="relative">
                  <input
                    type="text"
                    id="width"
                    name="width"
                    placeholder="Width"
                    value={formik.values.width}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full border border-solid ${
                      formik.touched.width && formik.errors.width
                        ? "border-[#DF1E1E]"
                        : "border-[#E5E5E5]"
                    } rounded-[4px] h-[36px] pl-[14px] pr-[35px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
                  />
                  <span className="absolute right-0 top-1/2 translate-y-[-50%] right-[14px] font-['Sofia_Pro'] text-[14px] text-[#AAAAAA] select-none">
                    px
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="height"
                    name="height"
                    placeholder="Height"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full border border-solid ${
                      formik.touched.height && formik.errors.height
                        ? "border-[#DF1E1E]"
                        : "border-[#E5E5E5]"
                    } rounded-[4px] h-[36px] pl-[14px] pr-[35px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999]`}
                  />
                  <span className="absolute right-0 top-1/2 translate-y-[-50%] right-[14px] font-['Sofia_Pro'] text-[14px] text-[#AAAAAA] select-none">
                    px
                  </span>
                </div>
              </div>
              {formik.touched.width && formik.errors.width && (
                <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.width}
                </label>
              )}
              {formik.touched.height && formik.errors.height && (
                <label className="pb-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.height}
                </label>
              )}
            </div>
            <div
              className={`pt-[20px] ${
                user?.role === "Designer" ? "" : " hidden "
              }`}
            >
              <label
                htmlFor="description"
                className="text-[#888888] font-['Sofia_Pro_Bold'] text-[12px] pb-[7px]"
              >
                Description
              </label>
              <textarea
                id="description"
                cols="30"
                rows="4"
                className={`w-full border border-solid ${
                  formik.touched.description && formik.errors.description
                    ? "border-[#DF1E1E]"
                    : "border-[#E5E5E5]"
                } rounded-[4px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] placeholder:text-[#999999] bg-[#FFFFFFB8] resize-none`}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Description"
              />
              {formik.touched.description && formik.errors.description && (
                <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                  <ErrorIcon className="mr-[7px] shrink-0" />
                  {formik.errors.description}
                </label>
              )}
            </div>
          </div>
          <div className="px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center">
            <button
              type="button"
              className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center"
              onClick={() => {
                formResetToDefault();
                project?.name ? props.closeModal() : navigate("/");
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center"
            >
              {project?._id ? "Rename" : "Create"}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ProjectNameInputModal;
