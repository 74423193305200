/** External Dependencies */
import React, { useState } from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import Accordion from "../../common/Accordion";
import { ReactComponent as NoFillIcon } from "../../icons/color-palletes/none.svg";
import { ReactComponent as NewFillIcon } from "../../icons/color-palletes/new-color.svg";
import { SET_ARTBOARD } from "components/Page/Editor/react-filerobot-image-editor/src/actions/setArtboard";
import {
  useOutsideClick,
  useStore,
} from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import { SketchPicker } from "react-color";
import rgba2hex from "components/Page/Editor/react-filerobot-image-editor/src/utils/rgba2hex";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";
import PalletePicker from "../../common/PalletePicker";
import { UPDATE_STATE } from "../../../actions";
import { TOOLS_IDS } from "../../../utils/constants";
import { colord } from 'colord'

const ArtboardOptions = () => {
  const { dispatch, artboard = {}, themeConfig } = useStore();

  const updateSelectedColor = (index, color) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        themeConfig: {
          Artboard: {
            background: themeConfig[TOOLS_IDS.ARTBOARD]?.background.map(
              (item, i) => {
                if (i === index) return colord(color).toHex();
                return item;
              }
            ),
          },
        },
      },
    });
  };

  const changeArtboardColor = (newColor) => {
    dispatch({
      type: SET_ARTBOARD,
      payload: {
        background: colord(newColor).toHex()
      },
    });
  };

  return (
    <Accordion title="Background">
      <div className="text-xs text-[#777777]">Fill</div>

      <PalletePicker
        color={artboard.background}
        onChange={changeArtboardColor}
        onPalleteAssign={updateSelectedColor}
        pallete={themeConfig[TOOLS_IDS.ARTBOARD].background}
      />
    </Accordion>
  );
};

ArtboardOptions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ArtboardOptions;
