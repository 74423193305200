import styled from "styled-components";

const TourBreakLine = styled.hr`
  width: 100%;
  border-color: #DDDDDD;
  margin: 24px 0;
`;

const BodyTextTitle = styled.div`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 7px;
  color: #111111;
`;

const BodyTextDesc = styled.div`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #555555;
`;

const BodyImageTextContext = styled.div`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #555555;
  margin-bottom: 12px;
`;

const BodyImageTextImage = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 4px;
  margin-right: 12px;
`;

const BodyImageTextContentWrapper = styled.div`
  width: 100%;
`;

const BodyImageTextCategory = styled.div`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 4px;
`

const BodyImageTextTitle = styled.div`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #555555;
`

const BodyImageTextLearnMore = styled.a`
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #000000;
`


export {
    TourBreakLine,
    BodyTextTitle,
    BodyTextDesc,
    BodyImageTextContext,
    BodyImageTextImage,
    BodyImageTextContentWrapper,
    BodyImageTextCategory,
    BodyImageTextTitle,
    BodyImageTextLearnMore,
};