import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as ErrorIcon } from '../../../assets/svg/icon-error.svg';

function PasswordInput(props) {

    const [passwordShown, setPasswordShown] = useState(false);
    //validation
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (<>
            <div className="mb-[20px]">
                <label htmlFor={props.id} className="font-['Sofia_Pro'] block mb-2 text-sm text-[#555555] mb-[8px]">{props.label}</label>
                <div className="relative w-full">
                    <div className="absolute inset-y-0 right-0 flex items-center px-2">
                        {passwordShown ? <FontAwesomeIcon icon="fa-solid fa-eye-slash" className="text-[#C4C4C4] pr-2 text-sm" onClick={togglePassword} /> : <FontAwesomeIcon icon="fa-solid fa-eye" className="text-[#C4C4C4] pr-2 text-sm" onClick={togglePassword} />}
                    </div>
                    <input type={passwordShown ? "text" : "password"} name={props.name} id={props.id} onChange={props.onChange} onBlur={props.onBlur} className={`font-['Sofia_Pro'] border ${ props.error ? 'border-[#DF1E1E]' : 'border-[#DDDDDD]' } text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${props.className}`} required="" autoComplete="off" />
                </div>
                {props.error && (
                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-start">
                        <ErrorIcon className="mr-[7px] shrink-0"/>
                        { props.errorMsg }
                    </label>
                )}
            </div>
    </>)
}

export default PasswordInput
