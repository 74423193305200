import { React, useCallback, useEffect, useRef, useState } from "react";
import MainHeader from "../../common/Header";
import MainFooter from "../../common/Footer";
// import { PictureAsPdfSharp } from '@mui/icons-material';
import PageAnimation from "components/common/PageAnimation";
// import { Helmet } from 'react-helmet-async';
import { Helmet } from "react-helmet";
import MCRedirectModal from "components/common/Modals/MCRedirectModal";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ReactComponent as ArrowDownIcon } from "images/icon-arrow-down.svg";
import { styled } from "@mui/material";
import accordionData from "./faq";
import Drawer from "react-bottom-drawer";

import { ReactComponent as LeftIcon } from "assets/svg/arrow-left.svg";
import { ReactComponent as RightIcon } from "assets/svg/arrow-right.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid #E5E5E5`,
  borderBottom: `1px solid #E5E5E5`,
  color: "#111",
  fontFamily: "Mark Pro",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },

  "& .MuiCollapse-entered": {
    background: "#F0F4F9",

    "& .MuiAccordionDetails-root": {
      borderTop: "none",
      transition: "none",
    },
  },
}));

const StyledIcon = styled(ArrowDownIcon)({
  "& path": {
    stroke: "#111",
  },
});

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<StyledIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: "row",
  color: "#111",
  transition: "none",
  paddingInline: "24px",

  "& .MuiTypography-root": {
    fontFamily: "Sofia Pro !important",
    fontSize: "18px",
  },

  "&.Mui-expanded": {
    background: "#F0F4F9",
  },

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    // transform: "rotate(90deg)",
  },

  "& .MuiAccordionSummary-content": {
    marginLeft: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "10px 24px 24px 24px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  color: "#555",

  "& .MuiTypography-root": {
    fontFamily: "Sofia Pro !important",
    fontSize: "14px",
    lineHeight: "18px",
  },

  "& ul": {
    listStyle: "disc",
    paddingLeft: "1.5rem",
    marginBlock: ".8rem",
  },
  "& a": {
    color: "#7B22EC",
    textDecoration: "underline"
  }
}));

const FAQ = () => {
  const [expanded, setExpanded] = useState();
  const [category, setCategory] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState(accordionData);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    document.body.classList.add("faq-page");
    return () => {
      document.body.classList.remove("faq-page");
    };
  }, []);

  const openDrawer = useCallback(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    setIsVisible(true);
  }, []);

  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const searchInput = useRef();

  const filters = [
    "General",
    "Usage and Navigation",
    "Design Features",
    "Importing/Exporting Files",
    "User Accounts / Access",
  ];

  return (
    <>
      <Helmet>
        <title>Ad Designer - FAQ</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen max-h-[100vh]">
          <MainHeader />
          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
            className="appDrawer"
          >
            <div className="pb-5">
              <div className="-mx-[10px] px-[28px] mb-3 text-[13px] font-extrabold py-3 uppercase tracking-[0.12em] border-b border-[#E5E5E5]">
                Category
              </div>
              <div className="flex flex-col gap-4 pb-6 px-[18px]">
                {filters.map((filter, i) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        i + 1 === category ? "text-[#7B22EC]" : ""
                      } hover:text-[#7B22EC] cursor-pointer rounded-[4px] text-[13px] text-[#555555] flex items-center`}
                      onClick={() => {
                        setCategory(i + 1);
                        setExpanded(undefined);
                        closeDrawer();
                      }}
                    >
                      {filter}
                    </div>
                  );
                })}
              </div>
            </div>
          </Drawer>

          <div className="grow flex flex-col">
            <div className="masthead mb-[30px]">
              <img
                src={"/images/faq/masthead_desktop.svg"}
                alt="masthead"
                className="object-cover w-[100%] hidden sm:block"
              ></img>
              <img
                src={"/images/faq/masthead_mobile.svg"}
                alt="masthead"
                className="object-cover w-[100%] sm:hidden"
              ></img>
            </div>

            <div className="relative mb-[50px] px-5">
              <div className="container mx-auto md:max-w-[1440px]">
                <div className="flex items-center mb-5">
                  <img
                    src={"/images/creative-templates/filter_icon.png"}
                    alt="masthead"
                    className=" w-[42px] h-[42px] border border-[#e5e5e5] rounded-full p-[10px] sm:hidden mr-1"
                    onClick={openDrawer}
                  ></img>
                  <div className="relative w-full sm:hidden">
                    <div className="absolute inset-y-0 right-0 flex items-center px-2">
                      <FontAwesomeIcon
                        icon="fa-solid fa-magnifying-glass"
                        className="text-[#C4C4C4] pr-2 text-sm"
                      />
                    </div>
                    <input
                      ref={searchInput}
                      type="text"
                      name="search"
                      id="search"
                      onChange={(e) => {
                        setData(
                          accordionData.filter((item) => {
                            return (
                              item.title
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()) ||
                              item.content
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            );
                          })
                        );
                      }}
                      placeholder="Search"
                      className="font-['Sofia_Pro'] border border-[#E5E5E5] text-sm rounded-[100px] block w-full py-[9px] pl-[20px] pr-[40px] focus:border-[#E5E5E5] focus:ring-0"
                      autoComplete="off"
                      disabled={false}
                    />
                  </div>
                </div>

                <div className="flex lg:gap-10 gap-4 lg:px-[10%]">
                  <aside className="w-[260px] shrink-0 hidden md:block">
                    <div className="rounded-[20px] border border-slate-300 p-5 shrink-0">
                      <h4 className="uppercase font-extrabold tracking-[0.12em] text-[11px] mb-3">
                        Categories
                      </h4>
                      <div className="flex flex-col gap-4">
                        {filters.map((filter, i) => {
                          return (
                            <div
                              key={i}
                              className={`${
                                i + 1 === category ? "text-[#7B22EC]" : ""
                              } hover:text-[#7B22EC] cursor-pointer rounded-[4px] text-xs text-[#555555] flex items-center`}
                              onClick={() => {
                                setCategory(i + 1);
                                setExpanded(undefined);
                              }}
                            >
                              {filter}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </aside>

                  <section>
                    <h2 className="text-[26px] mb-6">Design Features</h2>
                    {data
                      .filter((item) => item.category === category)
                      .map((item, i) => {
                        return (
                          <Accordion
                            key={i}
                            expanded={expanded === "panel" + i}
                            onChange={handleChange("panel" + i)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.content,
                                  }}
                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}

                    {/* Prev next navigation */}
                    {/* <div className="flex justify-between gap-8 mt-[50px]">
                      <div
                        className={`select-none cursor-pointer text-center p-5 border hover:border-[#111] rounded-[20px] flex-1 ${
                          category <= 1 ? "opacity-30" : ""
                        }`}
                        onClick={() => {
                          if (category > 1) {
                            setCategory((prev) => prev - 1);
                            setExpanded(undefined);
                          }
                        }}
                      >
                        <div className="mb-2 uppercase tracking-[.2em] text-[#111] text-xs font-bold">
                          Prev
                        </div>
                        <div className="text-[22px] font-normal flex items-center justify-center">
                          <LeftIcon className="mt-1" />{" "}
                          {category === 1 ? filters[0] : filters[category - 2]}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (category < filters.length) {
                            setCategory((prev) => prev + 1);
                            setExpanded(undefined);
                          }
                        }}
                        className={`select-none cursor-pointer text-center p-5 border hover:border-[#111] rounded-[20px] flex-1 ${
                          category >= filters.length ? "opacity-30" : ""
                        }`}
                      >
                        <div className="mb-2 uppercase tracking-[.2em] text-[#111] text-xs font-bold">
                          Next
                        </div>
                        <div className="text-[22px] font-normal flex items-center justify-center">
                          {category === filters.length ? filters[filters.length -1] : filters[category]}
                          <RightIcon className="mt-1" />
                        </div>
                      </div>
                    </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
          <MainFooter />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  );
};

export default FAQ;
