import { styled } from "@mui/material";
import { useState } from "react";
import {
  useStore,
  useTransformedImgData,
} from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import Button from "../common/Button";
import { ReactComponent as PreviewIcon } from "./../../../../../../../assets/svg/icon-preview.svg";
import { HIDE_LOADER, SHOW_LOADER } from "../../actions";
import ProjectPreviewModal from "../../../../../../common/Modals/ProjectPreviewModal";
import useMainContext from "context/Main";

const StyledIcon = styled(PreviewIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.primary.main,
  },
}));

export default function PreviewButton() {
  const [open, setOpen] = useState(false);
  const { dispatch } = useStore();
  const [imageFileInfo, setImageFileInfo] = useState({ quality: 0.92 });
  const transformImgFn = useTransformedImgData();
  const [preview, setPreview] = useState(null);
  const { project, setProject, openTemplate } = useMainContext();


  const handleClickOpen = () => {
    dispatch({ type: SHOW_LOADER });
    setOpen(true);

    const transformedData = transformImgFn(imageFileInfo, false, true);
    setPreview(transformedData);
    dispatch({ type: HIDE_LOADER });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<StyledIcon />}
        onClick={handleClickOpen}
        sx={{
          ".MuiButton-startIcon": {
            mr: "1px",
          },
        }}
      >
        <span>Preview</span>
      </Button>
      <ProjectPreviewModal
        showModal={open}
        closeModal={handleClose}
        item={{
          image: preview?.imageData?.imageBase64,
          insituBG: project?.presetDimension_id?.insituBG,
          position: project?.presetDimension_id?.position,
          group: project?.presetDimension_id?.group,
        }}
      />
    </>
  );
}
