import { object } from "prop-types";
import { TOOLS_IDS } from "../utils/constants";

export const REMOVE_ANNOTATIONS = "REMOVE_ANNOTATIONS";

const removeAnnotations = (state, payload) => {
  const { annotations } = state;

  payload.annotationsIds.forEach((id) => {
    if (state.designLayer && annotations[id]) {
      delete annotations[id];
    }
  });

  const arr = Object.values(annotations);
  const nextLayer = arr[arr.length - 1];

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    isDesignState: payload.isDesignState || true,
    annotations,
    selectionsIds: nextLayer ? [nextLayer.id] : [],
    toolId: nextLayer?.name || TOOLS_IDS.ARTBOARD,
  };
};

export default removeAnnotations;
