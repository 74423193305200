import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
const { styled } = require("@mui/material");

export const StyledAccordion = styled((props) => <MuiAccordion {...props} />)(
  () => ({
    width: "100%",
    display: "block",
    marginTop: "0 !important",
    boxShadow: "0px 0px !important",
    borderBottom: "1px solid #E0E0E0",
    borderRadius: "0px !important",
    "&::before": {
      opacity: "0 !important",
    },
    "& .MuiButtonBase-root": {
      minHeight: "inherit !important",
    },

    '&.Mui-expanded': {
      marginBottom: 0
    }
  })
);

export const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  paddingTop: '16px',
  paddingBottom: '16px',
  flexDirection: "row-reverse",
  minHeight: "20px",
  fontSize: "9px",
  color: "#111",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "5px !important",
    marginBottom: "0px !important",
    marginTop: "0px !important",
  },
}));
