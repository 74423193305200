import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from "./AuthContext";
import axios from "axios";
import { toast } from 'react-toastify';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = location.state?.path || '/';

    useEffect(() => {
        const loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            setUser(foundUser);
            if (foundUser?.token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${ foundUser.token }`;
            }
        }
    }, []);

    const login = async (email, password) => {
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            const response = await axios.post("/user/login", formData, {
                headers: {
                    Accept: "application/json",
                },
            });
            if (response?.data?.data && response?.data?.success) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${ response.data.data?.token }`;
                setUser(response.data.data);
                localStorage.setItem('user', JSON.stringify(response.data.data));
                navigate(redirectPath, { replace: true });
            } else {
                axios.defaults.headers.common['Authorization'] = '';
                console.error(response);
            }
        } catch (error) {
            axios.defaults.headers.common['Authorization'] = '';
            console.error(error);
        }
    }

    const logout = () => {
        axios.defaults.headers.common['Authorization'] = '';
        localStorage.removeItem('user');
        localStorage.removeItem('project');
        setUser(null);
        toast.success('Logged out successfully.');
    }

    const updateUser = (values) => {
        setUser({...user, ...values})
    }

    return (
        <AuthContext.Provider value={{ user, updateUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
