/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";

/** InternalDependencies */
import { StyledSpacedOptionFields } from "components/Page/Editor/react-filerobot-image-editor/src/components/common/AnnotationOptions/AnnotationOptions.styled";
import restrictNumber from "components/Page/Editor/react-filerobot-image-editor/src/utils/restrictNumber";
import { ReactComponent as SidesIcon } from "../../../../../../../../assets/svg/sides.svg";
import InputStandard from "../InputStandard";

const MIN_VALUE = 3;
const MAX_VALUE = 25;

const PolygonSidesField = ({
  annotation: polygon,
  updateAnnotation: updatePolygon,
  t,
}) => {
  const { sides } = polygon;

  const updateSidesNumber = (newSidesNumber) => {
    updatePolygon({
      sides: restrictNumber(newSidesNumber.target.value, MIN_VALUE, MAX_VALUE),
    });
  };

  return (
    <StyledSpacedOptionFields>
      <SidesIcon />
      <InputStandard
        sx={{ maxWidth: "70px" }}
        width="70px"
        onChange={updateSidesNumber}
        value={sides}
        name={"sides"}
        suffix=""
      />
    </StyledSpacedOptionFields>
  );
};

PolygonSidesField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default PolygonSidesField;
