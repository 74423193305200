import { Backdrop, Dialog, styled } from "@mui/material"
import Logout from '../../../assets/images/icon-logout.png';
import useMainContext from "context/Main";
import { useNavigate } from "react-router-dom";
import useAuth from "utils/Auth";
// import AssetGallery from '../../assets/images/icon-gallery.png';

const StyledBackdrop = styled(Backdrop)({
    backdropFilter: "blur(10px)"
})

const CustomBackdrop = (props) => {
    return <StyledBackdrop {...props}></StyledBackdrop>;
}

const ProfileMobileModal = props => {
    const navigate = useNavigate();
    const {logout} = useAuth();

    const {setLoader} = useMainContext();

    const handleLogout = () => {
        setLoader(false);
        logout();
        props.closeModal();
        navigate('/', {replace: true});
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: "hidden",
                },
            }}
            sx={{
                '.MuiPaper-root': {
                    maxWidth: '400px',
                    margin: '0',
                    width: '80%'
                }
            }}
            onClose={props.closeModal}
            open={props?.showModal}
            BackdropComponent={CustomBackdrop}
        >
            {/* <div className="w-full flex justify-end" onClick={props.closeModal}>
                <button onClick={props.closeModal}>
                    <img src={IconClose} alt="Close" className="w-[24px] h-[24px] mb-[12px]" />
                </button>
            </div> */}
            <div className="py-[20px] rounded-[20px] bg-[#FAFAFA]">
                <div className="select-none w-full max-w-[400px] max-h-[70vh] overflow-hidden overflow-y-scroll pl-[20px] pb-[15px] m-auto">
                    {props.avatar()}
                </div>
                <hr />
                {/* <div className="flex items-center px-[20px] pt-[20px]" onClick={() => navigate('/asset-gallery')}>
                    <span className="pr-2"><img src={AssetGallery} alt="Asset Gallery" className='h-[18px]' /></span>
                    <span className="text-[#111111] text-[18px] font-['Sofia_Pro'] font-[400] top-[1px] relative">Asset Gallery</span>
                </div> */}
                <div className="flex items-center px-[20px] pt-[20px]" onClick={handleLogout}>
                    <span className="pr-2"><img src={Logout} alt="Logout" className='h-[18px]' /></span>
                    <span className="text-[#111111] text-[18px] font-['Sofia_Pro'] font-[400]">Logout</span>
                </div>
            </div>
        </Dialog>
    )
}

export default ProfileMobileModal