/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import { StyledSpacedOptionFields } from "./AnnotationOptions.styled";
import styled from "styled-components";
import ColorInput from "../ColorInput";
import { colord } from "colord";

const StrokeFillWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 8px 0 8px 0px;

  &:hover,
  &:focus-within {
    background: #fff;
  }
`;

const AMColorInput = styled((props) => <ColorInput {...props} />)(() => ({
  backgroundColor: "red",
}));

const StrokeFill = ({ annotation, updateAnnotation, t }) => {
  const { stroke } = annotation;

  const changeStrokeColor = (newStrokeColor) => {
    updateAnnotation({ stroke: colord(newStrokeColor).toHex() });
  };

  return (
    <StyledSpacedOptionFields className="grow-0 shrink">
      <StrokeFillWrapper>
        <AMColorInput color={stroke} onChange={changeStrokeColor} />
      </StrokeFillWrapper>
    </StyledSpacedOptionFields>
  );
};

StrokeFill.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
};

export default StrokeFill;
