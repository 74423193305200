import getDimensionsMinimalRatio from "../utils/getDimensionsMinimalRatio";
import getArtboardToCanvasSpacing from "../utils/getArtboardToCanvasSpacing";

export const SET_ARTBOARD = "SET_ARTBOARD";

const setArtboard = (state, payload) => {
  const padding = getArtboardToCanvasSpacing();
  let scale = state.canvasScale;
  if (payload.width && payload.height) {
    scale = getDimensionsMinimalRatio(
      state.canvasWidth,
      state.canvasHeight,
      payload.width + padding,
      payload.height + padding
    );
  }

  return {
    ...state,
    artboard: { ...state.artboard, ...payload },
    canvasScale: scale,
  };
};

export default setArtboard;
