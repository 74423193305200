const webSafeFonts = [
  {
    family: "Arial",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Verdana",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Tahoma",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Trebuchet MS",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Times New Roman ",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Georgia ",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Garamond ",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
  },
  {
    family: "Courier New ",
    variants: [
      "regular",
      "700",
    ],
  },
  {
    family: "Brush Script MT ",
    variants: [
      "regular",
    ],
  },
];

export default webSafeFonts;
