/** External Dependencies */
import React, { useEffect, useState } from "react";

/** Internal Dependencies */
// import Tabs from '../Tabs';
import ToolsBar from "../ToolsBar";
import Separator from "../common/Separator";
import { useStore } from "../../hooks";
// import CloseButton from './CloseButton';
import SaveButton from "./SaveButton";
import DownloadButton from "./DownloadButton";
import PreviewButton from "./PreviewButton";
// import ResetButton from './ResetButton';
import UndoButton from "./UndoButton";
import RedoButton from "./RedoButton";
// import ImageDimensionsAndDisplayToggle from './ImageDimensionsAndDisplayToggle';
import CanvasZooming from "./CanvasZooming";
import {
  // StyledTopbar,
  StyledFlexCenterAlignedContainer,
  // StyledHistoryButtonsWrapper,
} from "./Topbar.styled";
// import BackButton from './BackButton';
// import { ReactComponent as Shape } from './icons/shape.svg';
// import { ReactComponent as FileArrowIn } from '../../../../assets/svg/icon-file-arrow-in.svg';
import {
  EditorHeader,
  CenterPanelHeader,
  RightPanelHeader,
} from "../../../../../../../Layout.styled";
import { RESET } from "../../actions";
import useMainContext from "../../../../../../../context/Main";
import ImportProjectModal from "../../../../../../common/Modals/ImportProjectModal";
// import LayerButton from './LayerButton';
import axios from "axios";
import { toast } from "react-toastify";
import GenericProjectNameModal from "components/common/Modals/GenericProjectNameModal";
import usePrompt from "../../hooks/usePrompt";
import useAuth from '../../../../../../../utils/Auth';
import useSaveProject from "../../hooks/useSaveProject";

const Topbar = () => {
  const { dispatch, config } = useStore();
  const { project, projectReloadIndex } = useMainContext();
  const [importProjectNameDialog, setImportProjectNameDialog] = useState(false);
  const { triggerPrompt, value, ...props } = usePrompt();

  useEffect(() => {
    dispatch({ type: RESET, payload: { config } });
  }, [projectReloadIndex]);

  return (
    <>
      <EditorHeader>
        <StyledFlexCenterAlignedContainer className="FIE_topbar-center-options">
          {/*<ImageDimensionsAndDisplayToggle />*/}
          {/*<Separator />*/}
        </StyledFlexCenterAlignedContainer>
        <CenterPanelHeader>
          <ToolsBar />
          <Separator />
          <UndoButton margin="0" />
          <RedoButton margin="0" />
          <Separator />
          <CanvasZooming />
          {/* <Separator />
          <LayerButton /> */}
          {/* <button onClick={() => setImportProjectNameDialog(true)}>
            <FileArrowIn />
          </button> */}
        </CenterPanelHeader>
        <RightPanelHeader className="tour__action-buttons">
          <DownloadButton />
          <PreviewButton />
          <SaveButton />
        </RightPanelHeader>
      </EditorHeader>

      {project?.name && (
        <GenericProjectNameModal
          {...props}
          initialValue={project?.name ?? ""}
        />
      )}

      <ImportProjectModal
        showModal={importProjectNameDialog}
        closeModal={() => setImportProjectNameDialog(false)}
      />
    </>
  );
};
export default Topbar;
