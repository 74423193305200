/** External Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Label from '@scaleflex/ui/core/label';

/** InternalDependencies */
import { StyledSpacedOptionFields } from '../../common/AnnotationOptions/AnnotationOptions.styled';
import restrictNumber from '../../../utils/restrictNumber';
import { ReactComponent as RadiusIcon } from "assets/svg/radius.svg";
import InputStandard from '../../common/InputStandard';

const MIN_VALUE = 0;
const MAX_VALUE = 150;

const RectCornerField = ({
  annotation: rect,
  updateAnnotation: updateRect,
  disabledRadiusCorner
}) => {
  const { cornerRadius } = rect;

  const updateCornerRadius = (newCornerRadius) => {
    updateRect({
      cornerRadius: restrictNumber(newCornerRadius.target.value, MIN_VALUE, MAX_VALUE),
    });
  };

  return (
    <StyledSpacedOptionFields className='flex-1'>
      <RadiusIcon title="Radius" className='shrink-0' />
      <InputStandard
        onChange={updateCornerRadius}
        value={cornerRadius}
        min={MIN_VALUE}
        max={MAX_VALUE}
        type="number"
        disabled={disabledRadiusCorner}
      />
    </StyledSpacedOptionFields>
  );
};

RectCornerField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
};

export default RectCornerField;
