import { motion } from "framer-motion";

const PageAnimation = ({ children }) => (
  <motion.div
    initial={{ 
      opacity: 0, 
    }}
    animate={{
      opacity: 1,
      transition: {
        duration: 1,
        type: "easeIn"
      } 
    }}
    // exit={{ 
    //   opacity: 0,
    //   transition: {
    //       duration: 0.3,
    //       type: "easeOut"
    //   },
    // }}
    // transition={{
    //   type: "spring",
    //   stiffness: 120,
    //   damping: 20,
    // }}
  >
    {children}
  </motion.div>
);

export default PageAnimation;