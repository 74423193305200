import { useEffect, React } from "react";
import { Link } from 'react-router-dom';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import Button from '../../common/Button';
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from 'react-helmet';

const PasswordChanged = () => {

  useEffect(() => {
    document.body.classList.add("password-changed-page");
    return () => {
      document.body.classList.remove("password-changed-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Ad Designer - Password Changed</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Password Changed</h5>
                <p className="font-['Sofia_Pro'] font-medium text-center text-[#555555]">Your password has been changed. <br />Please log in with your new password.</p>
                <Link to="/login">
                  <Button type="submit" color="blue" text="Log in" className="my-[40px]"></Button>
                </Link>
              </div>
            </div>
            <MainFooter />
        </div>
      </PageAnimation>
    </>
  )
}

export default PasswordChanged