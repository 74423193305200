//internal import
import { Button, styled } from "@mui/material";
import { ReactComponent as AlignLeftIcon } from "assets/svg/agm-align-left.svg";
import { ReactComponent as AlignRightIcon } from "assets/svg/agm-align-right.svg";
import { ReactComponent as AlignTopIcon } from "assets/svg/agm-align-top.svg";
import { ReactComponent as AlignBottomIcon } from "assets/svg/agm-align-bottom.svg";
import { ReactComponent as AlignVerticalCenterIcon } from "assets/svg/agm-align-vertical-center.svg";
import { ReactComponent as AlignHorizontalCenterIcon } from "assets/svg/agm-align-horizontal-center.svg";
import { SET_POSITION } from "../../../actions/setPosition";
import { useStore } from "../../../hooks";

const POSITIONS = [
  {
    key: "left",
    icon: AlignLeftIcon,
  },
  {
    key: "right",
    icon: AlignRightIcon,
  },
  {
    key: "top",
    icon: AlignTopIcon,
  },
  {
    key: "bottom",
    icon: AlignBottomIcon,
  },
  {
    key: "horizontal-center",
    icon: AlignHorizontalCenterIcon,
  },
  {
    key: "vertical-center",
    icon: AlignVerticalCenterIcon,
  },
];

const PositonButton = styled(Button)({
  padding: 0,
  minWidth: "inherit",
});

const PositionAlignment = () => {
  const { dispatch } = useStore();

  return (
    <div className="flex justify-around">
      {POSITIONS.map((pos) => (
        <PositonButton
          key={pos.key}
          onClick={() => {
            dispatch({
              type: SET_POSITION,
              payload: {
                position: pos.key,
              },
            });
          }}
        >
          <pos.icon />
        </PositonButton>
      ))}
    </div>
  );
};

export default PositionAlignment;
