// import {ReactComponent as IconError} from "../../assets/svg/error.svg";
import React from 'react';
import { BodyTextTitle, BodyTextDesc } from "./Tour.styled";


const TourBodyText = ({title, description}) => {
    return (
        　<div>
             <BodyTextTitle>
                 { title }
             </BodyTextTitle>
             <BodyTextDesc>
                 { description }
             </BodyTextDesc>
         </div>
    )
}

export default TourBodyText;