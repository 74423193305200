/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";
import Label from "@scaleflex/ui/core/label";
import Konva from "konva";

/** Internal Dependencies */
import restrictNumber from "../../../utils/restrictNumber";
import {
  StyledSpacedOptionFields,
  StyledLabel,
} from "./AnnotationOptions.styled";
import Slider from "../Slider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { AMInput, InputUnit } from "../AMTransform/index.styled";
import { TOOLS_IDS } from "../../../utils/constants";
import { useStore } from "../../../hooks";
import InputStandard from "../InputStandard";

const MIN_PERCENTANGE = -1;
const MAX_PERCENTANGE = 1;

const Input = styled(MuiInput)`
  width: 48px;
  padding: 5px;
  color: #555;
  &::before {
    display: none;
  }
  &::after {
    content: "%";
    color: #aaa;
    transform: scaleX(1);
    right: 0;
    position: relative;
    border-width: 0;
    padding-bottom: 0px;
  }
`;

const SaturationField = ({ annotation, updateAnnotation, t, title }) => {
  const { saturation } = annotation;
  const { dispatch, selectionsIds = [] } = useStore();

  const changeSaturation = (newSaturation) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        selected: selectionsIds[0],
        filter: {
          name: "saturation",
          value: newSaturation,
        },
      },
    });
  };

  const changeSaturationInput = (newSaturation) => {
    if (newSaturation.target.value > 1) {
      return;
    }
    if (
      !isNaN(newSaturation.target.value) ||
      newSaturation.target.value == "-"
    ) {
      updateAnnotation({
        saturation: newSaturation.target.value,
      });
    } else {
      return false;
    }
  };

  // const handleInputChange = (newSaturation) => {
  //   setValue(event.target.value === '' ? '' : Number(event.target.value));
  // };

  return (
    <StyledSpacedOptionFields>
      <Grid container sx={{ marginTop: "-6px", flexWrap: "nowrap" }}>
        <Grid item className="w-full" sx={{ marginRight: "18px" }}>
          <StyledLabel
            sx={{
              color: "#777",
              fontSize: "12px",
              fontFamily: "MarkForMCNrw",
              paddingLeft: "5px",
              marginBottom: "3px",
            }}
          >
            {title}
          </StyledLabel>
          <Slider
            annotation="%"
            onChange={changeSaturation}
            value={saturation}
            min={-1}
            max={1}
            step={0.1}
          />
        </Grid>
        <Grid item className="flex items-end">
          <InputStandard
            sx={{ width: "70px" }}
            suffix="%"
            onChange={changeSaturationInput}
            value={saturation}
          />
        </Grid>
      </Grid>
    </StyledSpacedOptionFields>
  );
};

SaturationField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default SaturationField;
