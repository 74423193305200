import { Modal } from "@mui/material"
// import { StyledGallerySearch, StyledGalleryTabItem } from "../AssetGallery/AssetGallery.styled"
import {
  StyledDialogHeader,
  StyledDialogWrapper,
} from "./Dialog.styled"

export default function Dialog({ dialogTitle, children, ...props }) {
  return (
    <Modal
      componentsProps={{
        backdrop: { sx: { backdropFilter: "blur(10px)" } },
      }}
      {...props}
    >
      <StyledDialogWrapper>
        <StyledDialogHeader>{dialogTitle}</StyledDialogHeader>
        {children}
      </StyledDialogWrapper>
    </Modal>
  )
}