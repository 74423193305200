import React, { useCallback, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ReactComponent as GenerateIcon } from '../../../assets/svg/icon-generate.svg';
import { ReactComponent as SelectIcon } from '../../../assets/svg/icon-check-circle-green.svg';
import { ReactComponent as EmptyImage } from '../../../assets/svg/image-empty.svg';
import { useAnnotation } from './react-filerobot-image-editor/src/hooks';
import { TOOLS_IDS } from './react-filerobot-image-editor/src/utils/constants';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/icon-error.svg';
import { useFormik } from "formik";
import * as Yup from "yup";
import {TrackGoogleAnalyticsEvent} from "../../../utils/GA4";

const HeadlineGenerator = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [generatedText, setGeneratedText] = useState([]);
    const [selectedText, setSelectedText] = useState({});
    const [text, saveText] = useAnnotation({ name: TOOLS_IDS.TEXT });

    const formik = useFormik({
        initialValues: {
            sentence: '',
        },
        validationSchema: Yup.object({
            sentence: Yup.string().min(2, 'Sentence must be at least 2 characters long').required('Sentence is required'),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            let {sentence} = values;
            TrackGoogleAnalyticsEvent('Generated','AI Generator', sentence)
            try {
                setSelectedText(null);
                const formData = new FormData();
                formData.append("phrase", sentence);
                const response = await axios.post(`/app/headline-generator`, formData, {
                    headers: {
                        Accept: "application/json",
                    },
                });
                setIsLoading(false);
                if (response?.data?.success && response?.data?.data) {
                    setGeneratedText(response.data.data);
                } else {
                    console.error(response);
                }
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        },
    })
    // const formik = useFormik({
    //     initialValues: {
    //         product_name: '',
    //         product_brand: '',
    //         product_category: '',
    //         product_features: '',
    //     },
    //     validationSchema: Yup.object({
    //       product_name: Yup.string().min(2, 'Product name must be at least 2 characters long').required('Product name is required'),
    //       product_brand: Yup.string().min(2, 'Product brand must be at least 2 characters long').required('Product brand is required'),
    //       product_category: Yup.string().min(2, 'Product category must be at least 2 characters long').required('Product category is required'),
    //       product_features: Yup.string().min(2, 'Product features must be at least 2 characters long').required('Product features is required'),
    //     }),
    //     onSubmit: async (values) => {
    //         setIsLoading(true);
    //         let {
    //             product_name,
    //             product_brand,
    //             product_category,
    //             product_features,
    //         } = values
    //         try {
    //             setSelectedText(null);
    //             const formData = new FormData();
    //             formData.append("product_name", product_name);
    //             formData.append("product_brand", product_brand);
    //             formData.append("product_category", product_category);
    //             formData.append("product_features", product_features);
    //             const response = await axios.post(`/app/headline-generator`, formData, {
    //                 headers: {
    //                     Accept: "application/json",
    //                 },
    //             });
    //             setIsLoading(false);
    //             if (response?.data?.success && response?.data?.data) {
    //                 setGeneratedText(response.data.data);
    //             } else {
    //                 console.error(response);
    //             }
    //         } catch (error) {
    //             setIsLoading(false);
    //             console.error(error);
    //         }
    //     }
    // })

    const insertText = useCallback(() => {
        props.closeModal(selectedText)

        if (!selectedText
        && Object.keys(selectedText).length === 0
        && Object.getPrototypeOf(selectedText) === Object.prototype) return

        saveText({
            text: selectedText.text
        })
    }, [props, saveText, selectedText])

    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } }
            }}
            open={props?.showModal}
            onClose={() => props.closeModal()}
        >
            <Box style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "75vw",
                bgcolor: "background.paper",
                border: "none",
                outline: "none",
                boxShadow: 24,
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.8)",
                overflow: "hidden",
            }}>
                <div className="text-[#111111] bg-[#DBDBDB] flex justify-start items-center px-[30px] font-['Sofia_Pro'] text-[15px] h-[40px]">
                    <GenerateIcon className='mr-[7px]' />
                    AI-based Text Generator
                </div>
                <div className='container p-[30px] bg-[#FFFFFF]'>
                    <div className="grid grid-cols-2">
                        <form onSubmit={ formik.handleSubmit } className='pr-[30px] border-r border-solid border-[#DDDDDD]'>
                            <div>
                                <label htmlFor="sentence" className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase pb-[7px]">What would you like to communicate?</label>
                                <textarea type="text" id="sentence" className={`w-full min-h-[120px] border border-solid ${ formik.touched.sentence && formik.errors.sentence ? 'border-[#DF1E1E]' : 'border-[#E5E5E5]' } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[12px] text-[#555555] placeholder:text-[#AAAAAA] bg-[#FFFFFFB8]`} value={ formik.values.sentence } onChange={ formik.handleChange } onBlur={ formik.handleBlur }
                                          placeholder="Examples: &#13;&#10; '23% cashback special'; &#13;&#10; 'generic New Year greeting'; &#13;&#10; 'affordable membership subscription'" />
                                {formik.touched.sentence && formik.errors.sentence && (
                                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                                        <ErrorIcon className="mr-[7px] shrink-0"/>
                                        { formik.errors.sentence }
                                    </label>
                                )}
                            </div>
                            <div className='pt-[30px]'>
                                <LoadingButton
                                    type='submit'
                                    className="h-[36px] border border-solid border-[#7B22ECB2] rounded-[4px] hover:bg-[#7B22EC1a] font-['Sofia_Pro'] text-[13px] text-[#7B22EC] flex justify-center items-center"
                                    fullWidth={true}
                                    disabled={isLoading}
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<GenerateIcon className='mr-[7px]' />}
                                    variant="outlined"
                                >
                                    Generate
                                </LoadingButton>
                            </div>
                        </form>
                        {/* <form onSubmit={ formik.handleSubmit } className='pr-[30px] border-r border-solid border-[#DDDDDD]'>
                            <div>
                                <label htmlFor="product_name" className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase pb-[7px]">Product name</label>
                                <input type="text" id="product_name" className={`w-full border border-solid ${ formik.touched.product_name && formik.errors.product_name ? 'border-[#DF1E1E]' : 'border-[#E5E5E5]' } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] bg-[#FFFFFFB8]`} value={ formik.values.product_name } onChange={ formik.handleChange } onBlur={ formik.handleBlur } placeholder='Your product name' />
                                {formik.touched.product_name && formik.errors.product_name && (
                                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                                        <ErrorIcon className="mr-[7px] shrink-0"/>
                                        { formik.errors.product_name }
                                    </label>
                                )}
                            </div>
                            <div className='pt-[20px]'>
                                <label htmlFor="product_brand" className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase pb-[7px]">Brand</label>
                                <input type="text" id="product_brand" className={`w-full border border-solid ${ formik.touched.product_brand && formik.errors.product_brand ? 'border-[#DF1E1E]' : 'border-[#E5E5E5]' } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] bg-[#FFFFFFB8]`} value={ formik.values.product_brand } onChange={ formik.handleChange } onBlur={ formik.handleBlur } placeholder='Your product brand' />
                                {formik.touched.product_brand && formik.errors.product_brand && (
                                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                                        <ErrorIcon className="mr-[7px] shrink-0"/>
                                        { formik.errors.product_brand }
                                    </label>
                                )}
                            </div>
                            <div className='pt-[20px]'>
                                <label htmlFor="product_category" className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase pb-[7px]">Category</label>
                                <input type="text" id="product_category" className={`w-full border border-solid ${ formik.touched.product_category && formik.errors.product_category ? 'border-[#DF1E1E]' : 'border-[#E5E5E5]' } rounded-[4px] h-[36px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] bg-[#FFFFFFB8]`} value={ formik.values.product_category } onChange={ formik.handleChange } onBlur={ formik.handleBlur } placeholder='Your product category' />
                                {formik.touched.product_category && formik.errors.product_category && (
                                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                                        <ErrorIcon className="mr-[7px] shrink-0"/>
                                        { formik.errors.product_category }
                                    </label>
                                )}
                            </div>
                            <div className='pt-[20px]'>
                                <label htmlFor="product_features" className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase pb-[7px]">Description</label>
                                <textarea id="product_features" cols="30" rows="4" className={`w-full border border-solid ${ formik.touched.product_features && formik.errors.product_features ? 'border-[#DF1E1E]' : 'border-[#E5E5E5]' } rounded-[4px] px-[14px] py-[11px] font-['Sofia_Pro'] text-[14px] text-[#555555] bg-[#FFFFFFB8] resize-none`} onChange={ formik.handleChange } onBlur={ formik.handleBlur } placeholder='Your product description' />
                                {formik.touched.product_features && formik.errors.product_features && (
                                    <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-center">
                                        <ErrorIcon className="mr-[7px] shrink-0"/>
                                        { formik.errors.product_features }
                                    </label>
                                )}
                            </div>
                            <div className='pt-[30px]'>
                                <LoadingButton
                                    type='submit'
                                    className="h-[36px] border border-solid border-[#7B22ECB2] rounded-[4px] hover:bg-[#7B22EC1a] font-['Sofia_Pro'] text-[13px] text-[#7B22EC] flex justify-center items-center"
                                    fullWidth={true}
                                    disabled={isLoading}
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<GenerateIcon className='mr-[7px]' />}
                                    variant="outlined"
                                >
                                    Generate
                                </LoadingButton>
                            </div>
                        </form> */}
                        <div className='pl-[30px] min-h-[320px] max-h-[446px] overflow-y-auto'>
                            <div className='flex justify-start items-center'>
                                <label className="text-[#888888] font-['Sofia_Pro_Bold'] text-[11px] tracking-[.12em] uppercase">Results</label>
                                <label className="text-[#888888] font-['Sofia_Pro'] text-[11px] px-[6px] relative top-[-2px]">{ generatedText?.length }</label>
                            </div>
                            {generatedText?.length > 0 && generatedText.map(caption => {
                                return <div key={`generated-text-${ caption?.id }`} className={`overflow-hidden relative cursor-pointer flex justify-start items-center my-[10px] py-[10px] pl-[12px] pr-[36px] border border-solid rounded-[4px] font-['Sofia_Pro'] text-[12px] ${selectedText?.id === caption?.id ? 'bg-[#DEF9EC] border-[#007067] text-[#0B9454]' : 'bg-[#FFFFFF66] border-[#E5E5E5] text-[#555555] hover:bg-[#7B22EC1a]' }`} onClick={() => setSelectedText(caption)}>
                                    { caption?.text }
                                    <SelectIcon className={`absolute transition-all ease-in-out duration-500 top-1/2 ${selectedText?.id === caption?.id ? 'right-[12px]' : 'right-[-100%]' } translate-y-[-50%]`} />
                                </div>
                            })}
                            {!(generatedText?.length > 0) && (
                                <div className='flex flex-col justify-center items-center gap-[14px] h-full'>
                                    <EmptyImage />
                                    <label className="text-center text-[#999999] max-w-[256px] font-['Sofia_Pro'] text-[13px] leading-[15px]">Generate text suggestions by including relevant details. We recommend describing your offer in at least three to five words. For the best results, use a combination of keywords and adjectives.</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='px-[30px] py-[8px] bg-[#F9F9F9] flex justify-between items-center'>
                    <button type='button' className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center" onClick={(e) => props.closeModal(e)}>Cancel</button>
                    <button type='button' className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center disabled:bg-[#BBBBBB] disabled:opacity-75" onClick={insertText} disabled={!selectedText?.text}>Use</button>
                </div>
            </Box>
        </Modal>
    )
}

export default HeadlineGenerator