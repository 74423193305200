import React from 'react'
import IfLoggedIn from '../utils/IfLoggedIn';
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../components/Page/Home";
import Editor from "../components/Page/Editor";
import EDM from "../components/Page/EDM";
import Login from "../components/Page/Login";
import ForgotPassword from "../components/Page/ForgotPassword";
import ResetPassword from "../components/Page/ResetPassword";
import Register from "../components/Page/Register";
import OnlyGuest from '../utils/OnlyGuest';
import ProtectedRoute from '../utils/ProtectedRoute';
import CreativeTemplates from "../components/Page/CreativeTemplates";
import PasswordChanged from "../components/Page/PasswordChanged";
import ResetPasswordNotification from "../components/Page/ResetPasswordNotification";
import Feature from "../components/Page/Feature";
import Profile from "../components/Page/Profile";
import NotFound from "../components/Page/404";
import MyProjects from "../components/Page/MyProjects";
import EmailVerify from "../components/Page/EmailVerify";
import EmailVerified from "../components/Page/EmailVerified";
import ForgetPasswordEmail from "../components/Page/ForgotPasswordEmail";
import ResetPasswordSuccessful from "../components/Page/ResetPasswordSuccess";
import AssetGallery from "../components/Page/AssetGallery";

import { AnimatePresence } from "framer-motion";
import Tour from 'components/Page/Editor/Tour/Tour';
import Privacy from 'components/Page/Privacy';
import Terms from 'components/Page/Terms';
import FAQ from 'components/Page/FAQ';
import Tutorial from 'components/Page/Tutorial';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
            <Route path='/' element={<IfLoggedIn><Home /></IfLoggedIn>}></Route>
            <Route path='/my-projects' element={<ProtectedRoute><MyProjects /></ProtectedRoute>}></Route>
            <Route path='/creative-templates/:sort' exact element={<IfLoggedIn><CreativeTemplates /></IfLoggedIn>}></Route>
            <Route path='/features' element={<Feature />}></Route>
            <Route path='/login' element={<OnlyGuest><Login /></OnlyGuest>}></Route>
            <Route path='/register' element={<OnlyGuest><Register /></OnlyGuest>}></Route>
            <Route path='/forgot-password' element={<OnlyGuest><ForgotPassword /></OnlyGuest>}></Route>
            <Route path='/reset-password' element={<OnlyGuest><ResetPassword /></OnlyGuest>}></Route>
            <Route path='/change-password-success' element={<OnlyGuest><PasswordChanged /></OnlyGuest>}></Route>
            <Route path='/verify-email' element={<OnlyGuest><EmailVerify /></OnlyGuest>}></Route>
            <Route path='/email-verification' element={<OnlyGuest><EmailVerified /></OnlyGuest>}></Route>
            <Route path='/reset-password-mail' element={<OnlyGuest><ResetPasswordNotification /></OnlyGuest>}></Route>
            <Route path='/forgot-password-mail' element={<OnlyGuest><ForgetPasswordEmail /></OnlyGuest>}></Route>
            <Route path='/reset-password-successful' element={<OnlyGuest><ResetPasswordSuccessful /></OnlyGuest>}></Route>
            <Route path='/edm-builder' element={<EDM />}></Route>
            {/* <Route path='/asset-gallery' element={<ProtectedRoute><AssetGallery /></ProtectedRoute>}></Route> */}
            <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>}></Route>
            <Route path='/privacy' element={<Privacy />}></Route>
            <Route path='/terms' element={<Terms />}></Route>
            <Route path='/faq' element={<FAQ />}></Route>
            <Route path='/tutorials' element={<Tutorial />}></Route>
            <Route path='/editor' element={
              <ProtectedRoute>
                <Tour>
                  <Editor />
                </Tour>
              </ProtectedRoute>
            }></Route>
            <Route path='*' element={<NotFound />}></Route>
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes