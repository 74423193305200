import React, { useRef, useEffect } from 'react';
import { render } from 'react-dom';
import MainHeader from '../../common/Header';
import EmailEditor from 'react-email-editor';
import styled from 'styled-components';
import sample from './sample.json';

const EDM = () => {
    useEffect(() => {
        document.body.classList.add("edm-editor-page");
        return () => {
            document.body.classList.remove("edm-editor-page");
        };
    }, []);

    // const Editor = styled.div`
    // flex: 1;
    // display: flex;
    // > iframe {
    //     flex: 1;
    //     width: 100%;
    //     height: 100%;
    //     min-height: ${props => props.minHeight || '500px'} !important;
    //     display: flex;
    //     border: 0px;
    // }
    // `

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        position: relative;
        height: calc(100vh - 57px);
    `;

    const Bar = styled.div`
        flex: 1;
        background-color: #eeeeee;
        color: #000;
        padding: 10px;
        display: flex;
        max-height: 65px;
        h1 {
            flex: 1;
            font-size: 16px;
            text-align: left;
        }
        button {
            // flex: 1;
            // padding: 10px;
            // margin-left: 10px;
            // font-size: 14px;
            // font-weight: bold;
            // background-color: #000;
            // color: #fff;
            // border: 0px;
            // max-width: 150px;
            // cursor: pointer;
            float: right;
        }
    `;

    const emailEditorRef = useRef(null);

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
        });
    };

    const saveDesign = () => {
        emailEditorRef.current.editor.saveDesign((design) => {
        //   alert('Design JSON has been logged in your developer console.');
        });
    };

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        const templateJson = {};
        emailEditorRef.current.editor.loadDesign(sample);
    }

    const onReady = () => {
        // editor is ready
    };

    return (<>
        <MainHeader />
        <Container>
                <Bar>
                    <button className="text-white bg-[#7B22EC] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-[14px] text-center mr-3" onClick={exportHtml}>Download</button>
                    <button className="text-white bg-[#7B22EC] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-[14px] text-center" onClick={saveDesign}>Save</button>
                </Bar>

                <React.StrictMode>
                    <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
                </React.StrictMode>
        </Container>
    </>)


}

export default EDM