export const SET_LAYER = "SET_LAYER";

const setLayer = (state, payload) => {
  const { targetId, ...updatedProps } = payload;

  const newState = {
    ...state,
    annotations: {
      ...state.annotations,
      [targetId]: { ...state.annotations[targetId], ...updatedProps },
    },
  };

  return {
    ...newState
  };
};

export default setLayer;
