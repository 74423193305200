// import {ReactComponent as IconError} from "../../assets/svg/error.svg";
import React, { useState, useEffect } from 'react';
import useAuth from "../../../../utils/Auth";
import { Box, Button, Checkbox } from "@mui/material";
import { useTour } from '@reactour/tour'
import { useLocation } from 'react-router-dom';


const TourWrapper = (props) => {
    const {user} = useAuth();
    const [checked, setChecked] = useState(false)
    const { setIsOpen } = useTour();
    let localShowTour = JSON.parse(localStorage.getItem('showTour'))

    const location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/editor') {
            setIsOpen(false)
        }
    }, [location]);

    useEffect(() => {
        if (localShowTour && typeof localShowTour === 'object') {
            setChecked(localShowTour[user?._id] || false)
        }
    }, [user])

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked)

        if (e.target.checked) {
            setIsOpen(false)
        }

        if (localShowTour) {
            localStorage.setItem('showTour', JSON.stringify({
                ...localShowTour,
                [user._id]: e.target.checked,
            }))
        } else {
            localStorage.setItem('showTour', JSON.stringify({
                [user._id]: e.target.checked,
            }))
        }

        localShowTour = JSON.parse(localStorage.getItem('showTour'))
    }

    return (
        <Box style={{
            width: props?.width || '480px',
            boxShadow: '0px 8px 60px rgba(0, 0, 0, 0.12)',
            bgcolor: "background.paper",
            border: "none",
            outline: "none",
            borderRadius: "20px",
            background: "rgba(255, 255, 255, 0.8)",
            overflow: "hidden",
            transform: props?.translate,
        }}>
            <div className="text-[#111111] bg-[#DBDBDB] px-[28px] flex items-center font-['Sofia_Pro'] text-[15px] h-[40px]">
                Getting Started ( {props?.tourObj?.currentStep + 1} of 6 )
            </div>
            <div className='p-[28px] bg-[#FFFFFF]'>
                {props?.body}
            </div>
            <div className='px-[28px] py-[8px] bg-[#F9F9F9] flex justify-between items-center'>
                <div className='inline-flex items-center'>
                    <Checkbox id="dsta" style={{padding: '0 9px 0 0'}} size='small' disableRipple={true} checked={checked}
                              onClick={handleCheckboxChange} />
                    <label for="dsta" className='text-[#888888] text-[14px]'>Don’t show this again</label>
                </div>
                <Button variant="outlined" style={{textTransform: 'none'}} className="!font-['Sofia_Pro'] border !border-[#7B22EC] !text-[14px] py-[8px] px-[12px] h-[30px]" onClick={() => {props?.onButtonClicked()}}><span className="text-[#7B22EC]">{props?.buttonText || 'Next'}</span></Button>
            </div>
        </Box>
    )
}

export default TourWrapper;