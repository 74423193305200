export const MOVE_CTX_MENU = "MOVE_CTX_MENU";

const moveCtxMenu = (state, payload) => {
  return {
    ...state,
    ctxMenu: payload
  };
};

export default moveCtxMenu;
