import { styled } from "@mui/material";

export const PalleteColorStyle = styled("div")(
  ({ background, active, withBorder }) => {
    // console.log(withBorder)
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      transition: "all .1s ease",
      background: background,
      width: 22,
      height: 22,
      borderRadius: "50%",
      border: withBorder <= 1 ? "1px solid #cccccc" : "none",
      ...(active
        ? {
            width: 18,
            height: 18,
            outline: "1px solid #DDDDDD",
            outlineOffset: "2px",
            marginBlock: "2px",
          }
        : {}),
    };
  }
);

export const PalleteNoColorStyle = styled(PalleteColorStyle)(({ active }) => ({
  position: "relative",
  background: "#fff",
  border: "1px solid #ccc",
  overflow: "hidden",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "1px",
    background: "#ccc",
    left: 0,
    top: "50%",
    transform: "rotate(-45deg)",
  },
}));
