import axios from "axios";
import React, { useEffect } from "react";
import AuthProvider from "./utils/AuthProvider";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import MainProvider from "./context/MainProvider";
import ExceptionHandler from "./Handler/ExceptionHandler";
import AnimatedRoutes from "routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { useLocation } from "react-router";
import semver from "semver";
// import ReactGA from "react-ga4";
// import Analytics from 'analytics'
// import googleTagManager from '@analytics/google-tag-manager'
import {InitializeGoogleAnalytics} from './utils/GA4'

// export const analytics = Analytics({
//   app: 'ad-maker',
//   plugins: [
//     googleTagManager({
//       containerId: 'GTM-T7KQWGL'
//     })
//   ]
// })
function App() {
  axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_BASE_URL}/api`;
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  library.add(fas);
  const location = useLocation();




  // ReactGA.initialize(process.env.REACT_APP_GA_ID);
  // ReactGA.initialize([
  //   {
  //     trackingId: process.env.REACT_APP_GA_ID,
  //   },
  // ]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    InitializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        // console.log(meta.version);
        // console.log(packageInfo.version);
        if (semver.lt(packageInfo.version, meta.version)) {
          window.location.reload(true);
        }
      });
  }, [location]);

  return (
    <AuthProvider>
      <MainProvider>
        <Helmet></Helmet>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <AnimatedRoutes />
        </CacheBuster>
        <ExceptionHandler />
      </MainProvider>
    </AuthProvider>
  );
}

export default App;
