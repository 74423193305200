export const googleFonts = [
  "Poppins",
  "Oswald",
  "Roboto",
  "Open Sans",
  "Montserrat",
  "Lato",
  "Fasthand",
  "Raleway",
  "Inter",
  "Playfair Display",
  "Rubik",
  "Lora",
  "Josefin Sans",
  "Karla",
  "Dosis",
  "Lobster",
];

export function formatFonts(fonts) {
  return fonts.map(
    (font) =>
      `${font.family}:${font.variants.join(",")?.replace("regular", "400")}`
  );
}
