import { useStore } from "../../../hooks";
import useCreative from "../../../hooks/useCreative";
import { TOOLS_IDS } from "../../../utils/constants";
import Accordion from "../Accordion";
import Order from "../Order";
import PositionAlignment from "../PositionAlignment";
import { StyledLabel, StyledSubWrapper } from "./AnnotationOptions.styled";

const AMPositionFields = (props) => {
  const isCreative = useCreative();
  const { toolId } = useStore();

  if (isCreative) {
    return (
      <Accordion title="Position" {...props}>
        {toolId !== TOOLS_IDS.ARROW && toolId !== TOOLS_IDS.LINE ? (
          <StyledSubWrapper sx={{ borderBottom: "1px solid #e3e3e3" }}>
            <PositionAlignment />
          </StyledSubWrapper>
        ) : null}
        <StyledSubWrapper>
          <StyledLabel>Order</StyledLabel>
          <Order />
        </StyledSubWrapper>
      </Accordion>
    );
  }
  return (
    <Accordion title="Order">
      <Order />
    </Accordion>
  );
};

export default AMPositionFields