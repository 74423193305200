import { useEffect, React } from "react";
import { Link, useNavigate } from 'react-router-dom';
// import useAuth from '../../../utils/Auth';
import MainHeader from '../../common/Header';
import Input from '../../common/Input';
import Button from '../../common/Button';
import MainFooter from '../../common/Footer';
import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from "yup";
import useMainContext from '../../../context/Main';
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
  // const auth = useAuth();
  const navigate = useNavigate();
  const {loader, setLoader} = useMainContext();

  const formik = useFormik({
    initialValues:{
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      let {email} = values;
      try {
        const formData = new FormData();
        formData.append("email", email);
        const response = await axios.post(`/user/forget-password`, formData, {
            headers: {
                Accept: "application/json",
            },
        });
        if (response?.data?.success) {
          navigate('/forgot-password-mail')
          setLoader(false);
        } else {
            console.error(response);
            setLoader(false);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    }
  })

  useEffect(() => {
    document.body.classList.add("forgot-password-page");
    return () => {
      document.body.classList.remove("forgot-password-page");
    };
  }, []);

  return (
    <>
      <Helmet>
          <title>Ad Designer - Forgot Password</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                  <form onSubmit={formik.handleSubmit}>
                      <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Forgot Password</h5>
                      <Input type="text" id="email" name="email" label="Email" placeholder="name@domain.com" value={ formik.values.email } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.email && formik.errors.email && { error: true, errorMsg: formik.errors.email })}> </Input>
                      <Button type="submit" color="blue" text="Send" className="mt-[20px] mb-[40px]" disabled={loader}></Button>
                      <div className="text-center mb-[20px]">
                          <p><span className="text-[#999999] text-md font-['Sofia_Pro']">Just remember?</span>  <Link to="/login" className="font-['Sofia_Pro'] text-[#555555] hover:underline">Login</Link></p>
                      </div>
                  </form>
              </div>
            </div>
            <MainFooter />
        </div>
      </PageAnimation>
    </>
  )
}

export default ResetPassword