/** External Dependencies */
import { styled } from "@mui/material";
import { Input } from "@scaleflex/ui/core";

export const StyledNodeControls = styled("div")(
  ({ top, left, visibility }) => ({
    position: "absolute",
    zIndex: 1,
    background: "#FEFEFE",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    top: (top || 0) + 8,
    left: (left || 0) + 4,
    transform: "translateX(-50%)",
    height: 34,
    padding: "6px 8px",
    gap: "6px",
    fontFamily: 'MarkForMCNrw, "Sofia Pro", sans-serif',
    transition: 'all .1s ease'
  })
);

export const IconButton = styled("div")(({ active, pathStyle }) => ({
  cursor: "pointer",
  padding: "4px 6px",
  whiteSpace: "nowrap",
  background: active ? "#EAEAEA" : "transparent",
  borderRadius: 4,
  minWidth: 26,
  minHeight: 26,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#555555",
  fontSize: "12px",
  transition: "all .3s ease",
  gap: 4,

  "> svg": {
    margin: "auto",
  },

  path: {
    stroke: "#777",
    ...pathStyle,
  },
  "&:hover": {
    background: "#EAEAEA",
  },
}));

export const StyledInput = styled(Input)({
  maxWidth: 60,
  height: 24,
  marginLeft: 5,
});

export const Separator = styled("div")({
  height: "100%",
  width: "1px",
  background: "#E0E0E0",
});
