import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)({
  textTransform: "none",
  color: "#777777",
  fontSize: "12px",
  fontWeight: "normal",
  borderColor: "#ddd",
  borderRadius: "5px",
  letterSpacing: "none",
  paddingInline: "8px",
  gap: "5px",
  height: "30px",
  flex: 1,
  "&:hover": {
    color: '#7b22ec',
    "circle": {
      fill: "#7b22ec",
      stroke: "#7b22ec"
    },
    "rect": {
      stroke: "#7b22ec"
    }
  }
});