import { useEffect, React } from "react";
import { useNavigate } from 'react-router-dom';
// import useAuth from '../../../utils/Auth';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import axios from 'axios';
import PasswordInput from '../../common/PasswordInput';
import Button from '../../common/Button';
import { useFormik } from "formik";
import * as Yup from "yup";
import useMainContext from '../../../context/Main';
import getQueryParams from '../../../utils/getQueryParams';
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
  const navigate = useNavigate();
  const {loader, setLoader} = useMainContext();

  const formik = useFormik({
    initialValues:{
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
      passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirmation Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/, 'Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers \& symbols.'),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      let {password, passwordConfirmation} = values;
      const {token} = getQueryParams();
      try {
        const formData = new FormData();
        formData.append("password", password);
        formData.append("confirmPassword", passwordConfirmation);
        formData.append("token", token);
        const response = await axios.post(`/user/reset-password/token`, formData, {
            headers: {
                Accept: "application/json",
            },
        });
        if (response?.data?.success) {
          navigate('/reset-password-successful')
          setLoader(false);
        } else {
            setLoader(false);
            console.error(response);
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  })

  useEffect(() => {
    document.body.classList.add("reset-password-page");
    return () => {
      document.body.classList.remove("reset-password-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Ad Designer - Reset Password</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                  <form onSubmit={ formik.handleSubmit }>
                      <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Reset Password</h5>
                      <PasswordInput id="password" name="password" label="Password" value={ formik.values.password } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.password && formik.errors.password && { error: true, errorMsg: formik.errors.password })}> </PasswordInput>
                      <PasswordInput id="passwordConfirmation" name="passwordConfirmation" label="Re-type Password " value={ formik.values.passwordConfirmation } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && { error: true, errorMsg: formik.errors.passwordConfirmation })}> </PasswordInput>
                      <Button type="submit" color="blue" text="Reset" className="mt-[20px] mb-[40px]" disabled={loader}></Button>
                  </form>
              </div>
            </div>
            <MainFooter />
        </div>
      </PageAnimation>
    </>
  )
}

export default ResetPassword