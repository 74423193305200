/** External Depepdencneis */
import React, { useCallback, useEffect, useMemo } from "react";

/** Internal Depepdencneis */
import { SELECT_TOOL } from "../../actions";
import { TABS_TOOLS, TOOLS_ITEMS } from "../tools/tools.constants";
import { TABS_IDS } from "../../utils/constants";
import { useStore } from "../../hooks";
import { StyledToolsBar } from "./ToolsBar.styled";
import ToolsBarItemOptionsWrapper from "./ToolsBarItemOptionsWrapper";
import { Box } from "@mui/system";
import { CustomTabPanel, StyledTab, StyledTabs } from "./Tabs/Tabs.styled";
import LayerManager from "./LayerManager";
import GlobalOptions from "../tools/GlobalOptions";
import useCreative from "../../hooks/useCreative";

const ToolProperties = () => {
  const [tab, setTab] = React.useState(0);

  const {
    t,
    dispatch,
    tabId,
    toolId,
    annotations,
    selectionsIds = [],
    config: { defaultTabId, defaultToolId, useCloudimage },
  } = useStore();

  const isCreative = useCreative();

  const currentTabId = tabId || defaultTabId;
  const currentToolId =
    toolId || defaultToolId || TABS_TOOLS[currentTabId]?.[0];

  const handleChange = (event, tabId) => {
    setTab(tabId);
  };

  const tabTools = useMemo(
    () => TABS_TOOLS[currentTabId] || [],
    [currentTabId]
  );

  const selectTool = useCallback((newToolId) => {
    dispatch({
      type: SELECT_TOOL,
      payload: {
        toolId: newToolId,
      },
    });
  }, []);

  const ToolOptionsComponent = useMemo(() => {
    if (!currentToolId) {
      return false;
    }

    if (currentTabId === TABS_IDS.ANNOTATE) {
      const selectionsLength = selectionsIds.length;
      if (selectionsLength === 1) {
        const selectedAnnotation = annotations[selectionsIds[0]];
        return TOOLS_ITEMS[selectedAnnotation?.name]?.ItemOptions;
      } else if (selectionsLength > 1) {
        // if there is more than 1 annotations selected in the canvas show this
        if (isCreative) {
          return GlobalOptions;
        } else {
          return null;
        }
      }
    }

    return currentTabId &&
      currentToolId &&
      TABS_TOOLS[currentTabId].includes(currentToolId)
      ? (!TOOLS_ITEMS[toolId]?.hideFn ||
          !TOOLS_ITEMS[toolId]?.hideFn({ useCloudimage })) &&
          TOOLS_ITEMS[toolId]?.ItemOptions
      : TOOLS_ITEMS[toolId]?.ItemOptions;
  }, [currentTabId, currentToolId, annotations, selectionsIds]);

  useEffect(() => {
    if (!toolId && currentToolId) {
      dispatch({
        type: SELECT_TOOL,
        payload: { toolId: currentToolId },
      });
    }
  }, []);

  return (
    <StyledToolsBar className="FIE_tools-bar-wrapper">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          position: "sticky",
          background: "#fff",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        }}
      >
        {/* ========= for debugging ======== */}
        {/* {Object.keys(annotations).map((id) => (
          <div
            style={{
              fontSize: "12px",
              padding: ".3rem 1rem",
              background:
                id?.split("-")[0] !== annotations[id].name ? "red" : "inherit",
              fontWeight: selectionsIds.includes(id) ? "bold" : 400,
            }}
          >
            {id}
          </div>
        ))} */}
        {/* ========================= */}
        <StyledTabs value={tab} onChange={handleChange}>
          <StyledTab label="Design" />
          <StyledTab label="Layers" />
        </StyledTabs>
      </Box>

      <CustomTabPanel active={tab === 0}>
        <ToolsBarItemOptionsWrapper>
          {ToolOptionsComponent && <ToolOptionsComponent t={t} />}
        </ToolsBarItemOptionsWrapper>
      </CustomTabPanel>
      <CustomTabPanel active={tab === 1}>
        <LayerManager />
      </CustomTabPanel>
    </StyledToolsBar>
  );
};

export default ToolProperties;
