/** External Dependencies */
import styled from 'styled-components';
import Input from '@scaleflex/ui/core/input';
import Select from '@scaleflex/ui/core/select';

const StyledFontFamilySelect = styled(Select)`
  width: 100%;
  flex: 1;
`;

const StyledFontContainer = styled('div')`
  display: flex;
  gap: 12px;
`

const StyledFontWeight = styled(Select)`
  width: 100%;
  flex: 1;
`;

const StyledInput = styled('input')`
  width: 60px;
  font-size: 12px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  height: 24px;
  padding: 6px;
  color: #555;
  box-shadow: none;
  text-transform: uppercase;
  margin-right: 6px;

  &:focus,
  &:active {
    outline:none;
    box-shadow: none !important;
  }
`;

export { StyledFontFamilySelect, StyledInput, StyledFontWeight };
