import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";

const tutorialJSON = [
  {
    mainTitle:
      "Getting Started: An introduction to Ad Designer and its features",
    steps: [
      {
        title: "Overview",
        content:
          "Ad Designer is an online graphic design platform that allows you to create visual marketing content.",
      },
      {
        title: "Main menu",
        content:
          "Create social ads, banners or custom designs, even without prior design experience.",
      },
      {
        title: "Social Ads",
        content:
          "Pick and go with design templates forsocial media ads and posts.",
      },
      {
        title: "Banner ads",
        content:
          "Predefined templates for online banner ads are also available in various dimensions to suit your marketing needs.",
      },
      {
        title: "Custom ads",
        content:
          "For an ad with custom dimensions, select ‘Create Custom’ and input your dimensions.",
      },
      {
        title: "Project/Templates",
        content:
          "View designs that were recently edited, templates that were recently used or explore popular templates for your next design.",
      },
    ],
  },
  {
    mainTitle:
      "Creating Designs: How to create various designs using Ad Designer’s templates",
    steps: [
      {
        title: "Overview",
        content:
          "This tutorial shows you how to use Ad Designer to create a design for a Facebook ad.",
      },
      {
        title: "Get Started",
        content:
          "Access the dropdown menu and click on ‘Facebook’. Name your project and click ‘Create’.",
      },
      {
        title: "Select Format",
        content:
          "The dimensions of your ad are predefined based on the format you select.",
      },
      {
        title: "Select Template",
        content: "Use these templates as a foundation for creating your ad.",
      },
      {
        title: "Edit Design",
        content:
          "Modify elements of the selected template, using the editing tools available, to suite the purpose of your ad.",
      },
    ],
  },
  {
    mainTitle:
      "Working with Text: How to add text over designs and change its position",
    steps: [
      {
        title: "Overview",
        content:
          "This tutorial shows you how to use Ad Designer to add text to your design.",
      },
      {
        title: "Getting Started",
        content:
          "Click on the Text tool. Draw a box on your design, at the position you wish to place the text.",
      },
      {
        title: "Replacing Text",
        content:
          "Double click on the placeholder text and replace it with your desired text.",
      },
      {
        title: "Use AI To Write",
        content:
          "You can also use the AI-based Text Generator. Input a minimum of five words on your desired topic and it will generate five relevant options for you.",
      },
      {
        title: "Edit Text Appearance",
        content:
          "You can change the font, size, spacing, alignment or color of the text on your design.",
      },
      {
        title: "Change Text Position",
        content:
          "To move the text, click and drag it to a new location on your design.",
      },
    ],
  },
  {
    mainTitle: "Working with Shapes: How to insert shapes and modify them",
    steps: [
      {
        title: "Overview",
        content:
          "This tutorial shows you how to use Ad Designer to add and modify shapes.",
      },
      {
        title: "Create Shape",
        content:
          "Select a shape from the Shapes tool. Draw the shape at the position you wish to place it.",
      },
      {
        title: "Move Shape",
        content:
          "Click and drag the shape to move it. Rotate it by moving it using the top circle.",
      },
      {
        title: "Change Shape Color",
        content:
          "Change the colour of the shape by clicking on a color on the palette.",
      },
      {
        title: "Change Order of Shape",
        content:
          "Change the order of the shape by clicking on the buttons here.",
      },
      {
        title: "Change Order of Elements",
        content:
          "You can also use the Layers tool to change the order of the shape and other elements on your design.",
      },
    ],
  },
  {
    mainTitle: "Using Images: How to add and modify images in your designs",
    steps: [
      {
        title: "Overview",
        content:
          "This tutorial shows you how to use Ad Designer to add and modify images in your design.",
      },
      {
        title: "Add Image From Computer",
        content:
          "Click on the Image Gallery tool. Drag and drop an image from your desktop or click on ‘browse’ to upload an image from your computer.",
      },
      {
        title: "Add Image From Library",
        content: "You can also select an image from Ad Designer’s Library.",
      },
      {
        title: "Access Past Images",
        content:
          "Images that were used in previous designs can also be retrieved under the gallery.",
      },
      {
        title: "Modify Image",
        content: "Move the image, resize it or remove its background.",
      },
    ],
  },
  {
    mainTitle:
      "Exporting and Duplicating Designs: How to save and export designs, return to unfinished designs and repurpose existing designs for a new project",
    steps: [
      {
        title: "Overview",
        content:
          "This tutorial shows you how to use Ad Designer to save, export, retrieve and duplicate your design.",
      },
      {
        title: "Save Design",
        content: "To save your design, click this button.",
      },
      {
        title: "Export Design",
        content: "To export your design, click this button.",
      },
      {
        title: "Return To Design",
        content:
          "To return to an unfinished design, scroll to ‘Recent Projects’. Click on ‘SEE ALL’ to view all unfinished designs.",
      },
      {
        title: "Repurpose Design",
        content:
          "To repurpose an existing design for a new project, hover over it. Click on this button and click ‘Duplicate’.",
      },
      {
        title: "Change Project Name",
        content:
          "After duplicating the design, name your new project and start working on it.",
      },
    ],
  },
];

export default function TutorialPopup({
  onClose,
  selectedTutorial,
  open,
}) {
  const [selectedStep, setSelectedStep] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setSelectedStep(0)
    }, 200)
  }, [open])
  const handleClose = () => {
    // setSelectedStep(0)
    onClose(selectedTutorial);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const tutorial = tutorialJSON[selectedTutorial] || [];

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".MuiPaper-root": {
          maxWidth: "1140px",
        },
      }}
    >
      <div className="lg:px-[54px] lg:py-[35px]">
        <svg
          onClick={() => onClose()}
          className="absolute cursor-pointer right-4 top-5 md:right-7"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.189 4.811C19.6369 5.2589 19.6369 5.98509 19.189 6.43299L13.622 12L19.189 17.567C19.6369 18.0149 19.6369 18.7411 19.189 19.189C18.7411 19.6369 18.0149 19.6369 17.567 19.189L12 13.622L6.43299 19.189C5.98509 19.6369 5.2589 19.6369 4.811 19.189C4.3631 18.7411 4.3631 18.0149 4.811 17.567L10.378 12L4.811 6.43299C4.3631 5.98509 4.3631 5.2589 4.811 4.811C5.2589 4.3631 5.98509 4.3631 6.43299 4.811L12 10.378L17.567 4.811C18.0149 4.3631 18.7411 4.3631 19.189 4.811Z"
            fill="#404040"
          ></path>
        </svg>
        <DialogTitle
          sx={{
            fontSize: { xs: "20px", sm: "30px", md: "30px" },
          }}
          fontFamily={"Sofia Pro"}
          mb={"24px"}
        >
          {/* Getting Started: An introduction to Ad Designer and its features */}
          {tutorial?.mainTitle}
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col-reverse lg:flex-row gap-[26px] items-center">
            <div className="border-l border-[#111]/40 basis-[400px] grow-0 shrink-0">
              {tutorial?.steps?.map((step, i) => {
                const active = selectedStep === i;
                return (
                  <div
                    key={i}
                    className={`pl-8 ${
                      active ? "border-l-2 border-[#7B22EC]" : ""
                    } ${i !== tutorial?.steps.length - 1 ? "mb-8" : ""}`}
                  >
                    <h3
                      className={`text-[18px] md:text-[22.5px] cursor-pointer font-normal mt-[-1%] hover:text-[#111] transition  ${
                        active ? "text-[#111]" : "text-[#111]/40"
                      }`}
                      onClick={() => setSelectedStep(i)}
                    >
                      <span>{i + 1}. </span>
                      {step.title}
                    </h3>
                    <div className={`ml-[1%] ${active ? "block" : "hidden"}`}>
                      <p className=" text-base md:text-lg font-light text-[#555] mb-4">
                        {step.content}
                      </p>
                      <div className="flex gap-[16.5px]">
                        <button
                          onClick={() => {
                            if (selectedStep > 0) {
                              return setSelectedStep((curr) => curr - 1);
                            }
                          }}
                          className={` border border-[#111] bg-white rounded-[4px] shadow-[3px_3px_0px_0px_#111] items-center h-[30px] px-[24px] ${
                            i === 0 ? "hidden" : "flex"
                          }`}
                        >
                          Prev
                        </button>
                        <button
                          onClick={() => {
                            if (selectedStep >= tutorial.steps.length - 1) {
                              return onClose()
                            }
                            setSelectedStep((curr) => curr + 1);
                          }}
                          className={`bg-[#7B22EC] rounded-[4px] shadow-[3px_3px_0px_0px_#422594] items-center h-[30px] text-white px-[24px]`}
                        >
                          {tutorial.steps.length === selectedStep + 1 ? 'End Tutorial' : 'Next'}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                backgroundImage: "url(/images/tutorial/frame.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                padding: "5px 10px",
                paddingTop: "3%",
              }}
            >
              <video
                className="aspect-[550/300]"
                loop
                autoPlay
                src={`/images/tutorial/${selectedTutorial + 1}/0${selectedStep + 1}/0${selectedTutorial + 1}_0${selectedStep + 1}.mp4`}
                width={550}
                height={300}
                controls
              >
                Your browser does not support the video tag.
              </video>
              {/* <img
                src={`/images/tutorial/step${selectedStep + 1}.png`}
                alt=""
              /> */}
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
