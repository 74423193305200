const { styled, Button } = require("@mui/material");

const StyledButton = styled(Button)(({ theme }) => ({
  height: "30px",
  textTransform: "none",
  fontSize: '13px',
  lineHeight: 1.5,

  "&.MuiButton-outlined": {
    borderColor: theme.palette.primary.main,
  },
}));

export default StyledButton;
