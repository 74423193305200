import React from "react";
import MainHeader from "../../common/Header";
import MainFooter from "../../common/Footer";
import { Link } from "react-router-dom";
import PageAnimation from "../../common/PageAnimation";
import { Helmet } from "react-helmet";
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Ad Designer - Privacy Policy</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen">
          <MainHeader />
          <div className="max-w-[800px] mx-auto my-10 min-h-[80vh] px-6">
            <h1 className="font-['Sofia_Pro'] text-[24px] leading-[28px] text-[#111111] mb-[20px]">
              Data Privacy Policy
            </h1>
            <h2 className="font-['Sofia_Pro'] text-[18px] leading-[21px] text-[#111111] mb-[2px]"></h2>
            <div className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#555555] mb-[20px] page-content">
              <p>
                Aiken Digital LLC, a limited liability company registered in the
                state of Delaware, with Registration No. 84-3277968, and its
                registered address at 2140 South DuPont Hwy, Camden, Zip Code
                19934 ("Aiken") is the owner of Ad Designer.
              </p>
              <ol start="1" type="1">
                <li>
                  We at Ad Designer are dedicated to keeping your information
                  private. This privacy statement will explain how we handle
                  your personal information when you use our Platform or visit
                  our website. Additionally, it informs you of your legal
                  protections and rights to privacy. To completely understand
                  how and why we are using your data; it is crucial that you
                  read this privacy statement as well as any other privacy
                  statements or fair processing statements we may provide on
                  occasion when we are collecting or processing personal data
                  about you. This privacy statement is meant to be a supplement
                  to existing notices and privacy statements and is not to
                  replace them and you are required to acknowledge that you have
                  read and understood its content when registering for our
                  business services.
                </li>
              </ol>
              <ol start="2" type="1">
                <li>
                  Ad Designer is committed to being responsible custodians of the
                  personal information that we collect in the course of
                  operating our business.
                </li>
              </ol>
              <ol start="3" type="1">
                <li>
                  This Data Privacy Policy ("Policy") explains in full how, in
                  our capacity as a data controller, we collect, use, disclose,
                  or otherwise process and safeguard personal information as
                  part of running our business and providing services to our
                  clients and customers.
                </li>
              </ol>
              <ol start="4" type="1">
                <li>
                  Information that identifies, relates to, characterises, or may
                  reasonably be linked, directly or indirectly, to a specific
                  natural person is referred to in this Policy as "personal
                  information." It excludes data that is regarded under
                  applicable law as anonymous, de-identified, or aggregated.
                  Please refer to our Data Privacy Notice on our web site for
                  applicable definitions.
                </li>
              </ol>
              <ol start="5" type="1">
                <li>
                  <strong>Who we are.</strong>
                  <ol start="1" type="a">
                    <li>
                      Ad Designer is the data controller (collectively referred to
                      as "we", "us" or "our" in this privacy policy) and
                      responsible for the processing of your personal data also
                      referred to as personal identifiable (PI) data.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      If you have any questions about this privacy policy or our
                      privacy practices, please contact us in the following
                      ways: Email address: info@theaddesigner.com
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      Any information relating to an identified or identifiable
                      individual is referred to as "Personal Data," "Personal
                      Information," or "information about you" as used in this
                      privacy statement. This includes, but is not limited to,
                      your name, last name, company name, email address, phone
                      number and date of birth. The purpose of this privacy
                      statement is to ensure that you are aware of the Personal
                      Data we gather about you, its uses, and its disclosures.
                      To summarise, we want to let you know how we respect your
                      privacy and what rights you have in relation to the
                      Personal Data we gather and use about you.
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      For further clarifications on this Privacy Policy,
                      questions, or concerns, please do not hesitate to contact
                      us via the details above.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="6" type="1">
                <li>
                  <strong>Personal Data we collect from you.</strong>
                  <ol start="1" type="a">
                    <li>
                      We may collect data, including your Personal Data, through
                      our interactions with you, and when you:
                      <ol start="1" type="i">
                        <li>
                          Register, or complete or submit information or forms
                          on our website.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>Download content on our website.</li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          Provide us with feedback and information via phone,
                          email, social media, etc.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>Answer a survey.</li>
                      </ol>
                      <ol start="5" type="i">
                        <li>
                          Subscribe to any newsletters or other content-related
                          material.
                        </li>
                      </ol>
                      <ol start="6" type="i">
                        <li>Use Live Chat.</li>
                      </ol>
                      <ol start="7" type="i">
                        <li>Use forums or leave comments on our website.</li>
                      </ol>
                      <ol start="8" type="i">
                        <li>Purchase or use our products and/or services.</li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      Online data about you may also come through cookies and
                      other similar technologies (such as pixel tags and device
                      identifiers) used on our site or other websites.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      We may collect now, and, in the future, the following
                      categories of Personal Information listed below from
                      consumers, for commercial and/or business purposes under
                      the California Consumer Privacy Act of 2018
                      (&ldquo;CCPA&rdquo;) / CPRA / GDPR and other applicable
                      jurisdictions:
                      <ol start="1" type="i">
                        <li>
                          Identifiers and registration contact information:such
                          as name, e-mail address, postal address, telephone
                          number, account name, online identifier, Internet
                          Protocol (IP) address, or other similar identifiers.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Customer records information:&nbsp;such as name,
                          signature, social security number, physical
                          characteristics or description, address, telephone
                          number, passport number, bank account number, credit
                          or debit card number, other financial information.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          Commercial information: includes delivery information,
                          records of goods purchased, obtained, or considered,
                          as well as other consumer histories or patterns and
                          transactions with us.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          Internet or other electronic network activity
                          information:information about a consumer's engagement
                          with an Internet website, application, or
                          advertisement, as well as device information and
                          device identifiers, browsing type and language,
                          browsing history, search history, and data connection
                          and use information, such as information you download.
                        </li>
                      </ol>
                      <ol start="5" type="i">
                        <li>
                          Geolocation data:such as&nbsp;city, state, zip code,
                          etc. related to your IP address.
                        </li>
                      </ol>
                      <ol start="6" type="i">
                        <li>
                          Inferenceson your preferences and characteristics.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      Other information includes anything you voluntarily choose
                      to share with us about how you use our services, surveys
                      we send you, etc.
                    </li>
                  </ol>
                  <ol start="5" type="a">
                    <li>
                      Customer information includes inquiries and other messages
                      you send to us directly via online forms, chats, e-mail,
                      phone, or postal mail, as well as summaries or voice
                      recordings of your conversations with customer service.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="7" type="1">
                <li>
                  <strong>Personal Data we collect automatically.</strong>
                  <ol start="1" type="a">
                    <li>
                      Our website automatically collects certain data, through
                      automatic data collection tools such as cookies, beacons,
                      among others. These tools automatically collect the
                      following information:
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>Technical information</li>
                  </ol>
                  <p>
                    Technical data, such as, but not limited to, browser type,
                    operating system, device details, online identifiers like
                    cookie data and Internet Protocol address (IP), domain name,
                    referral URL, time zone setting, and/or visit time stamp.
                  </p>
                  <ol start="1" type="1">
                    <li>Usage data</li>
                  </ol>
                  <p>
                    Usage Data is the information we collect about how the
                    Website is accessed and used by our visitors. It may
                    comprise your computer's Internet Protocol address (IP
                    address), browser version, list of pages you have viewed on
                    our website, the time spent on those pages, the date and
                    duration of your visit, and other analytical data.
                  </p>
                </li>
              </ol>
              <ol start="8" type="1">
                <li>
                  <strong>How do we use your personal data</strong>
                  <ol start="1" type="a">
                    <li>
                      Such business purposes may be:
                      <ol start="1" type="i">
                        <li>
                          To fulfil the purpose for which the Personal
                          Information is provided.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          To provide you with information, products and/or
                          services that you request from us.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          To communicate and respond to your requests and
                          inquiries to us.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          In order to run our company and provide our goods and
                          services: engage in business with customers and fulfil
                          requests for our services.
                        </li>
                      </ol>
                      <ol start="5" type="i">
                        <li>
                          To check in after they've been delivered (live chat,
                          email, or phone inquiries).
                        </li>
                      </ol>
                      <ol start="6" type="i">
                        <li>
                          To provide users with email alerts, event
                          registrations, and other notices concerning our
                          products and/or services, or events or news that might
                          be of interest to the consumer.
                        </li>
                      </ol>
                      <ol start="7" type="i">
                        <li>
                          To manage and enhance our site's technical and
                          functional aspects, as well as to provide its
                          information to our clients or others.
                        </li>
                      </ol>
                      <ol start="8" type="i">
                        <li>
                          To evaluate, develop, and enhance how our site is used
                          and functions.
                        </li>
                      </ol>
                      <ol start="9" type="i">
                        <li>
                          To modify, enhance, or improve our services and/or
                          provision of such services to our clients.
                        </li>
                      </ol>
                      <ol start="10" type="i">
                        <li>
                          As necessary and appropriate to protect the rights,
                          property of the Business, our clients, or others.
                        </li>
                      </ol>
                      <ol start="11" type="i">
                        <li>
                          For the purpose of fulfilling our obligations and
                          enforcing our rights stemming from any contract we
                          have with customers, clients or other contractual
                          third parties, including billing and collections.
                        </li>
                      </ol>
                      <ol start="12" type="i">
                        <li>
                          For testing, research, analysis, and product and
                          services development.
                        </li>
                      </ol>
                      <ol start="13" type="i">
                        <li>To manage the security of our Site.</li>
                      </ol>
                      <ol start="14" type="i">
                        <li>
                          To comply with applicable law and regulations to
                          operate our business.
                        </li>
                      </ol>
                      <ol start="15" type="i">
                        <li>
                          To respond to law enforcement requests as required by
                          applicable law, court order, or governmental
                          regulations.
                        </li>
                      </ol>
                      <ol start="16" type="i">
                        <li>
                          As described to the consumer when collecting the
                          consumer&rsquo;s Personal Information, or as otherwise
                          as set forth by the CPRA / GDPR / US Data Laws and
                          other Jurisdictions.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      We do not gather additional categories of personal data,
                      nor do we use the data we have acquired for purposes other
                      than those listed in this privacy statement.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="9" type="1">
                <li>
                  <strong>
                    Legal basis for processing your personal data.
                  </strong>
                  <ol start="1" type="a">
                    <li>
                      We rely on the following legal basis to collect and
                      process your Personal Data under applicable CPRA / GDPR /
                      US Data Laws and other Jurisdictions.
                      <ol start="1" type="i">
                        <li>
                          Consent: where required to obtain your consent, we
                          will do so. For example, certain jurisdictions require
                          us to obtain your consent to process precise location
                          information.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Performance of a contract: where processing is
                          necessary for the performance of a contract with you.
                          We may need to collect and use your personal
                          information to enter into a contract with you or to
                          perform a contract that you have with us. For example,
                          when you use our Digital Services, we will use your
                          personal information to respond to your requests and
                          provide you with such services.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          Legal obligation: where it is necessary as a legal
                          obligation for us to process your Personal Data to
                          comply with a legal requirement under applicable
                          legislation, such as when responding to a public or
                          governmental body, a court, or regulators.
                          <p>
                            Legitimate interest: We may use your personal
                            information for our legitimate interests to improve
                            our products and services, as well as the content on
                            our Digital Services. Consistent with our legitimate
                            interests and any choices that we offer or consents
                            that may be required under applicable laws, we may
                            use technical information and personal information
                            for our marketing purposes.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="10" type="1">
                <li>
                  <strong>How Ad Designer</strong>
                  <strong>&nbsp;uses cookies and other technologies</strong>
                  <ol start="1" type="a">
                    <li>
                      Cookies are small files that a site or its service
                      provider transfers to your computer&rsquo;s hard drive
                      through your Web browser (if you allow) that enables the
                      site&rsquo;s or service provider&rsquo;s systems to
                      recognize your browser and capture and remember certain
                      information.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      According to the laws and regulations, we are permitted to
                      keep cookies on your computer if they are essential for
                      the functioning of our website. We will ask for your
                      consent for all other types of cookies.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="11" type="1">
                <li>
                  <strong>Your rights.</strong>
                  <ol start="1" type="a">
                    <li>
                      Under current privacy laws and regulations, CCPA / CPRA /
                      US Data Laws and other Jurisdictions you possess certain
                      rights in relation to your Personal Data.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      You have the right to ask us for information on how we
                      collect, distribute, and sell your data in accordance with
                      the CCPA and CPRA, as well as when and how we will release
                      your information.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      Right to know
                      <p>
                        You have the right to know the specific pieces of
                        information we collect, use, disclose or sell, and have
                        collected, used, disclosed, or sold,&nbsp;covering the
                        12-month period preceding our receipt of your
                        information request.&nbsp;In particular, you can ask us
                        to disclose:
                      </p>
                      <ol start="1" type="1">
                        <li>
                          The specific pieces of your Personal Information we
                          collect or have collected.
                        </li>
                      </ol>
                      <ol start="2" type="1">
                        <li>
                          The categories of Personal Information we have to
                          collect or have collected about you.
                        </li>
                      </ol>
                      <ol start="3" type="1">
                        <li>
                          The categories of sources from which we collect or
                          have collected your Personal Information.
                        </li>
                      </ol>
                      <ol start="4" type="1">
                        <li>
                          The categories of your Personal Information that we
                          sell, or have sold, disclose, or have disclosed for a
                          business purpose.
                        </li>
                      </ol>
                      <ol start="5" type="1">
                        <li>
                          The categories of third parties to whom we sell or
                          have sold, disclose, or have disclosed your Personal
                          Information.
                        </li>
                      </ol>
                      <ol start="6" type="1">
                        <li>
                          The business or commercial purpose for which we have
                          to collect, sell, or have collected or sold your
                          Personal Information.
                        </li>
                      </ol>
                      <ol start="7" type="1">
                        <li>
                          To make a verifiable consumer request for information
                          about the Personal Information we have collected about
                          you, please email us at&nbsp;info@theaddesigner.com.
                        </li>
                      </ol>
                      <ol start="8" type="1">
                        <li>
                          Right to delete
                          <p>
                            You also have the right to request that we delete
                            Personal Information, subject to certain exceptions
                            according to applicable law. You may exercise your
                            right by emailing us at&nbsp;info@theaddesigner.com.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      Right to receive a copy
                      <p>
                        You have the right to request a copy of the Personal
                        Information we have collected about you. You may
                        exercise your right by using emailing us
                        at&nbsp;info@theaddesigner.com.
                      </p>
                    </li>
                  </ol>
                  <ol start="5" type="a">
                    <li>
                      Right to non-discrimination
                      <p>
                        If you exercise any of your consumer rights under the
                        CCPA / CPRA / US Data Laws, or other jurisdictions, you
                        have the right not to be subjected to discrimination by
                        us, including denial of goods or services or charging
                        you a different price or rate for them.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="12" type="1">
                <li>
                  <strong>How to exercise your rights</strong>
                  <ol start="1" type="a">
                    <li>
                      You might exercise any of your rights under the CCPA /
                      CPRA / US Data Laws, or other jurisdictions by submitting
                      your request for information required to be disclosed
                      and/or deleted by accessing our Contact Form or by
                      contacting us at the following e-mail address&nbsp;
                      <a href="mailto:info@theaddesigner.com">info@theaddesigner.com</a>.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      We shall confirm if the consumer making a request to know
                      or to delete is the consumer about whom we have collected
                      the Personal Information when we receive a verifiable
                      consumer request. In general, we won't ask for more
                      details from customers just to verify their identity.
                      However, if we are unable to confirm the consumer's
                      identification using the data we already hold, we may ask
                      for more information. This information will only be used
                      to confirm the consumer's identity. According to the
                      guidelines outlined in the CCPA / CPRA, we shall respond
                      to your verifiable consumer request in an appropriate
                      manner. If you have a password-protected account with us,
                      we may use that account to authenticate you in order to
                      prove your identity.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      Requests made by authorised agents
                      <ol start="1" type="i">
                        <li>
                          As per the CCPA / CPRA, you may also appoint an
                          authorised agent in writing or through a power of
                          attorney to act on your behalf and exercise your
                          privacy rights. We shall take all reasonable steps to
                          confirm that you have properly authorised your agent
                          to act on your behalf before granting his request. To
                          do this, they must send us written confirmation, such
                          as a signed authorization or a copy of a power of
                          attorney.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Some of these rights you exercise are subject to
                          certain exceptions (e.g. the right for us to delete
                          your Personal Data when we have a legal obligation to
                          retain such data, or such deletion affects a third
                          party). If you exercise one of these rights, we will
                          confirm your eligibility and get back to you within 10
                          (ten) days if you want access to your personal data or
                          5 (five) days if you want any changes made to, added
                          to, or removed from your data. Please get in touch
                          with us to exercise your rights. at{" "}
                          <a href="mailto:info@theaddesigner.com">
                            info@theaddesigner.com
                          </a>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="13" type="1">
                <li>
                  <strong>Who has access to your personal information?</strong>
                  <ol start="1" type="a">
                    <li>
                      Being your Data Controller, we may acquire, store,
                      process, and analyse your Personal Data in the manner and
                      within the constraints set out above.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      We do not disclose your information to third parties for
                      personal use unless you authorize it. However, we may
                      share it under special circumstances, including cases
                      when:
                      <ol start="1" type="i">
                        <li>
                          Law enforcement, government authorities, or third
                          parties with legal rights require it. We may share
                          information as may be permitted or required by the
                          laws of any jurisdiction that may apply to us, as
                          provided for under contract, or as we deem reasonably
                          necessary to provide legal services. In these
                          circumstances, we strive to take reasonable efforts to
                          notify you before we disclose information that may
                          reasonably identify you or your organization, unless
                          prior notice is prohibited by applicable law or is not
                          possible or reasonable in the circumstances.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Personal Information is required by our
                          representatives or Service Providers who carry out
                          tasks on our behalf.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>
                          We need to protect the rights, property, and safety of
                          the company and our employees.
                        </li>
                      </ol>
                      <ol start="4" type="i">
                        <li>
                          We need to use the information for handling legal
                          claims and disputes.
                        </li>
                      </ol>
                      <ol start="5" type="i">
                        <li>
                          We need to carry out an agreement we have entered with
                          you.
                        </li>
                      </ol>
                      <ol start="6" type="i">
                        <li>
                          There is an emergency or force majeure situation.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      We may collect aggregate data about our users which is not
                      personally identifiable and share this information with
                      our partners, Service Providers, advertisers, and other
                      third parties for marketing or promotional purposes.
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      With third parties based in nations other than your own,
                      we might share personal data or outsource processing to
                      them. As a result, the Personal Data we gather may be
                      gathered, transferred to, processed, and/or stored
                      anywhere Ad Designer, its affiliates, or service providers
                      have offices. Your Personal Data may therefore be covered
                      by privacy regulations from nations other than your own.
                      In this context, we might divulge your personal data to:
                      <ol start="1" type="i">
                        <li>
                          Our Affiliates to provide our services and/or
                          products.
                        </li>
                      </ol>
                      <ol start="2" type="i">
                        <li>
                          Our Service Providers that help us conduct our
                          business and support infrastructure, payment
                          processors when you purchase our products and/or
                          services.
                        </li>
                      </ol>
                      <ol start="3" type="i">
                        <li>Channel Partners.</li>
                      </ol>
                      <ol start="4" type="i">
                        <li>Vendors and Agents.</li>
                      </ol>
                      <ol start="5" type="i">
                        <li>Suppliers.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="14" type="1">
                <li>
                  <strong>Website functionality</strong>
                  <ol start="1" type="a">
                    <li>
                      We might work with Service Providers, such as people and
                      outside companies, to run our website, offer the website
                      on our behalf, provide website-related services, and
                      collect analytics data. Depending on the Personal
                      Information they have about you, Service Providers might
                      also assist us in tailoring your experience on the
                      website. These third parties are only entitled to use your
                      Personal Data to do these tasks; they are not allowed to
                      share it with anyone else or use it in any other way.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="15" type="1">
                <li>
                  <strong>Marketing tools</strong>
                  <ol start="1" type="a">
                    <li>
                      Service Providers help us improve our marketing operations
                      which are intended to engage our website&rsquo;s visitors
                      with tailored and relevant information based on previous
                      interaction with our website. You may ask us to stop using
                      your information for marketing reasons by contacting us
                      via the methods provided above.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="16" type="1">
                <li>
                  <strong>Website analytics</strong>
                  <ol start="1" type="a">
                    <li>
                      Google Analytics is a web analytics tool that records and
                      analyses website traffic on our website to monitor how it
                      is used. This information is made available to other
                      Google services. Google may use it to contextualize and
                      customize advertisements on its own network.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      In addition to information on how you use the Website,
                      Google Analytics may store your IP address. We, however,
                      do not have access to your IP address since it is
                      protected by Google.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      Visit the{" "}
                      <a href="http://www.google.com/intl/en/policies/privacy/">
                        Google Privacy Terms
                      </a>{" "}
                      web page to find out more about their data privacy
                      practices.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="17" type="1">
                <li>
                  <strong>Links to third-party websites</strong>
                  <ol start="1" type="a">
                    <li>
                      Our website may contain links to websites that are not
                      owned or managed by us. When you follow a third-party
                      link, you will leave our website and be sent to the third
                      party's website. We have no supervision over and do not
                      accept responsibility for any of the third party's
                      practices, content, or services provided on their website.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="18" type="1">
                <li>
                  <strong>Data retention and deletion</strong>
                  <ol start="1" type="a">
                    <li>
                      Your personal data will be retained for as long as is
                      required to achieve the purposes outlined in this Policy.
                      If you ask us to do so, we will remove your personal
                      information unless we are legally forced to do otherwise,
                      such as to comply with a court order, settle a legal
                      dispute, etc.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      Please keep in mind that we may require you to confirm
                      your identity before we reply to your requests to amend,
                      delete, or restrict the usage of your personal data. If we
                      are unable to offer access within a sensible time frame,
                      we will advise you of a date when the information will be
                      made available. We will provide an explanation if access
                      is restricted for any reason. If you wish to know what
                      personal information, we store about you or would like it
                      to be removed, please contact us using the methods
                      provided in this privacy policy. If you have any concerns
                      about how we process your personal data, we ask that you
                      contact us to resolve the issue.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="19" type="1">
                <li>
                  <strong>Keeping your personal data safe</strong>
                  <ol start="1" type="a">
                    <li>
                      We are firmly committed to the security of your personal
                      data. We will make our best efforts to protect your
                      Personal Data, by implementing adequate technical,
                      physical, administrative, and organizational measures, to
                      guarantee the security of your information.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      We put in place administrative, technical, and physical
                      precautions that are economically feasible to abide by
                      applicable legal obligations and protect the data we
                      collect. This includes requesting written guarantees from
                      third parties who may have access to your personal
                      information that they will safeguard it with measures
                      meant to offer a level of protection comparable to those
                      taken by Ad Designer, where necessary or relevant and
                      practical. No information system, however, can be
                      completely safe. Your information's complete security is
                      not something we can guarantee. Furthermore, we are not
                      liable for the security of data that you send to us across
                      networks that are not under our control, such as wireless
                      networks and the Internet.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      We have put in place several things, like (encryption,
                      pseudonymization, amongst other measures to protect your
                      information). Additionally, we advise you to choose a
                      secure password, avoid sharing it with anybody, and log
                      out of your account as soon as your session is over in
                      order to protect your personal data.
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      The information we gather may be kept on file in the US or
                      other nations where we or our service providers have
                      locations. We may transfer information to nations other
                      than your home country, which may have different data
                      protection laws and regulations than your nation.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="20" type="1">
                <li>
                  <strong>Data breaches</strong>
                  <ol start="1" type="a">
                    <li>
                      Despite our best efforts to secure your information if we
                      suffer a data breach, we will do our best to reduce its
                      effects and will follow the applicable notification
                      provision of the CCPA / CPRA / US Data Laws and any other
                      applicable laws.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="21" type="1">
                <li>
                  <strong>Do not sell my personal information</strong>
                  <ol start="1" type="a">
                    <li>
                      Residents of California have the option to refuse the sale
                      of their personal information under the CCPA and CPRA. We
                      never market data that can be used to specifically
                      identify you. We never provide third parties with
                      information about you in return for cash or other
                      benefits. As stated in our General Privacy Policy, which
                      you can find at{" "}
                      <Link to={"/privacy"}>
                        https://www.theaddesigner.com/privacy
                      </Link>
                      , we only share information with third parties who
                      cooperate with us for the purposes specified therein.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      However, due to the broad definition of &ldquo;sale&rdquo;
                      of Personal Information under CCPA / CPRA / US Data
                      Laws,&nbsp;including the provision of Personal Information
                      to another business or third party for monetary and other
                      valuable consideration, should you prefer for us not to
                      sell your Personal Information, please opt-out of the sale
                      of your Personal Information by contacting us
                      at&nbsp;info@theaddesigner.com or by completing our contact
                      form.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      If you have given an authorised agent signed authorization
                      to act on your behalf, you may utilise that person to
                      submit an opt-out request. If an agent doesn't provide
                      documentation proving that they have the proper authority
                      to act on your behalf, we may reject their request to opt
                      out. Where not, we shall carry out your request in a
                      timely manner and in line with the law if applicable.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="22" type="1">
                <li>
                  <strong>
                    {" "}
                    Third parties with whom we may share your data with.
                  </strong>
                  <ol start="1" type="a">
                    <li>
                      We may share your data with third parties who provide
                      services on our behalf.
                    </li>
                  </ol>
                  <ol start="2" type="a">
                    <li>
                      All our third-party service providers are required to take
                      appropriate security measures to protect your data in line
                      with our policies. We do not allow them to use your data
                      for their own purposes. We permit them to process your
                      data only for specified purposes and in accordance with
                      our instructions.
                    </li>
                  </ol>
                  <ol start="3" type="a">
                    <li>
                      We may also share your personal data with third parties if
                      we are under a duty to disclose or share your personal
                      data in order to comply with any legal obligation, or to
                      protect the rights, property or safety of our site, our
                      users, and others.
                    </li>
                  </ol>
                  <ol start="4" type="a">
                    <li>
                      Where your data is shared with third parties, we will seek
                      to share the minimum amount necessary.
                    </li>
                  </ol>
                </li>
              </ol>
              <ol start="23" type="1">
                <li>
                  <strong>Changes to this privacy policy</strong>
                  <ol start="1" type="a">
                    <li>
                      Ad Designer may update this policy periodically and will
                      revise the date at the top of this policy to reflect the
                      date when such update occurred. If we make any material
                      changes in the way we collect, use, and/or share the
                      personal information that you have provided, we will
                      endeavour to provide you with notice before such changes
                      take effect, such as by posting prominent notice on our
                      website.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <MainFooter />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  );
};

export default Privacy;
