/** External Dependencies */
import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { InputAdornment, InputBase } from "@mui/material";

/** Internal Dependencies */
import { ReactComponent as FSizeIcon } from "../../../icons/properties/font-size.svg";
import { ReactComponent as FVertAlignIcon } from "../../../icons/properties/v-align.svg";
import { ReactComponent as FLetterSpacingIcon } from "../../../icons/properties/letter-spacing.svg";
import { TOOLS_IDS, TRANSFORMERS_LAYER_ID } from "../../../../utils/constants";
import { ENABLE_TEXT_CONTENT_EDIT, UPDATE_STATE } from "../../../../actions";
import { useStore } from "../../../../hooks";
import {
  activateTextChange,
  deactivateTextChange,
} from "./handleTextChangeArea";
import TextAlignmentFields from "./TextAlignmentFields";
import Accordion from "../../../common/Accordion";
import Dropdown from "../../../common/Dropdown";
import HeadlineGenerator from "../../../../../../HeadlineGenerator";
import googleFonts from "../../../../../../../../../assets/fonts/google-fonts.json";
import { ReactComponent as InfoIcon } from "../../../../../../../../../assets/svg/info.svg";
import { ReactComponent as GenerateIcon } from "../../../../../../../../../assets/svg/icon-generate.svg";
import useMainContext from "../../../../../../../../../context/Main";
import {
  StyledLabel,
  StyledSubWrapperInline,
} from "../../../common/AnnotationOptions/AnnotationOptions.styled";
import PalletePicker from "../../../common/PalletePicker";
import rgba2hex from "../../../../utils/rgba2hex";
import usePrompt from "../../../../hooks/usePrompt";
import ConfirmModal from "components/common/Modals/ConfirmModal";
import AMPositionFields from "../../../common/AnnotationOptions/AMPositionFields";
import InputStandard from "../../../common/InputStandard";
import FontLib from "../../../common/FontLib";

const TextControls = ({ text, saveText }) => {
  const { openHeadlineGeneratorModal, setOpenHeadlineGeneratorModal } =
    useMainContext();
  const {
    themeConfig,
    selectionsIds,
    dispatch,
    textIdOfEditableContent,
    designLayer,
    config,
    annotations,
  } = useStore();

  const { fonts = [], onFontChange } = config[TOOLS_IDS.TEXT];

  const { triggerPrompt, value, ...props } = usePrompt();
  const updateText = (payload) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        annotations: {
          ...annotations,
          [payload.id]: {
            ...annotations[payload.id],
            opacity: payload.opacity,
          },
        },
      },
    });
  };
  const changeTextProps = useCallback(
    (e) => {
      const { name, value, type } = e.target;
      saveText((latestText) => ({
        id: latestText.id,
        [name]:
          type === "number" || name === "letterSpacing"
            ? parseFloat(value)
            : value,
      }));
    },
    [saveText]
  );

  const changeAnnotationFill = useCallback(
    (newFill) => {
      saveText({
        fill:
          typeof newFill === "string"
            ? newFill
            : rgba2hex(`rgba(${Object.values(newFill?.rgb).join(", ")})`),
      });
    },
    [saveText]
  );

  const changeFontFamily = useCallback(
    (newFontFamily) => {
      changeTextProps({
        target: { name: "fontFamily", value: newFontFamily },
      });

      if (
        text.fontFamily !== newFontFamily &&
        typeof onFontChange === "function"
      ) {
        const reRenderCanvasFn = designLayer.draw.bind(designLayer);
        onFontChange(newFontFamily, reRenderCanvasFn);
      }
    },
    [changeTextProps, text, designLayer]
  );

  const changeFontWeight = useCallback(
    (newStyle) => {
      let fontStyle =
        text.fontStyle
          ?.replace("normal", "")
          .replace(new RegExp("[0-9]", "g"), "")
          .split(" ") || [];

      if (Object.keys(fontStyle).length > 0 && fontStyle.includes(newStyle)) {
        fontStyle = fontStyle.filter((style) => style !== newStyle);
      } else {
        fontStyle.push(newStyle);
      }

      changeTextProps({
        target: {
          name: "fontStyle",
          value: fontStyle.join(" ").trim() || "normal",
        },
      });
    },
    [text]
  );

  const disableTextEdit = useCallback(() => {
    dispatch({
      type: ENABLE_TEXT_CONTENT_EDIT,
      payload: {
        textIdOfEditableContent: null,
      },
    });
  }, []);

  const changeTextContent = useCallback((newContent) => {
    changeTextProps({
      target: {
        name: "text",
        value: newContent,
      },
    });
  }, []);

  useEffect(() => {
    let transformer;
    if (textIdOfEditableContent && text.id === textIdOfEditableContent) {
      const canvasStage = designLayer.getStage();
      [transformer] = canvasStage.findOne(`#${TRANSFORMERS_LAYER_ID}`).children;
      activateTextChange(
        textIdOfEditableContent,
        canvasStage,
        transformer,
        changeTextContent,
        disableTextEdit,
        saveText,
        updateText
      );
    }

    return () => {
      if (transformer && textIdOfEditableContent) deactivateTextChange();
    };
  }, [textIdOfEditableContent]);

  const weights = useMemo(() => {
    let variants =
      googleFonts.items.find((font) => font.family === text.fontFamily)
        ?.variants || [];

    variants = variants
      .map((v) => (v === "regular" ? "400" : v))
      .filter((v) => /^\d+$/.test(v));
    return variants.length
      ? variants
      : ["100", "200", "300", "400", "500", "700", "800", "900"];
  }, [text.fontFamily]);

  const updateSelectedColor = (index, color) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        themeConfig: {
          [TOOLS_IDS.TEXT]: {
            color: themeConfig[TOOLS_IDS.TEXT]?.color.map((item, i) => {
              if (i === index) return color.hex;
              return item;
            }),
          },
        },
      },
    });
  };

  return (
    <div>
      <Accordion title="Need writing assistance?" icon={<InfoIcon />}>
        <button
          className="flex w-full justify-center items-center h-[35px] text-[#555] text-[13px] rounded-md border border-solid border-[#AAAAAA]"
          onClick={() => {
            if (selectionsIds[0]?.split("-")[0] === TOOLS_IDS.TEXT) {
              setOpenHeadlineGeneratorModal(true);
            } else {
              triggerPrompt({
                onSubmit: async () => {
                  props.closeModal();
                },
              });
            }
          }}
        >
          <GenerateIcon className="mr-[7px]" /> AI-based Text Generator
        </button>
      </Accordion>
      <HeadlineGenerator
        showModal={openHeadlineGeneratorModal}
        closeModal={(e) => {
          setOpenHeadlineGeneratorModal(false);
        }}
      />

      <Accordion title="Text">
        <div className="flex gap-3 [&>*]:flex-1">
          {/* <Dropdown
            name="fontFamily"
            value={text.fontFamily}
            onChange={(e) => changeFontFamily(e.target.value)}
            data={fonts?.sort() || []}
          /> */}
          <FontLib
            value={text.fontFamily}
            onClick={(value) => changeFontFamily(value)}
          />

          {/* Todos:
            1. Render preview font using this images list https://github.com/getstencil/GoogleWebFonts-FontFamilyPreviewImages
            2. Use virtual list to save performance
            3. Implement, Webfontload on selection
           */}
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={text.fontFamily}
            label="Age"
            onChange={(e) => changeFontFamily(e.target.value)}
          >
            {fonts.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select> */}
          <Dropdown
            name="fontWeight"
            onChange={(e) => changeFontWeight(e.target.value)}
            value={
              (text.fontStyle.match(/\d+/) &&
                parseInt(text.fontStyle.match(/\d+/)[0])) ||
              400
            }
            data={weights?.sort() || []}
          />
        </div>
        <div className="flex justify-between">
          <div className="flex mt-3 max-w-[40%] items-center gap-2">
            <FSizeIcon style={{ flex: "0 0 24px" }} />
            <InputStandard
              type="number"
              size="small"
              min={0}
              name="fontSize"
              onChange={changeTextProps}
              //defaultValue={text.fontSize || 0}
              value={text.fontSize || 0}
            />
          </div>
          <div className="flex mt-3 max-w-[40%] items-center gap-2">
            <FVertAlignIcon style={{ flex: "0 0 24px" }} />

            <InputStandard
              type="number"
              size="small"
              min={0}
              name="lineHeight"
              onChange={changeTextProps}
              // defaultValue={text.lineHeight || 0}
              value={text.lineHeight || 0}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex mt-3 max-w-[40%] items-center gap-2">
            <FLetterSpacingIcon style={{ flex: "0 0 24px" }} />
            <InputStandard
              type="number"
              size="small"
              min={0}
              name="letterSpacing"
              onChange={changeTextProps}
              // defaultValue={text.letterSpacing || 0}
              value={text.letterSpacing || 0}
            />
          </div>
        </div>
        <div className="mt-6">
          <TextAlignmentFields annotation={text} updateAnnotation={saveText} />
        </div>

        <div className="mt-6">
          <StyledSubWrapperInline style={{ paddingTop: "0px", border: "none" }}>
            <StyledLabel>Color</StyledLabel>

            <PalletePicker
              color={text.fill}
              onChange={changeAnnotationFill}
              onPalleteAssign={updateSelectedColor}
              pallete={themeConfig[TOOLS_IDS.TEXT].color}
            />

            {/* <AMColorInput
              color={text.fill}
              onChange={changeAnnotationFill}
            /> */}
          </StyledSubWrapperInline>
        </div>
      </Accordion>

      <AMPositionFields />

      <ConfirmModal
        closeModal={props.closeModal}
        onConfirm={props.onSubmit}
        confirmText={"Okay"}
        modalTitle={"AI-based Text Generator"}
        modalStatus={props.modalStatus}
        modalContent={
          "Get started with the AI Text Generator by selecting or drawing a textbox."
        }
        hideDismissButton
      />
    </div>
  );
};

TextControls.defaultProps = {
  children: null,
};

TextControls.propTypes = {
  text: PropTypes.instanceOf(Object).isRequired,
  saveText: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default TextControls;
