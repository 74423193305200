import * as React from "react";
import PropTypes from "prop-types";

import { StrokeItem, StyledSelect } from "./BorderTypeDropdown.styled";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ReactComponent as ArrowDownIcon } from "images/icon-arrow-down.svg";
import { ReactComponent as StrokeSolidIcon } from "./images/solid.svg";
import { ReactComponent as StrokeDashIcon } from "./images/dash.svg";

export default function BorderTypeDropdown({ annotation, updateAnnotation }) {
  const handleChange = (e) => {
    const value = e.target.value;
    let dash = [];

    if (annotation.dashEnabled) {
      dash = []
    }

    if (value === "dashStroke" && !annotation.dash) {
      dash = [20];
    }

    updateAnnotation({
      dashEnabled: value === "dashStroke",
      dash,
    });
  };

  return (
    <FormControl variant="standard">
      <StyledSelect
        value={annotation.dashEnabled ? "dashStroke" : "solidStroke"}
        onChange={handleChange}
        IconComponent={() => <ArrowDownIcon />}
      >
        <MenuItem value={"solidStroke"}>
          <StrokeItem>
            <div className="flex items-center justify-items-center gap-[5px]">
              <span className="mt-[2px]">
                <StrokeSolidIcon />
              </span>
              <span>Solid</span>
            </div>
          </StrokeItem>
        </MenuItem>
        <MenuItem value={"dashStroke"}>
          <StrokeItem>
            <div className="flex items-center justify-items-center gap-[5px]">
              <span className="mt-[2px]">
                <StrokeDashIcon />
              </span>
              <span>Dash</span>
            </div>
          </StrokeItem>
        </MenuItem>
      </StyledSelect>
    </FormControl>
  );
}

BorderTypeDropdown.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
};
