/** External Dependencies */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Ellipse } from 'react-konva';

/** Internal Dependencies */
import nodesCommonPropTypes from '../nodesCommonPropTypes';
import Konva from "konva";

const EllipseNode = ({
  id,
  name,
  fill,
  x,
  y,
  radiusX,
  radiusY,
  scaleX,
  scaleY,
  offsetX,
  offsetY,
  rotation,
  annotationEvents,
  stroke,
  strokeWidth,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor,
  shadowOpacity,
  opacity,
  dash,
  dashEnabled=false,
  blurRadius,
  ...otherProps
}) => {

  const ellipseRef = React.useRef();
  useEffect(() => {
    // ellipseRef?.current?.cache({ offset: 100 });
  })

  return (
    <Ellipse
      id={id}
      name={name}
      rotation={rotation}
      scaleX={scaleX}
      scaleY={scaleY}
      stroke={stroke}
      strokeWidth={strokeWidth}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      shadowBlur={shadowBlur}
      shadowColor={shadowColor}
      shadowOpacity={shadowOpacity}
      fill={fill}
      x={x}
      y={y}
      radiusX={radiusX}
      radiusY={radiusY}
      offsetX={-radiusX}
      offsetY={-radiusY}
      opacity={opacity}
      dash={dash}
      dashEnabled={dashEnabled}
      filters={[Konva.Filters.Blur]}
      blurRadius={blurRadius}
      ref={ellipseRef}
      {...annotationEvents}
      {...otherProps}
    />
  )
};

EllipseNode.defaultProps = {
  ...nodesCommonPropTypes.defaults,
  fill: '#000',
  radiusX: 0,
  radiusY: 0,
};

EllipseNode.propTypes = {
  ...nodesCommonPropTypes.definitions,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  annotationEvents: PropTypes.instanceOf(Object).isRequired,
  radiusX: PropTypes.number,
  radiusY: PropTypes.number,
  fill: PropTypes.string,
};

export default EllipseNode;
