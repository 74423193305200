import React, { useState } from 'react';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/icon-error.svg';

function Input(props) {
    return (
        <div className="mb-[20px]">
            <label htmlFor={props?.id} className="font-['Sofia_Pro'] block mb-2 text-sm text-[#555555] mb-[8px]">{props?.label}</label>
            <input onChange={props.onChange} onBlur={props.onBlur} type={props?.type} name={props?.name} id={props?.id} placeholder={props?.placeholder} value={props.value || ''} className={`font-['Sofia_Pro'] text-sm text-[#333333] rounded-lg focus:ring-blue-500 border ${ props.error ? 'border-[#DF1E1E]' : 'border-[#DDDDDD]' } focus:border-blue-500 block w-full p-2.5 ${props.className}`} />
            {props.error && (
                <label className="pt-[10px] font-['Sofia_Pro'] text-[12px] text-[#DF1E1E] flex justify-start items-start">
                    <ErrorIcon className="mr-[7px] shrink-0"/>
                    { props.errorMsg }
                </label>
            )}
        </div>
    )
}

export default Input
