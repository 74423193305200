/** External Dependencies */
import React, { useCallback } from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import { useStore } from "../../../hooks";
import OpacityField from "./OpacityField";
import StrokeFields from "./StrokeFields";
import StrokeFill from "./StrokeFill";
import {
  StyledOptions,
  StyledSubWrapper,
  StyledSubWrapperInline,
  StyledLabel,
} from "./AnnotationOptions.styled";
import InputTransform from "../AMTransform";
import RectCornerField from "../../tools/Rect/RectCornerField";
import SelectDropdown from "../Select";
import Accordion from "../Accordion";
import PolygonSidesField from "./PolygonSides";
import Effect from "../../tools/Image/OptionGroup/Effect";
import PalletePicker from "../PalletePicker";
import { UPDATE_STATE } from "../../../actions";
import rgba2hex from "../../../utils/rgba2hex";
import AMPositionFields from "./AMPositionFields";
import BorderFields from "./BorderFields";

// const store = useStore();
// console.log("~ annotations", store.annotations)

const AnnotationOptions = ({
  children,
  morePoppableOptionsPrepended,
  moreOptionsPopupComponentsObj,
  morePoppableOptionsAppended,
  annotation,
  updateAnnotation,
  hideFillOption,
  hidePositionField,
  disabledRadiusCorner,
  className,
  shapeField,
  showBorderField,
  hideCornerRadius,
  hidePolygonSides,
  hideFill,
  ...rest
}) => {
  const { t, themeConfig, dispatch } = useStore();

  const changeAnnotationFill = useCallback(
    (newFill) => {
      updateAnnotation({
        fill:
          typeof newFill === "string"
            ? newFill
            : rgba2hex(`rgba(${Object.values(newFill?.rgb).join(", ")})`),
      });
    },
    [updateAnnotation]
  );

  const updateSelectedColor = (index, color) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        themeConfig: {
          shape: {
            color: themeConfig["shape"]?.color.map((item, i) => {
              if (i === index) return color.hex;
              return item;
            }),
          },
        },
      },
    });
  };

  return (
    <div>
      <StyledOptions
        className={`FIE_annotations-options${className ? ` ${className}` : ""}`}
      >
        <Accordion title="Transform">
          <InputTransform
            updateAnnotation={updateAnnotation}
            annotation={annotation}
          />
        </Accordion>

        <AMPositionFields
          updateAnnotation={updateAnnotation}
          annotation={annotation}
        />

        {shapeField && (
          <Accordion title="Appearance">
            {!hideFill && (
              <StyledSubWrapperInline style={{ paddingTop: "0px" }}>
                <StyledLabel>Fill</StyledLabel>

                <PalletePicker
                  color={annotation.fill}
                  onChange={changeAnnotationFill}
                  onPalleteAssign={updateSelectedColor}
                  pallete={themeConfig["shape"].color}
                />
              </StyledSubWrapperInline>
            )}
            <StyledSubWrapper>
              <BorderFields
                updateAnnotation={updateAnnotation}
                annotation={annotation}
                disabledRadiusCorner={disabledRadiusCorner}
              />

            </StyledSubWrapper>

            <StyledSubWrapper>
              <OpacityField
                title="Opacity"
                updateAnnotation={updateAnnotation}
                annotation={annotation}
                t={t}
              />
            </StyledSubWrapper>
          </Accordion>
        )}

        <Accordion title="Effects">
          <Effect
            updateAnnotation={updateAnnotation}
            annotation={annotation}
            t={t}
          />
        </Accordion>

        {children}
      </StyledOptions>
    </div>
  );
};

AnnotationOptions.defaultProps = {
  children: undefined,
  morePoppableOptionsPrepended: [],
  moreOptionsPopupComponentsObj: {},
  morePoppableOptionsAppended: [],
  hideFillOption: false,
  hidePositionField: false,
  className: undefined,
};

AnnotationOptions.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  children: PropTypes.node,
  hideFillOption: PropTypes.bool,
  morePoppableOptionsPrepended: PropTypes.arrayOf(PropTypes.object),
  morePoppableOptionsAppended: PropTypes.arrayOf(PropTypes.object),
  moreOptionsPopupComponentsObj: PropTypes.instanceOf(Object),
  hidePositionField: PropTypes.bool,
  className: PropTypes.string,
};

export default AnnotationOptions;
