import React, { useState, useRef } from 'react';
import { Navbar, Dropdown } from "@nextui-org/react";
import { useNavigate, Link } from 'react-router-dom';
import useAuth from '../../../utils/Auth';
import useMainContext from '../../../context/Main';
import { ReactComponent as AvatarIcon } from '../../../assets/svg/icon-avatar.svg';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar } from '@mui/material';
// import MasterCardLogo from '../../../assets/images/logo-mc.png';
import Logo from '../../../assets/images/logo-addesigner.png';
// import AssetGallery from '../../../assets/images/icon-gallery.png';
import Logout from '../../../assets/images/icon-logout.png';
import ProfileMobileModal from '../Modals/ProfileMobileModal';
import {TrackGoogleAnalyticsEvent} from "../../../utils/GA4";
// import { Helmet } from 'react-helmet-async';

export default function App() {
    const navigate = useNavigate();
    const {user, logout} = useAuth();

    const [openMobileModal, setOpenMobileModal] = useState(false)
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

    const navbarToggleRef = useRef()

    const HandleSideMenu = (name = null) => {
      isSideMenuOpen && navbarToggleRef.current.click()
      if (name !== null) {
        TrackGoogleAnalyticsEvent('Mobile', 'Navigation Menu', name)
      }
    }

    const email = user?.email;
    const {pathname} = useLocation();
    const {setLoader} = useMainContext();

    const handleLogout = () => {
        setLoader(false);
        logout();
        navigate('/', {replace: true});
    }

    const navigationItems = [
        {
            name: 'Features',
            url: '/features',
            target: '_self'
        },
        {
            name: 'Creative Templates',
            url: '/creative-templates/popular',
            target: '_self'
        },
        {
          name: 'Mastercard Marketing Hub',
          url: 'https://www.mastercard.us/en-us/business/overview/grow-your-business/digital-doors/marketing-hub/content.html',
          target: '_blank'
        },
        {
          name: 'Help',
          children: [
            {
              name: 'FAQ',
              url: '/faq',
            },
            {
              name: 'Tutorials',
              url: '/tutorials',
            },
          ]
        },
        {
          name: 'Contact Us',
          url: 'mailto:info@theaddesigner.com',
          target: '_blank'
        },
    ]

    // const collapseItems = [
    //   "Features",
    //   "Creative Templates",
    //   "Mastercard Marketing Resource Center",
    // ];

    const {
        setProject,
        reloadProjectIndex,
    } = useMainContext();

    const openEditor = (path) => {
        if (user) {
            localStorage.removeItem('project');
            setProject(null);
            reloadProjectIndex();
            navigate('/editor');
        } else {
            navigate(path, {replace: true});
        }
    }

    const avatarIcon = () => {
        return user?.first_name?.length > 0 || user?.last_name?.length > 0 ? <Avatar
        sx={{ width: 28, height: 28, p: 2, fontSize: 14, bgcolor: '#ef4444' }}
        >
          {user?.first_name[0]?.toUpperCase()}{user?.last_name[0]?.toUpperCase()}
        </Avatar> : <Avatar
            sx={{ width: 28, height: 28 }}
            src='/images/avatar.png'
        />
    }
    const avatarIconBigger = () => {
        return user?.first_name?.length > 0 || user?.last_name?.length > 0 ? <Avatar
        sx={{ width: 40, height: 40, p: 2, fontSize: 14, bgcolor: '#ef4444' }}
        >
          {user?.first_name[0]?.toUpperCase()}{user?.last_name[0]?.toUpperCase()}
        </Avatar> : <Avatar
            sx={{ width: 40, height: 40 }}
            src='/images/avatar.png'
        />
    }

    const profileDropDown = () => {
      return <div className="flex">
          {avatarIconBigger()}
          <div className="flex flex-col">
              <span className="text-sm text-[#111111] text-[16px] font-['Sofia_Pro'] pl-2 text-ellipsis overflow-hidden max-w-[150px]">{email}</span>
              <span className="font-['Sofia_Pro'] pl-2 text-left text-[13px] hover:underline text-[#7B22EC] cursor-pointer font-[400] underline" onClick={() => navigate('/profile')}>View Profile</span>
          </div>
      </div>
    }

    return (
      <>
        <Navbar variant="sticky" height="55px"
        disableShadow={pathname === '/editor'}
        css={{
            borderBottom: "1px solid #555555",
            "@xs": {
                // borderTop: "1px solid #555555",
                background: "#FFFFFF",
                // justifyContent: 'start',
            },
        }}
        >
          <Navbar.Brand>
            <div className='shrink-0 flex justify-start items-center flex-row flex-nowrap gap-x-[8px] w-[262px] mr-2'>
                <img src={Logo} alt="Ad Designer" className='cursor-pointer w-[142px] h-[17px]' onClick={() => navigate('/', {replace: true})} />
                <div className="font-['Sofia_Pro'] text-[10px] leading-[14px] text-[#777777] mt-[3px]">Sponsored by Mastercard<sup>&#174;</sup></div>
                {/* <img src={MasterCardLogo} alt="Mastercard" className='w-[27px] h-[16px]' /> */}
            </div>
          </Navbar.Brand>

          <Navbar.Content
            enableCursorHighlight
            activeColor="secondary"
            hideIn="md"
            variant="underline"
            css={{
                "@xs": {
                    fg: "1",
                    ml: '50px',
                },
            }}
          >
            {
                navigationItems.map(function(item, index) {
                  if(item.target === '_blank'){
                      return <a onClick={() => {TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', item.name)}} key={index} className="group text-[14px] font-['Sofia_Pro']" href={item.url} target="_blank"> <span className="hover:transition-all ease-in-out duration-500 p-[5px] group-hover:bg-[#EEEEEE] rounded-[8px]">{item.name}</span> </a>
                  } else if (item.children) {
                    return (
                      <Dropdown isBordered>
                        <Navbar.Item>
                          <Dropdown.Button
                            auto
                            light
                            css={{
                              px: 0,
                              dflex: "center",
                              svg: { pe: "none" },
                            }}
                            // iconRight={icons.chevron}
                            ripple={false}
                          >
                            {item.name}
                          </Dropdown.Button>
                        </Navbar.Item>
                        <Dropdown.Menu
                          css={{
                            $$dropdownMenuWidth: "340px",
                            $$dropdownItemHeight: "70px",
                            "& .nextui-dropdown-item": {
                              // py: "$4",
                              height: '30px',
                              fontSize: '14px',
                              fontWeight: '300',
                              // dropdown item left icon
                              svg: {
                                color: "$secondary",
                                mr: "$4",
                              },
                              // dropdown item title
                              "& .nextui-dropdown-item-content": {
                                w: "100%",
                                color: '#555',
                              },
                            },
                          }}
                        >
                          {item.children.map(sub => (
                            <Dropdown.Item
                              key={sub.url}
                              showFullDescription
                            >
                              <Link to={sub.url} className='block'>{sub.name}</Link>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )

                  } else {
                      return <Link onClick={() => {TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', item.name)}} key={index} to={item.url} className={`group font-['Sofia_Pro'] text-[14px] h-full flex items-center ${(pathname === item.url) ? 'shadow-[inset_0_-2px_0_rgba(0,0,0,1)] font-[700]' : ''}`}>
                                <span className={`hover:transition-all ease-in-out duration-500 p-[5px] ${(pathname !== item.url) ? 'group-hover:bg-[#EEEEEE]' : ''} rounded-[8px]`}>{item.name}</span>
                             </Link>

                  }
                })
            }
          </Navbar.Content>
          <Navbar.Content
            hideIn="md"
            gap="$0"
            css={{
                "@xs": {
                    jc: "flex-end",
                },
            }}
          >
            {user && (<>
                {user?.role === 'User' && (
                  <button className='border-x border-solid border-[#555555] px-[16px] h-full font-style mr-3' onClick={() => {TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', 'My Projects'); navigate('/my-projects')}}>My Projects</button>
                )}
                <Dropdown isBordered>
                    <Navbar.Item>
                    <Dropdown.Button
                        auto
                        light
                        css={{
                            px: 0,
                            pr: 7,
                            dflex: "center",
                            svg: { pe: "none" },
                        }}
                        ripple={false}
                    >
                        {avatarIcon()}
                    </Dropdown.Button>
                    </Navbar.Item>
                    <Dropdown.Menu
                    aria-label="ACME features"
                    disabledKeys={["display"]}
                    css={{
                        $$dropdownMenuWidth: "340px",
                        $$dropdownItemHeight: "45px",
                        "& .nextui-dropdown-item": {
                        py: "$4",
                        // dropdown item left icon
                        textAlign: "center",
                        svg: {
                            color: "$secondary",
                            mr: "$4",
                        },
                        // dropdown item title
                        "& .nextui-dropdown-item-content": {
                            w: "100%",
                            fontWeight: "$semibold",
                        },
                        },
                    }}
                    >
                      <Dropdown.Item
                          className="text-sm opacity-[1]"
                          key="display"
                          css={{
                              "@xs": {
                                borderRadius: "0",
                              },
                            }}
                      >
                        {profileDropDown()}
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                          key="gallery"
                          withDivider="true"
                          // color="error"
                          className="font-['Sofia_Pro'] text-left"
                      >
                          <div className="flex items-center" onClick={() => navigate('/asset-gallery')}>
                              <span className="pr-2"><img src={AssetGallery} alt="Asset Gallery" className='h-[18px]' /></span>
                              <span className="text-[#111111] text-[14px] font-['Sofia_Pro'] font-[400] top-[1px] relative">Asset Gallery</span>
                          </div>
                      </Dropdown.Item> */}
                      <Dropdown.Item
                          key="logout"
                          // color="error"
                          withDivider="true"
                          className="font-['Sofia_Pro'] text-left"
                      >
                          <div className="flex items-center" onClick={handleLogout}>
                              <span className="pr-2"><img src={Logout} alt="Logout" className='h-[18px]' /></span>
                              <span className="text-[#111111] text-[14px] font-['Sofia_Pro'] font-[400]">Logout</span>
                          </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {pathname !== '/editor' && (
                    <button className='px-[16px] h-full bg-[#7B22EC] text-white font-style ml-2' onClick={() => {
                      TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', user?.role === 'Designer' ? 'Create Template' : 'Create Design')
                      openEditor('/login');
                      // analytics.track("custom_click", {
                      //   navigation: 'Create Design',
                      // });
                    }}>
                      {user?.role === 'Designer' ? 'Create Template' : 'Create Design'}
                    </button>
                )}
            </>)}
            {!user && (<>
                <button className='border-l border-solid border-[#555555] px-[16px] h-full flex items-center font-style' onClick={() => {TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', 'Login'); navigate('/login', {replace: true})}}><AvatarIcon className='mr-1' /><span className="font-[400]">Login</span></button>
                <button className='px-[16px] h-full bg-[#7B22EC] text-white font-style' onClick={() => {TrackGoogleAnalyticsEvent('Desktop', 'Navigation Menu', 'Get Started'); openEditor('/register')}}><span className="font-[400]">Get Started</span></button>
            </>)}
          </Navbar.Content>

          <div className="flex items-center">
            <div className="xl:hidden pr-[5px]" showin="lg" onClick={ () => user ? setOpenMobileModal(true) : navigate('/login')}>
              {!user && (<>
                <AvatarIcon onClick={() => {TrackGoogleAnalyticsEvent('Mobile', 'Navigation Menu', 'Avatar Icon Login')}} className='w-[32px] h-[32px]' />
              </>)}
              {user && (<>
                {avatarIcon()}
              </>)}

            </div>
            <Navbar.Toggle showIn="md" ref={navbarToggleRef} onChange={(isSelected) => setIsSideMenuOpen(isSelected)}/>
          </div>
          <Navbar.Collapse
            css={{
                borderTop: "1px solid #555555",
                background: "#FFFFFF",
            }}
          >
          {user && (<>
            <div onClick={() => {HandleSideMenu('My Projects'); navigate('/my-projects');}} className=" left-[5%] bg-[#FFFFFF] text-[#000000] p-[15px] text-center button mb-[30px] border border-[#000000] rounded-[8px]">
              <span className="text-[14px] font-[400] font-['Sofia_Pro']">My Projects</span>
            </div>
          </>)}

          {!user && (<>
            <div onClick={() => {HandleSideMenu('Get Started'); navigate('/login'); }} className=" left-[5%] bg-[#7B22EC] text-white p-[15px] text-center button mb-[30px] rounded-[8px]">
              <span className="text-[14px] font-[400] font-['Sofia_Pro']">Get Started</span>
            </div>
          </>)}
          {

            navigationItems.map(function(item, index) {
              if(item.target === '_blank'){
                  return <Navbar.CollapseItem key={index}>
                            <a onClick={() => {TrackGoogleAnalyticsEvent('Mobile', 'Navigation Menu', item.name)}} href={item.url} target="_blank"> {item.name} </a>
                          </Navbar.CollapseItem >
              }
              else {
                  return <Navbar.CollapseItem
                            key={index}
                            activeColor="secondary"
                            // css={{
                            //   color: index === collapseItems.length - 1 ? "$error" : "",
                            // }}
                            // isActive={index === 1}
                          >
                            <Link onClick={() => HandleSideMenu(item.name)} to={item.url} className={`font-['Sofia_Pro'] h-full flex items-center ${(pathname === item.url) ? 'text-[#7B22EC] shadow-[inset_0_-2px_0_rgba(123,34,236,1)] font-[700]' : ''}`}>
                                {item.name}
                            </Link>
                          </Navbar.CollapseItem >
              }
            })
          }
          </Navbar.Collapse>
        </Navbar>
        <ProfileMobileModal avatar={profileDropDown} showModal={openMobileModal} closeModal={() => setOpenMobileModal(false)} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon={false}
          toastClassName={(style) => {
            if (style.type === 'success') {
              return 'Toastify__toast Toastify__toast--close-on-click bg-[#D7F1EF] text-[#0B9454] !rounded-[8px] !min-h-[38px]'
            } else if (style.type === 'error') {
              return 'Toastify__toast Toastify__toast--close-on-click bg-[#ffcaca] text-[#DF1E1E] !rounded-[8px] !min-h-[38px]'
            }
            return style.defaultClassName
          }}
          bodyClassName={() => "Toastify__toast-body !font-['Sofia_Pro'] !text-[14px]"}
        />
      </>
    );
  };