import React from 'react';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import { Link } from 'react-router-dom';
import PageAnimation from '../../common/PageAnimation';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import * as NotFoundAnimation from '../../../assets/lottie/404.json'

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Ad Designer - 404 Page not found</title>
            </Helmet>
            <PageAnimation>
                <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
                    <MainHeader />
                    <div className='grow flex flex-col justify-center items-center'>
                        <div className="md:hidden">
                            <Lottie options={
                                    {
                                        loop: true,
                                        autoplay: true,
                                        animationData: NotFoundAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }
                                }
                                width={200}
                                height={245}
                                isStopped={false}
                                isPaused={false}
                            />
                        </div>
                        <div className="hidden md:block">
                            <Lottie options={
                                    {
                                        loop: true,
                                        autoplay: true,
                                        animationData: NotFoundAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }
                                }
                                width={400}
                                height={490}
                                isStopped={false}
                                isPaused={false}
                            />
                        </div>
                        <h1 className="font-['Sofia_Pro'] text-[30px] leading-[36px] text-[#111111] my-[16px]">Oops! Page not found.</h1>
                        <p className="font-['Sofia_Pro'] text-[24px] leading-[34px] text-[#555555] text-center">This page doesn’t exist or was removed.</p>
                        <p className="font-['Sofia_Pro'] text-[24px] leading-[34px] text-[#555555] text-center">Please head back to the homepage.</p>
                        <Link to='/' className="my-[42px] btn-3d font-['Sofia_Pro'] flex items-center justify-center text-white bg-[#7B22EC] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-[14px] text-center opacity-1">Back to Home</Link>
                    </div>
                    <MainFooter />
                </div>
            </PageAnimation>
        </>
    )
}

export default NotFound