import { act } from "@testing-library/react";

const { styled, Tab, Tabs } = require("@mui/material");

export const StyledTabs = styled(Tabs)({
  ".MuiTabs-indicator": {
    display: "none !important",
  },
});

export const StyledTab = styled(Tab)({
  padding: "8px 12px",
  minHeight: "28px",
  minWidth: "unset",
  background: "#fff",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 13,
  lineHeight: "90%",
  textTransform: "none",
  color: "#111",
  margin: "12px 0 12px 12px",

  "&.Mui-selected": {
    background: "#FFFFFF !important",
    color: "#111 !important",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
});

export const CustomTabPanel = styled("div")(({ active }) => ({
  display: active ? "block" : "none",
}));
