import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from "react-bottom-drawer";

const ThumbnailCardFilter = (props) => {
    const [tags, setTags] = useState([])
    const [isVisible, setIsVisible] = useState(false);
    const openDrawer = useCallback(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
        setIsVisible(true);
      }, []);
    const closeDrawer = useCallback(() => setIsVisible(false), []);
    const fetchTags = async () => {
        try {
            const response = await axios.get(`/app/tags`);
            if (response?.data?.data && response?.data?.success) {
                setTags(response.data.data.filter(item => item !== 'Custom'));
            } else {
                console.error(response);
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchTags();
    }, []);

    const searchInput = useRef();
    useEffect(() => {
        if (!props?.loading) {
            searchInput.current.focus();
        }
    }, [props?.loading]);

    return (
        <>
            <div className="sm:border sm:border-[#E5E5E5] border-top rounded-[20px] sm:rounded-b-[0] sm:rounded-t-[20px] sm:p-[20px] mb-1 sm:mb-0">
                <div className="flex items-center">
                    <img src={'/images/creative-templates/filter_icon.png'} alt="masthead" className=" w-[42px] h-[42px] border border-[#e5e5e5] rounded-[10px] p-[10px] sm:hidden mr-1" onClick={openDrawer}></img>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 right-0 flex items-center px-2">
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="text-[#C4C4C4] pr-2 text-sm" />
                        </div>
                        <input ref={searchInput} type="text" name="search" id="search" onChange={(e) => props.onSearch(e)} placeholder="Search" className="font-['Sofia_Pro'] border border-[#E5E5E5] text-sm rounded-[100px] block w-full py-[9px] pl-[20px] pr-[40px] focus:border-[#E5E5E5] focus:ring-0" autoComplete="off" disabled={props?.loading} />
                    </div>
                </div>
            </div>
            <Drawer
                duration={250}
                hideScrollbars={true}
                onClose={closeDrawer}
                isVisible={isVisible}
                className="appDrawer"
            >
                <div className="pb-5">
                    {tags && (
                        tags.map((item, index) => (
                            <button key={`tags-${ index }`} onClick={() => props.onFilter(item)} className={`w-xs mt-[10px] text-[#111111] border border-[#E5E5E5] bg-[#FFFFFF] enabled:hover:bg-[#7B22EC] enabled:hover:text-white font-medium rounded-[40px] text-sm px-7 mr-3 py-[10px] text-center ${props.active === item && 'active'} [&.active]:border-[#555555]`} disabled={props?.loading}>{item}</button>
                        ))
                    )}
                </div>
            </Drawer>
            <div className="border border-[#E5E5E5] border-top border-t-0 p-[20px] mb-10 hidden sm:block sm:rounded-b-[20px]">
                <p className="font-['Sofia_Pro'] text-[#111111] text-s">FILTER</p>
                <div>
                    {tags && (
                        tags.map((item, index) => (
                            <button key={`tags-${ index }`} onClick={() => props.onFilter(item)} className={`w-xs mt-[10px] text-[#111111] border border-[#E5E5E5] bg-[#FFFFFF] enabled:hover:bg-[#7B22EC] enabled:hover:text-white font-medium rounded-[40px] text-sm px-7 mr-3 py-[10px] text-center ${props.active === item && 'active'} [&.active]:border-[#555555]`} disabled={props?.loading}>{item}</button>
                        ))
                    )}
                </div>
            </div>
        </>
    )
}

export default ThumbnailCardFilter