import React from "react";
import { AccordionDetails, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";

import { ReactComponent as ArrowDownIcon } from "images/icon-arrow-down.svg";
import useMainContext from "../../../../../../../../context/Main";
import PopoverModal from "../../../../../../../common/Modals/PopoverModal";
import { StyledAccordion, StyledAccordionSummary } from "./Accordion.styled";

export default function Accordion({
  title = "Title here",
  icon,
  children,
  expanded = true,
}) {
  const { anchorEl, setAnchorEl } = useMainContext();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <StyledAccordion defaultExpanded={true}>
        <StyledAccordionSummary expandIcon={<ArrowDownIcon />} tabIndex={-1}>
          <Typography
            sx={{
              fontSize: "10px",
              color: "#111",
              letterSpacing: "1.5px",
              // lineHeight: 0,
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
          <div className="w-[15px]" onClick={handleClick}>
            {icon}
          </div>
          {icon && (
            <Popover
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  boxShadow: "0px 8px 60px rgba(0, 0, 0, 0.12)",
                },
              }}
              className="popover-text-generator mt-[-30px]"
              onClick={(e) => e.stopPropagation()}
            >
              <div style={{ overflow: "hidden", borderRadius: "20px" }}>
                <PopoverModal />
              </div>
            </Popover>
          )}
        </StyledAccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>{children}</AccordionDetails>
      </StyledAccordion>
    </>
  );
}
