import { styled } from "@mui/material";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

export const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  border-color: ${(props) => getColor(props)};
  background-color: rgba(255, 255, 255, 0.72);
  border: 1px dashed #e5e5e5;
  color: #bdbdbd;
  outline: none;
  height: 100%;
  transition: border 0.24s ease-in-out;
`;
