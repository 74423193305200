import {
  InputBase,
  NativeSelect,
  styled,
} from "@mui/material";
import React from "react";

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      background: 'transparent'
    }
  },
}));

export default function Dropdown({ data, value, ...props }) {
  return (
    <NativeSelect value={value || ""} {...props} input={<StyledInput />}>
      {data?.length &&
        data.map((item) => (
          <option key={item.value || item} value={item.value || item}>
            {item.label || item}
          </option>
        ))}
    </NativeSelect>
  );
}
