import React from 'react';
import { Modal, Box } from '@mui/material';
import { ReactComponent as IconError } from '../../../assets/svg/error.svg';
import useMainContext from '../../../context/Main';

const ErrorModal = (props) => {
    const {setLoader} = useMainContext();

    const ActionBtn = props.error?.actionBtn || null

    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } }
            }}
            open={props?.showModal}
            onClose={() => {setLoader(false); props.closeModal()}}
        >
            <Box style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "none",
                outline: "none",
                boxShadow: 24,
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.8)",
                overflow: "hidden",
            }}>
                <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center font-['Sofia_Pro'] text-[15px] h-[40px]">{props?.error?.title}</div>
                <div className='p-[30px] bg-[#F9F9F9] flex flex-row justify-start items-center gap-[14px]'>
                    <IconError className='min-w-[16px]' />
                    <div className="text-[#555555] font-['Sofia_Pro'] text-[12px] leading-[14px] relative top-[1px]">
                        {props?.error?.message}
                    </div>
                </div>
                <div className='px-[30px] py-[8px] bg-[#FFFFFF] flex justify-end items-center'>
                    <div className='mr-2'>
                        {props?.error?.actionBtn ? <ActionBtn {...props} /> : null}
                    </div>
                    <button type="submit" className="font-['Sofia_Pro'] text-[14px] text-white bg-[#7B22EC] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center" onClick={() => {setLoader(false); props.closeModal()}}>OK</button>
                </div>
            </Box>
        </Modal>
    )
}

export default ErrorModal