/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";
import AMPositionFields from "../../common/AnnotationOptions/AMPositionFields";

/** Internal Dependencies */

const GlobalOptions = ({ t }) => {
  return (
    <>
      <AMPositionFields />
    </>
  );
};

GlobalOptions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default GlobalOptions;
