/** External Dependencies */
import styled from "styled-components";

const MainLayout = styled.div`
  height: calc(100vh - 57px);
  width: 100vw;
  // min-width: 1024px;
  // min-height: 678px;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: hidden;
  .main-items {
    flex: 0 0 300px;
    &:last-child {
      flex: 1 0;
    }
  }
`;

const LeftPanelHeader = styled.div`
  border-bottom: 1px solid #e0e0e0;
  height: 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
`;

const EditorHeader = styled.div`
  border-bottom: 1px solid #e0e0e0;
  height: 44px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const CenterPanelHeader = styled.div`
  flex: 1 0;
  padding: 0 15px 0 15px;
  display: flex;
  gap: 10px;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 300px;
`;

const RightPanelHeader = styled.div`
  position: absolute;
  right: 0;
  flex: 0 0 300px;
  gap: 10px;
  padding: 0 15px 0 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const LeftPanelContent = styled.div`
  height: calc(100% - 44px);
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
`;

const EditorContent = styled.div`
  height: calc(100% - 44px);
  display: flex;
  flex-flow: row nowrap;
`;

const CenterPanelContent = styled.div`
  flex: 1 0;
  background-color: #f1f2f3;
  height: 100%;
  width: 100%;
`;

const RightPanelContent = styled.div`
  flex: 0 0 305px;
  border-left: 1px solid #e0e0e0;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BackButton = styled.button`
  min-height: 30px;
  border: none;
  background: none;
  font: normal normal 500 14px/18px MarkForMCNrw;
  color: #555555;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export {
  MainLayout,
  LeftPanelHeader,
  EditorHeader,
  CenterPanelHeader,
  RightPanelHeader,
  LeftPanelContent,
  EditorContent,
  CenterPanelContent,
  RightPanelContent,
  BackButton,
};
