import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
// import useAuth from './Auth';

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    // const auth = useAuth();
    const loggedInUser = localStorage.getItem('user');
    if (!loggedInUser) {
      return <Navigate to='/login' state={{ path: location.pathname }} replace />
    }
    axios.defaults.headers.common['Authorization'] = '';
    const foundUser = JSON.parse(loggedInUser);
    if (foundUser?.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${ foundUser.token }`;
    }
    return children
}

export default ProtectedRoute