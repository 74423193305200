import { useState } from "react";
import { SET_ANNOTATION } from "../actions";
import { TOOLS_IDS } from "../utils/constants";
import useAnnotation from "./useAnnotation";
import useStore from "./useStore";
const ADDED_IMG_SPACING_PERCENT = 0.15;

const useImportImages = ({ replace = false }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [image, _, addNewImage] = useAnnotation(
    {
      name: TOOLS_IDS.IMAGE,
      opacity: 1,
    },
    false
  );
  const {
    dispatch,
    shownImageDimensions,
    config,
    selectionsIds,
    adjustments: { crop = {} },
  } = useStore();

  const addImgScaled = (loadedImg) => {
    const layerWidth = crop.width || shownImageDimensions.width;
    const layerHeight = crop.height || shownImageDimensions.height;
    const layerCropX = crop.x || 0;
    const layerCropY = crop.y || 0;

    const newImgRatio = Math.min(
      1,
      layerWidth /
        (loadedImg.width + loadedImg.width * ADDED_IMG_SPACING_PERCENT),
      layerHeight /
        (loadedImg.height + loadedImg.height * ADDED_IMG_SPACING_PERCENT)
    );

    addNewImage({
      image: loadedImg,
      x: Math.round(layerCropX + layerWidth / 2 - (loadedImg.width * newImgRatio) / 2),
      y: Math.round(layerCropY + layerHeight / 2 - (loadedImg.height * newImgRatio) / 2),
      width: Math.round(loadedImg.width * newImgRatio),
      height: Math.round(loadedImg.height * newImgRatio),
      ...config[TOOLS_IDS.IMAGE], // reset annotation
    });
  };

  const layerWidth = crop.width || shownImageDimensions.width;
  const layerHeight = crop.height || shownImageDimensions.height;

  const replaceCurrent = (loadedImg) => {
    const newImgRatio = Math.min(
      image.width / loadedImg.width,
      image.height / loadedImg.height
    );

    const scaledImage = {
      ...config.annotationsCommon,
      ...config[TOOLS_IDS.IMAGE],
      padding: 1,
      image: loadedImg,
      x: Math.round(image.x),
      y: Math.round(image.y),
      width: Math.round(loadedImg.width * newImgRatio),
      height: Math.round(loadedImg.height * newImgRatio),
      id: selectionsIds[0],
      name: TOOLS_IDS.IMAGE,
      replaceCurrent: true,
    };

    dispatch({
      type: SET_ANNOTATION,
      payload: scaledImage,
    });
  };

  const constructImage = (imageUrl, onLoad) => {
    setLoading(true);
    if (imageUrl) {
      const img = document.createElement("img");
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        if (onLoad && typeof onLoad === "function") onLoad();
        replace ? replaceCurrent(img) : addImgScaled(img);

        setLoading(false);
      };
      img.onerror = (err) => {
        console.error(err);
        setErrors("Error loading image");
        setLoading(false);
      };
      img.src = imageUrl;
    }
  };

  return { loading, errors, constructImage };
};

export default useImportImages;
