/** External Dependencies */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import Konva from "konva";
import { useStrictMode } from "react-konva";

/** Internal Dependencies */
import {
  CHANGE_POINTER_ICON,
  CLEAR_ANNOTATIONS_SELECTIONS,
  SELECT_TOOL,
  ZOOM_CANVAS,
} from "../../actions";
import {
  DEFAULT_ZOOM_FACTOR,
  POINTER_ICONS,
  TABS_IDS,
  TOOLS_IDS,
} from "../../utils/constants";
import { useStore } from "../../hooks";
import { endTouchesZooming, zoomOnTouchesMove } from "./touchZoomingEvents";
import { StyledCanvasNode } from "./MainCanvas.styled";
import defaultConfig from "../../context/defaultConfig";
import useAuth from "../../../../../../../utils/Auth";

const ZOOM_DELTA_TO_SCALE_CONVERT_FACTOR = 0.006;

const CanvasNode = ({ children }) => {
  useStrictMode(true);
  const canvasRef = useRef();
  const {
    dispatch,
    pointerCssIcon,
    tabId,
    toolId,
    designLayer,
    canvasWidth,
    canvasHeight,
    canvasScale,
    selectionsIds = [],
    zoom = {},
    config: { previewPixelRatio, disableZooming },
  } = useStore();
  Konva.pixelRatio = previewPixelRatio;
  const defaultZoomFactor = DEFAULT_ZOOM_FACTOR;
  const isZoomEnabled = !disableZooming && toolId !== TOOLS_IDS.CROP;
  const [isPanningEnabled, setIsPanningEnabled] = useState(
    tabId !== TABS_IDS.ANNOTATE &&
      tabId !== TABS_IDS.WATERMARK &&
      zoom.factor > defaultZoomFactor
  );

  const width = designLayer?.attrs?.clipWidth || 1075;
  const height = designLayer?.attrs?.clipHeight || 1075;
  const {user} = useAuth()

  useEffect(() => {
    const newSize = parseFloat(Math.round(width / 25.5));
    defaultConfig.Text.fontSize = newSize < 16 ? 16 : newSize;
  }, [width, height]);

  const cursorStyle = useMemo(
    () => ({
      cursor:
        pointerCssIcon === POINTER_ICONS.DEFAULT && tabId === TABS_IDS.ANNOTATE
          ? POINTER_ICONS.DRAW
          : pointerCssIcon,
    }),
    [tabId, pointerCssIcon]
  );

  const saveZoom = (newZoomProps) => {
    dispatch({
      type: ZOOM_CANVAS,
      payload: newZoomProps,
    });
  };

  const handleCanvasDragEnd = (e) => {
    if (
      e.currentTarget.draggable() &&
      e.target.nodeType.toLowerCase() === "stage" &&
      isZoomEnabled &&
      isPanningEnabled
    ) {
      saveZoom({
        factor: zoom.factor,
        x: e.target.x(),
        y: e.target.y(),
        preparedDimensions: true,
      });
    }
  };

  const clearSelections = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.currentTarget.container?.().focus();

      if (e.target instanceof Konva.Stage && selectionsIds.length > 0) {
        dispatch({
          type: SELECT_TOOL,
          payload: {
            toolId: TOOLS_IDS.ARTBOARD,
          },
        });
        dispatch({
          type: CLEAR_ANNOTATIONS_SELECTIONS,
        });
      }
    },
    [selectionsIds]
  );

  const dragBoundFunc = (pos) => {
    const x = Math.min(0, Math.max(pos.x, canvasWidth * (1 - zoom.factor)));
    const y = Math.min(0, Math.max(pos.y, canvasHeight * (1 - zoom.factor)));

    return {
      x,
      y,
    };
  };

  const handleZoom = (e) => {
    e.evt.preventDefault();
    //const newScale = (zoom.factor || defaultZoomFactor) +  e.evt.deltaY * -ZOOM_DELTA_TO_SCALE_CONVERT_FACTOR;
    // const pointer = e.currentTarget.getPointerPosition();
    // saveZoom({
    //   // ...pointer,
    //   factor: newScale,
    // });
    if(user?.role === "Designer") {
      saveZoom({
        factor: e.evt.deltaY < 0 ? zoom.factor * 1.1 : zoom.factor / 1.1,
      });
    }
  };

  const preventDraggingIfMultiTouches = (e) => {
    if (e.evt.touches?.length > 1) {
      setIsPanningEnabled(false);
    }
  };

  const resetPanningAbility = () =>
    setIsPanningEnabled(
      tabId !== TABS_IDS.ANNOTATE || tabId === TABS_IDS.WATERMARK
    );

  const endTouchesZoomingEnablePanning = () => {
    endTouchesZooming(resetPanningAbility);
  };

  const mapKeyboardKeys = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (document.getElementsByClassName("deleteNode").length > 0) {
        document
          .querySelectorAll(".deleteNode div")
          .forEach((el) => el.click());
      }
    }
    if ((e.ctrlKey && e.keyCode === 68) || (e.metaKey && e.keyCode === 68)) {
      e.preventDefault();
      document.querySelectorAll(".tour__action-buttons button")[0].click();
      return false;
    }
    if ((e.ctrlKey && e.keyCode === 80) || (e.metaKey && e.keyCode === 80)) {
      e.preventDefault();
      document.querySelectorAll(".tour__action-buttons button")[1].click();
      return false;
    }
    if ((e.ctrlKey && e.keyCode === 83) || (e.metaKey && e.keyCode === 83)) {
      e.preventDefault();
      document.querySelectorAll(".tour__action-buttons button")[2].click();
      return false;
    }
    if ((e.ctrlKey && e.keyCode === 90) || (e.metaKey && e.keyCode === 90)) {
      document.getElementsByClassName("FIE_topbar-undo-button")[0].click();
    }
    if ((e.ctrlKey && e.keyCode === 89) || (e.metaKey && e.keyCode === 89)) {
      document.getElementsByClassName("FIE_topbar-redo-button")[0].click();
    }
    if (
      (e.code === "Space" || e.key === "Control") &&
      !e.repeat &&
      zoom.factor > defaultZoomFactor &&
      isZoomEnabled
    ) {
      e.preventDefault();
      setIsPanningEnabled(true);
      dispatch({
        type: CHANGE_POINTER_ICON,
        payload: {
          pointerCssIcon: POINTER_ICONS.DRAG,
        },
      });
    }
  };

  const revertKeyboardKeysEffect = (e) => {
    if (e.code === "Space") {
      e.preventDefault();
      resetPanningAbility();
    }
  };

  const focusCanvasOnEnter = () => {
    if (canvasRef.current) {
      canvasRef.current.container().focus();
    }
  };

  useEffect(() => {
    dispatch({
      type: CHANGE_POINTER_ICON,
      payload: {
        pointerCssIcon: POINTER_ICONS[isPanningEnabled ? "DRAG" : "DEFAULT"],
      },
    });
  }, [isPanningEnabled]);

  useEffect(() => {
    setIsPanningEnabled(
      tabId !== TABS_IDS.ANNOTATE &&
        tabId !== TABS_IDS.WATERMARK &&
        zoom.factor > defaultZoomFactor
    );

    // Remove & register the event on changing tabId, zoom.factor, defaultZoomFactor to always access latest state.
    let canvasContainer;
    if (canvasRef.current) {
      canvasContainer = canvasRef.current.container();
      canvasContainer.addEventListener("mouseenter", focusCanvasOnEnter);
      canvasContainer.addEventListener("keydown", mapKeyboardKeys);
      canvasContainer.addEventListener("keyup", revertKeyboardKeysEffect);
    }

    return () => {
      if (canvasContainer) {
        canvasContainer.removeEventListener("mouseenter", focusCanvasOnEnter);
        canvasContainer.removeEventListener("keydown", mapKeyboardKeys);
        canvasContainer.removeEventListener("keyup", revertKeyboardKeysEffect);
      }
    };
  }, [tabId, zoom.factor, defaultZoomFactor]);

  // Zoom panning is done by dragging mouse except in annotate tab,
  // it's done by toggling panning through mouse right click (enable/disable) then drag mouse.
  const zoomedResponsiveCanvasScale =
    canvasScale * ((isZoomEnabled && zoom.factor) || defaultZoomFactor);
  return (
    <StyledCanvasNode
      className="FIE_canvas-node"
      tabIndex={-1}
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      scaleX={zoomedResponsiveCanvasScale}
      scaleY={zoomedResponsiveCanvasScale}
      x={(isZoomEnabled && zoom.x) || null}
      y={(isZoomEnabled && zoom.y) || null}
      // zoomFactor={(isZoomEnabled && zoom.factor) || defaultZoomFactor}
      onWheel={isZoomEnabled ? handleZoom : undefined}
      onTap={clearSelections}
      onClick={clearSelections}
      onTouchMove={
        isZoomEnabled ? (e) => zoomOnTouchesMove(e, saveZoom) : undefined
      }
      onDragStart={preventDraggingIfMultiTouches}
      onTouchEnd={isZoomEnabled ? endTouchesZoomingEnablePanning : undefined}
      dragBoundFunc={dragBoundFunc}
      draggable={isZoomEnabled && isPanningEnabled}
      onDragEnd={handleCanvasDragEnd}
      style={cursorStyle}
    >
      {children}
    </StyledCanvasNode>
  );
};

CanvasNode.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(CanvasNode);
