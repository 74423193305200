/** External Dependencies */
import React, { useRef, useState } from "react";

/** Internal Dependencies */
import { useAnnotation, useStore } from "../../../hooks";
import { TOOLS_IDS } from "../../../utils/constants";
import ImageControls from "./ImageControls";
import Accordion from "../../common/Accordion";
import OpacityField from "../../common/AnnotationOptions/OpacityField";
import BrightnessField from "../../common/AnnotationOptions/BrightnessField";
import ContrastField from "../../common/AnnotationOptions/ContrastField";
import SaturationField from "../../common/AnnotationOptions/SaturationField";

const ImageOptions = () => {
  const { t } = useStore();
  const [image, saveImage] = useAnnotation(
    {
      name: TOOLS_IDS.IMAGE,
      opacity: 1,
    },
    false
  );

  return (
    <div>
      <ImageControls image={image} saveImage={saveImage} t={t}>
        <Accordion title="Appearance">
          <OpacityField
            title="Opacity"
            updateAnnotation={saveImage}
            annotation={image}
            t={t}
          />
        </Accordion>
        <Accordion title="Image">
          <BrightnessField
            title="Brightness"
            updateAnnotation={saveImage}
            annotation={image}
            t={t}
          />
          <ContrastField
            title="Contrast"
            updateAnnotation={saveImage}
            annotation={image}
            t={t}
          />
          <SaturationField
            title="Saturation"
            updateAnnotation={saveImage}
            annotation={image}
            t={t}
          />
        </Accordion>
      </ImageControls>
    </div>
  );
};

export default ImageOptions;
