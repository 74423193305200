/** External Dependencies */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import { useStore } from "../../../hooks";
import { SET_LATEST_COLOR } from "../../../actions";
import { StyledColorPicker, StyledPickerTrigger } from "./ColorInput.styled";
import { ClickAwayListener, Grow, Popper } from "@mui/material";
import AmColorPicker from "../AmColorPicker";

const ColorInput = ({ onChange, color }) => {
  const {
    selectionsIds = [],
    config: { annotationsCommon = {} },
    latestColor,
  } = useStore();
  const [anchorEl, setAnchorEl] = useState();
  const [currentColor, setCurrentColor] = useState(
    () => latestColor || color || annotationsCommon.fill
  );

  const changeColor = (color) => {
    setCurrentColor(color);
    onChange(color);
  };

  const togglePicker = (e) => {
    if (e.target.closest("#color-picker")) return;
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  useEffect(() => {
    const colorToSet = (selectionsIds.length === 0 && latestColor) || color;
    setCurrentColor(colorToSet);
    onChange(colorToSet);
  }, [color, selectionsIds]);

  const id = Boolean(anchorEl) ? "color-picker" : undefined;

  return (
    <>
      <StyledPickerTrigger
        className="FIE_color-picker-triggerer"
        onClick={togglePicker}
        $color={currentColor}
        onChange={onChange}
      />
      <ClickAwayListener
        onClickAway={(e) => {
          if (
            !e.target.closest("#color-picker") &&
            !e.target.closest(".FIE_color-picker-triggerer")
          ) {
            setAnchorEl(null);
          }
        }}
      >
        <Popper
          id={id}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={togglePicker}
          placement="top-end"
        >
          {/* <Grow in={Boolean(anchorEl)}> */}
          <AmColorPicker onChange={changeColor} color={currentColor} />
          {/* </Grow> */}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

ColorInput.defaultProps = {
  position: "top",
  color: undefined,
};

ColorInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  color: PropTypes.string,
};

export default ColorInput;
