import { React, useEffect, useState, useMemo, useRef } from "react";
import MainHeader from "../../common/Header";
import MainFooter from "../../common/Footer";
import axios from "axios";
import useMainContext from "../../../context/Main";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import DeleteConfirmationModal from "../../common/Modals/DeleteConfirmationModal";
import debounce from "lodash.debounce";
// import ThumbnailCardSkeleton from "../../common/Thumbnail/ThumbnailCardSkeleton";
import ThumbnailCardTopImage from "components/common/Thumbnail/ThumbnailCardTopImage";
import ThumbnailCardFilter from "../../common/Thumbnail/ThumbnailCardFilter";
import fileDownload from "js-file-download";
import ProjectPreviewModal from "../../common/Modals/ProjectPreviewModal";
import useAuth from "../../../utils/Auth";
import {TrackGoogleAnalyticsEvent} from "../../../utils/GA4";
import PageAnimation from "../../common/PageAnimation";
import { Helmet } from "react-helmet";
import FolderIcon from "../../../assets/images/icon-folder.png";
import GenericProjectNameModal from "components/common/Modals/GenericProjectNameModal";
import usePrompt from "../Editor/react-filerobot-image-editor/src/hooks/usePrompt";
import { toast } from "react-toastify";
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const MyProjects = () => {
  const source = useRef(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const { openProject } = useMainContext();
  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("-1");
  const [loader, setLoader] = useState(false);
  const [openDeleteConformationModal, setOpenDeleteConformationModal] =
    useState(false);
  const [openProjectPreviewModal, setOpenProjectPreviewModal] = useState(false);
  const { triggerPrompt, value, ...prompt } = usePrompt();
  const moreButton = [
    {
      id : 1,
      name: 'Preview',
    },
    {
      id : 2,
      name: 'Edit'
    },
    {
      id : 3,
      name: 'Duplicate'
    },
    {
      id : 4,
      name: 'Download'
    },
    {
      id : 5,
      name: 'Delete',
      textColor: '!text-[#DF1E1E]'
    },
  ]

  useEffect(() => {
    setItems([]);
    setTotalRecords(0);
    setLoadMore(false);
    setSelectedItem({});
    setPage(1);
  }, [search, filter, sort]);

  useEffect(() => {
    source.current = new AbortController();
    return () => {
      if (source.current) {
        source.current.abort();
      }
      debouncedChangeHandler.cancel();
    };
  }, []);

  const changeHandler = (event) => setSearch(event.target.value);
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 800),
    []
  );
  const handleSortChange = (event) =>
    setSort(event.target.value);

  const mapProject = (source) => {
    return {
        id: source?._id,
        image: source?.thumbnail_url,
        thumbnail: source?.thumbnail_preview_url,
        type: source?.presetDimension_id?.group,
        size: `${ source?.presetDimension_id?.width }x${ source?.presetDimension_id?.height }`,
        link: '/editor',
        title: source?.name,
        created_at: source?.createdAt,
        insituBG: source?.presetDimension_id?.insituBG,
        position: source?.presetDimension_id?.position,
        group: source?.presetDimension_id?.group,
    }
  }

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `/project/?page=${page}&sortBy=${sort}&filter=${filter}&q=${search}&records=8`
      );
      setLoader(false);
      if (response?.data?.data && response?.data?.success) {
        setItems((currentItems) => {
          return [
            ...currentItems,
            ...response.data.data?.docs.map(mapProject)
          ];
        });
        setLoadMore(response.data.data?.hasNextPage);
        setTotalRecords(response.data.data?.totalDocs);
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!loader) {
      fetchData();
    }
  }, [page, search, filter, sort]);

  const viewProject = async (item) => {
    try {
      if (user) {
        setItems(
          items.map((project) => {
            if (project?.id === item?.id) {
              return { ...project, ...{ loading: true } };
            } else {
              return { ...project, ...{ loading: null } };
            }
          })
        );
        await openProject(item?.id, { signal: source.current.signal });
      } else {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const triggerSaveAsHandler = (project) => {
    triggerPrompt({
      onSubmit: async (newProjectName) => {
        prompt.closeModal();

        if (project?.id) {
          const formData = new FormData();
          formData.append("project_id", project.id);
          formData.append("filename", newProjectName); // saveas filename
          const response = await axios.post(`project/clone`, formData, {
            headers: {
              Accept: "application/json",
            },
          });
          if (
            response?.data?.success &&
            response?.data?.data &&
            response.data?.message
          ) {
            setItems([...[response.data.data].map(mapProject), ...items])
            toast.success(response.data.message);
          } else {
            toast.error(JSON.stringify(response));
          }
        }
      },
    });
  };
  const moreAction = async (action, item) => {
    const { name } = action;
    if (name === "Preview") {
      TrackGoogleAnalyticsEvent('Preview', 'My Projects', item.title)
      setOpenProjectPreviewModal(true);
    } else if (name === "Edit") {
      TrackGoogleAnalyticsEvent('Edit', 'My Projects', item.title)
      await viewProject(item);
    } else if (name === "Duplicate") {
      TrackGoogleAnalyticsEvent('Duplicate', 'My Projects', item.title)
      triggerSaveAsHandler(item)
    } else if (name === "Download") {
      TrackGoogleAnalyticsEvent('Download', 'My Projects', item.title)
      if (item?.id && item?.image) {
        try {
          const response = await axios.get(`/project/download/${item?.id}`, {
            responseType: "blob",
          });
          if (response?.data) {
            fileDownload(
              response.data,
              item?.image.substring(item?.image.lastIndexOf("/") + 1)
            );
          } else {
            console.error(response);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (name === "Delete") {
      setOpenDeleteConformationModal(true);
    }
  };

  const deleteProject = async (item) => {
    try {
      if (user) {
        const response = await axios.delete(`/project/${item?.id}`);
        if (response?.data?.success && response?.data?.data) {
          setItems([]);
          setTotalRecords(0);
          setLoadMore(false);
          setSelectedItem({});
          setPage(1);
          fetchData();
        }
      } else {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ad Designer - My Projects</title>
      </Helmet>
      <PageAnimation>
        <div className="flex flex-col w-full min-h-screen max-h-[100vh]">
          <MainHeader />
          <div className="grow flex flex-col">
            <div className="container mx-auto w-full px-5">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <h1 className="text-[#111111] font-['Sofia_Pro_Bold'] text-[40px] leading-[44x] mt-[50px] mb-[30px]">
                    My Projects
                  </h1>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <ThumbnailCardFilter
                    onSearch={debouncedChangeHandler}
                    loading={loader}
                    active={filter}
                    onFilter={(e) => setFilter(e)}
                  />
                </div>
                <div className="col-span-12 md:col-span-9">
                  <div className="container mx-auto max-w-6xl mb-[50px]">
                    <div
                      className={`flex justify-between items-center mb-[20px] mx-[0px] md:mx-[40px] pb-4 border-b border-[#E5E5E5]`}
                    >
                      <div>
                        <span className="font-['Sofia_Pro'] text-[#555555]">
                          {totalRecords}
                        </span>
                        <span className="font-['Sofia_Pro'] text-[#999999] text-sm">
                          {" "}
                          results
                        </span>
                      </div>
                      <div>
                        <span className="font-['Sofia_Pro'] text-[11px] tracking-[.1em] text-[#777777] text-sm pr-1">
                          SORT BY
                        </span>
                        <Select
                          onChange={handleSortChange}
                          defaultValue={sort}
                          displayEmpty
                          sx={{ m: 1, minWidth: 100 }}
                          inputProps={{ "aria-label": "Without label" }}
                          className="h-[40px] !font-['Sofia_Pro'] rounded-[4px] removeBorder !mr-0"
                        >
                          <MenuItem value="-1" className="!font-['Sofia_Pro']">
                            Recent
                          </MenuItem>
                          <MenuItem value="1" className="!font-['Sofia_Pro']">
                            Past
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div
                      className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px] mx-[0px] md:mx-[40px]`}
                    >
                      {items && items.length > 0 && (
                        <>
                          {items &&
                            items.map((item) => {
                              return (
                                <div key={item.id}>
                                  <ThumbnailCardTopImage
                                    item={item}
                                    clicked={(e) => viewProject(item)}
                                    loading={item?.loading}
                                    more={moreButton}
                                    moreClicked={(e) => {
                                      setSelectedItem(item);
                                      moreAction(e, item);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          <DeleteConfirmationModal
                            showModal={openDeleteConformationModal}
                            closeModal={() => {
                              setOpenDeleteConformationModal(false);
                              setSelectedItem({});
                            }}
                            item={selectedItem}
                            conform={() => deleteProject(selectedItem)}
                          />
                        </>
                      )}
                      {/* <div className={`${loader ? '' : 'hidden'}`}>
                                                {[...Array(8)].map((x, i) =>
                                                    <ThumbnailCardSkeleton key={`my-project-${ i }`} />
                                                )}
                                            </div> */}
                    </div>
                    <div
                      className={`${
                        loader || items.length > 0 ? "hidden" : "flex"
                      } justify-center items-center flex-col min-h-[50vh]`}
                    >
                      <img
                        src={FolderIcon}
                        alt="Empty folder"
                        className="w-[80px] h-[80px] my-[18px]"
                      />
                      <p className="mb-[2px] font-['Sofia_Pro'] text-[14px] leading-[17px] text-[#111111]">
                        There are no projects here
                      </p>
                      <div>
                        <label className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#777777]">
                          Start
                        </label>{" "}
                        <Link
                          to="/editor"
                          className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#7B22EC] hover:text-[#777777] mb-[18px]"
                        >
                          creating designs.
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      loadMore ? "flex" : "hidden"
                    } justify-center pb-[30px]`}
                  >
                    <button
                      className="btn-load-more"
                      onClick={() => setPage(page + 1)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MainFooter />
          {selectedItem?.id && (
            <GenericProjectNameModal
              {...prompt}
              initialValue={selectedItem?.title ?? ""}
              confirmButtonText="Duplicate"
              modalTitle="Duplicate"
            />
           )}
          <ProjectPreviewModal
            showModal={openProjectPreviewModal}
            closeModal={() => {
              setOpenProjectPreviewModal(false);
              setSelectedItem({});
            }}
            item={selectedItem}
          />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  );
};

export default MyProjects;
