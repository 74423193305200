/** External Dependencies */
import styled from "styled-components";
import ColorPicker from "@scaleflex/ui/core/color-picker";

const StyledPickerTrigger = styled.div.attrs(({ $color }) => ({
  style: {
    background:
      $color === "rgba(0,0,0,0)"
        ? "repeating-conic-gradient(#5d6d7e 0% 25%, transparent 0% 50%) 50% / 8px 8px"
        : $color,
  },
}))`
  background: ${({ theme }) => theme.palette["icons-primary"]};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  /* border: 2px solid ${({ theme }) => theme.palette["borders-strong"] || "#ddd"}; */
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  right: 0;
`;

const StyledColorPicker = styled(ColorPicker)`
  max-width: 352px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  padding: 16px;

  .SfxColorPicker-range-picker {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .SfxColorPicker-bar-wrapper {
    width: 100%;
  }

  .SfxInput-root {
    height: 35px;
    background: #fff;
    border: 1px solid #dddddd;
    border-radius: 4px;

  }

  .SfxColorItem-label {
    border-radius: 4px;
  }
`;

export { StyledPickerTrigger, StyledColorPicker };
