import StyledButton from "./Button.styled";

export default function Button({
  variant,
  children,
  color,
  disabled,
  size,
  prefix,
  ...props
}) {
  return (
    <StyledButton
      variant={variant}
      color={color}
      disabled={disabled}
      size={size}
      {...props}
    >
      {children}
    </StyledButton>
  );
}
