import { useEffect, useState, useMemo, useRef } from 'react';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import axios from 'axios';
import useMainContext from '../../../context/Main';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DeleteConfirmationModal from '../../common/Modals/DeleteConfirmationModal';
import debounce from 'lodash.debounce';
// import ThumbnailCardSkeleton from '../../common/Thumbnail/ThumbnailCardSkeleton';
import ThumbnailCard from '../../common/Thumbnail/ThumbnailCard';
// import ThumbnailCardFilter from '../../common/Thumbnail/ThumbnailCardFilter';
import useAuth from '../../../utils/Auth';
import fileDownload from 'js-file-download';
import ProjectPreviewModal from '../../common/Modals/ProjectPreviewModal';
import PageAnimation from '../../common/PageAnimation';
import { Helmet } from 'react-helmet';
import TemplateTypeDimensionModal from '../../common/Modals/TemplateTypeDimensionModal';
import { toast } from 'react-toastify';
import isMobileOrTablet from "../../../utils/isMobileOrTablet";
import { TrackGoogleAnalyticsEvent } from '../../../utils/GA4';
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const CreativeTemplates = () => {
    const source = useRef(null)
    const navigate = useNavigate()
    const {user} = useAuth()
    const params = useParams()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState({})
    const [page, setPage] = useState(1)
    const [loadMore, setLoadMore] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const {openTemplate} = useMainContext()
    const [filter, setFilter] = useState('All')
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState(params?.sort)
    const [loader, setLoader] = useState(false)
    const [openDeleteConformationModal, setOpenDeleteConformationModal] = useState(false)
    const [openProjectPreviewModal, setOpenProjectPreviewModal] = useState(false)
    const [openTemplateTypeDimensionModal, setOpenTemplateTypeDimensionModal] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [selectedProject, setSelectedProject] = useState('')

    const [moreButton, setMoreButton] = useState([
        {
          id : 1,
          name: 'Preview',
        },
        {
          id : 2,
          name: 'Use Template'
        },
    ])


    const [isMobile, setIsMobile] = useState(isMobileOrTablet());

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(isMobileOrTablet())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setItems([])
        setTotalRecords(0)
        setLoadMore(false)
        setSelectedItem({})
        setPage(1)
    }, [search, filter, sort])

    useEffect(() => {
        source.current = new AbortController();
        return () => {
            if (source.current) {
                source.current.abort()
            }
            debouncedChangeHandler.cancel();
        }
    }, []);

    const changeHandler = (event) => setSearch(event.target.value);
    const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 800), []);
    const handleSortChange = (event) => setSort(event.target.value);
    const fetchData = async () => {
        try {
            setLoader(true);
            const response = await axios.get(`template/groups/?page=${ page }&sortBy=${ sort }&filter=${ filter }&q=${ search }&records=8`);
            setLoader(false);
            if (response?.data?.data && response?.data?.success) {
                setItems(currentItems => {
                    return [...currentItems, ...response.data.data?.docs.map(project => {
                        return {
                            id: project?._id,
                            image: project?.preview_template_id?.thumbnail_url,
                            thumbnail: project?.preview_template_id?.thumbnail_preview_url,
                            // type: project?.preview_template_id?.presetDimension_id?.group,
                            // size: `${ project?.preview_template_id?.presetDimension_id?.width }x${ project?.preview_template_id?.presetDimension_id?.height }`,
                            partner: project?.partner,
                            link: '/editor',
                            title: project?.name,
                            detail: project?.preview_template_id?.description || <>&nbsp;</>,
                            preview_template_id: project?.preview_template_id?._id,
                            insituBG: project?.preview_template_id?.presetDimension_id?.insituBG,
                            position: project?.preview_template_id?.presetDimension_id?.position,
                            group: project?.preview_template_id?.presetDimension_id?.group,
                        }
                    })]
                })
                setLoadMore(response.data.data?.hasNextPage);
                setTotalRecords(response.data.data?.totalDocs);
            } else {
                console.error(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, search, filter, sort]);

    const viewTemplate = async (item) => {
        if (isMobile) return;

        try {
            if (user) {
                // setItems(items.map(project => {
                //     if (project?.id === item?.id) {
                //         return {...project, ...{loading: true}}
                //     } else {
                //         return {...project, ...{loading: null}}
                //     }
                // }));
                // await openTemplateFile(item);
                if (item?.id) {
                    setSelectedProject(item.title)
                    setSelectedId(item.id)
                    setOpenTemplateTypeDimensionModal(true);
                } else {
                    toast.error('Something went wrong');
                }
            } else {
                navigate('/login', {replace: true});
            }
        } catch (error) {
            console.error(error);
        }
    }

    const openTemplateFile = async (template) => {
        TrackGoogleAnalyticsEvent('Creative Templates', 'Use Template', `${selectedProject} : ${template?.presetDimension_id?.descriptionKey} : ${template?.presetDimension_id?.width}x${template?.presetDimension_id?.height}`)
        await openTemplate(template?.id, null, {signal: source.current.signal});
    }

    const moreAction = async (action, item) => {
        const {name} = action;
        if (name === 'Preview') {
            TrackGoogleAnalyticsEvent('Creative Templates', 'Preview Template', item.title)
            setOpenProjectPreviewModal(true)
        } else if (name === 'Edit' || name === 'Use Template') {
            await viewTemplate(item);
        } else if (name === 'Download') {
            TrackGoogleAnalyticsEvent('Creative Templates', 'Download', item.title)
            if (item?.preview_template_id && item?.image) {
                try {
                    const response = await axios.get(`/template/download/${ item?.preview_template_id }`, {
                        responseType: 'blob',
                    });
                    if (response?.data) {
                        fileDownload(response.data, item?.image.substring(item?.image.lastIndexOf('/')+1));
                    } else {
                        console.error(response);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                toast.error('Unable to Download. No template linked to group.');
            }
        } else if (name === 'Duplicate') {
        } else if (name === 'Delete') {
            setOpenDeleteConformationModal(true);
        }
    }

    const deleteProject = async (item) => {
        try {
            if (user) {
                if (item?.preview_template_id) {
                    const response = await axios.delete(`/template/${ item.preview_template_id }`);
                    if (response?.data?.success && response?.data?.data) {
                        setItems([])
                        setTotalRecords(0)
                        setLoadMore(false)
                        setSelectedItem({})
                        setPage(1)
                        fetchData()
                    }
                } else {
                    toast.error('Unable to Delete. No template linked to group.');
                }
            } else {
                navigate('/login', {replace: true});
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Helmet>
                <title>Ad Designer - Creative Templates</title>
            </Helmet>
            <PageAnimation>
                <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
                    <MainHeader />
                    <div className='flex flex-col grow'>
                        <div className="masthead mb-[30px]">
                            <img src={'/images/creative-templates/masthead_desktop.png'} alt="masthead" className="object-cover w-[100%] hidden sm:block"></img>
                            <img src={'/images/creative-templates/masthead_mobile.png'} alt="masthead" className="object-cover w-[100%] sm:hidden"></img>
                        </div>
                        <div className="container w-full px-5 mx-auto">
                            <div className="grid grid-cols-12">
                                {/* <div className="col-span-12 md:col-span-3">
                                    <ThumbnailCardFilter onSearch={debouncedChangeHandler} active={filter} onFilter={(e) => setFilter(e)} />
                                </div> */}
                                <div className="col-span-12">
                                    <div className="container mx-auto max-w-6xl mb-[50px]">
                                        <div className={`flex justify-between items-center mb-[20px] md:mx-[40px] pb-4 border-b border-[#E5E5E5]`}>
                                            <div>
                                                <span className="font-['Sofia_Pro'] text-[#555555]">{totalRecords}</span>
                                                <span className="font-['Sofia_Pro'] text-[#999999] text-sm"> results</span>
                                            </div>
                                            <div>
                                                <span className="font-['Sofia_Pro'] text-[11px] tracking-[.1em] text-[#777777] text-sm pr-1">SORT BY</span>
                                                <Select
                                                    onChange={handleSortChange}
                                                    defaultValue={sort}
                                                    displayEmpty
                                                    sx={{ m: 1, minWidth: 100 }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    className="h-[40px] !font-['Sofia_Pro'] rounded-[4px] removeBorder !mr-0"
                                                >
                                                    <MenuItem value='popular' className="!font-['Sofia_Pro']">Popular</MenuItem>
                                                    <MenuItem value='recent' className="!font-['Sofia_Pro']">Recent</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="md:mx-[40px]">
                                            <div className={`grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px]`}>
                                                {items && items.length > 0 && (
                                                    <>
                                                        {items.map(item =>
                                                            <div key={item.id}>
                                                                <ThumbnailCard item={item} more={isMobile ? null : moreButton} clicked={(e) => viewTemplate(item)} loading={item?.loading} moreClicked={(e) => {setSelectedItem(item); moreAction(e, item)}} />
                                                            </div>
                                                        )}
                                                        <DeleteConfirmationModal showModal={openDeleteConformationModal} closeModal={() => {setOpenDeleteConformationModal(false); setSelectedItem({})}} item={selectedItem} conform={() => deleteProject(selectedItem)} />
                                                    </>
                                                )}
                                                {/* <div className={`${loader ? '' : 'hidden'}`}>
                                                    {[...Array(8)].map((x, i) =>
                                                        <ThumbnailCardSkeleton key={`creative-template-${ i }`} />
                                                    )}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${loadMore ? 'flex' : 'hidden'} justify-center pb-[30px]`}>
                                        <button className='btn-load-more' onClick={() => setPage(page + 1)}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Load More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MainFooter />
                    <ProjectPreviewModal showModal={openProjectPreviewModal} closeModal={() => {setOpenProjectPreviewModal(false); setSelectedItem({})}} item={selectedItem} />
                    <TemplateTypeDimensionModal showModal={openTemplateTypeDimensionModal} closeModal={() => {setOpenTemplateTypeDimensionModal(false)}} confirmModal={(event) => openTemplateFile(event)} id={selectedId} />
                </div>
                <MCRedirectModal />
            </PageAnimation>
        </>
    )
}

export default CreativeTemplates
