import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";

import { ReactComponent as ProportionalIcon } from "../../../../../../../../assets/svg/proportional.svg";
import { ReactComponent as ProportionalLockedIcon } from "../../../../../../../../assets/svg/proportional-locked.svg";
import { ReactComponent as MagicIcon } from "../../icons/magic-wand.svg";
import { ReactComponent as RotateIcon } from "../../../../../../../../assets/svg/rotate.svg";
import { ReactComponent as FlipHorizontalIcon } from "../../../../../../../../assets/svg/flip-horizontal.svg";
import { ReactComponent as FlipVerticalIcon } from "../../../../../../../../assets/svg/flip-vertical.svg";
import { ReactComponent as RadiusX } from "assets/svg/radiusx.svg";
import { ReactComponent as RadiusXY } from "assets/svg/radiusxy.svg";
import { InputLabel, AngleCorner, FlipButton } from "./index.styled.js";
import { useEffect } from "react";
import { useStore } from "../../../hooks";
import { TOOLS_IDS } from "../../../utils/constants";
import useAuth from "utils/Auth";
import InputStandard from "../InputStandard";
import useCreative from "../../../hooks/useCreative";

export default function InputTransform({ annotation, updateAnnotation }) {
  const {
    width,
    height,
    radiusX,
    radiusY,
    radius,
    rotation,
    x,
    y,
    scaleX,
    scaleY,
  } = annotation;
  const { toolId, artboard } = useStore();
  const [dimensionLocked, setDimensionLocked] = useState({
    status: false,
    payload: {
      width: null,
      height: null,
    },
  });
  const isCreative = useCreative();

  const dimensionLockHandler = () => {
    setDimensionLocked({ ...dimensionLocked, status: !dimensionLocked.status });
  };
  // console.log("woi", annotation);

  // const handleChange = debounce((e) => {
  //   const { name: prop } = e.target
  //   console.log('test', e.target.value)

  //   let payload = {
  //     [prop]: Math.round(parseInt(e.target.value, 10))
  //   }

  //   if (prop === 'width' && dimensionLocked.status) {
  //     const ratio = width / height
  //     payload = { ...payload, height: width * ratio }
  //   }

  //   if (dimensionLocked.status) {
  //     updateAnnotation(payload);
  //     // return setDimensionLocked({ ...dimensionLocked, payload })
  //   }

  //   updateAnnotation(payload);
  // }, 500)

  const handleChange = (e) => {
    const { name: prop } = e.target;

    let payload = {
      [prop]: parseFloat(e.target.value),
    };

    if (prop === "width" && dimensionLocked.status) {
      const ratio = width / height;
      payload = { ...payload, height: width * ratio };
    }

    if (dimensionLocked.status) {
      return updateAnnotation(payload);
    }

    updateAnnotation(payload);
  };

  const flipObject = (direction) => {
    const val = annotation[`scale${direction?.toUpperCase()}`] || 1;

    updateAnnotation({
      [`scale${direction?.toUpperCase()}`]: val * -1,
    });
  };

  const [widthName, setWidthName] = useState("width");
  const [heightName, setHeightName] = useState("height");
  useEffect(() => {
    if (toolId === "Ellipse") {
      setWidthName("radiusX");
      setHeightName("radiusY");
    } else if (toolId === "Polygon") {
      setWidthName("radius");
      setHeightName("radius");
    } else {
      setWidthName("width");
      setHeightName("height");
    }
  }, [toolId]);

  return (
    <Box>
      {(toolId === "Rect" || toolId === "Image") && isCreative ? (
        <div
          title="make the object width full, but will maintain the aspect ratio"
          className="flex items-center justify-center border cursor-pointer rounded-md px-5 py-1 text-sm font-bold hover:bg-indigo-200 transition mb-4 w-ful "
          onClick={() => {
            const rect = {};
            if (annotation.name === TOOLS_IDS.IMAGE) {
              rect.width = artboard.width;
              rect.height =
                (annotation.height / annotation.width) * artboard.height;
            } else {
              rect.width = artboard.width;
              rect.height = artboard.height;
            }
            updateAnnotation({
              x: 0,
              y: 0,
              width: rect.width,
              height: rect.height,
            });
          }}
        >
          <MagicIcon style={{ width: "16px" }} className="mr-2" />
          Fit to canvas
        </div>
      ) : null}
      <div className="flex justify-between">
        <div className="inline-flex items-center">
          {toolId === "Rect" || toolId === "Image" ? (
            <div>
              <div className="flex items-center mx-1">
                <InputLabel>W</InputLabel>
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={width || radiusX || radius || 0}
                    name={widthName}
                    min={1}
                  />
                </div>
              </div>
              <div className="flex items-center mx-1">
                <InputLabel>H</InputLabel>
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={height || radiusY || radius || 0}
                    name={heightName}
                    min={1}
                    disabled={dimensionLocked.status}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {toolId === "Ellipse" ? (
            <div>
              <div className="flex items-center">
                <RadiusX />
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={width || radiusX || radius || 0}
                    name={widthName}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <RadiusX style={{ transform: "rotate(-90deg)" }} />
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={height || radiusY || radius || 0}
                    name={heightName}
                    disabled={dimensionLocked.status}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {toolId === "Polygon" ? (
            <div>
              <div className="flex items-center">
                <RadiusX />
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={width || radiusX || radius || 0}
                    name={widthName}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <RadiusX style={{ transform: "rotate(-90deg)" }} />
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={height || radiusY || radius || 0}
                    name={heightName}
                    disabled={dimensionLocked.status}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/*<div>*/}
          {/*  <AngleCorner />*/}
          {/*  {!dimensionLocked.status ? (*/}
          {/*    <ProportionalIcon*/}
          {/*      // onClick={dimensionLockHandler}*/}
          {/*      className="my-2 cursor-pointer"*/}
          {/*    />*/}
          {/*  ) : (*/}
          {/*    <ProportionalLockedIcon*/}
          {/*      // onClick={dimensionLockHandler}*/}
          {/*      className="my-2 cursor-pointer"*/}
          {/*    />*/}
          {/*  )}*/}
          {/*  <AngleCorner style={{ transform: "rotate(90deg)" }} />*/}
          {/*</div>*/}
        </div>
        <div
          className="inline-flex items-center mx-1"
          style={{
            maxWidth:
              toolId === "Line" || toolId === "Arrow" ? "128px" : "unset",
          }}
        >
          <div>
            <div>
              <div className="flex items-center">
                <InputLabel>X</InputLabel>
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={x || 0}
                    name="x"
                  />
                </div>
              </div>
              <div className="flex items-center">
                <InputLabel>Y</InputLabel>
                <div className="relative flex items-center m-2">
                  <InputStandard
                    onChange={handleChange}
                    value={y || 0}
                    name="y"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mt-2">
        <div className="relative flex items-center gap-[6px]">
          <InputLabel>
            <RotateIcon />
          </InputLabel>

          <InputStandard
            onChange={handleChange}
            value={Math.round(rotation) || 0}
            name="rotation"
            suffix="°"
            sx={{ width: "70px" }}
          />
        </div>

        <div className="inline-flex items-center mx-1 gap-2">
          <FlipButton onClick={() => flipObject("x")} active={scaleX < 0}>
            <FlipHorizontalIcon />
          </FlipButton>
          <FlipButton onClick={() => flipObject("y")} active={scaleY < 0}>
            <FlipVerticalIcon />
          </FlipButton>
        </div>
      </div>
    </Box>
  );
}
