import { Backdrop, Dialog, styled } from "@mui/material";
import Saving from "../../../assets/images/icon-saving.png";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(10px)",
});

const CustomBackdrop = (props) => {
  return <StyledBackdrop {...props}></StyledBackdrop>;
};

const ProjectSavingModal = (props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      sx={{
        ".MuiPaper-root": {
          maxWidth: "80vw",
        },
      }}
      open={Boolean(props?.showModal)}
      BackdropComponent={CustomBackdrop}
    >
      <div className="flex justify-center items-center flex-col gap-[16px] rounded-[20px] bg-[#FAFAFA] w-[200px] h-[200px] select-none">
        <img src={Saving} alt="Project Saving" className="w-[50px]" />
        <div className="font-['Sofia_Pro'] text-[16px] leading-[20px] text-[#555555]">
          {props.modalLabel  === "save" ? "Saving..." : "Downloading JPEG"}
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectSavingModal;
