/** External Dependencies */
import styled from 'styled-components';
import Label from '@scaleflex/ui/core/label';

const StyledToolsBar = styled.div`
  /* width: fit-content; */
  max-width: 100%;
  margin: 0 0px;

  & > div {
    width: 100%;

    & > div {
      width: 100%;
    }
  }

  [data-phone='true'] & {
    padding: 0;
    margin-top: 8px;
    max-height: initial;
  }
`;

const StyledToolsBarItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  [data-phone='true'] & {
    background: ${({ theme }) => theme.palette['bg-primary']};
  }
`;

const StyledToolsBarItemButton = styled.div(
  ({ theme }) => `
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    padding: 2px;
    gap: 10px;
    border-radius: 6px;

    &,
    * {
      cursor: pointer;
    }

    &:hover {
      background: ${theme.palette['bg-primary-active']};
    }

    &[aria-selected='true'] {
      background: ${theme.palette['bg-primary-active']};

      * {
        color: ${theme.palette['accent-primary-active']};
      }
    }
  `,
);

const StyledToolsBarItemButtonLabel = styled(Label)`
  margin-left: 6px;
`;

const StyledToolsBarItemOptionsWrapper = styled.div`
  position: relative;
  width: 100%;
  transition: max-height 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StyledToolsBar,
  StyledToolsBarItems,
  StyledToolsBarItemButton,
  StyledToolsBarItemButtonLabel,
  StyledToolsBarItemOptionsWrapper,
};
