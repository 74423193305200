/** Internal Dependencies */
import getArtboardToCanvasSpacing from "../utils/getArtboardToCanvasSpacing";
import getDimensionsMinimalRatio from "../utils/getDimensionsMinimalRatio";

export const SET_CANVAS_SIZE = "SET_CANVAS_SIZE";

const setCanvasSize = (state, payload) => {

  const padding = getArtboardToCanvasSpacing()
  const scale = getDimensionsMinimalRatio(
    payload.canvasWidth,
    payload.canvasHeight,
    state.artboard.width + padding,
    state.artboard.height + padding
  );

  return {
    ...state,
    initialCanvasWidth: payload.canvasWidth,
    initialCanvasHeight: payload.canvasHeight,
    canvasWidth: payload.canvasWidth,
    canvasHeight: payload.canvasHeight,
    canvasScale: scale,
  };
};

export default setCanvasSize;
