import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
// import { PictureAsPdfSharp } from '@mui/icons-material';
import PageAnimation from 'components/common/PageAnimation';
// import { Helmet } from 'react-helmet-async';
import {Helmet} from "react-helmet";
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Feature = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("features-page");
    return () => {
      document.body.classList.remove("features-page");
    };
  }, []);

  const informations = [
    {
      id: 1,
      header: 'Create professional assets with easily editable layouts',
      description: 'Customize your campaigns for Facebook, Instagram, LinkedIn, and all major digital marketing channels as much, or as little, as you want. You’re in control.',
      linkText: 'Explore Creative Templates',
      url: '/creative-templates/popular',
      image: '/images/features/feature_01.png'
    },
    {
      id: 2,
      header: 'Access a vast library of stock images at your fingertips',
      description: 'Upload an image, or easily search an extensive library of images that include photography, patterns, graphics, illustrations, and more. All carefully crafted for a wide variety of businesses.',
      link: null,
      image: '/images/features/feature_02.png'
    },
    {
      id: 3,
      header: 'Write better marketing content faster ',
      description: 'Writing effective marketing content can be tough or time-consuming. The AI-based Text Generator crafts the right message for your needs, saving you precious time so you can get on with your day.',
      link: null,
      image: '/images/features/feature_03.png'
    },
    {
      id: 4,
      header: 'Remove image backgrounds with ease',
      description: 'Remove image backgrounds in seconds. Our AI is trained to recognize subjects and erase image backgrounds automatically.',
      link: null,
      image: '/images/features/feature_04.png'
    },
  ];

  return (
    <>
      <Helmet>
        <title>Ad Designer - Features</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='grow flex flex-col'>
              <div className="masthead mb-[30px]">
                <img src={'/images/features/masthead_desktop.png'} alt="masthead" className="object-cover w-[100%] hidden sm:block"></img>
                <img src={'/images/features/masthead_mobile.png'} alt="masthead" className="object-cover w-[100%] sm:hidden"></img>
              </div>
              <div className="relative mb-[50px] px-5 md:px-0">
                <div className="container mx-auto md:w-8/12">
                  <div className="grid grid-cols-12 gap-5 sm:gap-[40px]">
                    {informations.map((item, i) => {
                        return <div key={item.id} className="flex flex-col col-span-12  md:col-span-6 border border-[#E5E5E5] rounded-[20px] p-[40px]">
                          <h3 className="font-['Sofia_Pro'] text-[30px] text-[#111111] group-hover:text-[#7B22EC] leading-[34px] mb-[16px]">{parse(item.header)}</h3>
                          <p className="flex-1 mb-[16px] font-['Sofia_Pro'] text-[#555555]" >
                            {item.description}
                            <span className="mt-[15px] flex items-center">
                              {item.linkText && <>
                                <Link className="h-full inline-block font-['Sofia_Pro'] text-[#7B22EC]" to={item?.url}>{item?.linkText}</Link>
                                <span className="inline-block pl-1 cursor-pointer"><img src="/images/features/arrow.png" alt="arrow" onClick={() => navigate(item?.url)} /></span>
                                </>
                              }
                            </span>
                          </p>
                          <img src={item.image} alt={"item.id"} className="m-auto" />
                        </div>
                    })}
                  </div>
                </div>
              </div>

            </div>
            <MainFooter />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  )
}

export default Feature
