import { ReactComponent as TimeIcon } from '../../../assets/svg/icon-time.svg';
import MorePopupButton from './MorePopupButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';

const ThumbnailCardTopImage = (props) => {
    const random = Math.floor(Date.now() / 1000)
    const [hoverState, setHoverState] = useState(false);
    const hoverStatus = (child) => {
        setHoverState(child);
    }
    const [windowSize, setWindowSize] = useState(getWindowSize())
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    useEffect(() => {
        setWindowSize(getWindowSize());
    }, []);

    return (
        <div className="h-full relative group" onMouseEnter={()=> {setHoverState(true)}} onMouseLeave={()=> {setHoverState(false)}}>
            <div className="flex flex-col h-full border border-[#E5E5E5] rounded-[20px] pb-[20px] bg-white hover:shadow-[0_4px_30px_rgba(0,0,0,0.12)] cursor-pointer" onClick={() => !(windowSize.innerWidth >= 1024) ? '' : props.clicked(props.item)} data-aos="fade-up">
                <div className="flex flex-1 w-full">
                    <div className="flex overflow-hidden h-[170px] w-full rounded-t-[20px]">
                        <img src={`${props.item.image}?date=${random}`} alt={props.item.image} className="object-cover object-center w-full m-auto h-full b-4 transition duration-200 ease-in group-hover:scale-[120%]" />
                    </div>
                </div>
                {props.item?.type && props.item?.size && (
                    <div className="flex justify-between mb-[5.5px] mt-[20px] px-[20px]">
                        <span className="font-['Sofia_Pro'] text-[10px] text-[#999999] uppercase tracking-[.12em]">{props.item.type}</span>
                        <span className="font-['Sofia_Pro'] text-[10px] text-[#999999]">{props.item.size}</span>
                    </div>
                )}
                {props.item?.title && (
                    <p className="font-['Sofia_Pro'] text-[18px] text-[#111111] px-[20px] line-clamp-1 leading-[23px] mb-2 md:mb-[5.5px]">{props.item.title}</p>
                )}
                {props.item?.detail && (
                    <p className="font-['Sofia_Pro'] text-[14px] text-[#555555] px-[20px] line-clamp-2">{props.item.detail}</p>
                )}
                {props.item?.created_at && (
                    <p className="font-['Sofia_Pro'] text-[13px] text-[#555555] px-[20px] flex flex-row justify-start items-center flex-nowrap">
                        <TimeIcon className='inline mr-[4px]' />
                        <span>{props.item.created_at}</span>
                    </p>
                )}
            </div>
            {props?.more && (
                <div className={` ${hoverState == true ? '' : 'hidden'} block absolute top-[8px] right-[8px]`}  >
                    <MorePopupButton key={props.item?.id} more={props.more} clicked={(e) => props.moreClicked(e)} setHoverState={hoverStatus}/>
                </div>
            )}
            {props?.loading && (
                <div className="absolute inset-0 w-full h-full rounded-[20px] bg-gray-700 opacity-50">
                    <div className="flex justify-center items-center h-full">
                        <FontAwesomeIcon className="animate-spin text-white text-[50px]" icon="fa-solid fa-spinner" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ThumbnailCardTopImage