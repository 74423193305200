/** Internal Dependencies */
export const DUPLICATE_ANNOTATIONS = "DUPLICATE_ANNOTATIONS";

const duplicateAnnotations = (state, payload) => {
  const { annotations } = state;
  const { newIds } = payload;

  const newAnn = {};
  newIds.forEach((o) => {
    const orig = annotations[o.baseId];

    newAnn[o.id] = {
      ...orig,
      x: orig.x + 20,
      y: orig.y + 20,
      id: o.id,
    };
  });

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    isDesignState: !payload.dismissHistory,
    annotations: {
      ...annotations,
      ...newAnn
    },
  };
};

export default duplicateAnnotations;
