import { useEffect, React } from "react";
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from 'react-helmet';

const ResetPasswordNotification = () => {
  useEffect(() => {
    document.body.classList.add("reset-password-notification-page");
    return () => {
      document.body.classList.remove("reset-password-notification-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Ad Designer - Password Reset Notification</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                
              <div className="flex p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70 md:min-h-[264px]">
                <div className="flex flex-col items-center justify-center w-full">
                  <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Check Your Email</h5>
                  <p className="font-['Sofia_Pro'] font-medium text-center text-[#555555]">We’ve sent password reset link to your email. <br /> Please check your inbox or spam folder. </p>
                </div>
              </div>
            </div>
            <MainFooter />
        </div>
      </PageAnimation>
    </>
  )
}

export default ResetPasswordNotification