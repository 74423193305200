import { useEffect, React } from "react";
import { Link } from 'react-router-dom';
import useAuth from '../../../utils/Auth';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import Button from '../../common/Button';
import PasswordInput from '../../common/PasswordInput';
import Input from '../../common/Input';
import { useFormik } from "formik";
import * as Yup from "yup";
import useMainContext from '../../../context/Main';
import PageAnimation from "components/common/PageAnimation";
import { Helmet } from 'react-helmet';
import MCRedirectModal from 'components/common/Modals/MCRedirectModal';

const Login = () => {
  const auth = useAuth();
  const {loader, setLoader} = useMainContext();
  
  const formik = useFormik({
    initialValues:{
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(8, 'Password must be at least 8 characters long').required('Password is required')
    }),
    onSubmit: (values) => {
      setLoader(true);
      let {email, password} = values;
      auth.login(email, password);
    }
  })

  useEffect(() => {
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Ad Designer - Login</title>
      </Helmet>
      <PageAnimation>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                  <form onSubmit={ formik.handleSubmit }>
                      <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Login</h5>
                      <Input type="text" id="email" name="email" label="Email" placeholder="name@domain.com" value={ formik.values.email } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.email && formik.errors.email && { error: true, errorMsg: formik.errors.email })}> </Input>
                      <PasswordInput id="password" name="password" label="Password" value={ formik.values.password } onChange={ formik.handleChange } onBlur={ formik.handleBlur } {...(formik.touched.password && formik.errors.password && { error: true, errorMsg: formik.errors.password })}> </PasswordInput>
                      <div className="flex items-start">
                          <div className="flex items-start">
                              {/* <div className="flex items-center h-5">
                                  <input id="remember" type="checkbox" value="" className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required="" />
                              </div>
                              <label htmlFor="remember" className="ml-2 text-sm font-['Sofia_Pro'] font-normal text-[#555555]">Remember me</label> */}
                          </div>
                          <Link to="/forgot-password" className="font-['Sofia_Pro'] ml-auto text-sm text-[#555555] hover:underline">Forgot Password?</Link>
                      </div>
                      <Button type="submit" color="blue" text="Log In" className="mt-[44px]" disabled={loader}></Button>
                      <div className="text-center my-[40px]">
                          <p><span className="text-[#999999] text-md font-['Sofia_Pro']">Don’t have an account?</span>  <Link to="/register" className="font-['Sofia_Pro'] text-[#555555] hover:underline">Sign up now</Link></p>
                      </div>
                  </form>
              </div>
            </div>
            <MainFooter />
        </div>
        <MCRedirectModal />
      </PageAnimation>
    </>
  )
}

export default Login