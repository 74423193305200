import React from 'react';
import { Link } from 'react-router-dom';
// import PrivacyModal from '../Modals/PrivacyModal';
// import TermsModal from '../Modals/TermsModal';

const MainFooter = () => {
    // const [openPrivacyModal, setOpenPrivacyModal] = useState(false)
    // const [openTermsModal, setOpenTermsModal] = useState(false)

    return (
        <>
            <div className='shrink-0 h-[44px] bg-[#000000] px-[28px] py-[13px] flex flex-row justify-center md:justify-between items-center'>
                <div className='h-[18px] flex items-center gap-x-[8px]'>
                    <label className="font-['Sofia_Pro'] text-[13px] leading-[18px] text-[#FFFFFF]">&#169; {new Date().getFullYear()} Ad Designer. All rights reserved.</label>
                    <div className='h-[18px] hidden md:flex justify-start items-center'>
                        <Link to="/privacy" className="font-['Sofia_Pro'] text-[13px] leading-[18px] text-[#FFFFFF] hover:opacity-50">Privacy</Link>
                        <span className="font-['Sofia_Pro'] text-[13px] leading-[18px] text-[#FFFFFF] opacity-50 px-[4px]">•</span>
                        <Link to="/terms" className="font-['Sofia_Pro'] text-[13px] leading-[18px] text-[#FFFFFF] hover:opacity-50">Terms</Link>
                    </div>
                </div>
                <div className='h-[18px] hidden md:flex items-center flex-nowrap gap-x-[8px]'>
                    <label className="font-['Sofia_Pro'] text-[13px] leading-[18px] text-[#FFFFFF]">Sponsored by Mastercard<sup>&#174;</sup></label>
                </div>
            </div>
            {/* <PrivacyModal showModal={openPrivacyModal} closeModal={() => setOpenPrivacyModal(false)} />
            <TermsModal showModal={openTermsModal} closeModal={() => setOpenTermsModal(false)} /> */}
        </>
    )
}

export default MainFooter