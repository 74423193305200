import moveArray from "../utils/moveArray";

export const SEND_FORWARD = "SEND_FORWARD";

const sendForward = (state, payload) => {
  let { annotations } = state;
  const { annotationsIds } = payload;
  if (annotationsIds.length > 1)
    return alert(
      "Multi select + object arrangement is still in development. Deselect on of the object and try again"
    );
  const selected = annotationsIds[0];
  if (!selected) return state;

  const currIndex = Object.values(annotations).findIndex(
    (item) => item.id === selected
  );

  let result = {};

  // console.log("curr", currIndex);
  // console.log("length", Object.values(annotations).length - 1);

  if (currIndex > Object.values(annotations).length - 1) {
    result = { ...annotations };
  } else {
    let newAnnotations = moveArray(
      Object.values(annotations),
      currIndex,
      currIndex + 1
    );
    // console.log("🚀 ~ file: sendForward.js ~ line 27 ~ sendForward ~ newAnnotations", newAnnotations)

    newAnnotations.forEach((item) => {
      result[item.id] = item;
    });
  }

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    isDesignState: payload.isDesignState || true,
    annotations: result,
  };
};

export default sendForward;
