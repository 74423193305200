import { styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  DUPLICATE_ANNOTATIONS,
  REMOVE_ANNOTATIONS,
  REORDER_LAYER,
  SELECT_ANNOTATION,
} from "../../../actions";
import { useStore } from "../../../hooks";
import randomId from "../../../utils/randomId";
import LayerList from "./LayerList";
import { Scroller } from "./LayerManager.styled";

import { ReactComponent as CloneIcon } from "./images/clone.svg";
import { ReactComponent as TrashIcon } from "./images/trash.svg";
import { ReactComponent as EmptyIcon } from "./images/empty-state.svg";

const ActionBtn = styled("div")((props) => ({
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  padding: 2,
  gap: 10,
  width: 20,
  height: 20,
  borderRadius: 4,
  transition: "all .1s ease",
  userSelect: "none",

  "&:hover": {
    background: "rgba(0, 0, 0, 0.05)",
    border: "0.5px solid rgba(0, 0, 0, 0.2)",
  },
}));

export default function LayerManager() {
  const { annotations, dispatch, selectionsIds } = useStore();

  const data = Object.values(annotations).reverse();

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    if (!destination) return;

    if (
      destination.droppableId.id === source.droppableId &&
      destination.index === source.index
    )
      return;

    dispatch({
      type: REORDER_LAYER,
      payload: {
        draggableId,
        source,
        destination,
      },
    });
  };

  const scrollerRef = useRef();

  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.style.maxHeight = `calc(100vh - ${scrollerRef.current?.offsetTop}px)`;
    }
  }, []);

  const removeSelectedNodes = () => {
    dispatch({
      type: REMOVE_ANNOTATIONS,
      payload: {
        annotationsIds: selectionsIds,
      },
    });
  };

  const duplicateSelectedNodes = () => {
    const newIds = selectionsIds.map((id) => {
      return {
        baseId: annotations[id].id,
        id: randomId(annotations[id].name),
      };
    });

    dispatch({
      type: DUPLICATE_ANNOTATIONS,
      payload: {
        annotationsIds: selectionsIds,
        newIds,
      },
    });

    setTimeout(() => {
      dispatch({
        type: SELECT_ANNOTATION,
        payload: {
          annotationId: newIds,
        },
      });
    });
  };

  const actions = {
    // lock: {
    //   icon: LockIcon,
    //   onClick: undefined,
    //   active: false
    // },
    // group: {
    //   icon: FolderIcon,
    //   onClick: undefined,
    // },
    clone: {
      icon: CloneIcon,
      onClick: duplicateSelectedNodes,
    },
    delete: {
      icon: TrashIcon,
      onClick: removeSelectedNodes,
    },
  };

  return (
    <div>
      {/* Layer actions */}
      <div className="border-b">
        <div className="flex items-center gap-4 ml-[20px] my-[12px]">
          <div className="text-[9px] uppercase font-medium">Tools</div>
          <div className="flex items-center gap-2">
            {Object.values(actions).map((action, i) => (
              <ActionBtn {...action} key={i}>
                <action.icon />
              </ActionBtn>
            ))}
          </div>
        </div>
      </div>

      <Scroller ref={scrollerRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="layer-droppable">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data.length ? (
                    <LayerList annotations={data} />
                  ) : (
                    <div className="text-center text-xs text-slate-600 font-['Sofia_pro'] p-4">
                      <EmptyIcon className="w-5 h-5 mx-auto" />
                      No layers
                    </div>
                  )}

                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Scroller>
    </div>
  );
}
