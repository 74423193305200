import { React } from 'react';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import { Helmet } from 'react-helmet';

const EmailVerify = () => {
    return (
      <>
        <Helmet>
            <title>Ad Designer - Verify Email</title>
        </Helmet>
        <div className='flex flex-col w-full min-h-screen max-h-[100vh]'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
              <div className="p-4 w-[90%] sm:w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Verify your email address</h5>
                <p className="font-['Sofia_Pro'] font-medium text-center text-[#555555]">We have sent a verification link to your email.<br />Please check your inbox or spam folder.</p>
              </div>
            </div>
            <MainFooter />
        </div>
      </>
    )
}

export default EmailVerify