/** External Dependencies */
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as DuplicateIcon } from "../../components/icons/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../components/icons/trash-bin.svg";

/** Internal Dependencies */
import { useDebouncedCallback, useStore } from "../../hooks";
import {
  DUPLICATE_ANNOTATIONS,
  REMOVE_ANNOTATIONS,
  SELECT_ANNOTATION,
} from "../../actions";
import {
  NODES_TRANSFORMER_ID,
  TOOLS_IDS,
  WATERMARK_ANNOTATION_ID,
} from "../../utils/constants";
import debounce from "../../utils/debounce";
import {
  IconButton,
  Separator,
  StyledNodeControls,
} from "./NodeControls.styled";

import FontStyle from "./FontStylePopup";
import ColorSet from "./ColorSet";
import ReplaceImage from "./Image/ReplaceImage";
import RemoveBackground from "./Image/RemoveBackground";
import randomId from "components/Page/Editor/react-filerobot-image-editor/src/utils/randomId";

const NodeControls = () => {
  const {
    selectionsIds = [],
    designLayer,
    annotations,
    dispatch,
    toolId,
  } = useStore();

  const [position, setPosition] = useState({ left: 0, top: 0 });

  const nodesTransformer = useMemo(
    () => designLayer?.getStage()?.findOne(`#${NODES_TRANSFORMER_ID}`),
    [designLayer]
  );
  const selectionsLength = selectionsIds.length;

  const updatePosition = debounce(() => {
    const pos = nodesTransformer?.getClientRect();

    if (!nodesTransformer) {
      return;
    }
    setPosition({
      left: pos.x + pos.width / 2,
      top: pos.y + pos.height,
    });
  }, 0);

  useEffect(() => {
    updatePosition();
  }, [selectionsIds, nodesTransformer, annotations]);

  if (selectionsLength === 0 || !nodesTransformer) return null;

  const duplicateSelectedNodes = () => {
    const newIds = selectionsIds.map((id) => {
      return {
        baseId: annotations[id].id,
        id: randomId(annotations[id].name),
      };
    });

    dispatch({
      type: DUPLICATE_ANNOTATIONS,
      payload: {
        annotationsIds: selectionsIds,
        newIds,
      },
    });

    setTimeout(() => {
      dispatch({
        type: SELECT_ANNOTATION,
        payload: {
          annotationId: newIds,
        },
      });
    });
  };

  const removeSelectedNodes = () => {
    dispatch({
      type: REMOVE_ANNOTATIONS,
      payload: {
        annotationsIds: selectionsIds,
      },
    });
  };

  return (
    <StyledNodeControls
      className="FIE_annotation-controls-overlay"
      left={position.left}
      top={position.top}
    >
      {toolId === TOOLS_IDS.TEXT && (
        <>
          <FontStyle />
          <Separator />
          {/* <ColorSet /> */}
        </>
      )}

      {toolId === TOOLS_IDS.IMAGE && (
        <>
          <RemoveBackground />
          {/* <CropImage /> */}
          <Separator />
          <ReplaceImage />
          <Separator />
        </>
      )}

      <div className="flex">
        {selectionsIds[0] !== WATERMARK_ANNOTATION_ID && (
          <IconButton onClick={duplicateSelectedNodes} style={{ padding: 2 }}>
            <DuplicateIcon />
          </IconButton>
        )}
        <div className="deleteNode">
          <IconButton onClick={removeSelectedNodes} style={{ padding: 2 }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </StyledNodeControls>
  );
};

export default NodeControls;
