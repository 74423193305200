/** External Dependencies */
import { useMemo, useCallback } from "react";

/** Internal Dependencies */
import { SET_ANNOTATION, SELECT_ANNOTATION, SELECT_TOOL } from "../actions";
import {
  TOOLS_IDS,
  TABS_IDS,
  WATERMARK_ANNOTATION_ID,
  NODES_TRANSFORMER_ID,
} from "../utils/constants";
import useStore from "./useStore";

const useAnnotationEvents = () => {
  const { tabId, dispatch } = useStore();

  const isAnnotationEventsDisabled = useMemo(
    () => tabId !== TABS_IDS.ANNOTATE && tabId !== TABS_IDS.WATERMARK,
    [tabId]
  );

  const updateAnnotation = useCallback((annotationProps) => {
    dispatch({
      type: SET_ANNOTATION,
      payload: annotationProps,
    });
  }, []);

  const updatePositionOnDragEnd = useCallback((e) => {
    updateAnnotation({
      id: e.target.id(),
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
    });
  }, []);

  const getAnnotationTransformProps = useCallback((e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const transformProps = {
      id: e.target.id(),
      rotation: e.target.rotation(),
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
      scaleX: 1,
      scaleY: 1,
    };

    if (e.target.name() === TOOLS_IDS.TEXT) {
      transformProps.width = Math.max(e.target.width() * e.target.scaleX(), 5);
      transformProps.height = Math.max(
        e.target.height() * e.target.scaleY(),
        5
      );
      transformProps.scaleX = 1;
      transformProps.scaleY = 1;
    }

    if (e.target.name() === TOOLS_IDS.RECT) {
      transformProps.width = Math.round(e.target.width() * e.target.scaleX());
      transformProps.height = Math.round(e.target.height() * e.target.scaleY());
    }

    if (e.target.name() === TOOLS_IDS.ELLIPSE) {
      transformProps.radiusX = Math.round(e.target.radiusX() * e.target.scaleX());
      transformProps.radiusY = Math.round(e.target.radiusY() * e.target.scaleY());
    }

    if (e.target.name() === TOOLS_IDS.POLYGON) {
      transformProps.radius = Math.round(e.target.radius() * e.target.scaleX());
    }

    if (e.target.name() === TOOLS_IDS.IMAGE) {
      transformProps.width = Math.round(e.target.width() * scaleX);
      transformProps.height = Math.round(e.target.height() * scaleY);
      node.scaleX(1);
      node.scaleY(1)
      node.clearCache();
    }

    if (
      e.target.name() === TOOLS_IDS.LINE ||
      e.target.name() === TOOLS_IDS.ARROW
    ) {
      transformProps.scaleX = e.target.scaleX();
      transformProps.scaleY = e.target.scaleY();
    }

    return transformProps;
  }, []);

  const updateAnnotationTransform = useCallback((e) => {
    updateAnnotation(getAnnotationTransformProps(e));
  }, []);

  const updateTextAnnotationOnTransform = useCallback((e) => {
    if (e.target.name() === TOOLS_IDS.TEXT) {
      e.target.setAttrs(getAnnotationTransformProps(e));
    }
  });

  const selectAnnotationOnClick = useCallback((e) => {
    const multiple = e.evt.ctrlKey || e.evt.shiftKey || e.evt.metaKey;

    dispatch({
      type: SELECT_ANNOTATION,
      payload: {
        annotationId: e.target.id(),
        multiple,
      },
    });
    // TODO: Remove this once we implement the possibility to select annotation
    // while any annotation tool is opened without changing the tool.

    // debounce(() => {
    dispatch({
      type: SELECT_TOOL,
      payload: {
        toolId: e.target.name(),
        keepSelections: true,
      },
    });
    // }, 0)();
  }, []);

  return useMemo(
    () =>
      isAnnotationEventsDisabled
        ? {}
        : {
            onTransform: updateTextAnnotationOnTransform,
            onTransformEnd: updateAnnotationTransform,
            onDragEnd: updatePositionOnDragEnd,
            onClick: selectAnnotationOnClick,
            onTap: selectAnnotationOnClick,
          },
    [isAnnotationEventsDisabled]
  );
};

export default useAnnotationEvents;
