import React from 'react'
import { Modal, Box } from '@mui/material'

const DeleteConfirmationModal = (props) => {
    return (
        <Modal
            componentsProps={{
                backdrop: { sx: { backdropFilter: "blur(10px)" } }
            }}
            open={props?.showModal}
            onClose={() => props.closeModal()}
        >
            <Box style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "none",
                outline: "none",
                boxShadow: 24,
                borderRadius: "20px",
                background: "rgba(255, 255, 255, 0.8)",
                overflow: "hidden",
            }}>
                <div className="text-[#111111] bg-[#DBDBDB] flex justify-center items-center px-[30px] font-['Sofia_Pro'] text-[15px] h-[40px]">Delete Project</div>
                <div className='container p-[30px] bg-[#F9F9F9]'>
                    <p className="font-['Sofia_Pro'] text-[14px] leading-[18x] text-[#555555]">Are you sure you want to delete <span className="font-['Sofia_Pro_Bold'] text-[14px] leading-[18x] text-[#555555]">{props.item.title}</span>?</p>
                    <p className="font-['Sofia_Pro'] text-[14px] leading-[18x] text-[#555555]">Once deleted they cannot be recovered.</p>
                </div>
                <div className='px-[30px] py-[8px] bg-[#FFFFFF] flex justify-between items-center'>
                    <button className="font-['Sofia_Pro'] text-[14px] text-[#888888] flex items-center" onClick={(e) => props.closeModal(e)}>Cancel</button>
                    <button className="font-['Sofia_Pro'] text-[14px] text-white bg-[#DF1E1E] py-[8px] px-[12px] h-[30px] rounded-[4px] flex items-center disabled:bg-[#BBBBBB] disabled:opacity-75" onClick={() => {props.conform(); props.closeModal()}}>Yes, Delete</button>
                </div>
            </Box>
        </Modal>
    )
}

export default DeleteConfirmationModal