export const getFontStyle = (str) => {
  str = str.split(' ')

  str.filter(item => {
    return !hasNumber(item)
  })

  str.join(' ')
}

export default function hasNumber(str) {
  return /\d/.test(str);
}