import { styled } from "@mui/material";

export const StyledAmColorPicker = styled("div")`
  /* Custom layout */
  width: 320px;
  height: unset;
  padding: 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);

  /* Custom poiners */
  .react-colorful__saturation-pointer {
    width: 15px;
    height: 15px;
    border-radius: 50px;
  }
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 5px solid #fff;
  }
  .react-colorful {
    width: unset;
    height: unset;
  }

  .react-colorful__saturation {
    margin-bottom: 1rem;
    border-radius: 8px;
    height: 238px;
    box-shadow: Cinset 0 0 0 1px rgb(0 0 0 / 10%);
    padding: 0;
  }

  .react-colorful__hue {
    margin-bottom: 1rem;
  }

  .react-colorful__hue,
  .react-colorful__alpha {
    height: 14px;
    border-radius: 5px;
  }
`;
