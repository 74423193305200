import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Button(props) {
    switch (props?.color) {
        case 'black':
            return <button {...props} className={`btn-3d-2 font-['Sofia_Pro'] flex items-center justify-center w-full text-[#111111] border border-[#111111] bg-[#FFFFFF] hover:bg-[#000000] hover:text-white font-medium rounded-lg text-sm px-5 py-[14px] text-center  ${props.disabled ? 'opacity-[0.8]' : 'opacity-1'} ${props?.className}`} disabled={props.disabled}> {props?.text} {props.disabled ? <FontAwesomeIcon className="animate-spin ml-2" icon="fa-solid fa-spinner" /> : ''}</button>;
        case 'white':
            return <button {...props} className={`btn-3d-3 font-['Sofia_Pro'] flex items-center justify-center w-full text-white border border-[#111111] bg-[#111111] hover:bg-[#ffffff] hover:text-black font-medium rounded-lg text-sm px-5 py-[14px] text-center ${props?.className}`} disabled={props.disabled}> {props?.text} {props.disabled ? <FontAwesomeIcon className="animate-spin ml-2" icon="fa-solid fa-spinner" /> : ''}</button>;
        case 'blue':
            return <button {...props} className={`btn-3d font-['Sofia_Pro'] flex items-center justify-center w-full text-white bg-[#7B22EC] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-[14px] text-center ${props.disabled ? 'opacity-[0.8]' : 'opacity-1'} ${props?.className}`} disabled={props.disabled}>{props?.text} {props.disabled ? <FontAwesomeIcon className="animate-spin ml-2" icon="fa-solid fa-spinner" /> : ''}</button>;
        default:
            return <button {...props} className={`btn-3d font-['Sofia_Pro'] flex items-center justify-center w-full text-white bg-[#7B22EC] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-[14px] text-center ${props.disabled ? 'opacity-[0.8]' : 'opacity-1'} ${props?.className}`} disabled={props.disabled}>{props?.text} {props.disabled ? <FontAwesomeIcon className="animate-spin ml-2" icon="fa-solid fa-spinner" /> : ''}</button>;
    }
}

export default Button
