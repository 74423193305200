import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useRef, useEffect } from "react";

import isMobileOrTablet from "../../../utils/isMobileOrTablet";

const MorePopupButton = (props) => {
  const popMenu = useRef(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let boxType = {};
  let innerBoxType = {};
  if (props.type === "square") {
    boxType = {
      bgcolor: "#FFFFFF",
      border: 1,
      borderColor: "#DDDDDD",
      borderRadius: 1,
      width: "28px",
      height: "28px",
      "&:hover": {
        bgcolor: "#DDDDDD",
      },
    };
    innerBoxType = {
      width: "16px",
      color: "#888888",
    };
  } else {
    boxType = {
      bgcolor: "#FFFFFF",
      border: 1,
      borderColor: "#DDDDDD",
      width: "32px",
      height: "32px",
      "&:hover": {
        bgcolor: "#EEEEEE",
      },
    };
    innerBoxType = {
      width: "24px",
      fontSize: "20px",
      color: "#757575",
    };
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    setWindowSize(getWindowSize());
  }, []);

  let more = props.more;
  if (isMobileOrTablet()) {
    more = props.more.filter((item) => item.name !== "Edit");
  }

  return (
    <PopupState variant="popover" popupId="buttons-popup-menu">
      {(popupState) => (
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <IconButton {...bindTrigger(popupState)} sx={boxType}>
            <MoreHorizIcon sx={innerBoxType} />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            className="mt-1"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClick={(e) => {
              if (!popMenu.current.contains(e.target)) {
                props.setHoverState(true);
              }
            }}
          >
            {more &&
              more.map((item) => {
                return (
                  <MenuItem
                    ref={popMenu}
                    key={item.id}
                    className={`${item.textColor} ${item.className} !font-['Sofia_Pro'] !text-sm hover:!bg-[#EEEEEE]`}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      props.clicked(item);
                      popupState.close();
                    }}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Menu>
        </div>
      )}
    </PopupState>
  );
};

export default MorePopupButton;
