import {StyledGalleryImageItem, StyledGallerySearch} from "./AssetGallery.styled";
import InfiniteScroll from "react-infinite-scroll-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Masonry} from "@mui/lab";
import {useState, useEffect, useCallback} from "react";
import {useDebounce} from "use-debounce";
import axios from "axios";
import NotFound from "./NotFound";
import debounce from 'lodash.debounce';
import { StyledSpinner } from "../../../common/Spinner/Spinner.styled";


export default function ImageTabContent({onThumbClick}) {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1)
    const [loadMore, setLoadMore] = useState(true);
    const [selected, setSelected] = useState(null);
    const [dataLength, setDataLength] = useState(0);

    const isSelected = (image) => {
        if (selected?.path) {
            return selected?.path === image?.path
        } else if (selected?.id) {
            return selected?.id === image?.id
        } else {
            return false
        }
    }


    const fetchImages = () => {
        if(loading) return;
        setLoading(true)
        axios("/image/library", {
            params: {
                q: query,
                page: page,
                per_page: 15,
            },
        })
            .then((res) => {
                if (!res?.data?.data?.total) {
                    setLoading(false);
                    setNotFound(true);
                }

                const data = [...images, ...res?.data?.data?.hits]
                setImages(data);
                setDataLength(data.length)

                setLoadMore(data.length < res?.data?.data?.total)
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchImages();
    }, [page, query]);

    const handleSearch = (e) => {
        setPage(1)
        setLoading(false)
        setImages([])
        setDataLength(0)
        setLoadMore(true)
        setNotFound(false)
        setQuery(e.target.value)
    };

    const debouncedChangeHandler = useCallback(debounce(handleSearch, 800), []);

    return (
        <>
            <StyledGallerySearch
                component={"input"}
                type="search"
                name="keyword"
                defaultValue={query}
                onChange={debouncedChangeHandler}
                placeholder="Search"
            />

            {/* Image list */}
            <div id="ImageLibraryScrollable" className="h-[200px] rounded-[5px] overflow-y-auto">
                <InfiniteScroll
                    dataLength={dataLength}
                    next={() => setPage(page + 1)}
                    hasMore={loadMore}
                    loader={<div className="w-full flex justify-center h-[24px]"><StyledSpinner size="20px" color="#333" /></div>}
                    endMessage={<NotFound text="Not Found" visibility={notFound} />}
                    scrollableTarget="ImageLibraryScrollable"
                    scrollThreshold="100px"
                    className="!overflow-hidden"
                >
                    {images && images.length > 0 && (
                        <Masonry columns="3" spacing="2" sx={{ width: "unset" }}>
                            {images.map((image) => {
                                return (
                                    <StyledGalleryImageItem
                                        selected={isSelected(image)}
                                        onClick={() => {
                                            setSelected(image);
                                            onThumbClick(image);
                                        }}
                                        key={image?.id || image?.path}
                                    >
                                        <img src={image?.preview ?? image?.previewURL} className="animate-in fade-in zoom-in" alt="" />
                                    </StyledGalleryImageItem>
                                );
                            })}
                        </Masonry>
                    )}
                </InfiniteScroll>
            </div>
        </>
    );
}
