import React, { memo, useEffect, useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import WebFont from "webfontloader";
import { areEqual, FixedSizeList as List } from "react-window";
import webSafeFonts from "../../../utils/webSafeFonts";

import InfiniteLoader from "react-window-infinite-loader";
import { Box, ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useDebounce from "../../../hooks/useDebounce";
import googleFonts from "assets/fonts/google-fonts.json";

const LOADING = 1;
const LOADED = 2;

const itemStatusMap = {};

const isItemLoaded = (index) => Boolean(itemStatusMap[index]);

const Row = memo(({ data, index, isScrolling, style }) => {
  const [fonts, onClick, currentFont, closeDropdown] = data;
  const isActive = fonts[index].family === currentFont;

  return (
    <Box
      onClick={(e) => {
        onClick(fonts[index].family);

        if (currentFont === fonts[index].family) {
          closeDropdown();
        }
      }}
      sx={{
        ...style,
        background: isActive ? "#d1c4e9" : "#fff",
        color: isActive ? "#000" : "#555",
        fontWeight: isActive ? "bold" : "normal",
        fontFamily: fonts[index].family,
        borderBottom: "1px solid #e7e7e7",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        paddingInline: "12px",
        transition: "all .3s ease",
        "&:hover": {
          background: "#e7e7e7",
          color: "#000",
        },
      }}
    >
      {fonts[index].family}

      {isActive && <CheckIcon sx={{ fill: "#000" }} />}
    </Box>
  );
}, areEqual);

const combinedFonts = () =>
  webSafeFonts
    .concat(googleFonts.items)
    .sort((a, b) => a.family.localeCompare(b.family)) || [];

export default function FontLib({ onClick, value: currentFont }) {
  const [fonts, setFonts] = useState(combinedFonts());
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const searchInputRef = useRef(null);

  const debouncedQuery = useDebounce(query, 400);

  const itemSize = 36;

  useEffect(() => {
    if (open) {
      searchInputRef.current.focus();
    }
  }, [open]);

  const loadFont = (startIndex, stopIndex) => {
    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = LOADING;
    }

    const fontsToLoad = fonts.slice(startIndex, stopIndex).map(
      (f) =>
        `${f.family}:${f.variants
          .map((v) => (v === "regular" ? "400" : v))
          .filter((v) => /^\d+$/.test(v))
          .join(",")}`
    );

    if (fontsToLoad.length) {
      WebFont.load({
        google: {
          families: fontsToLoad,
        },
        active: () => {
          for (let index = startIndex; index <= stopIndex; index++) {
            itemStatusMap[index] = LOADED;
          }
        },
      });
    }
  };

  const toggleOpen = (e) => {
    if (e.target.tagName === "INPUT") return;
    if (e.target.textContent !== currentFont) return;
    setOpen(!open);
  };

  const filterFont = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (debouncedQuery) {
      loadFont(0, itemSize);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    if (!query) return setFonts(combinedFonts());
    setFonts(
      combinedFonts()?.filter((font) => {
        return font?.family?.toLowerCase().includes(query?.toLowerCase());
      })
    );
  }, [query]);

  const closeDropdown = () => {
    setOpen(false);
  };

  const selectedFontIndex = () =>
    fonts.findIndex((font) => font.family === currentFont) || 0;

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div onClick={toggleOpen}>
        <Box
          sx={{
            height: "30px",
            // background: "blue"
            border: "1px solid #ddd",
            fontSize: "13px",
            color: "#000",
            background: "rgba(244, 244, 244, 0.6)",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            cursor: "pointer",
            position: "relative",
            width: "190px",
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "190px",
              fontFamily: currentFont,
              fontWeight: "bold",
            }}
          >
            {currentFont || "Font Family"}
          </span>
          <ArrowDropDownIcon sx={{ position: "absolute", right: 0 }} />
          {open ? (
            <Box
              sx={{
                position: "absolute",
                top: "calc(100% + 1px)",
                left: 0,
                width: 250,
                // maxHeight: 400
                background: "#fff",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                boxShadow: "0 10px 20px 0px rgba(0,0,0,.15)",
                zIndex: 3,
                opacity: 1,
                color: "#555555",
              }}
            >
              <div className="p-1">
                <input
                  defaultValue={query}
                  onChange={filterFont}
                  type="text"
                  ref={searchInputRef}
                  className="h-8 border-slate-300 rounded-md w-full text-sm"
                />
              </div>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={fonts.length}
                loadMoreItems={loadFont}
              >
                {({ onItemsRendered, ref, ...p }) => (
                  <List
                    overscanCount={10}
                    useIsScrolling
                    height={200}
                    width={"vertical"}
                    itemCount={fonts.length}
                    itemSize={itemSize}
                    itemData={[fonts, onClick, currentFont, closeDropdown]}
                    onItemsRendered={onItemsRendered}
                    initialScrollOffset={itemSize * selectedFontIndex()}
                    ref={ref}
                  >
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            </Box>
          ) : null}
        </Box>
      </div>
    </ClickAwayListener>
  );
}
