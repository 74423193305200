function moveArrayElement(array, fromIndex, toIndex) {
  if (fromIndex === toIndex) {
    return array;
  }

  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);

  return array;
}

export const REORDER_LAYER = "REORDER_LAYER";

const reorderLayer = (state, payload) => {
  let { annotations } = state;
  const { source, destination } = payload;

  const orderedArr = moveArrayElement(
    Object.values(annotations).reverse(),
    source.index,
    destination.index
  );

  return {
    ...state,
    // not stored in state, used in reducer to consider in undo/redo stacks
    isDesignState: payload.isDesignState || true,
    annotations: Object.fromEntries(
      orderedArr.reverse().map((item, index) => [item.id, item])
    ),
  };
};

export default reorderLayer;
