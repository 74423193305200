const accordionData = [
  {
    category: 1,
    title: "What is Ad Designer?",
    content:
      "Ad Designer is an online graphic design platform that allows small to mid-sized businesses to create a wide variety of visual marketing content. It features a user-friendly interface with true to form functionality that makes it easy for users to create professional-looking designs without prior design experience.",
  },
  {
    category: 1,
    title:
      "What kind of designs can I create with Ad Designer? Are there any templates available?",
    content:
      "Ad Designer allows you to create a wide variety of designs. It offers predefined templates so that users can quickly create web banners and social media graphics for Facebook, Instagram, X and LinkedIn. Ad Designer also provides a wide range of customizable templates and design elements to suit your needs.",
  },
  {
    category: 1,
    title: "Can I use Ad Designer on my phone or tablet?",
    content:
      "You may access and view Ad Designer on your phone or tablet. However, editing designs requires the use of  Ad Designer on your desktop.",
  },
  {
    category: 2,
    title: "How do I create a new design in Ad Designer?",
    content:
      "On the main menu, choose the type of design you want to create from the options displayed. You can select from three options - namely Social Ads, Banners or Custom. Alternatively, get inspired and start a design by using a template found under ‘Popular Templates’ on the main menu or within the design editor. ",
  },
  {
    category: 2,
    title: "How do I add text to my design?",
    content:
      "Select the ‘T’ tool from the toolbar on the top of the screen. Draw a text box on your design, where you want to add text, and replace the placeholder text with your desired text.  ",
  },
  {
    category: 2,
    title: "How do I adjust the size and position of an element in my design?",
    content:
      "Select the element you want to adjust. A blue outline will appear around it. Resize it by clicking and dragging the small circles on the edges or corners of the blue outline. Move it by clicking and dragging it to the required position.  ",
  },
  {
    category: 2,
    title: "How do I save my design and return to it later in Ad Designer? ",
    content:
      "Click on the ‘Save’ button located on the top right corner of the screen. ‘Asset saved successfully’ will appear on the top of the screen after your design has been saved. To return to your design, scroll down to ‘Recent Projects’. If you do not see your design under ‘Recent Projects’, click on ‘SEE ALL’ and search for the design. ",
  },
  {
    category: 2,
    title: "How do I add text to my design?",
    content:
      "Select the ‘T’ tool from the toolbar on the top of the screen. Draw a text box on your design, where you want to add text, and replace the placeholder text with your desired text.  ",
  },
  {
    category: 2,
    title: "Can I use my own images and graphics in Ad Designer?",
    content:
      "Yes. You can upload your own photos, logos, and graphics to ‘My Gallery’ and use them in your designs. Simply click on the image gallery icon at the top. A pop-up will appear, prompting you to use an image from our stock image library or to upload your own image.",
  },
  {
    category: 2,
    title: "Can I use stock images from the Ad Designer Library in my ads?",
    content: `Yes, you can. However, please adhere to the following usage rules:
    <ul>
      <li>You are not allowed to redistribute or sell any of these images on other stock media sites or channels.</li>
      <li>You are not allowed to convert any of these images into digital content, such as NFTs, for sale or redistribution.</li>
    </ul>
    `,
  },
  {
    category: 2,
    title:
      "Can I use a stock image from the Ad Designer Library as a logo for my brand and potentially label and sell merchandise with this logo?",
    content:
      "You may use stock images from the Ad Designer Library for your brand logo. However, you cannot claim any ownership over the images used - this includes registering your brand logo as a trademark. For more details, refer to our <a href='/terms'>Terms of Use</a>.",
  },
  {
    category: 2,
    title:
      "I have found images in the Ad Designer Library depicting trademarks, logos or brands. Can I use these images in my own ads?",
    content:
      "You may only use such images as-is for personal purposes, but cannot use them for commercial purposes. To use them in your ads, you are required to remove any elements that identify a particular brand or company. For more details, refer to our <a href='/terms'>Terms of Use</a>.",
  },
  {
    category: 2,
    title:
      "Can I download images from the internet, edit them on Ad Designer and use them in my own ads?",
    content:
      "Yes, you may. However, it is your responsibility to ensure that images taken from the internet are checked for copyright before using them in creative assets. It is important to verify the usage rights and obtain the necessary permissions or licenses from the copyright owner. Ad Designer does not perform copyright checks on images taken from the internet and is not liable for any copyright infringement or legal consequences resulting from the use of such images. It is recommended to use properly licensed or royalty-free images to avoid any copyright infringements.",
  },
  {
    category: 2,
    title:
      "My marketing plans have changed. How can I make changes to the details in my creative assets using Ad Designer?",
    content: `Scroll down to ‘Recent Projects’. Click on ‘SEE ALL’ and search for the creative assets you wish to edit under 'My Projects'. Click on it to access editor mode, where you can make the required changes.  `,
  },
  {
    category: 2,
    title:
      "My team ran a campaign last year and we wish to reuse the creative assets for a similar campaign this year. Can I do that in Ad Designer?",
    content:
      "Yes, you can do that by duplicating the creative assets you wish to reuse. Hover over the creative assets, click on the action button (three dots) at the top right corner of the creative, then click on 'Duplicate'. From there, you will have the option to use the creative assets as they are, or edit them for your new campaign.  ",
  },
  {
    category: 2,
    title: "Can I create GIFs directly in Ad Designer?",
    content:
      "No. Ad Designer does not have the capability to create GIFs. However, you can edit images using Ad Designer and use a third-party application to combine the images to form a GIF.",
  },
  {
    category: 2,
    title:
      "Why do I need to enter a minimum of five words into the AI-based Text Generator to get the best results?",
    content: `<div>The AI-based Text Generator is a powerful tool that can generate text on a variety of topics. However, it is important to provide the generator with enough information to generate accurate and relevant results. By entering a minimum of five words, you are providing the generator with a more solid foundation to work from. This will help to ensure that the generated text is more accurate, relevant, and coherent.</div>
      <ul>
        <li>Be as specific as possible. The more specific you are, the better the generator will be able to understand what you are looking for.</li>
        <li>Use keywords and phrases that are relevant to your topic. This will help the generator to generate text that is more focused and on-topic.</li>
        <li>Avoid using slang or jargon. The generator may not be familiar with these terms and may generate incorrect or irrelevant text.</li>
      </ul>
      `,
  },
  {
    category: 3,
    title:
      "The background of the image I’ve uploaded can’t be removed/isn’t properly removed using the ‘Remove Background’ tool. What can I do? ",
    content: `<p>Try replacing your image with another, as the 'Remove Background' tool in Ad Designer supports images of subjects clearly meant to be in the foreground such as people, products, animals, cars, and other objects.</p>
     <p>Good lighting and high contrast between the foreground and background improve results. Also, blurry and single-color backgrounds are easier to remove. Images with one or a few people work better than photos of large groups, and the whole subject should be visible in the image without being cut off.</p>
      `,
  },
  {
    category: 3,
    title:
      "I want to place certain objects on top of each other, but I can’t select some of the objects because they are placed behind another object. What can I do?",
    content: `On the right sidebar, click on the tab labeled ‘Layers’. Under this tab, all images, text and shapes used in your design are displayed as a list. You can reorder each object by dragging and dropping them in the order you want them to appear. `,
  },
  {
    category: 4,
    title: "Can I import my own creative assets and edit them on Ad Designer?",
    content:
      "Only creative assets designed in Ad Designer can be edited. Any creative assets that are designed outside Ad Designer cannot be edited using the platform tools.",
  },
  {
    category: 4,
    title: "How do I export my design on Ad Designer?",
    content:
      "On the top right of your screen, click on the button ‘Download JPG’.  ",
  },
  {
    category: 4,
    title: "What file formats can I export my designs in, on Ad Designer?",
    content:
      "Designs can be exported in JPEG only. This is a file format that you can upload on social media channels or on the ad server to use as banner ads.",
  },
  {
    category: 4,
    title:
      "Can I publish my creative assets directly on my company’s social media channels from Ad Designer?",
    content:
      "At this point, Ad Designer does not support publishing of creative assets directly to social media channels, as Ad Designer is primarily used to create and edit designs. However, you can download the creative assets designed on Ad Designer as an image file (JPEG) and share them with internal teams or media partners.",
  },
  {
    category: 5,
    title:
      "I have not received any account-related emails from Ad Designer. How can I resolve this?",
    content: `<ul>
        <li>Check if the email address you entered during account registration is correct.
        </li>
        <li>Check if the email address you entered during account registration is correct.
        </li>
        <li>Refresh your inbox as delays in receiving emails may sometimes occur.</li>
        <li>Initiate a resend of the emails. You can initiate these requests on Ad Designer.</li>
        <li>Check with your company’s IT department or email settings to verify if emails from Ad Designer are blocked. Depending on your company’s security settings, access to emails originating from an external organization may be restricted.</li>
      </ul>`,
  },
  {
    category: 5,
    title: "How can I delete my account completely?",
    content:
      "Please drop us an email at <a href='mailto:info@theaddesigner.com'>info@theaddesigner.com</a> with your request. Before proceeding with your request for account deletion, we will perform the necessary checks to confirm that the account belongs to you.",
  },
  {
    category: 5,
    title:
      "Previously, I sent in a request to delete my account. Can I restore access to my account if I want to continue using Ad Designer?",
    content:
      "Unfortunately, we are unable to retrieve deleted accounts. All account deletions are final. However, you may create a new account using the same email address as your deleted account.",
  },
  {
    category: 5,
    title: "Can I change my email address after signing up for an account?",
    content:
      "No, unfortunately, once you have signed up for an account, you cannot change the email address associated with it. This is because the email address is used as a unique identifier for your account and changing it could potentially cause issues with your account information and access. We recommend that you double-check your email address before submitting it during the sign-up process to ensure that it is accurate and up-to-date.",
  },
  {
    category: 5,
    title: "Can I collaborate with others on a design in Ad Designer?",
    content:
      "No. Ad Designer is designed for single-user access only. Only one person can work on a design at a time. However, Ad Designer allows you to easily save and export your designs, so you can easily share them with others for viewing or editing outside of the software.",
  },
  {
    category: 5,
    title: "I am facing an issue on Ad Designer. How can I report the issue?",
    content:
      "Please email us at <a href='mailto:info@theaddesigner.com'>info@theaddesigner.com</a> . We will address your issue and are happy to receive feedback to improve our services.",
  },
];
export default accordionData;
