import { React, useState, useEffect } from 'react';
import MainHeader from '../../common/Header';
import MainFooter from '../../common/Footer';
import axios from 'axios';
import useMainContext from "../../../context/Main";
import getQueryParams from "../../../utils/getQueryParams";
import { Helmet } from 'react-helmet';

const EmailVerified = () => {
    const {token} = getQueryParams();
    const {loader, setLoader} = useMainContext();
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [verifyFailedText, setVerifyFailedText] = useState('');

    function verifyingMessage() {
        return (
            <div className="p-4 w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Verifying Email Address...</h5>
            </div>
        )
    }
    function verifiedMessage() {
        return (
            <div className="p-4 w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Email address verified successfully</h5>
                <p className="font-['Sofia_Pro'] font-medium text-center text-[#555555]">You may now login and use Ad Designer.</p>
            </div>
        )
    }
    function verifyFailedMessage() {
        return (
            <div className="p-4 w-full max-w-xl bg-white rounded-[24px] border border-[#555555] shadow-md sm:p-6 md:p-[44px] bg-[#FFFFFF]/70">
                <h5 className="text-4xl font-['Sofia_Pro'] font-medium text-center text-[#111111] mb-[30px]">Email address Failed to verify</h5>
                <p className="font-['Sofia_Pro'] font-medium text-center text-[#555555]">{verifyFailedText}</p>
            </div>
        )
    }

    useEffect(  () => {
        const verifyEmail = async () => {
            setLoader(true);

            const response = await axios.get(`/user/verify/${token}`)
            if (response?.data?.success) {
                setVerifySuccess(true)
                setLoader(false);
            } else {
                console.error(response);
                setVerifyFailedText(response.message);
                setLoader(false);
            }
        }

        verifyEmail();
    }, []);

    return (
        <>
        <Helmet>
            <title>Ad Designer - Verify Email ({verifySuccess ? 'Completed' : 'Failed'})</title>
        </Helmet>
        <div className='flex flex-col w-full min-h-screen'>
            <MainHeader />
            <div className='bg-cover bg-bottom sm:bg-center bg-[url("../../assets/images/backgrounds/bg-mobile.png")] sm:bg-[url("../../assets/images/backgrounds/bg.jpeg")] grow flex flex-col justify-center items-center'>
                {
                    loader ? verifyingMessage() : verifySuccess ? verifiedMessage() : verifyFailedMessage()
                }
            </div>
            <MainFooter />
        </div>
        </>
    )
}

export default EmailVerified