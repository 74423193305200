import React, { useMemo, useState } from "react";
import { RgbaStringColorPicker } from "react-colorful";

import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import { StyledAmColorPicker } from "./AmColorPicker.styled";
extend([namesPlugin]);

const AmColorPicker = ({ color, id, ...rest }) => {
  const rgbaString = useMemo(() => {
    return color.startsWith("rgba") ? color : colord(color).toRgbString();
  }, [color]);

  const rgb = useMemo(() => {
    return colord(color).toRgb();
  }, [color]);

  const hex = useMemo(() => {
    return colord(color).toHex();
  }, [color]);

  const [hexValue, setHexValue] = useState(hex);

  const alpha = useMemo(() => {
    return colord(color).alpha();
  }, [color]);
  // console.log(rest);

  return (
    <StyledAmColorPicker className="am-color-picker" id={id}>
      <RgbaStringColorPicker color={rgbaString} {...rest} />
      <div className="flex gap-[12px] flex-wrap max-w-full mt-4">
        <div className="bg-red flex-1 text-sm text-slate-800">
          Hex
          <div className="flex">
            <input
              type="text"
              value={hexValue || 0}
              className="border-slate-300 px-2 py-1 rounded-md flex-1 text-sm uppercase"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  rest.onChange(hexValue);
                }
              }}
              onChange={(e) => {
                setHexValue(e.target.value);
              }}
            />
            {/* <input
              type="text"
              value={alpha * 100}
              // className="border flex-[50px] flex-1"
            /> */}
          </div>
        </div>

        {/* <div className="flex gap-1 flex-1">
          <label>
            R
            <input type="number" className="flex-1 border" value={rgb.r} />
          </label>
          <label>
            G
            <input type="number" className="flex-1 border" value={rgb.g} />
          </label>

          <label>
            B
            <input type="number" className="flex-1 border" value={rgb.b} />
          </label>
        </div> */}
      </div>
    </StyledAmColorPicker>
  );
};

export default AmColorPicker;
