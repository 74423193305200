/** External Dependencies */
import React from "react";
import PropTypes from "prop-types";

/** Internal Dependencies */
import restrictNumber from "../../../utils/restrictNumber";
import {
  StyledSpacedOptionFields,
  StyledLabel,
} from "./AnnotationOptions.styled";
import Slider from "../Slider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { AMInput, InputUnit } from "../AMTransform/index.styled";
import { useStore } from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import InputStandard from "../InputStandard";

const MIN_PERCENTANGE = 0;
const MAX_PERCENTANGE = 10;

const Input = styled(MuiInput)`
  width: 48px;
  padding: 5px;
  color: #555;
  &::before {
    display: none;
  }
  &::after {
    content: "%";
    color: #aaa;
    transform: scaleX(1);
    right: 0;
    position: relative;
    border-width: 0;
    padding-bottom: 0px;
  }
`;

const BlurField = ({ annotation, updateAnnotation, t, title }) => {
  const { blurRadius } = annotation;
  const { dispatch, selectionsIds = [] } = useStore();

  const changeBlurriness = (newBlurriness) => {
    // console.log('new blur', newBlurriness)
    updateAnnotation({
      blurRadius: newBlurriness,
      MIN_PERCENTANGE,
      MAX_PERCENTANGE,
    });
    // dispatch({
    //   type: 'SET_FILTER',
    //   payload: {
    //     selected: selectionsIds[0],
    //     filter: {
    //       name: 'blurRadius',
    //       value: newBlurriness
    //     }
    //   }
    // })
  };

  const changeBlurInput = (newBlurriness) => {
    if (!isNaN(newBlurriness.target.value)) {
      updateAnnotation({
        blurRadius: restrictNumber(newBlurriness.target.value),
      });
    } else {
      return false;
    }
  };

  // const handleInputChange = (newBlurriness) => {
  //   setValue(event.target.value === '' ? '' : Number(event.target.value));
  // };

  return (
    <StyledSpacedOptionFields>
      <Grid container sx={{ marginTop: "-6px", flexWrap: "nowrap" }}>
        <Grid item className="w-full" sx={{ marginRight: "18px" }}>
          <StyledLabel
            sx={{
              color: "#777",
              fontSize: "12px",
              fontFamily: "MarkForMCNrw",
              paddingLeft: "5px",
              marginBottom: "3px",
            }}
          >
            {title}
          </StyledLabel>
          <Slider
            annotation="%"
            onChange={changeBlurriness}
            min={0}
            max={10}
            step={0.1}
            value={blurRadius}
          />
        </Grid>
        <Grid item className="flex items-end">
          <InputStandard
            sx={{ width: "70px" }}
            suffix="%"
            onChange={changeBlurInput}
            value={blurRadius * 10}
            disabled
            readOnly
          />
        </Grid>
      </Grid>
    </StyledSpacedOptionFields>
  );
};

BlurField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default BlurField;
