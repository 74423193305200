import { ClickAwayListener, Grow, Popper } from "@mui/material";
import React, { useState } from "react";
import AmColorPicker from "../AmColorPicker";
import { checkContrast } from "./colorUtils";
import { PalleteColorStyle, PalleteNoColorStyle } from "./PalletePicker.style";

export default React.memo(function PalletePicker(props) {
  const [pallete, setPallete] = useState(
    props?.pallete?.length
      ? props.pallete
      : ["#3B63EC", "#2C3990", "#FF7FA6", "#FFBC60"]
  );

  const [activeColor, setActive] = useState(() =>
    props.pallete.findIndex((c) =>
      props.color?.toLowerCase().includes(c?.toLowerCase())
    )
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popper" : undefined;

  const togglePicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClick = (color, index) => {
    setActive(index);
    props.onChange(color);
  };

  const closePicker = (e) => {
    if (!e.target.closest(".am-color-picker")) {
      setAnchorEl(null);
    }
  };


  return (
    <div className="flex gap-[6px] items-center flex-nowrap h-[26px]">
      {pallete.length &&
        pallete.map((palleteColor, index) => {
          return (
            <PalleteColorStyle
              key={index}
              onClick={() => handleClick(palleteColor, index)}
              active={activeColor === index ? 1 : 0}
              background={palleteColor}
              withBorder={checkContrast("#ffffff", palleteColor)}
            />
          );
        })}

      <PalleteNoColorStyle
        active={activeColor === "no-bg" ? 1 : 0}
        onClick={() => {
          if (typeof props.onChange === "function") {
            setActive("no-bg");
            props.onChange("#00000000");
          }
        }}
      />
      <ClickAwayListener
        onClickAway={closePicker}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Popper
          id={id}
          anchorEl={anchorEl}
          open={open}
          // placement="top-start"
          // disablePortal
        >
          <Grow in={open}>
            <div>
              <AmColorPicker
                color={props.color}
                onChange={(newColor) => {
                  props.onChange(newColor);

                  if (typeof activeColor === "number") {
                    setPallete(
                      pallete.map((p, i) => {
                        if (i === activeColor) return newColor;
                        return p;
                      })
                    );

                    props.onPalleteAssign(activeColor, newColor);
                  }
                }}
              />
            </div>
          </Grow>
        </Popper>
      </ClickAwayListener>

      <PalleteColorStyle
        background={"#ffffff"}
        withBorder={checkContrast("#ffffff", "#ffffff")}
        onClick={togglePicker}
      >
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.34375 1.99219V8.69531"
            stroke="#777777"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.69531 5.34375H1.99219"
            stroke="#777777"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PalleteColorStyle>
    </div>
  );
});
