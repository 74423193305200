import { ReactComponent as ReplaceIcon } from "../../icons/replace-image.svg";
import { UPDATE_STATE } from "../../../actions";
import { useStore } from "components/Page/Editor/react-filerobot-image-editor/src/hooks";
import { IconButton } from "../NodeControls.styled";

export default function ReplaceImage() {
  const { dispatch } = useStore();

  const triggerUploadInput = () => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        imageGallery: {
          isActive: true,
          replaceMode: true,
        },
      },
    });
  };

  return (
    <div>
      <IconButton onClick={triggerUploadInput}>
        <ReplaceIcon />
        Replace
      </IconButton>
    </div>
  );
}
