import ErrorModal from "../components/common/Modals/ErrorModal";
import React, {useState, useEffect} from "react";
import axios from "axios";
import useMainContext from "../context/Main";
import { ContactlessOutlined } from "@mui/icons-material";
import SendVerificationBtn from "components/common/Modals/actionButtons/SendVerificationBtn";
import RedirectToLoginBtn from "components/common/Modals/actionButtons/RedirectToLoginBtn";


const ExceptionHandler = () => {
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [errorModalRedirectPath, setErrorModalRedirectPath] = useState(null);
    const { setErrorModalController } = useMainContext();

    useEffect(() => {
        setErrorModalController({
            showErrorModal,
            setShowErrorModal,
            errorMessage,
            setErrorMessage,
            errorModalRedirectPath,
            setErrorModalRedirectPath,
        })
    }, [])

    axios.interceptors.response.use(response => {
            return response;
        },
        error => {
            if(error.response) {
                if (error.response?.status === 401 && ((typeof error.response?.data?.message === 'string' && error.response?.data?.message === 'Unauthenticated.') || (typeof error.response?.data?.data?.message === 'string' && error.response?.data?.data?.message === 'Unauthenticated.'))) {
                    // navigate('/login', {replace: true});
                    axios.defaults.headers.common['Authorization'] = '';
                    localStorage.removeItem('user');
                    localStorage.removeItem('project');
                    setShowErrorModal(true);
                    setErrorModalRedirectPath('/login')
                    setErrorMessage({title: 'Logged Out', message: 'Token expired. Please try login again.'});
                } else if (typeof error.response?.data?.message === 'string') {
                    if (error.response?.status === 403 && error.response?.data?.message === 'Forbidden') {
                        setErrorMessage({title: 'Unauthorized access', message: 'This user does not have access rights to the content.'});
                    } else {
                        setErrorMessage({title: 'Failed', message: error.response?.data?.message});
                    }
                    setShowErrorModal(true);
                } else if (typeof error.response?.data?.data === 'string') {
                    setShowErrorModal(true);
                    setErrorMessage({title: 'Failed', message: error.response?.data?.data});
                } else if (typeof error.response?.data?.data === 'object') {
                    setShowErrorModal(true);

                    if (error.response?.data?.data?.status === 2) {
                        setErrorMessage({
                            title: 'Failed',
                            message: error.response?.data?.message,
                            data: error.response?.data?.data,
                            actionBtn:  error.response?.status === 401 ? SendVerificationBtn : RedirectToLoginBtn
                        });
                    } else {
                        setErrorMessage({
                            title: 'Failed',
                            message: JSON.stringify(error.response?.data?.data),
                        });
                    }
                } else {
                    setShowErrorModal(true);
                    if (error.response.data.error.confirmNewPassword) {
                        setErrorMessage({title: 'Error', message: error.response?.data?.error.confirmNewPassword});
                    } else {
                        setErrorMessage({title: 'Error', message: 'Something went wrong'});
                    }
                }
            } else {
                if (error?.message === 'canceled' || error?.name === 'CanceledError') {
                    console.error('Pending request cancelled.')
                } else {
                    setShowErrorModal(true);
                    setErrorMessage({title: 'Oops!', message: 'Something went wrong'});
                }
            }
            return Promise.reject(error);
        });

    function onErrorModalClose() {
        setShowErrorModal(false);
        setErrorMessage({});

        if (errorModalRedirectPath) {
            window.location.pathname = errorModalRedirectPath;
            setErrorModalRedirectPath(null);
        }
    }


    return (
        <>
            {showErrorModal && (<ErrorModal showModal={showErrorModal} closeModal={onErrorModalClose} error={errorMessage} />)}
        </>
    )
}

export default ExceptionHandler;
