import { ClickAwayListener } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  CLEAR_ANNOTATIONS_SELECTIONS,
  SELECT_ANNOTATION,
  SELECT_TOOL,
  SET_LAYER,
} from "../../../actions";
import { useStore } from "../../../hooks";
import { StyledLayerItem } from "./LayerManager.styled";

import { ReactComponent as EyeIcon } from "./images/eye.svg";
import { ReactComponent as CheckboxIcon } from "./images/empty-checkbox.svg";
import drag from "./images/drag.png";

const LayerItem = ({ layer, Icon, isDragging }) => {
  const { dispatch, selectionsIds } = useStore();

  const [layerName, setLayerName] = useState({
    isEditing: false,
  });
  const inputRef = useRef();

  useEffect(() => {
    if (layerName.isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [layerName.isEditing, inputRef]);

  const renameLayer = ({ id, name }) => {
    dispatch({
      type: SET_LAYER,
      payload: {
        targetId: id,
        layerName: name,
      },
    });
  };

  const toggleVisibility = (e) => {
    e.stopPropagation();
    dispatch({
      type: SET_LAYER,
      payload: {
        targetId: layer.id,
        visible: !Boolean(layer.visible),
      },
    });

    dispatch({
      type: CLEAR_ANNOTATIONS_SELECTIONS,
    });
  };

  return (
    <StyledLayerItem
      onClick={() => {
        dispatch({
          type: SELECT_ANNOTATION,
          payload: {
            annotationId: layer.id,
          },
        });

        dispatch({
          type: SELECT_TOOL,
          payload: {
            toolId: layer.name,
            keepSelections: true,
          },
        });
      }}
      active={selectionsIds.includes(layer.id)}
      isDragging={isDragging}
    >
      <div className="basis-[38px] grow-0 flex items-center justify-center bg-[rgba(0,0,0,0.06)] h-full">
        <div
          className="hover:bg-slate-100 p-1 transition cursor-pointer rounded-md"
          onClick={toggleVisibility}
        >
          {layer.visible ? <EyeIcon /> : <CheckboxIcon />}
        </div>
      </div>
      <div className="flex items-center gap-[12px] flex-1">
        <Icon />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 10,
          }}
          onDoubleClick={(e) => {
            setLayerName({ ...layerName, id: layer.id, isEditing: true });
          }}
        >
          {layerName.isEditing ? (
            <ClickAwayListener
              onClickAway={() => {
                setLayerName({
                  ...layerName,
                  id: layer.id,
                  isEditing: false,
                });
              }}
            >
              <input
                className="h-[10px] border-none px-1 text-[12px]"
                defaultValue={layer.layerName}
                type="text"
                maxLength={25}
                ref={inputRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Escape") {
                    if (!e.target.value) {
                      renameLayer({ id: layer.id, name: "No name" });
                    }

                    return setLayerName({
                      ...layerName,
                      isEditing: false,
                    });
                  }
                }}
                onKeyUp={(e) => {
                  renameLayer({ id: layer.id, name: e.target.value });
                }}
              />
            </ClickAwayListener>
          ) : (
            layer?.layerName ?? layer.name
          )}
          <img
            src={drag}
            alt="dragger"
            style={{ width: 16, height: 16, alignSelf: "flex-end" }}
          />
        </div>
      </div>
    </StyledLayerItem>
  );
};

export default LayerItem;
