/** External Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Label from '@scaleflex/ui/core/label';
import Konva from 'konva';


/** Internal Dependencies */
import restrictNumber from '../../../utils/restrictNumber';
import { StyledSpacedOptionFields, StyledLabel } from './AnnotationOptions.styled';
import Slider from '../Slider';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import {AMInput, InputUnit} from "../AMTransform/index.styled";
import {TOOLS_IDS} from "../../../utils/constants";
import InputStandard from '../InputStandard';

const MIN_PERCENTANGE = 0;
const MAX_PERCENTANGE = 1;

const Input = styled(MuiInput)`
  width: 48px;
  padding: 5px;
  color: #555;
  &::before{
    display: none;
  };
  &::after {
    content: '%';
    color: #aaa;
    transform: scaleX(1);
    right: 0;
    position: relative;
    border-width: 0;
    padding-bottom: 0px;
  }
`;

const OpacityField = ({ annotation, updateAnnotation, t, title }) => {
  const { opacity } = annotation;

  const changeOpacity = (newOpactiy) => {
    updateAnnotation({
      opacity: restrictNumber(
        newOpactiy / 100,
        MIN_PERCENTANGE,
        MAX_PERCENTANGE,
      )
    });
  };

  const changeOpacityInput = (newOpactiy) => {
    if ( !isNaN(newOpactiy.target.value)  ){
      updateAnnotation({
        opacity: restrictNumber(
          newOpactiy.target.value / 100,
          MIN_PERCENTANGE,
          MAX_PERCENTANGE,
        ),
      });
    }else{
      // console.log('is not a number')
      return false
    }
  };

  // const handleInputChange = (newOpactiy) => {
  //   setValue(event.target.value === '' ? '' : Number(event.target.value));
  // };

  return (
    <StyledSpacedOptionFields>
      <Grid container sx={{marginTop: '-6px', flexWrap: 'nowrap',}}>
        <Grid item className='w-full' sx={{marginRight: '18px'}}>
          <StyledLabel sx={{
            color: '#777',
            fontSize: '12px',
            fontFamily: 'MarkForMCNrw',
            marginBottom: '3px',
          }}>{title}</StyledLabel>
          <Slider
            annotation="%"
            onChange={changeOpacity}
            value={Math.round(opacity * 100)}
          />
        </Grid>
        <Grid item className='flex items-end'>
          <InputStandard
            sx={{ width: "70px"}}
            suffix="%"
            min={0}
            max={100}
            onChange={changeOpacityInput}
            value={Math.round(opacity * 100)}
          />
        </Grid>
      </Grid>


    </StyledSpacedOptionFields>
  );
};

OpacityField.propTypes = {
  annotation: PropTypes.instanceOf(Object).isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default OpacityField;
