/** External Dependencies */
import styled from "styled-components";
import Select from "@mui/material/Select";

const StyledSelect = styled(Select)({
  ".MuiInput-input": {
    "&:focus": {
      background: "transparent",
    },
  },
});

const StrokeItem = styled.div`
  font-size: 12px;
  font-family: "MarkForMCNrw";
`;

export { StrokeItem, StyledSelect };
