/** External Dependencies */
import { useState, useEffect } from "react";
import { ReactComponent as ImageIcon } from "../../../../../../../../assets/svg/icon-img.svg";
import Box from "@mui/material/Box";
import axios from "axios";

/** Internal Dependencies */
import ToolsBarItemButton from "../../ToolsBar/ToolsBarItemButton";
import { TOOLS_IDS } from "../../../utils/constants";
import { UPDATE_STATE } from "../../../actions";
import { useAnnotation, useStore } from "../../../hooks";

import {
  StyledCancelBtn,
  StyledDialogFooter,
  StyledMainBtn,
  StyledUploadTitle,
} from "./Dialog/Dialog.styled";

import Dialog from "./Dialog";
import AssetGallery from "./AssetGallery";
import ImageUpload from "./ImageUpload";
import useImportImages from "components/Page/Editor/react-filerobot-image-editor/src/hooks/useImportImage";
import {TrackGoogleAnalyticsEvent} from "../../../../../../../../utils/GA4";

const ImageButton = ({ selectTool, isSelected }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [uploaded, setUploaded] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [userNewUploadImages, setUserNewUploadImages] = useState([]);
  const [skeletonAmount, setSkeletonAmount] = useState([]);
  const { dispatch, config, imageGallery } = useStore();
  let imageLibrary = true;

  const [image] = useAnnotation(
    {
      name: TOOLS_IDS.IMAGE,
      opacity: 1,
    },
    false
  );
  const { constructImage, loading } = useImportImages({
    replace: imageGallery.replaceMode,
  });
  const handleClose = () => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        imageGallery: {
          isActive: false,
          replaceMode: false,
        },
      },
    });
    setSelectedImage([]);
  };

  const handleThumbClick = async (image) => {
    setSelectedImage(image);
    // Multiple selection
    // if (selectedImages.find(f => f.path === file.path)) {
    //   setSelectedImages(selectedImages.filter(f => {
    //     return f.path !== file.path
    //   }))
    // } else {
    //   setSelectedImages([...selectedImages, file])
    // }
  };

  const handleImportImage = async () => {
    TrackGoogleAnalyticsEvent('Inside Editor', 'Upload Image', activeTab === 0 ? 'Library' : 'My Gallery')
    setIsFetching(true);
    try {
      constructImage(selectedImage?.largeImageURL || selectedImage.image_url);
      setIsFetching(false);
      dispatch({
        type: UPDATE_STATE,
        payload: {
          imageGallery: {
            isActive: false,
            replaceMode: false,
          },
        },
      });
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles.length <= 0) {
      return;
    }

    setActiveTab(1);
    setSkeletonAmount((prevState) => prevState + acceptedFiles.length);

    const promises = acceptedFiles.map(async (file) => {
      const data = new FormData();
      data.append("image", file, file.name);

      const res = await axios.post("/image/my-library", data);
      return res?.data?.data;
    });

    Promise.all(promises).then((newImages) => {
      setSkeletonAmount((prevState) => prevState - acceptedFiles.length);
      setUserNewUploadImages(newImages);
    });
  };

  const handleClick = (e) => {
    setSelectedImage(null);

    dispatch({
      type: UPDATE_STATE,
      payload: {
        imageGallery: {
          isActive: true,
        },
      },
    });
    selectTool(TOOLS_IDS.IMAGE, e);
  };

  // useEffect(() => {
  //   Object.assign(image, config[TOOLS_IDS.IMAGE]);
  // }, [isFetching]);

  const renderButtonText = () => {
    let text = "";
    if (loading || isFetching) {
      text = "Importing";
    } else {
      text = imageGallery?.replaceMode ? "Replace" : "Import";
    }

    return text;
  };

  return (
    <>
      <ToolsBarItemButton
        className="FIE_image-tool-button tour__upload-image"
        id={TOOLS_IDS.IMAGE}
        Icon={ImageIcon}
        onClick={handleClick}
        isSelected={isSelected}
      />

      <Dialog
        open={imageGallery.isActive}
        onClose={handleClose}
        dialogTitle={
          imageGallery?.replaceMode ? "Replace selected image" : "Upload Image"
        }
      >
        <Box sx={{ p: 3 }}>
          <StyledUploadTitle variant="h6" component="h2">
            Upload File
          </StyledUploadTitle>

          <ImageUpload onDrop={handleDrop} />

          <AssetGallery
            onThumbClick={handleThumbClick}
            uploaded={uploaded}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            userNewUploadImages={userNewUploadImages}
            skeletonAmount={skeletonAmount}
          />
        </Box>

        {/* Dialog footer */}
        <StyledDialogFooter>
          <StyledCancelBtn
            variant="text"
            onClick={() =>
              dispatch({
                type: UPDATE_STATE,
                payload: {
                  imageGallery: {
                    isActive: false,
                    replaceMode: false,
                  },
                },
              })
            }
          >
            Cancel
          </StyledCancelBtn>

          <StyledMainBtn
            loading={loading || isFetching}
            // loadingPosition="end"
            disabled={loading || isFetching || !selectedImage}
        // endIcon=""
            onClick={handleImportImage}
          >
            {renderButtonText()}
          </StyledMainBtn>
        </StyledDialogFooter>
      </Dialog>
    </>
  );
};

export default ImageButton;
