import {Box, Button} from "@mui/material";
import React from "react";
import useMainContext from "../../../context/Main";

const PopoverModal = () => {
  const {setOpenHeadlineGeneratorModal} = useMainContext();
  const {setAnchorEl} = useMainContext();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box style={{
      width: '480px',

      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      borderRadius: "20px",
      background: "transparent",
    }}>
      <div className="text-[#111111] bg-[#DBDBDB] px-[28px] flex items-center font-['Sofia_Pro'] text-[15px] h-[40px]">
        <p>Tool Tip: Text Generator</p>
      </div>
      <div className='p-[28px] bg-[#FFFFFF]'>
        <p className="text-[18px] text-[#111111] mb-[8px]">Writer’s block? Let AI inspire you.</p>
        <p>The Wording Generator uses artificial intelligence to create a variety of wording options that better communicate your marketing intentions.</p>
      </div>
      {/* <div className='px-[28px] py-[8px] bg-[#F9F9F9] flex justify-end items-center'>
        <Button variant="outlined" style={{textTransform: 'none'}} className="!font-['Sofia_Pro'] border !border-[#7B22EC] !text-[14px] py-[8px] px-[12px] h-[30px]"><span className="text-[#7B22EC]"
        onClick={(e)=>{
          e.stopPropagation();
          setOpenHeadlineGeneratorModal(true);
          handleClose();
        }}
        >Try now</span></Button>
      </div> */}
    </Box>
  )
}

export default PopoverModal;