import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputAdornment, InputBase } from "@mui/material";

const InputStandard = ({ suffix, value, width, ...props }) => {
  const [displayValue, setDisplayValue] = useState(value || "");

  const inputRef = useRef();
  useEffect(() => {
    // if (value !== displayValue) {
    if (value < props.min) {
      setDisplayValue(props.min);
    } else {
      setDisplayValue(value);
    }
    // }
  }, [value]);

  // const selectValue = useCallback(() => {
  //   if (value < props.min) {
  //     inputRef?.current?.focus();
  //     inputRef?.current?.setSelectionRange(0, inputRef?.current?.value?.length);
  //   }
  // }, [value]);

  const handleFocus = useCallback(() => {
    inputRef?.current?.setSelectionRange(0, inputRef?.current?.value?.length);
  }, []);

  // useEffect(() => {
  //   selectValue();
  // }, [value]);

  const onCommitValue = (e) => {
    const min = props.min;
    if (e.key === "Enter" || e.type === "blur") {
      if (
        e.target.value === undefined ||
        e.target.value === null ||
        e.target.value === "" ||
        !/^[0-9.,-]+$/.test(e.target.value)
      )
        return setDisplayValue(value);

      // if (typeof e.target.value === "string") {
      //   setDisplayValue(value);
      // }
      // console.log(min);
      // console.log("value", e.target.value);
      // console.log("check", min && e.target.value < min);

      if (min && e.target.value < min) {
        e.target.value = min;
      }

      // console.log('new e value', e.target.value)

      if (props.onChange && typeof props.onChange === "function") {
        props?.onChange(e);
      }
    }
  };
  return (
    <InputBase
      sx={{
        padding: "0 4px",
        "&.Mui-disabled ": {
          opacity: 0.7,
          background: "#e8e8e8",
          "&:hover": {
            opacity: ".7 !important",
            background: "#e8e8e8",
          },
        },
      }}
      inputRef={inputRef}
      size="small"
      endAdornment={
        suffix !== "" ? (
          <InputAdornment
            position="end"
            sx={{
              marginLeft: "4px",
              "& span": {
                padding: 0,
              },
            }}
          >
            {suffix ?? "px"}
          </InputAdornment>
        ) : null
      }
      {...props}
      type="text"
      value={displayValue}
      // value={value}
      // onKeyDown={selectValue}
      onKeyDown={onCommitValue}
      onBlur={onCommitValue}
      onFocus={handleFocus}
      onClick={handleFocus}
      onChange={(e) => {
        setDisplayValue(e.target.value);
        // props.onChange(e);
      }}
    />
  );
};

export default InputStandard;
