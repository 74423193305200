/** External Dependencies */
import React, { useEffect, useMemo } from "react";
import { Transformer } from "react-konva";

/** Internal Dependencies */
import {
  NODES_TRANSFORMER_ID,
  POINTER_ICONS,
  TOOLS_IDS,
} from "../../../utils/constants";
import { useStore } from "../../../hooks";
import {
  CHANGE_POINTER_ICON,
  ENABLE_TEXT_CONTENT_EDIT,
} from "../../../actions";

const NodesTransformer = () => {
  const {
    selectionsIds = [],
    theme,
    designLayer,
    dispatch,
    config: { useCloudimage },
    toolId,
  } = useStore();

  const selections = useMemo(
    () =>
      designLayer?.findOne
        ? selectionsIds
            .map((selectionId) => designLayer.findOne(`#${selectionId}`))
            .filter(Boolean)
        : [],
    [selectionsIds]
  );

  const changePointerIconToMove = () => {
    dispatch({
      type: CHANGE_POINTER_ICON,
      payload: {
        pointerCssIcon: POINTER_ICONS.MOVE,
      },
    });
  };

  const changePointerIconToDraw = () => {
    dispatch({
      type: CHANGE_POINTER_ICON,
      payload: {
        pointerCssIcon: POINTER_ICONS.DRAW,
      },
    });
  };

  const enableTextContentChangeOnDblClick = () => {
    if (selections.length === 1 && selections[0].name() === TOOLS_IDS.TEXT) {
      dispatch({
        type: ENABLE_TEXT_CONTENT_EDIT,
        payload: {
          textIdOfEditableContent: selections[0].id(),
        },
      });
    }
  };

  const enabledAnchors =
    toolId === "Polygon"
      ? ["top-left", "top-right", "bottom-left", "bottom-right"]
      : undefined;

  // ALT is used to center scaling
  // SHIFT is used to scaling with keeping ratio
  return (
    <Transformer
      id={NODES_TRANSFORMER_ID}
      useSingleNodeRotation={true}
      enabledAnchors={enabledAnchors}
      // centeredScaling={true}
      rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
      nodes={selections}
      rotateAnchorOffset={30}
      anchorSize={10}
      anchorCornerRadius={7}
      padding={3}
      ignoreStroke={toolId !== "Line"}
      anchorStroke={"#0C8CE9"}
      anchorFill={"#ffffff"}
      anchorStrokeWidth={2}
      borderStroke={"#0C8CE9"}
      borderStrokeWidth={2}
      // borderDash={[2]}
      rotateEnabled={!useCloudimage}
      onMouseOver={changePointerIconToMove}
      onMouseLeave={changePointerIconToDraw}
      onClick={enableTextContentChangeOnDblClick}
      onTap={enableTextContentChangeOnDblClick}
      flipEnabled={false}
      shouldOverdrawWholeArea
    />
  );
};

export default NodesTransformer;
