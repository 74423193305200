import { ReactComponent as TimeIcon } from '../../../assets/svg/icon-time.svg';
import MorePopupButton from './MorePopupButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useEffect, useState} from "react";

import isMobileOrTablet from '../../../utils/isMobileOrTablet';

const ThumbnailCard = (props) => {
    const random = Math.floor(Date.now() / 1000)
    const [hoverState, setHoverState] = useState(false);
    const hoverStatus = (child) => {
      setHoverState(child);
    }
  const [windowSize, setWindowSize] = useState(getWindowSize())
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  useEffect(() => {
    setWindowSize(getWindowSize());
  }, []);

    return (
        <div className="relative h-full group" onMouseEnter={()=> {setHoverState(true)}} onMouseLeave={()=> {setHoverState(false)}}>
            <div className="relative flex flex-col h-full border border-[#E5E5E5] rounded-[20px] p-[20px] hover:shadow-[0_4px_30px_rgba(0,0,0,0.12)] cursor-pointer" onClick={() => isMobileOrTablet() ? '' : props.clicked(props.item)} data-aos="fade-up">
                {props?.item?.partner ? <div className='w-full h-[28px] text-center text-white bg-[#7B22EC] rounded-t-xl flex items-center justify-center text-[12px] font-["Sofia_Pro"]'>Inspired by {props?.item?.partner}</div> : ''}
                <div className={`flex flex-1 m-auto pb-[38px] ${props?.item?.partner ? 'pt-4' : ''}`}>
                    <div className="flex overflow-hidden h-[150px]">
                        {props.item.image && (<img src={`${props.item.image}?date=${random}`} alt={props.item.image}
                              className="object-cover m-auto h-full b-4 transition duration-200 ease-in group-hover:scale-[120%]"/>)}
                        {!props.item.image && (
                            <div className="flex items-center m-auto h-full b-4 text-center border-[1px] border-[#ff0000]">
                                To release Templates, at least need a 1080x1080 Social Ads or a 300x250 Banner
                            </div>
                        )}
                    </div>
                </div>
                {props.item?.type && props.item?.size && (
                    <div className="flex justify-between mb-4 mt-[20px]">
                        <span className="font-['Sofia_Pro'] text-[10px] text-[#999999] uppercase tracking-[.12em]">{props.item.type}</span>
                        <span className="font-['Sofia_Pro'] text-[10px] text-[#999999]">{props.item.size}</span>
                    </div>
                )}
                {props.item?.title && (
                    <p className="font-['Sofia_Pro'] text-[18px] text-[#111111] line-clamp-1 leading-[23px] mb-2 md:mb-1">{props.item.title}</p>
                )}
                {props.item?.detail && (
                    <p className="font-['Sofia_Pro'] text-[14px] text-[#555555] line-clamp-2">{props.item.detail}</p>
                )}
                {props.item?.created_at && (
                    <p className="font-['Sofia_Pro'] text-[13px] text-[#555555] flex flex-row justify-start items-center flex-nowrap">
                        <TimeIcon className='inline mr-[4px]' />
                        {props.item.created_at}
                    </p>
                )}
            </div>
            {props?.more && (
                <div className={` ${hoverState == true ? '' : 'hidden'} group-hover:block absolute top-[8px] right-[8px]`}>
                    <MorePopupButton key={props.item?.id} more={props.more} clicked={(e) => props.moreClicked(e)} />
                </div>
            )}
            {props?.loading && (
                <div className="absolute inset-0 w-full h-full rounded-[20px] bg-gray-700 opacity-50">
                    <div className="flex items-center justify-center h-full">
                        <FontAwesomeIcon className="animate-spin text-white text-[50px]" icon="fa-solid fa-spinner" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ThumbnailCard