// import {ReactComponent as IconError} from "../../assets/svg/error.svg";
import React from 'react';
import { TourProvider } from '@reactour/tour'
import TourWrapper from "./TourWrapper";
import { TourBreakLine } from "./Tour.styled";
import TourBodyText from "./TourBodyText";
import TourBodyImageText from "./TourBodyImageText";
import { ReactComponent as ShapesIcon } from '../../../../assets/svg/icon-shape.svg';
import { ReactComponent as TextIcon } from '../../../../assets/svg/icon-text.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/svg/icon-img.svg';

const Tour = ({children}) => {
    // Tour guide schema
    const steps = [
        {
            selector: '.tour__dimension',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title='Select a format'
                                description='Select from a range of Social Media formats that best suit your marketing needs.'
                            />
                            {/*<TourBreakLine />*/}
                            {/*<TourBodyImageText*/}
                            {/*    image={require('../../assets/images/tour/DimensionTour.png')}*/}
                            {/*    category='Social Media Marketing'*/}
                            {/*    title='Social Media Image Sizes for Every Network'*/}
                            {/*/>*/}
                        </>}
                        onButtonClicked={() => (obj.setCurrentStep(obj.currentStep + 1))}
                        buttonText='Next'
                    />
                )
            },
            position: 'right',
        },
        {
            selector: '.tour__templates',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title='Select a template'
                                description='Choose a template that best suits your needs as a starting point, or design from scratch. For first time users, we recommend starting from a template as you familiarise yourself with the Ad Designer.'
                            />
                            {/*<TourBreakLine />*/}
                            {/*<TourBodyImageText*/}
                            {/*    image={require('../../assets/images/tour/DimensionTour.png')}*/}
                            {/*    category='Social Media Marketing'*/}
                            {/*    title='Social Media Image Sizes for Every Network'*/}
                            {/*/>*/}
                        </>}
                        onButtonClicked={() => (obj.setCurrentStep(obj.currentStep + 1))}
                        buttonText='Next'
                    />
                )
            },
        },
        {
            selector: '.tour__shapes',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title={<div className='inline-flex align-middle'>
                                    <div className='mr-[4px]'><ShapesIcon /></div> <div className='relative'>Draw Shapes</div>
                                </div>}
                                description='The Draw Shapes tool provides a variety of shapes and styles to enhance your campaigns and assets.'
                            />
                        </>}
                        onButtonClicked={() => (obj.setCurrentStep(obj.currentStep + 1))}
                        buttonText='Next'
                    />
                )
            },
        },
        {
            selector: '.tour__text',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title={<div className='inline-flex align-middle'>
                                    <div className='mr-[4px]'><TextIcon /></div> <div className='relative'>Add Text</div>
                                </div>}
                                description='Create copy using the AI-powered Headline Generator, or lend your voice to your craft.'
                            />
                        </>}
                        onButtonClicked={() => (obj.setCurrentStep(obj.currentStep + 1))}
                        buttonText='Next'
                    />
                )
            },
        },
        {
            selector: '.tour__upload-image',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title={<div className='inline-flex items-center'>
                                    <div className='mr-[4px]'><ImageIcon /></div> <div className='relative'>Insert Image</div>
                                </div>}
                                description='Upload or choose from a variety of images ranging from photographs to illustrations.'
                            />
                            {/*<TourBreakLine />*/}
                            {/*<TourBodyImageText*/}
                            {/*    image={require('../../assets/images/tour/ImageButtonTour.png')}*/}
                            {/*    category='Digital Advertising'*/}
                            {/*    title='Product Photography 101: The Guide for Small Business Owners'*/}
                            {/*/>*/}
                        </>}
                        onButtonClicked={() => (obj.setCurrentStep(obj.currentStep + 1))}
                        buttonText='Next'
                    />
                )
            },
        },
        {
            selector: '.tour__action-buttons',
            content: (obj) => {
                return (
                    <TourWrapper
                        width='480px'
                        tourObj={obj}
                        body={<>
                            <TourBodyText
                                title='Design Completed'
                                description={<span>Once you are done designing, you may choose to <strong>Download</strong>, <strong>Preview</strong> and/or <strong>Save</strong>.</span>}
                            />
                        </>}
                        onButtonClicked={() => (obj.setIsOpen(false))}
                        buttonText='Ok, got it.'
                        translate='translate(-140px, 0px)'
                    />
                )
            },
        },
    ]

    return (
        <TourProvider steps={steps} disableInteraction styles={{
            popover: (base) => ({...base, padding: 0, color: 'rgba(255, 255, 255, 0.8)', boxShadow: 0, borderRadius: 20}),
            maskWrapper: (base) => ({ ...base, color: '#ffffff', backdropFilter: 'blur(20px)'}),
            close: (base) => ({ ...base, display: 'none' }),
            controls: (base) => ({ ...base, display: 'none' }),
            badge: (base) => ({ ...base, display: 'none' }),
        }}>
            {children}
        </TourProvider>
    )
}

export default Tour;