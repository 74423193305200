import { styled, Tab, Tabs, Typography } from "@mui/material";

export const StyledAssetGalleryOuter = styled("div")({
  background: "rgba(255, 255, 255, 0.6)",
  borderRadius: "4px",
});

export const StyledGalleryTab = styled("div")({
  height: "36px",
  borderBottom: "1px solid #DDDDDD",
  padding: "0 18px",
  display: "flex",
  alignItems: "center",
});

export const StyledGalleryTabItem = styled(Typography)({
  borderRadius: "15px",
  display: "inline-flex",
  padding: "3px 8px",
  fontSize: "10px",
  textTransform: "uppercase",
  fontWeight: "bold",
  color: "#BBBBBB",

  "&.active": {
    background: "#EAEAEA",
    borderRadius: "15px",
    display: "inline-flex",
    padding: "3px 8px",
    fontSize: "10px",
    textTransform: "uppercase",
    color: "#222222",
    fontWeight: "bold",
  },
});

export const StyledGallerySearch = styled("input")({
  padding: "10px 12px",
  background: "rgba(255, 255, 255, 0.9)",
  border: "1px solid #DDDDDD",
  borderRadius: "4px",
  marginBottom: '16px',
  width: "100%",
  height: "30px",
  fontSize: "13px",
  outline: "none",
});

export const StyledGalleryImageItem = styled("div")(({ selected }) => ({
  // overflow: 'hidden',
  position: "relative",
  cursor: "pointer",
  maxWidth: "100%",
  borderRadius: "5px",
  boxSizing: "border-box",

  img: {
    margin: "2px",
    borderRadius: "5px",
    display: "inline-block",
    outline: selected ? "2px solid #0B9454" : "none",
    width: "100%",
    height: "100%",
  },
}));

export const StyledImageGrid = styled("div")({
  display: "grid",
  gap: "10px",
  gridTemplateColumns: "repeat(3, 1fr)",
  marginTop: "16px",
  maxHeight: "229px",
  overflowY: "auto",
  gridTemplateRows: "masonry",
});

export const StyledTabs = styled(Tabs)({
  height: "36px",
  borderBottom: "1px solid #DDDDDD",
  padding: "0 18px",
  display: "flex",
  alignItems: "center",
  minHeight: 0,

  ".MuiTabs-indicator": {
    display: "none",
  },
});

export const StyledTab = styled(Tab)({
  fontSize: "10px",
  letterSpacing: "0.1em",
  borderRadius: "15px",
  height: "unset",
  minHeight: "unset !important",
  display: "inline-flex",
  padding: "3px 8px",
  textTransform: "uppercase",
  fontWeight: "bold",
  color: "#BBBBBB",
  minWidth: "unset",

  "&.Mui-selected": {
    background: "#EAEAEA",
    borderRadius: "15px",
    display: "inline-flex",
    padding: "3px 8px",
    fontSize: "10px",
    textTransform: "uppercase",
    color: "#222222 !important",
    fontWeight: "bold",
  },
});

export const ImageSkeletonContainer = styled("div")(({ loading }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "500px",
  display: loading ? "flex" : "none",
  zIndex: 9,
  background: "#f4f4f4",
}));

export const StyledNotFoundContainer = styled('div')({
  minHeight: '200px',
  fontSize: 12,
  textAlign: 'center',
  opacity: '.6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

export const ImageListContainer = styled('div')({
  overflowY: "auto",
  overflowX: "hidden",
  height: '100%',
})