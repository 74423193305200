/** External Dependencies */
import styled from "styled-components";
import Label from "@scaleflex/ui/core/label";

const StyledToolsBar = styled.div`
  width: 100%;
  width: fit-content;
  /* margin: 0 auto; */

  [data-phone="true"] & {
    padding: 0;
    margin-top: 8px;
    max-height: initial;
  }
`;

const StyledToolbarGroupItems = styled.div`
  &:before {
    content: "";
    position: absolute;
    top: -4px;
    height: 4px;
    background: transparent;
    left: 0;
    width: 100%;
  }
  position: absolute;
  top: 100%;
  margin-top: 4px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background: white;
  min-width: 34px;
  z-index: 10;
  padding: 8px 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  & > * {
    width: 100%;
    padding: 5px 2px;
  }

  circle,
  rect,
  path {
    stroke: #555 !important;
  }
`;

const StyledToolsBarItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 15px;

  [data-phone="true"] & {
    background: ${({ theme }) => theme.palette["bg-primary"]};
  }
`;

const StyledToolsBarItemButton = styled.div(
  ({ theme, grey }) => `
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 6px;
    transition: all .3s ease;
    width: 26px;
    height: 26px;

    path,
    rect,
    circle {
      stroke: #555555;
      transition: all .3s ease;
    }

    &:hover,
    &[aria-selected='true'] {
      path,
      rect,
      circle {
        stroke: #FF5F00
      }
    }

    &,
    * {
      cursor: pointer;
    }

    &:hover {
      background: ${grey ? "#F2F2F2" : theme.palette["bg-primary-active"]};
    }

    &[aria-selected='true'] {
      background: ${theme.palette["bg-primary-active"]};

      * {
        color: ${theme.palette["accent-primary-active"]};
      }
    }
  `
);

const StyledToolsBarItemButtonLabel = styled(Label)`
  margin-left: 6px;
`;

const StyledToolsBarItemOptionsWrapper = styled.div`
  position: relative;
  width: 100%;
  transition: max-height 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledToolbarGroup = styled(StyledToolsBarItemButton)({
  position: "relative",
  "&:hover": {
    "& > div": {
      display: "flex",
    },
  },
});
export {
  StyledToolsBar,
  StyledToolsBarItems,
  StyledToolsBarItemButton,
  StyledToolsBarItemButtonLabel,
  StyledToolsBarItemOptionsWrapper,
  StyledToolbarGroup,
  StyledToolbarGroupItems,
};
