import { Backdrop, Dialog, styled } from "@mui/material";
import { ReactComponent as PreviewIcon } from "../../../assets/svg/icon-preview.svg";
import React, { useEffect, useCallback } from "react";

const random = Math.floor(Date.now() / 1000)
const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(10px)",
});

const CustomBackdrop = (props) => {
  return <StyledBackdrop {...props}></StyledBackdrop>;
};

const ProjectPreviewModal = (props) => {
  const pos = props?.item?.position;

  useEffect(() => {
    let top = 0;
    setTimeout(() => {
      const modal = document.querySelector("#preview-modal .MuiPaper-root");
      if (props?.item?.group === "Banner") {
        switch (props?.item?.position) {
          case "b300x250":
            top = 766 - 80;
            break;
          case "b336x280":
            top = 1676 - 80;
            break;

          case "b150x600":
            top = 1056 - 80;
            break;

          case "b300x600":
            top = 0;
            break;

          case "b970x250":
            top = 1676 - 80;
            break;
          default:
            break;
        }
      }

      modal?.scrollTo({
        top: top,
        behavior: "smooth",
      });
    }, 300);
  });

  const mapKeyboardKeys = useCallback((event) => {
    const { keyCode, key } = event;
    if (keyCode === 8 || keyCode === 46 || keyCode === 27 || key === "Escape") {
      if (document.querySelectorAll(".justify-end button").length > 0) {
        document
          .querySelectorAll(".justify-end button")
          .forEach((el) => el.click());
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", mapKeyboardKeys);
    return () => {
      window.removeEventListener("keyup", mapKeyboardKeys);
    };
  }, [mapKeyboardKeys]);

  const renderInsitu = () => {
    if (props?.item?.position && props?.item?.insituBG) {
      return (
        <div className={`relative`} id={`wrapper-${pos}`}>
          <img
            src={`/images/insitu/${props?.item?.group.toLowerCase()}/${props?.item?.insituBG.replace('.png', '.svg')}`}
            alt="insitu"
            className="insitu-bg"
          />
          <img
            src={props?.item?.image}
            alt=""
            className={`${pos} absolute ${
              pos === "" ? "top-0 left-0 right-0" : ""
            }`}
          />
          {/* <img src={props?.item?.image} alt="" className="w-full h-auto max-h-[85vh] overflow-hidden object-contain" /> */}
        </div>
      );
    } else {
      return <img src={`${props?.item?.image}`} alt="" />;
    }
  };

  return (
    <Dialog
      id="preview-modal"
      sx={{
        ".MuiPaper-root": {
          maxWidth: "unset",
          maxHeight: "80vh",
          borderRadius: 0,
        },
      }}
      open={props?.showModal}
      BackdropComponent={CustomBackdrop}
    >
      <div className="fixed w-full h-[44px] left-0 top-0 bg-[#111111] px-[18px] select-none">
        <div className="hidden lg:grid grid-cols-3 gap-4">
          <div className="w-full"></div>
          <div className="w-full h-[44px] flex justify-center items-center">
            <h1 className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#FFFFFF] line-clamp-1">
              {props?.item?.title}
            </h1>
          </div>
          <div className="w-full flex justify-end items-center">
            <button
              className="flex justify-center items-center border border-solid border-[#FFFFFF80] hover:border-[#FFFFFF] rounded-[4px] py-[1px] px-[12px] font-['Sofia_Pro'] text-[14px] text-[#FFFFFF]"
              onClick={() => props.closeModal()}
            >
              <PreviewIcon className="inline mr-[4px] stroke-svg-white" />
              Exit Preview
            </button>
          </div>
        </div>
        <div className="h-[44px] lg:hidden flex justify-between items-center gap-4">
          <h1 className="font-['Sofia_Pro'] text-[14px] leading-[18px] text-[#FFFFFF] line-clamp-1">
            {props?.item?.title}
          </h1>
          <button
            className="border border-solid border-[#FFFFFF80] hover:border-[#FFFFFF] rounded-[4px] py-[1px] px-[12px] font-['Sofia_Pro'] text-[14px] text-[#FFFFFF] shrink-0"
            onClick={() => props.closeModal()}
          >
            <PreviewIcon className="inline mr-[4px] stroke-svg-white" />
            Exit
          </button>
        </div>
      </div>

      {/* Insitu / frame */}
      {renderInsitu()}
    </Dialog>
  );
};

export default ProjectPreviewModal;
