import { useDropzone } from "react-dropzone";
import { StyledDropzoneWrapper } from "../Dialog/Dialog.styled";
import { Container } from "./ImageUpload.styled";
import { ReactComponent as ImageIcon } from "../../../../../../../../../assets/svg/icon-img.svg";
import useMainContext from "../../../../../../../../../context/Main";

export default function ImageUpload({ onDrop }) {
  const { errorModalController } = useMainContext();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
      onDrop,
      maxFiles: 5,
      maxSize: 3145728, // bytes
      onDropRejected: (err) => {
        let modalTitle = "Image Upload Failed";
        let modalMessage =
          "One or more images size over 3MB limit OR amount images upload over limit of 5. Else was invalid image type upload.";
        let isTooManyFiles = false;
        const tooLargeFileNames = [];

        err.forEach((e) => {
          if (isTooManyFiles) return;

          if (e.errors[0].code === "too-many-files") {
            isTooManyFiles = true;
            modalTitle = "Image Upload Failed - Over Files Limit";
            modalMessage = "Files uploaded more than 5 limit amount";
          }

          if (e.errors[0].code === "file-too-large") {
            tooLargeFileNames.push(e.file.name);
          }
        });

        if (!isTooManyFiles && tooLargeFileNames.length > 0) {
          modalTitle = "Image Upload Failed - File/Files Too Large";
          modalMessage = `Files larger than 3MB: ${tooLargeFileNames.join(
            ", "
          )}`;
        }

        errorModalController.setErrorMessage({
          title: modalTitle,
          message: modalMessage,
        });
        errorModalController.setShowErrorModal(true);
      },
    });

  return (
    <>
      {
        <StyledDropzoneWrapper className="container cursor-pointer">
          <Container
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <ImageIcon style={{ opacity: 0.4, marginBottom: ".5rem" }} />
            <input {...getInputProps({ name: "dropzone", id: "dropzone" })} />
            <p>
              Drop your image here or{" "}
              <span className="font-['Sofia_Pro'] text-[15px] text-[#7B22EC] underline">
                browse
              </span>
            </p>
            <small>(jpg or png below 3mb; Max 5 Files.)</small>
          </Container>
        </StyledDropzoneWrapper>
      }
    </>
  );
}
